import {Component, inject} from '@angular/core';
import {DIALOG_DATA} from "@angular/cdk/dialog";

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent {
  data = inject(DIALOG_DATA);
  
}
