import { Component } from '@angular/core';

@Component({
  selector: 'app-app-launch-header',
  templateUrl: './app-launch-header.component.html',
  styleUrls: ['./app-launch-header.component.scss']
})
export class AppLaunchHeaderComponent {

}
