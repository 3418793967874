import {Component} from '@angular/core';
import {AntiCorruptionService} from "../service/anti-corruption.service";

@Component({
  selector: 'app-anti-corruption',
  templateUrl: './anti-corruption.component.html',
  styleUrls: ['./anti-corruption.component.scss']
})
export class AntiCorruptionComponent {
  constructor(private antiCorruptionService: AntiCorruptionService) {
  }

  getAntiCorruptionEnglishPdf() {
    this.antiCorruptionService.getAntiCorruptionEnglishPdf();
  }

  getAntiCorruptionChinesePdf() {
    this.antiCorruptionService.getAntiCorruptionChinesePdf();
  }

  getAntiCorruptionDutchPdf() {
    this.antiCorruptionService.getAntiCorruptionDutchPdf();
  }

  getAntiCorruptionFrenchPdf() {
    this.antiCorruptionService.getAntiCorruptionFrenchPdf();
  }

  getAntiCorruptionGermanPdf() {
    this.antiCorruptionService.getAntiCorruptionGermanPdf();
  }

  getAntiCorruptionPortuguesePdf() {
    this.antiCorruptionService.getAntiCorruptionPortuguesePdf();
  }

  getAntiCorruptionRussianPdf() {
    this.antiCorruptionService.getAntiCorruptionRussianPdf();
  }

  getAntiCorruptionSpanishPdf() {
    this.antiCorruptionService.getAntiCorruptionSpanishPdf();
  }

  getAntiCorruptionRomanianPdf() {
    this.antiCorruptionService.getAntiCorruptionRomanianPdf();
  }

  getAntiCorruptionThaiPdf() {
    this.antiCorruptionService.getAntiCorruptionThaiPdf();
  }

  getAntiCorruptionVietnamesePdf() {
    this.antiCorruptionService.getAntiCorruptionVietnamesePdf();
  }
}
