<app-common-header></app-common-header>
<div class="row">
  <div class="page-title">
    <b>Parent Code Search :</b>
  </div>
</div>
<form [formGroup]="gsdbForm" (ngSubmit)="onSubmit()">
  <div class="mt-2 ml-2 container">
    <div>
      <p class="parent-code">ParentCode</p>
      <input type="text" class="input pl-2 pr-2" formControlName="parentCode"/>
      <div *ngIf="gsdbForm.get('parentCode')!.invalid && gsdbForm.get('parentCode')!.touched">
        <small class="warning" *ngIf="gsdbForm.get('parentCode')?.errors?.['required']">Parent code is required.</small>
        <small
          class="warning"
          *ngIf="gsdbForm.get('parentCode')?.errors?.['maxlength'] || gsdbForm.get('parentCode')?.errors?.['minlength']">Enter
          valid parent code.</small>
      </div>
    </div>
    <button type="submit" class="block mt-2 xml-button" [disabled]="gsdbForm.invalid">Display XML</button>
  </div>
</form>
