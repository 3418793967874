import {Component} from '@angular/core';
import {TermsService} from "../service/terms.service";

@Component({
  selector: 'app-indirect-terms',
  templateUrl: './indirect-terms.component.html',
  styleUrls: ['./indirect-terms.component.scss']
})
export class IndirectTermsComponent {
  constructor(private termsService: TermsService) {
  }

  getGtcTermsEnglishPdf() {
    this.termsService.getGtcTermsEnglishPdf();
  }

  getGtcTermsChinesePdf() {
    this.termsService.getGtcTermsChinesePdf();
  }

  getGtcTermsDeutschPdf() {
    this.termsService.getGtcTermsDeutschPdf();
  }

  getGtcTermsDutchPdf() {
    this.termsService.getGtcTermsDutchPdf();
  }

  getGtcTermsHungarianPdf() {
    this.termsService.getGtcTermsHungarianPdf();
  }

  getGtcTermsSpanishPdf() {
    this.termsService.getGtcTermsSpanishPdf();
  }

  getGtcTermsPolishPdf() {
    this.termsService.getGtcTermsPolishPdf();
  }

  getGtcTermsCzechPdf() {
    this.termsService.getGtcTermsCzechPdf();
  }

  getGtcTermsRussianPdf() {
    this.termsService.getGtcTermsRussianPdf();
  }

  getGtcTermsItalianPdf() {
    this.termsService.getGtcTermsItalianPdf();
  }

  getGtcTermsPortuguesePdf() {
    this.termsService.getGtcTermsPortuguesePdf();
  }

  getGtcTermsFinnishPdf() {
    this.termsService.getGtcTermsFinnishPdf();
  }

  getGtcTermsGreekPdf() {
    this.termsService.getGtcTermsGreekPdf();
  }

  getGtcTermsDanishPdf() {
    this.termsService.getGtcTermsDanishPdf();
  }

  getGtcTermsNorwegianPdf() {
    this.termsService.getGtcTermsNorwegianPdf();
  }

  getGtcTermsRomanianPdf() {
    this.termsService.getGtcTermsRomanianPdf();
  }

  getGtcTermsSwedishPdf() {
    this.termsService.getGtcTermsSwedishPdf();
  }

  getGtcTermsThaiPdf() {
    this.termsService.getGtcTermsThaiPdf();
  }

  getGtcTermsKoreanPdf() {
    this.termsService.getGtcTermsKoreanPdf();
  }

}
