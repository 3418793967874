import { Component } from '@angular/core';

@Component({
  selector: 'app-user-hierarchy-home',
  templateUrl: './user-hierarchy-home.component.html',
  styleUrls: ['./user-hierarchy-home.component.scss']
})
export class UserHierarchyHomeComponent {

}
