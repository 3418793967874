import {Component} from '@angular/core';
import {IndirectService} from "../service/indirect.service";
import {map} from "rxjs";

@Component({
  selector: 'app-indirect-left-sidebar',
  templateUrl: './indirect-left-sidebar.component.html',
  styleUrls: ['./indirect-left-sidebar.component.scss']
})
export class IndirectLeftSidebarComponent {

  constructor(private indirectService: IndirectService) {
  }

  showPiiSupplementPdf(): void {
    this.indirectService.getPiiSupplementPdf();
  }

  getInfoSecuritySupplementPdf() {
    this.indirectService.getInfoSecuritySupplementPdf();
  }

  getUkPurchasingTaxPdf() {
    this.indirectService.getUkPurchasingTaxPdf();
  }

  getSupplierTravelExpensePolicyPdf() {
    this.indirectService.getSupplierTravelExpensePolicyPdf();
  }

  getMarketingServicesSupplementDoc() {
    this.indirectService.getMarketingServicesSupplementDoc()
  }
}
