<app-common-header></app-common-header>
<div class="container-fluid">
    <div class="row">
        <div class="page-title" [hidden]="formDisabled">
            <b>Ford Supplier Portal -- Registration :: Step 1of 2 :: Identify CSA</b>
        </div>
        <div class="page-title" [hidden]="!formDisabled">
            <b>Ford Supplier Portal -- Registration :: Admin Search Results</b>
        </div>
    </div>

    <div class="row" [hidden]="formDisabled">
        <div class="col-9">
            <div class="row">
                   <div class="font1"><br>
                    <p><b>Welcome
			            to Ford Supplier Portal (FSP) Registration!</b> <br>
            			<span class="font-size">First, we will need to determine if your company
			            is currently registered on FSP. <br>Please provide the requested information
			            to determine if your company is registered.</span></p>
                   </div>
            </div>
            <div class="row font1">
                <div>
                    <b class="font2">
                        Your Global Supplier Database (GSDB)
                        Site or Parent Code
                    </b><br>
                       <span class="font-size"> All FSP Suppliers must be members of Ford Motor Company's Global Supplier
                        Database (GSDB).<br>Companies have a 4-character GSDB Parent Code,
                        and different locations within a company<br> have 5-character site
                        codes. If you don't know your Parent/Site code, you can obtain
                        it from your<br> Ford Buyer, who will
                        respond to you with the correct code.</span>
               </div>
            </div><br>
            <div class="row font1">
                <div class="font3">
                    <form [formGroup]="registrationCheckForm" (ngSubmit) = "onSubmit()">
                        <div>
                            <label>Parent / Site Code <br>
                            <span><input type="text" class="input-text" formControlName="siteCode"></span>
                            <span class="button-span"><button type="submit"
                                class="btn button btn-primary btn-sm" [disabled]="registrationCheckForm.invalid">Check Registration Status</button></span>
                            </label><br>
                            <span id="siteError" *ngIf = "registrationCheckForm.controls['siteCode'].invalid && (
                                registrationCheckForm.controls['siteCode'].dirty || registrationCheckForm.controls['siteCode'].touched)"
                                class="text-danger"><b>Please Enter a valid parent/site code </b> </span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="row" [hidden]="!formDisabled">
        <div class="row-disable-padding">
            <img src="\assets\adminsearchhdr.gif">
        </div>
    </div>

    <div class="row" [hidden]="!formDisabled">
        <div class="col-12">
            <div class="row result-font1">
                <div *ngIf="resultStatus === 'registered' && memReg !==null ">
                    <div>
                        <b>
                            GSDB Parent Code: {{memReg.code}}<br>
                            GSDB Code Entered: {{siteCode}}<br><br>
                            Company Name: {{memReg.name}}<br><br>
                            Your company is registered for the Ford Supplier Portal (FSP).<br>
                          </b>
                    </div><br>
                    <div class="result-font2">
                        <b>
                            Please follow the instructions from one of the two situations below applies to you.
                        </b>
                        <ol style="list-style-type: decimal;" class="result-font3">
                           <br> <li>If you need a new Covisint FSP Id to access FSP (which is replacing FSN),
                             <a href="https://us.register.covisint.com/start.html">click here</a> to register for FSP and Covisint as a
                              new user in your organization. <a href="https://www.fsp-wlp.ford.com/FSP/registration/newUser/index.html">Click here</a> for
                               instructions about how to register as a new user.</li>
                               <li>If you already have a
                                Covisint FSP Id and need access to an FSP application or a site code,
                                 <a
                                  href="https://us.register.covisint.com">click here</a> to logon
                                  to Covisint Connection and Administration to request access.
                                   <a href="https://www.fsp-wlp.ford.com/FSP/registration/requestApplications/index.html">Click here</a> to for instructions about how to get
                                    access to an FSP application.</li>
                          </ol>
                          <b>If you need additional assistance with: </b>
                            <ul style="list-style-type: disc;" class="result-font3">
                                <li>Getting a new Covisint FSP ID </li>
                                <li>Getting access to an FSP application </li>
                                <li>Getting access to a GSDB site code </li>
                            </ul>

                            <p><b>Please contact the person(s) shown below. </b></p>
                            <table class="table1">
                                <thead>
                                    <tr >
                                        <th class="th">Corporate Security Administrator</th>
                                        <th class="th">Telephone Number</th>
                                        <th class="th">E-Mail Address</th>
                                        <th class="th"> &nbsp;Organization&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let security of memReg.csas ">
                                    <tr>
                                        <td>{{security.name}} {{security.lastName}}</td>
                                        <td>{{security.telephoneNumb}}</td>
                                        <td>{{security.email}}</td>
                                        <td>{{security.organization}}</td>
                                    </tr>
                                </tbody>


                            </table>

                    </div>
                </div>

                <div *ngIf="resultStatus === 'unregistered' && memReg !== null ">
                    <div>
                        <b>
                            GSDB Parent Code : <span class="text-danger">{{memReg.code}}</span><br>
                            GSDB Code Entered : <span class="text-danger"> {{siteCode}}</span><br><br>
                            Company Name: <span class="text-danger">{{memReg.name}}</span><br><br>
                            Your company is currently <span class="text-danger">NOT</span> registered for the Ford Supplier Portal (FSP).<br>
                        </b>
                    </div>
                    <div>
                            <p style="font-size: 15px; padding-top: 5px;"><b>You
                                will need to register your company.</b></p>
                            <p style="font-size: 15px; "><b>For instructions about how to register, please
                                <a target="AuxWindow" href="https://fsp.covisint.com/">
                                  click here</a>.
                                </b></p>
                            <p>
                                <a href="https://us.register.covisint.com/start.html">
                                    <img src="/assets/pnc.gif" class="image" alt="Proceed and Close Button"></a><br>
                            </p>
                    </div>
                </div>

                <div *ngIf="resultStatus === 'invalid'">
                   <div><br>
                    <p>The GSDB supplier site/parent code you entered is not
                        valid or your company is not currently registered for access to FSN.</p>
                        <p>If you typed your supplier code incorrectly, please <a href="#" (click)="redirectToForm($event)">click here to return
                        to the previous page and search again</a>. </p><p>If you entered your
                        supplier code correctly, then your company is not registered to access FSN.  Please<br>
                        <a href="https://us.register.covisint.com/start.html">click here to register your company for FSN</a>.</p>
                   </div>
                </div>
            </div>
        </div>
    </div>
</div>
