import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EverestTokenDetails } from 'src/app/models/perl-migration-web/everest-token/everest-token-details.model';

@Component({
  selector: 'app-everest-token-lookup-report',
  templateUrl: './everest-token-lookup-report.component.html',
  styleUrls: ['./everest-token-lookup-report.component.scss']
})
export class EverestTokenLookupReportComponent{

  type:boolean = false;
  tokenDetails: EverestTokenDetails|null=null;

  constructor(private router: Router){
    const navigation = this.router.getCurrentNavigation();
    if(navigation!=null){
      const state = navigation.extras.state as {data:{type: string,tokenDetails:any}} ;
      if(state.data.type=='file'){
            this.type = true;
      }
      if(state.data.type == 'code'){
        this.type= false;
        this.tokenDetails = state.data.tokenDetails;
      }
    }
  }
}
