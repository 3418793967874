import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {UserAccessList, UserAccessReview} from 'src/app/models/paars-web/user-access-review.model';
import {
  UserAccessReviewInstructionsComponent
} from '../user-access-review-instructions/user-access-review-instructions.component';
import {UserAccessReviewSystemsComponent} from '../user-access-review-systems/user-access-review-systems.component';
import {
  UserAccessReviewSystemsDescComponent
} from '../user-access-review-systems-desc/user-access-review-systems-desc.component';
import {UserAccessReviewCriticalComponent} from '../user-access-review-critical/user-access-review-critical.component';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserAccessService} from "../service/user-access.service";
import {PopUpComponent} from "../../../common/pop-up/pop-up.component";
import {ReviewNotCompletedComponent} from "../../common/review-not-completed/review-not-completed.component";
import {ReviewCompletedComponent} from "../../common/review-completed/review-completed.component";

@Component({
  selector: 'app-user-access-review',
  templateUrl: './user-access-review.component.html',
  styleUrls: ['./user-access-review.component.scss']
})
export class UserAccessReviewComponent implements OnInit {

  hasRecords: boolean = true;
  notReviewed: boolean = false;
  userRecords: UserAccessReview[] = [];
  searchGroup: FormGroup;
  userAccessDetails: FormGroup;
  records!: FormArray<FormGroup>;
  copyRecords!: FormArray<FormGroup>;
  date: string = ""

  constructor(private dialog: MatDialog, private fb: FormBuilder, private userAccService: UserAccessService) {
    this.searchGroup = this.fb.group({
      cdsId: [''],
      system: ['']
    })
    this.userAccessDetails = new FormGroup({
      records: this.fb.array([]),
      copyRecords: this.fb.array([]),
    })
  }

  ngOnInit(): void {
    this.records = this.userAccessDetails.get('records') as FormArray;
    this.copyRecords = this.userAccessDetails.get('copyRecords') as FormArray;
    this.userRecords.forEach((record) => {
      this.pushFormGroup(record);
    });
    this.userAccService.getTaskDate().subscribe(date => {
      this.date = date.date
    })
    this.userAccService.getUserAccReviewDetails("REVIEWED").subscribe(data => {
      this.userRecords = data
      this.getUserAccReviewsDetails("REVIEWED")
    })
  }

  getUserAccReviewsDetails(reviewAction: string) {
    if (this.userRecords.length === 0) {
      this.hasRecords = false
      if (reviewAction === "NOTREVIEWED") {
        this.notReviewed = true
      }
      this.records.clear()
    } else {
      this.hasRecords = true
      this.notReviewed = false
      this.records.clear()
      this.userRecords.forEach((record) => {
        this.pushFormGroup(record);
      });
      if (reviewAction === "REVIEWED") {
        this.copyRecords = this.fb.array(
          this.records.controls.map(record => this.cloneFormGroup(record as FormGroup))
        );
      }
    }
  }

  cloneFormGroup(formGroup: FormGroup): FormGroup {
    const clonedGroup = this.fb.group({});
    Object.keys(formGroup.controls).forEach(key => {
      // Get the control from the original group
      const control = formGroup.controls[key];      // Create a new FormControl based on the original's value and validators
      let clonedControl = new FormControl(control.value, control.validator)
      if (control.disabled) {
        clonedControl.disable()
      }
      clonedGroup.addControl(key, clonedControl);
    });
    return clonedGroup;
  }

  pushFormGroup(record: UserAccessReview) {
    this.records = this.userAccessDetails.get('records') as FormArray;
    this.records.push(this.createUserForms(record));
  }

  createUserForms(record: UserAccessReview): FormGroup {
    return this.fb.group({
      approve: [record.approveFlag === "Y" ? "Y" : record.removeFlag === "Y" ? "N" : record.ncm == "Y" ? "NCM" : ""],
      currentManager: new FormControl({
        value: record.newApproverCdsid,
        disabled: record.ncm !== "Y"
      }),
      cdsId: [record.userCdsid],
      name: [record.userName],
      racfId: [record.racfId],
      system: [record.system],
      jobFunction: [record.jobFunction],
      systemAccess: [record.systemAccess],
      divPlt: [record.divPlt],
      delegation: [record.delegation],
      userAccessId: [record.userAccessId],
      comments: [record.comments, Validators.required]
    })
  }

  exit() {
    window.close();
  }

  openSuperCDS(event: Event) {
    event.preventDefault();
    window.open('http://www.sdcds.ford.com');
  }

  instructions() {
    this.dialog.open(UserAccessReviewInstructionsComponent);
  }

  systemsList() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = '75%';
    this.dialog.open(UserAccessReviewSystemsComponent, dialogConfig);
  }

  systemDesc() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = '75%';
    this.dialog.open(UserAccessReviewSystemsDescComponent, dialogConfig);
  }

  ciriticalInfo() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = '75%';
    this.dialog.open(UserAccessReviewCriticalComponent, dialogConfig);
  }

  searchRecords() {
    this.notReviewed = false
    this.hasRecords = true
    const cdsId: string = this.searchGroup.get('cdsId')?.value;
    const system: string = this.searchGroup.get('system')?.value;
    if (cdsId === "" && system === "") {
      this.records.clear()
      this.copyRecords.controls.forEach((formGroup: FormGroup) => {
        this.records.push(formGroup)
      })
    } else {
      let fileterRecords =
        this.records.controls.filter(record => {
          let recordCdsId = record.get("cdsId")?.value
          let recordSystem = record.get("system")?.value
          return ((recordCdsId.match(cdsId) &&
              recordSystem.match(system))
          );
        }).map(record => this.cloneFormGroup(record as FormGroup))
      this.records.clear()
      this.searchGroup.get("cdsId")?.setValue("")
      this.searchGroup.get("system")?.setValue("")
      fileterRecords.forEach((formGroup: FormGroup) => {
        this.records.push(formGroup)
      })
    }
  }

  enableNcm(cdsId: String) {
    this.records.controls.forEach((formGroup: FormGroup) => {
      if (formGroup.get('cdsId')?.value === cdsId) {
        formGroup.get('currentManager')?.enable();
        formGroup.get("approve")?.setValue("NCM")
        formGroup.get('currentManager')?.valueChanges.subscribe((newValue) => {
          this.currentManagerChange(cdsId, newValue);
        })
      }
    })
  }

  currentManagerChange(cdsId: String, newValue: String) {
    this.records.controls.forEach((formGroup: FormGroup) => {
      if (formGroup.get('cdsId')?.value === cdsId && formGroup.get("approve")?.value === "NCM") {
        formGroup.get('currentManager')?.setValue(newValue, {emitEvent: false});
        formGroup.get('currentManager')?.markAsDirty();
      }
    })
  }

  disableNcm(record: FormGroup) {
    record.get('currentManager')?.reset()
    record.get('currentManager')?.disable();
  }

  submitRecords() {
    this.userAccService.saveUserAccReviewDetails(this.getUserRecords()).subscribe((data) => {
      this.userRecords = data.userAccessReviewList
      this.getUserAccReviewsDetails("REVIEWED")
      if (data.status === "Not Completed") {
        this.dialog.open(ReviewNotCompletedComponent, {
          minWidth: '500px',
          maxWidth: "700px",
          disableClose: true,
          data: {
            title: "NCM"
          },
        })
      } else if (data.status === "Completed") {
        this.dialog.open(ReviewCompletedComponent, {
          minWidth: '500px',
          maxWidth: "700px",
          disableClose: true,
        })
      }
    }, (error) => {
      this.displayError()
    })
  }

  getUserRecords(): UserAccessReview[] {
    let userAccRecords: UserAccessReview[] = []
    let cdsId: string | null = sessionStorage.getItem('userId')
    this.records.controls.forEach((formGroup: FormGroup) => {
      if (formGroup.get("approve")?.value !== "" || formGroup.get("comments")?.value !== "") {
        let userRecord: UserAccessReview = new UserAccessReview()
        userRecord.approveFlag = formGroup.get("approve")?.value == "Y" ? "Y" : "N"
        userRecord.removeFlag = formGroup.get("approve")?.value == "N" ? "Y" : "N"
        userRecord.ncm = formGroup.get("approve")?.value == "NCM" ? "Y" : "N"
        userRecord.racfId = formGroup.get("racfId")?.value
        userRecord.userCdsid = formGroup.get("cdsId")?.value
        userRecord.userName = formGroup.get("name")?.value
        userRecord.system = formGroup.get("system")?.value
        userRecord.jobFunction = formGroup.get("jobFunction")?.value
        userRecord.systemAccess = formGroup.get("systemAccess")?.value
        userRecord.divPlt = formGroup.get("divPlt")?.value
        userRecord.delegation = formGroup.get("delegation")?.value
        userRecord.comments = formGroup.get("comments")?.value
        userRecord.userAccessId = formGroup.get("userAccessId")?.value
        userRecord.approverCdsId = cdsId
        userRecord.newApproverCdsid = formGroup.get("currentManager")?.value
        userAccRecords.push(userRecord)
      }
    })
    return userAccRecords;
  }


  saveRecords() {
    this.userAccService.saveUserAccReviewDetails(this.getUserRecords()).subscribe((data: UserAccessList) => {
      this.userRecords = data.userAccessReviewList
      this.getUserAccReviewsDetails("REVIEWED")
    }, (error) => {
      this.displayError()
    })
  }

  updateRecords() {
    //call update records api
  }

  displayError() {
    this.dialog.open(PopUpComponent, {
      maxWidth: '500px',
      data: {
        message: "Please enter valid details and retry again.",
      },
    })
  }

  viewUncompletedRecords() {
    this.userAccService.getUserAccReviewDetails("NOTREVIEWED").subscribe(data => {
      this.userRecords = data
      this.getUserAccReviewsDetails("NOTREVIEWED")
    })
  }
}
