import {Component, inject} from '@angular/core';
import {DIALOG_DATA} from "@angular/cdk/dialog";
import {MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup} from "@angular/forms";
import {UserHierarchyService} from "../service/user-hierarchy.service";
import {NewApprover} from "../../../models/paars-web/usrHierarchyReview";

@Component({
  selector: 'app-new-approver',
  templateUrl: './new-approver.component.html',
  styleUrls: ['./new-approver.component.scss']
})
export class NewApproverComponent {
  data = inject(DIALOG_DATA);
  readonly dialogRef = inject(MatDialogRef<NewApproverComponent>);
  newApproverForm: FormGroup;
  isError: boolean = false

  constructor(private fb: FormBuilder, private userHierarchyService: UserHierarchyService) {
    this.newApproverForm = this.fb.group(({
      approverCdsId: [''],
      replaceCdsId: ['']
    }))
  }

  displayUpper(cdsid: string) {
    this.newApproverForm.get(cdsid)?.setValue(this.newApproverForm.get(cdsid)?.value.toUpperCase())
    this.isError = false
  }

  delete() {
    let approverCdsId = this.newApproverForm.get("approverCdsId")?.value
    if (this.data?.approverIds.includes(approverCdsId)) {
      let newApprover: NewApprover = new NewApprover()
      newApprover.deleteCdsId = approverCdsId
      newApprover.status = "DELETE"
      this.userHierarchyService.setNewApprover(newApprover)
      this.dialogRef.close()
    } else {
      this.isError = true
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  replace() {
    let approverCdsId = this.newApproverForm.get("approverCdsId")?.value
    let replaceId = this.newApproverForm.get("replaceCdsId")?.value
    // For invalid api needs to create one api
    if (replaceId !== "") {
      if (this.data?.approverIds.includes(approverCdsId)) {
        let newApprover: NewApprover = new NewApprover()
        newApprover.deleteCdsId = approverCdsId
        newApprover.status = "REPLACE"
        newApprover.replaceCdsId = replaceId
        this.userHierarchyService.setNewApprover(newApprover)
        this.dialogRef.close()
      } else {
        this.isError = true
      }
    }
  }
}
