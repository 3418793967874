import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {GsdbQueryRequestBody} from "../../../models/perl-migration-web/gsdb-online/gsdbQueryBody";
import {Observable} from "rxjs";
import {GsdbQueryResults} from "../../../models/perl-migration-web/gsdb-online/gsdbQueryResults";

@Injectable({
  providedIn: 'root'
})
export class GsdbQueryService {
  private url = environment.perlMigrationApiUrl
  private gsdbQueryResponse!: GsdbQueryResults;

  constructor(private http: HttpClient) {
  }

  setGsdbQueryResponse(response: GsdbQueryResults) {
    this.gsdbQueryResponse = response
  }

  getGsdbQueryResponse(): GsdbQueryResults {
    return this.gsdbQueryResponse;
  }

  getGsdbOnlineQueryResults(requestBody: GsdbQueryRequestBody): Observable<GsdbQueryResults> {
    return this.http.post<GsdbQueryResults>(this.url + '/api/v1/gsdbOnlineQuery', requestBody);
  }

  deleteGsdbOnlineQueryDetails() {
    return this.http.delete(this.url + '/api/v1/gsdbOnlineQuery');
  }
}
