<div class="history-container">
  <p class="heading pl-1">Production Terms and Conditions</p>
  <div>
    <table class="m-2 table-container" border="1" cellpadding="3" cellspacing="0">
      <tr>
        <th class="text-center font-size-16">Date</th>
        <th class="text-center font-size-16">Update Description</th>
      </tr>
      <tr>
        <td>11/3/2003</td>
        <td>Global Terms & Conditions website launch</td>
      </tr>
      <tr>
        <td>4/21/2004</td>
        <td>Added Supplemental Terms - Clarification on Supply of Goods in the European Union</td>
      </tr>
      <tr>
        <td>4/30/2004</td>
        <td>Revised FAQs to include Section 11 Setoff</td>
      </tr>
      <tr>
        <td>9/30/2004</td>
        <td>Revised FAQs to include Sections 4.02, 4.05-4.07, 9.03, 19.01, 23,06, 34.20, and a general FAQ regarding
          Ford's Acquisition of Ownership of Intellectual Property
        </td>
      </tr>
      <tr>
        <td>9/30/2004</td>
        <td>N.A. MP&L Requirements for Materials Management & Communication Supplier-Guide</td>
      </tr>
      <tr>
        <td valign="top">6/1/2005</td>
        <td>The following GTC Supplier-Guides have
          been revised, details of the revisions are contained within each
          document:
          <ul class="list-disc ml-8">
            <li class="mt-3">Capacity Planning</li>
            <li>Claims</li>
            <li>Environmental Requirements</li>
            <li>FCSD Packaging &amp; Shipping - N. America</li>
            <li>FCSD Service Parts Guide - Europe</li>
            <li>FCSD Service Parts Guide - N. America</li>
            <li>Label Standards, Shipping Parts/Identification</li>
            <li>MP&amp;L Guide, Europe</li>
            <li>MP&amp;L Requirements For Materials Management/Communication, N. America</li>
            <li>Packaging Terms &amp; Conditions, European</li>
            <li>Returnable Containers ASN Guidelines</li>
            <li>Returnable Packaging Guidelines for N. America</li>
            <li>Shipping &amp; Routing Guide, N. American</li>
            <li>Social Responsibility</li>
            <li>Tooling</li>
            <li>Volvo MP&amp;L Guide</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td valign="top">6/1/2005</td>
        <td>Achieving Flawless
          Product Execution and Supplier Performance through Collaboration
          Supplier-Guide has been deleted.
        </td>
      </tr>
      <tr>
        <td valign="top">6/1/2005</td>
        <td>GTCs Supplemental
          Terms - Australia Supplement deleted from Ford of Australia business
          centre site and added to the Ford Supplier Portal.
        </td>
      </tr>
      <tr>
        <td valign="top">11/28/2005</td>
        <td>GTC Delivery Terms Supplier-Guide have
          been revised, details of the revisions are contained within document
        </td>
      </tr>
      <tr>
        <td valign="top">12/15/2005</td>
        <td>GTC Vehicle Parts Branding
          Supplier-Guide has been renamed to &quot;Global Automotive Parts Trademarks&quot;
          and the policy directive has been updated
        </td>
      </tr>
      <tr>
        <td valign="top">5/1/2006</td>
        <td>The following GTC Supplier-Guides have
          been revised, details of the revisions are contained within each
          document:
          <ul class="list-disc ml-8">
            <li class="mt-3">Environmental Requirements, Supplier</li>
            <li>FCSD Packaging and Shipping Guide, Europe</li>
            <li>FCSD Service Parts Guide, Europe</li>
            <li>Land Rover Customer Service ÃÂ Service Parts Guide (new
              Supplier-Guide)
            </li>
            <li>MP&amp;L Requirements for Materials Management &amp; Communication,
              North America
            </li>
            <li>News Release &amp; Publicity Guidelines, Supplier</li>
            <li>Shipping &amp; Routing Guide, North America</li>
            <li>Social Responsibility</li>
            <li>Steel Raw Material Sales Program (new Supplier-Guide)</li>
            <li>Volvo MP&amp;L Guide</li>
            <li>Volvo Supplemental Requirements for Service and Component Parts</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td valign="top">8/11/2006</td>
        <td>The following GTC Supplier-Guides
          have been revised, details of the revisions are contained within each
          document:<br>
          - Payment Terms<br>
          - Tax
        </td>
      </tr>
      <tr>
        <td valign="top">1/01/2007</td>
        <td>
          <p>The following GTC Supplier-Guides
            have been revised, details of the revisions are contained within each
            document: Label Standards, Shipping Parts/Identification (Asia,
            Africa, North America, South America)</p>
          <p>&nbsp;</p>
          <p>The Following GTC
            Supplier-Guides are new:</p>
          <p>- International Export
            Operations Export Market Packaging Guidelines</p>
          <p>- New Model Programs
            Warehouse Shipping Instructions</td>
      </tr>
      <tr>
        <td valign="top">05/01/2007</td>
        <td>
          <p>The following GTC Supplier-Guides have been revised. Details of the
            revisions are contained within each document:</p>
          <p class="mt-3">- Capacity Planning</p>
          <p>- Dispute Resolution ÃÂ Production & Non-Production</p>
          <p>- FCSD Packaging & Shipping ÃÂ North America</p>
          <p>- FCSD Service Parts Guide - EU</p>
          <p>- International Export Operations Export Market Packaging
            Guidelines</p>
          <p>- Label Standards, Shipping Parts/Identification (Asia, Africa,
            North America, South America)</p>
          <p>- Land Rover Customer Service ÃÂ Service Parts Guide</p>
          <p>- MP&L Requirements for Materials Management & Communication, North
            America</p>
          <p>- Packaging Terms and Conditions for EP parts, Europe</p>
          <p>- Returnable Packaging Guidelines for North America, Production
            Part</p>
          <p>- Shipping & Routing Guide, North America</p>
          <p>- Volvo MP&L Guide</p>
          <p>- Volvo Supplemental Requirements for Service and Component
            Parts</p>
          <p class="mt-3 mb-3">The following GTC Supplier-Guides are new:</p>
          <p>- Bailment of Production Parts for Ford of Europe Plants and Export
            Distribution Centres - Europe</p>
          <p>- Delivery Terms and Customs Guide for Production Part Sourcing
            (FoE & JLR)</p>
          <p>- Shipments for Prod Parts to FoE Plants & Export Distribution
            Centres</p>
          <p class="mt-3 mb-3">The following GTC Supplier-Guides have been deleted:</p>
          <p>- Customs Supplier Guide</p>
          <p>- Delivery Terms (WIPS, Production)</p>
          <p>- MP&L Guide, European</p>
        </td>
      </tr>
      <tr>
        <td valign="top">09/01/2007</td>
        <td>
          <p>The following GTC Supplier-Guide is new:</p>
          <p class="mt-3">- Customs, Supplier & Import Guide for Shipping to North
            America</p>
        </td>
      </tr>
      <tr>
        <td valign="top">05/01/2008</td>
        <td>
          <p>The following GTC Supplier-Guides have been revised. Details of the revisions are contained within each
            document:</p>
          <p class="mt-3">- Capacity Planning</p>
          <p>- Customs, Supplier & Import Guide for Shipping to NA</p>
          <p>- Environmental Requirements, Supplier</p>
          <p>- FCSD Packaging & Shipping ÃÂ NA</p>
          <p>- International Export Operations ÃÂ Export Market Packaging Guidelines (MFG 1750I)</p>
          <p>- Packaging Guidelines for Production Parts ÃÂ NA (MFG 1750NA)</p>
          <p>- Packaging Terms & Conditions ÃÂ Europe</p>
          <p>- Shipping & Routing Guide ÃÂ NA</p>
          <p>- Social Responsibility</p>
          <p>- Tooling Procurement & Audits</p>
          <p>- Volvo MP&L</p>
          <p>- Volvo Supplemental Requirements for Service & Component Parts</p>
          <p>- Volvo Warranty Cost Recovery Program</p>
          <p>- Warranty Chargeback (Added an addendum)</p>
        </td>
      </tr>
      <tr>
        <td>12/01/2008</td>
        <td>
          <p>The following GTC Supplier-Guides have been revised. Details of the revisions are contained within each
            document:</p>
          <p>- Payment</p>
          <p>- Tooling Procurement and Audits</p>
        </td>
      </tr>
      <tr>
        <td>01/12/2009</td>
        <td>
          <p>GTC Tax Supplier-Guide has been revised. Details of the revisions are contained within document.</p>
        </td>
      </tr>
      <tr>
        <td>05/01/2009</td>
        <td>
          <p>The following GTC Supplier-Guides have been revised. Details of the revisions are contained within each
            document:</p>
          <p>- Capacity Planning</p>
          <p>- Co-Managed Buy Program for Resins</p>
          <p>- Customs, Supplier & Import Guide for Shipping to: Canada, Mexico and United States</p>
          <p>- Environmental Requirements, Supplier</p>
          <p>- FCSD Packaging & Shipping ÃÂ NA</p>
          <p>- FCSD Service Parts Guide - NA</p>
          <p>- Label Standards, Shipping Parts/Identification (Asia, Africa, North America, South America) </p>
          <p>- MP&L Requirements for Materials Management & Communication, North America</p>
          <p>- New Model Programs Warehouse Shipping Instructions</p>
          <p>- News Release & Publicity Guidelines, Supplier</p>
          <p>- Packaging Terms & Conditions (Global 1750 Export) - All Regions</p>
          <p>- Packaging Terms & Conditions - Europe</p>
          <p>- Packaging Guidelines for Production Parts (MFG 1750 NA) - North America</p>
          <p>- Shipping & Routing Guide ÃÂ NA</p>
          <p>- Social Responsibility</p>
          <p>- Tax</p>
        </td>
      </tr>
      <tr>
        <td valign="top">06/08/2009</td>
        <td>
          <p style="margin-top: 0; margin-bottom: 0">GTC Tooling Procurement & Audits Supplier-Guide has been revised.
            Details of the revisions are contained within document.</p>
        </td>
      </tr>
      <tr>
        <td>07/20/2009</td>
        <td>
          <p>GTC Payment Supplier-Guide has been revised. Details of the revisions are contained within document.</p>
        </td>
      </tr>
      <tr>
        <td>09/09/2009</td>
        <td>
          <p>GTC Tooling Procurement & Audits Supplier-Guide has been revised. Details of the revisions are contained
            within document.</p>
        </td>
      </tr>
      <tr>
        <td>05/07/2010</td>
        <td>
          <p>The following GTC Supplier-Guides have been revised. Details of the revisions are contained within each
            document:</p>
          <p>- Customs, Supplier & Import Guide for Shipping to: NA, CA, and MX</p>
          <p>- Environmental Requirements, Supplier</p>
          <p>- FCSD Service Parts Guide ÃÂ EU</p>
          <p>- FCSD Service Parts Guide ÃÂ NA</p>
          <p>- FCSD Service Parts Guide ÃÂ APA (new Supplier Guide)</p>
          <p>- Global Automotive Parts Marking Requirements</p>
          <p>- Packaging Terms & Conditions (Global 1750 Export) ÃÂ All Regions</p>
          <p>- Packaging Guidelines for Production Parts ÃÂ NA</p>
          <p>- Shipping & Routing Guide, NA</p>
          <p>- Social Responsibility</p>
          <p>- Tax</p>
          <p>- Tooling Procurement and Audits</p>
          <p>- Volvo Container Selection Process (new Supplier Guide)</p>
          <p>- Volvo MP&L Guide</p>
          <p>- Volvo Part Trademarks & Marking (new Supplier Guide)</p>
          <p>- Volvo Requirements for Conformity of Production</p>
          <p>- Volvo Supplemental Requirements for Service and Component Parts</p>
        </td>
      </tr>
      <tr>
        <td>05/07/2010</td>
        <td>
          <p>Added Supplemental Terms ÃÂ Purchase Orders Related to Grant DE-EE0002026 and Invoice Template.</p>
        </td>
      </tr>
      <tr>
        <td>10/01/2010</td>
        <td>
          <p>The FCSD Packaging Guide has been revised.</p>
        </td>
      </tr>
      <tr>
        <td>11/29/2010</td>
        <td>
          <p>The Payment Terms Supplier Guide has been revised. Details of revisions are contained within document. The
            following GTC Supplier Guide is new: Powertrain Prototype E-Warrants Supplier Guide.</p>
        </td>
      </tr>
      <tr>
        <td>03/03/2011</td>
        <td>
          <p>GTC Capacity Planning Supplier-Guide has been revised. Details of the revisions are contained within
            document.</p>
        </td>
      </tr>
      <tr>
        <td>05/17/2011</td>
        <td>
          <p>The following GTC Supplier-Guides have been revised. Details of the revisions are contained within each
            document:</p>
          <p>- Automaterials: Market-Based Raw Material Commodity Price Management System</p>
          <p>- Co-Managed Buy Program for Resins</p>
          <p>- Customs, Supplier & Import Guide for Shipping to: Canada, Mexico, United States</p>
          <p>- Delivery Terms & Customs Guide for Production Part Sourcing (FoE)</p>
          <p>- Environmental Requirements, Supplier</p>
          <p>- FCSD Service Parts Guide ÃÂ North America</p>
          <p>- Human Rights & Working Conditions</p>
          <p>- Label Standards, Shipping Parts/Identification</p>
          <p>- MP&L Requirements for Materials Management & Communications, North America</p>
          <p>- Packaging Terms & Conditions (Global 1750 Export) ÃÂ All Regions</p>
          <p>- Packaging Terms & Conditions (EU1750) ÃÂ Europe</p>
          <p>- Packaging Guidelines for Production Parts ÃÂ North America (MFG 1750NA)</p>
          <p>- Shipping & Routing Guide, North America</p>
          <p>- Tax</p>
          <p>- Tooling Purchase and Audit Supplier Guide</p>
          <p></p>
          <p>Deleted Guides: Volvo Container Selection Process, Volvo MP&L Guide, Volvo Part Trademarks & Marking
            Directive, Volvo Requirements for Conformity of Production, Volvo Supplemental Requirements for Service and
            Component Parts, Volvo Warranty Cost Recover Program</p>
        </td>
      </tr>
      <tr>
        <td>07/18/2011</td>
        <td>
          <p>The following GTC Supplier-Guides have been revised. Details of the revisions are contained within each
            document:</p>
          <p>- News Release & Publicity Guidelines, Supplier</p>
          <p>- Enhanced Supplier Tool Tagging/Marking Requirements and Revised Payment Approval Process</p>
          <p>- Warranty Chargeback</p>
        </td>
      </tr>
      <tr>
        <td>04/01/2012</td>
        <td>
          <p>Revised Supplemental Terms ÃÂ Canada for Production and FM&SP</p>
        </td>
      </tr>
      <tr>
        <td>5/1/2012</td>
        <td>
          The following GTC Supplier-Guides have been revised. Details of the
          revisions are contained within each document:
          <ul class="list-disc ml-8">
            <li>Automaterials</li>
            <li>Co-managed Buy Program for Resins</li>
            <li>Customs Import And Export
              <ul class="list-circle ml-8">
                <li>Canada</li>
                <li>Mexico</li>
                <li>United States</li>
              </ul>
            </li>
            <li>Environmental</li>
            <li>FCSD Packaging - North America</li>
            <li>FCSD Service Parts Guide - North America</li>
            <li>FCSD Service Parts Guide - Asia Pacific And Africa</li>
            <li>Label Standards, Shipping Parts/Identification (Global)</li>
            <li>MP&L Requirements for Materials Management and Communication, North America</li>
            <li>Packaging Terms And Conditions (Global 1750 Export) - All Regions</li>
            <li>Packaging Terms And Conditions (Europe EU1750) Europe</li>
            <li>Packaging Guidelines for Production Parts, North America(MFG 1750NA)</li>
            <li>Payment</li>
            <li>Shipments for Production Parts to FOE Plants And Export Distribution Center's</li>
            <li>Social Responsibility (including Anti-Bribery) (Supplier-Guide formerly titled Human Rights and Working
              Conditions)
              <ul class="list-circle ml-8">
                <li>Associated embedded documents Policy Letter No. 24 (Code of Human Rights, Basic Working Conditions,
                  and Corporate Responsibility), Summary: Giving Gifts, Favors & Entertainment , Summary: Accepting
                  Gifts, Favors & Entertainment at Ford
                </li>
              </ul>
            </li>
            <li>Steel Raw Materials Sales Program</li>
            <li>Tooling Purchase and Audit
              <ul class="list-circle ml-8">
                <li>Including Tagging/Marking Requirements and Revised Payment Approval Process</li>
              </ul>
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          08/01/2012
        </td>
        <td>
          <p>Updated documents posted</p>
          <p>- Tax Supplier-Guide</p>
          <p>- Production Supplemental Terms - Russia</p>
        </td>
      </tr>
      <tr>
        <td valign="top">05/01/2013</td>
        <td>
          The following GTC Supplier-Guides have been revised. Details of the
          revisions are contained within each document:
          <ul class="list-disc ml-8">
            <li>Customs, Supplier Customs Import And Export
              <ul class="list-circle ml-8">
                <li>Canada</li>
                <li>Mexico</li>
                <li>United States</li>
              </ul>
            </li>
            <li>Environmental Requirements, Supplier</li>
            <li>FCSD Packaging and Shipping - North America</li>
            <li>FCSD Service Parts Guide - North America and Europe</li>

            <li>Label Standards, Shipping Parts/Identification (Asia, Africa, North America, South America)</li>
            <li>MP&L Requirements for Materials Management & Communication, North America</li>
            <li>Packaging Terms & Conditions (Global 1750 Export) - All Regions
            </li>
            <li>Packaging Terms And Conditions (Europe EU1750) Europe</li>
            <li>Packaging Guidelines for Production Parts, North America(MFG 1750NA)</li>
            <li>Payment</li>
            <li>Shipping and Routing Guide for NA Assembly, Manufacturing and Prototype Plants</li>
            <li>Social Responsibility and Anti-Corruption Requirements
            </li>
            <li>Tax</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>09/09/2013</td>
        <td>
          <p>Revised Ford of Australia Supplemental Terms for Production Purchasing</p>
          <p>Revised Payment Terms Supplier-guide.</p>
        </td>
      </tr>
      <tr>
        <td>03/24/2014</td>
        <td>
          <p>NEW Supplier-guide - Supplier Requirements for U.S. Government Subcontractors Supplier-Guide</p>
        </td>
      </tr>
      <tr>
        <td>05/01/2014</td>
        <td>
          The following GTC Supplier-Guides have been revised. Details of the
          revisions are contained within each document:
          <ul class="list-disc ml-8">
            <li class="mt-3">Co-Managed Buy Program for Resins</li>
            <li>Customs, Supplier Customs Import And Export
              <ul class="list-circle ml-8">
                <li>Canada</li>
                <li>Mexico</li>
                <li>United States</li>
              </ul>
            </li>
            <li>Dispute Resolution, GTC
              <ul class="list-circle ml-8">
                <li>Production</li>
                <li>Non-Production</li>
              </ul>
            </li>
            <li>Environmental Requirements, Supplier</li>
            <li>FCSD Service Parts Guide - Global</li>
            <li>Label Standards, Shipping Parts/Identification (Asia, Africa, North America, South America)</li>
            <li>MP&L Requirements for Materials Management & Communication, North America</li>
            <li>Packaging Terms & Conditions (Global 1750 Export) - All Regions
            </li>
            <li>Packaging Terms And Conditions (Europe EU1750) Europe</li>
            <li>Packaging Guidelines for Production Parts, North America(MFG 1750NA)</li>
            <li>Social Responsibility and Anti-Corruption Requirements
            </li>
            <li>Tax</li>
            <li>Tooling Purchase and Audit Supplier Guide
              -Including: Enhanced Supplier Tool Tagging/Marking Requirements and Revised Payment Approval Process
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>02/13/2015</td>
        <td>
          <p>The Payment Terms Supplier Guide has been revised</p>
        </td>
      </tr>
      <tr>
        <td>03/05/2015</td>
        <td>
          <p>The Supplier's Guide for Production Part Shipment to Ford of Europe
            Plants and Export Distribution Centers Supplier Guide has been revised.</p>
        </td>
      </tr>
      <tr>
        <td>05/01/2015</td>
        <td>
          The following GTC Supplements have been updated
          <ul class="list-disc ml-8">
            <li>Payment Supplier Guide updated</li>
          </ul>
        </td>
      <tr>
        <td>06/05/2015</td>
        <td>
          The following GTC Supplier-Guides have been revised. Details of the
          revisions are contained within each document:
          <ul class="list-disc ml-8">
            <li>Customs, Supplier Customs Import And Export
              <ul class="list-circle ml-8">
                <li>Canada</li>
                <li>Mexico</li>
                <li>United States</li>
              </ul>
            </li>
            <li>Environmental Requirements, Supplier</li>
            <li>FCSD Service Parts Guide - Global</li>
            <li>MP&L Requirements for Materials Management & Communication, North America</li>
            <li>Packaging Terms & Conditions (Global 1750 Export) - All Regions</li>
            <li>Packaging Terms And Conditions (Europe EU1750) Europe</li>
            <li>Packaging Guidelines for Production Parts, North America(MFG 1750NA)</li>
            <li>Packaging Guidelines for Production Parts, Asia Pacific (ADDED)</li>
            <li>Social Responsibility and Anti-Corruption Requirements</li>

          </ul>
        </td>
      </tr>
      <tr>
        <td>09/01/2015</td>
        <td>
          The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>FCSD Packaging and Shipping- North America</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>10/01/2015</td>
        <td>
          The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Payment</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>12/01/2015</td>
        <td>
          The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Payment</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>01/01/2016</td>
        <td>
          The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Payment</li>
            <li>Supplier Requirements for U.S. Government Subcontractors Supplier-Guide</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>05/01/2016</td>
        <td>
          The following GTC Supplier-Guides have been revised. Details of the revisions are contained within each
          document:
          <ul class="list-disc ml-8">
            <li>Payment</li>
            <li>Environmental Requirements, Supplier</li>
            <li>Social Responsibility and Anti-Corruption Requirements</li>
            <li>Global Export Market Packaging Terms and Conditions</li>
            <li>Steel Raw Material Sales Program</li>
            <li>Label Standards, Shipping Parts/Identification Global</li>
            <li>MP&L Requirements for Materials Management & Communication, North America</li>
            <li>FCSD Service Parts Supplier Guide- Global</li>
            <li>Bailment of Production Parts for Ford of Europe Plants and Export Distribution Centres</li>
            <li>Shipments to Ford of Europe for Production Part Plants and Export Distribution Centres</li>
            <li>Customs Import And Export</li>
            <ul class="list-circle ml-8">
              <li>Canada</li>
              <li>Mexico</li>
              <li>United States</li>
            </ul>
            <li>Packaging Guidelines for Production Parts, North America(MFG 1750NA</li>
            <li>Enhanced Supplier Tool Tagging/Marking Requirements and Revised Payment Approval Process
              <ul class="list-circle ml-8">
                <li>Supplier instruction for Progress Payments</li>
              </ul>
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>06/01/2016</td>
        <td>
          The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Dispute Resolution, GTC (Non Production)</li>
            <li>Tax</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>07/01/2016</td>
        <td>
          The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Payment</li>
            <li>Tool Purchase and Audit</li>
            <li>Enhanced Supplier Tool Tagging / Marking Requirements</li>
            <li>FCSD Pack and Supply Manual (EU)</li>
            <li>Export Control Notification Supplier Submission Guidelines (New)</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>08/01/2016</td>
        <td>
          The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Social Responsibility and Anti-Corruption</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>11/01/2016</td>
        <td>
          The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Payment</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>12/01/2016</td>
        <td>
          The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Enhanced Supplier Tool Tagging / Marking Requirements and Revised Payment Approval Process</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>5/01/2017</td>
        <td>
          The following GTC Supplier-Guides have been revised. Details of the revisions are contained within each
          document:
          <ul class="list-disc ml-8">
            <li>Payment</li>
            <li>Environmental Requirements, Supplier</li>
            <li>Packaging Guidelines for Production Parts, Asia Pacific</li>
            <li>Packaging Terms and Condition (EU1750) Europe</li>
            <li>Social Responsibility and Anti-Corruption Requirements</li>
            <li>Global Export Market Packaging Terms and Conditions</li>
            <li>MP&L Requirements for Materials Management & Communication, North America
            <li>FCSD Service Parts Supplier Guide- Global</li>
            <li>News Release & Publicity Guidelines, Supplier</li>
            <li>Shipments to Ford of Europe for Production Part Plants and Export Distribution Centres</li>
            <li>Packaging Guidelines for Production Parts, North America (MFG 1750NA)</li>
            <li>Enhanced Supplier Tool Tagging/Marking Requirements and Revised Payment Approval Process</li>
            <ul class="list-circle ml-8">
              <li>Acceptability or Unacceptability of Tooling Costs (download file for examples)</li>
            </ul>
            <li>Updated Indirect Terms and Conditions to reflect addition of Health care and Indirect Solutions</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>8/01/2017</td>
        <td>
          The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Payment</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>11/01/2017</td>
        <td>
          The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Label Standards, Shipping Parts/Identification (Global)</li>
            <li>Payment</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>12/01/2017</td>
        <td>
          The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Payment (Changed to Indirect Payment Schedule and Production Payment
              Schedule
            </li>
            <li>PII Supplement</li>
            <li>Enhanced Supplier Tool Tagging/Marking Requirements and Revised Payment
              Approval Process
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>02/01/2018</td>
        <td>
          The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>GTC Supplemental Terms - Australia</li>
            <li>Indirect Solutions Terms and Conditions</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>05/01/2018</td>
        <td>The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Environmental Requirements, Supplier</li>
            <li>Social Responsibility and Anti-Corruption
              Requirements
            </li>
            <li>Delivery Terms & Customs Guide for Production Part
              Sourcing (FoE)
            </li>
            <li>Packaging Terms and Condition (EU1750) Europe</li>
            <li>Packaging Terms & Conditions (Global 1750 Export) -
              All Regions
            </li>
            <li>Supplier Requirements for U.S. Government
              Subcontractors Supplier-Guide
            </li>
            <li>Packaging Guidelines for Production Parts, North
              America (MFG 1750NA)
            </li>
            <li>Shipments to Ford of Europe for Production Part
              Plants and Export Distribution Centres
            </li>
            <li>Tax</li>
            <li>Customs, Supplier Customs Import & Export Guide
              <ul class="list-circle ml-8">
                <li>Canada</li>
                -
                <li>United States</li>
              </ul>
            </li>
            <li>Ford Parts Brand Protection</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>06/01/2018</td>
        <td>
          The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Production Payment Schedule
            <li>Social Responsibility and Anti-Corruption Requirements
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>08/01/2018</td>
        <td>
          The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Enhanced Supplier Tool Tagging/Marking Requirements and Revised Payment
              Approval Process
            <li>Supplier Instructions for Tooling Progress Payments (Located in the Supplier
              Guide above)
            <li>FCSD Service Parts Guide - Global
            <li>Tooling Purchase and Audit Supplier-Guide</li>
            <ul class="list-circle ml-8">
              <li>Acceptability or Unacceptability of Tooling Costs</li>
            </ul>
          </ul>
        </td>
      </tr>
      <tr>
        <td>09/01/2018</td>
        <td>
          The following GTC Supplier-Guides have been revised. Details of the revisions are
          contained within each document:
          <ul class="list-disc ml-8">
            <li>Supplier Instructions for Tooling Progress Payments (Located in Enhanced
              Supplier Tool Tagging/Marking Requirements and Revised Payment Approval
              Process Supplier Guide)
            <li>FCSD Service Parts Guide - Global
            <li>Label Standards, Shipping Parts/Identification - Global
          </ul>
        </td>
      </tr>
      <tr>
        <td>10/01/2018</td>
        <td>
          The following GTC Supplier-Guides have been revised. Details of the revisions are
          contained within each document:
          <ul class="list-disc ml-8">
            <li>Production Payment Schedule
            <li>Indirect Payment Schedule
          </ul>
        </td>
      </tr>
      <tr>
        <td>11/01/2018</td>
        <td>
          The following GTC Supplier-Guides have been revised. Details of the revisions are
          contained within each document:
          <ul class="list-disc ml-8">
            <li>Advertising Guidelines, Supplier
            <li>News Release & Publicity Guidelines, Supplier
          </ul>
        </td>
      </tr>
      <tr class="list-disc ml-8">
        <td>5/01/2019</td>
        <td>
          The following GTC Supplier-Guides have been revised. Details of the revisions are
          contained within each document:
          <ul class="list-disc ml-8">
            <li>Dispute Resolution, GTC - Non-Production
            <li>Environmental Requirements, Supplier
            <li>FCSD Service Parts Guide - Global
            <li>Indirect Payment Schedule
            <li>Indirect Solutions Terms and Conditions
            <li>News Release & Publicity Guidelines, Supplier
            <li>Packaging Terms and Conditions (EU1750) - Europe
            <li>Packaging Terms and Conditions (Global 1750 Export) - All Regions
            <li>Production Payment Schedule
            <li>Shipments for Prod Parts to FoE Plants & Export Distribution Centres
            <li>Supplier Responsibility and Anti-Corruption Requirements
            <li>Personally Identifiable Information (PII) Supplemental Terms and Conditions
            <li>Indirect Payment Schedule and Production Payment Schedule, effective date
              was updated from May 2019 to July 2019 due to legal reasons
            <li>Social Responsibility and Anti-Corruption Requirements
          </ul>
        </td>
      </tr>
      <tr>
        <td>06/01/2019</td>
        <td>
          The following GTC Supplier-Guides have been revised. Details of the revisions are
          contained within each document:
          <ul class="list-disc ml-8">
            <li>Software as a Service (SaaS) Terms and Conditions
            <li>Ford Motor Credit Supplemental Terms and Conditions (U.S. ONLY)
            <li>Insurance Supplemental Terms

          </ul>
        </td>
      </tr>
      <tr>
        <td>12/01/2019</td>
        <td>
          The following GTC Supplier-Guides have been revised. Details of the revisions are
          contained within each document:
          <ul class="list-disc ml-8">
            <li>Indirect Payment Schedule
          </ul>
        </td>
      </tr>
      <tr>
        <td>06/01/2020</td>
        <td>
          The following GTC Supplier Guides have been revised. Details of the revisions are
          contained within each document (highlighted in yellow). Administrative changes (such
          as updated contact information or links) have been made to certain Supplier Guides,
          but are not included in the list below:
          <ul class="list-disc ml-8">
            <li>Customs, Supplier Customs Import & Export Guide
              <ul class="list-circle ml-8">
                <li>Canada</li>
                <li>Mexico</li>
                <li>United States</li>
              </ul>
            <li>Environmental Requirements, Supplier
            <li>FCSD Service Parts Guide - Global
            <li>Label Standards, Shipping Parts/Identification (Global)
            <li>MP&L Requirements for Materials Management & Communication, North America
            <li>Packaging Terms & Conditions (Global 1750 Export) - All Regions
            <li>Packaging Terms and Conditions (EU1750) - Europe
            <li>Packaging Guidelines for Production Parts - North America
            <li>Social Responsibility and Anti-Corruption Requirements
            <li>Tooling Purchase and Audit Supplier Guide
              <ul class="list-circle ml-8">
                <li>Including: Enhanced Supplier Tool Tagging/Marking Requirements and
                  Revised Payment Approval Process
                </li>
              </ul>
            <li>Supplier Requirements for U.S. Government Subcontractors Supplier-Guide
          </ul>
        </td>
      </tr>
      <tr>
        <td>10/09/2020</td>
        <td>
          The following GTC Supplier Guide has been revised. Details of the revisions are
          contained within each document (highlighted in yellow):
          <ul class="list-disc ml-8">
            <li>Production Payment Schedule - Effective October 1, 2020</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>11/21/2020</td>
        <td>
          The following GTC Supplier Guide has been revised. Details of the revisions are
          contained within each document (highlighted in yellow):
          <ul class="list-disc ml-8 mt-3">
            <li>Supplier Requirements for U.S. Government Subcontractors Supplier Guide -
              Effective November 21, 2020
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>12/1/2020</td>
        <td>
          The following GTC Supplier Guide has been revised. Details of the revisions are
          contained within each document (highlighted in yellow):
          <ul class="list-disc ml-8 mt-3">
            <li>Indirect Payment Schedule
          </ul>
        </td>
      </tr>
      <tr>
        <td>1/21/2021</td>
        <td>
          The Supplier Requirements for U.S. Government Subcontractors Supplier Guide has been
          revised to remove sections referring to Executive Order 13950 as it is no longer in
          effect as of January 21, 2021.
        </td>
      </tr>
      <tr>
        <td>4/7/2021</td>
        <td>
          Updates to the Ford Production Purchasing Global Terms & Conditions (PPGTCs) have
          been posted, with updates effective July 1, 2021. Ford PPGTCs rev. Jan 1, 2004 will
          remain posted until July 1, 2021, when new terms become effective.
        </td>
      </tr>
      <tr>
        <td>5/3/2021</td>
        <td>
          PPGTC Supplier Guides have been updated. Details of the revisions are contained
          within each document.
        </td>
      </tr>
      <tr>
        <td>10/5/2021</td>
        <td>
          2021 PPGTC Supplier Frequently Asked Questions (FAQ's) has been posted to the GTC
          Change Summary & FAQ's page
        </td>
      </tr>
      <tr>
        <td>12/8/2021</td>
        <td>
          Indirect Only: Ford Healthcare Terms and Conditions for Insured Services -- Fully
          Insured Plans (ISA) and Ford Healthcare Terms and Conditions for Administrative
          Services Only -- Self Insured Plans (ASA) have been updated.
        </td>
      </tr>
      <tr>
        <td>04/01/2022</td>
        <td>
          Indirect Only: The Indirect Solutions and SaaS Global Terms have been updated to
          include new privacy language to address changes in the law in California, Colorado
          and Virginia. They have also been updated to change the reference for Ã¢ÂÂWeb
          GuidesÃ¢ÂÂ to Ã¢ÂÂSupplier GuidesÃ¢ÂÂ. Additional language was added to
          clarify that an assignment by a Seller does not release the Seller from liability.
        </td>
      </tr>
      <tr>
        <td>05/02/2022</td>
        <td>
          The following GTC Supplier Guides have been revised. Details of the revisions are
          contained within each document (highlighted in yellow). Administrative changes (such
          as updated contact information or links) have been made to certain Supplier Guides,
          but are not included in the list below:
          <ul class="list-disc ml-8">
            <li>Claims
            <li>Customs, Supplier Customs Import & Export Guide
              <ul class="list-circle ml-8">
                <li>Canada
                <li>Mexico
                <li>United States
              </ul>
            <li>Environmental Requirements, Supplier
            <li>FCSD Service Parts Guide - Global
            <li>Label Standards, Shipping Parts/Identification (Global)
            <li>Packaging Terms & Conditions (Global 1750 Export) - All Regions
            <li>Packaging Terms and Conditions (EU1750) - Europe
            <li>Shipments for Prod Parts to FoE Plants & Export Distribution Centres
            <li>Shipping and Routing Guide for NA Assembly, Manufacturing and Prototype
              Plants
            <li>Social Responsibility and Anti-Corruption
            <li>Supply Chain Inclusivity and Diversity
          </ul>
        </td>
      </tr>
      <tr>
        <td>5/1/2023</td>
        <td>
          Two Supplier Guides have been added.
          <ul class="list-disc ml-8">
            <li>Digital Manufacturing Data
            <li>Value Chain Transparency</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>5/1/2023</td>
        <td>
          Two Regional Supplements have been added.
          <ul class="list-disc ml-8">
            <li>UK Purchasing Tax Supplement (UK Only)
            <li>ERISA Supplement (US Only)
          </ul>
          Update of Healthcare Terms and Conditions for Healthcare and Related Services ASA
          <br>
          Update of Healthcare Terms and Conditions for Healthcare and Related Services ISA

        </td>
      </tr>
      <tr>
        <td>7/1/2023</td>
        <td>
          The following GTC Supplier Guides have been revised. Details of the revisions are
          contained within each document:
          <ul class="list-disc ml-8">
            <li>Autocurrency Overview & Operating Policy
            <li>Automaterials: Market-Based Raw Material Commodity Price Management System,
              Overview and Operating Policy
            <li>Chronic Supplier Improvement Process (CSIP)
            <li>Environmental Requirements, Supplier
            <li>FCSD Service Parts Guide â Global
            <li>MP&L Requirements for Materials Management & Communication, North America
            <li>News Release & Publicity Guidelines, Supplier
            <li>Packaging Guidelines for Production Parts, China
            <li>Packaging Guidelines for Production Parts - North America
            <li>Packaging Terms and Conditions (EU1750) â Europe
            <li>Production Purchase Order Non-Renewal Process
            <li>Social Responsibility and Anti-Corruption Requirements
            <li>Tooling Purchase and Audit
            <li>Warranty Chargeback</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>7/1/2023</td>
        <td>
          <li>Update to the Ford Supplier Portal - UK Purchasing Tax Supplement (UK Only) has
            been added to the Ford Supplier Facing Portal
          <li>Minor update of the Global Terms & Conditions for Indirect Solutions
        </td>
      </tr>
      <tr>
        <td>10/01/2023</td>
        <td>
          <li>Minor updates to Healthcare Terms and Conditions for Healthcare and Related
            Services ASA (Self Insured)
            <ul class="list-disc ml-8">
              <li>Updates to Supplier Code of Conduct language to align with Ford
                Corporate guidelines
              <li>Updates to Diversity and Inclusion language to align with Corporate
                guidelines
              <li>Revised references to PII to PI (Personal Information) which is a
                broader tern relating to any identifiable information tied to a person
              <li>Removed language allowing TPA to terminate without cause
            </ul>
          <li>Minor update of the Healthcare Terms and Conditions for Healthcare and Related
            Services ISA (Fully Insured)
            <ul class="list-disc ml-8">
              <li>Updates to Working Conditions definition to align with corporate
                guidelines.
              <li>Updates to Supplier Code of Conduct language to align with Ford
                Corporate guidelines.
              <li>Updates to Supplier Diversity and Inclusion language to align with
                corporate guidelines.
              <li>Removed language allowing Carrier to terminate without cause.
              <li>Updated PI language to align with the California Consumer Privacy Act
                (CCPA)
              <li>Revised references to PII to PI (Personal Information) which is a
                broader tern relating to any identifiable information tied to a person
            </ul>
          <li>Minor update to the Indirect GTC Supplemental Terms – Australia to reflect
            legally required changes due to unfair contract terms laws in Australia.
        </td>
      </tr>
      <tr>
        <td>01/04/2024</td>
        <td>
          <li>Administrative updates have been made to the Tax Supplier Guide for Production
            and Non-Production Goods
        </td>
      </tr>
      <tr>
        <td>15/04/2024</td>
        <td>
          <li>

            The following GTC Supplier Guides have been revised. Details of the revisions are contained within each
            document:
            <ul class="list-disc ml-8">
              <li>Advertising Guidelines, Supplier (Guide remove as the language was merged with News Release, Publicity
                & Advertising Guidelines, Supplier)
              </li>
              <li>Customs, Supplier Customs Import & Export Guide - United States</li>
              <li>Delivery Terms & Customs Guide for Production Part Sourcing (FoE)</li>
              <li>Environmental Requirements</li>
              <li>Export Controls and Sanctions Compliance</li>
              <li>MP&L Requirements for Materials Management & Communication, North America</li>
              <li>New Model Programs Warehouse Shipping Instructions</li>
              <li>News Release, Publicity & Advertising Guidelines, Supplier</li>
              <li>Packaging Terms & Conditions (Global 1750 Export) - All Regions</li>
              <li>Packaging Terms and Conditions (EU1750) - Europe</li>
              <li>Packaging Guidelines for Production Parts - North America</li>
              <li>Packaging Guidelines for Production Parts, China</li>
              <li>Shipping and Routing Guide for NA Assembly, Manufacturing and Prototype Plants</li>
              <li>Social Responsibility and Anti-Corruption Requirements</li>
              <li>Supplier Requirements for U.S. Government Subcontractors</li>
              <li>Supply Chain Inclusivity and Diversity</li>
              <li>Tax</li>
              <li>Value Chain Transparency</li>
            </ul>
          </li>
        </td>
      </tr>
    </table>
  </div>
</div>
