import {Component, inject} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {countries, stateOrProvice} from "../../../constants/perl-migration-web/gsdbContacts";
import {PopUpComponent} from "../../../common/pop-up/pop-up.component";
import {Dialog} from "@angular/cdk/dialog";
import {GsdbContactsRequestBody} from "../../../models/perl-migration-web/gsdb-online/gsdbContacts";
import {GsdbContactsService} from "../service/gsdb-contacts.service";
import {Router} from "@angular/router";
import {ConfirmationComponent} from "../../../common/confirmation/confirmation.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-gsdb-contacts-search',
  templateUrl: './gsdb-contacts-search.component.html',
  styleUrls: ['./gsdb-contacts-search.component.scss']
})
export class GsdbContactsSearchComponent {
  dialog = inject(Dialog);
  contactsForm: FormGroup
  gsdbStateOrProvice = stateOrProvice;
  gsdbCountries = countries
  readonly matDialog = inject(MatDialog);
  order = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "ignore"]

  constructor(private fb: FormBuilder, private gsdbService: GsdbContactsService, private router: Router) {
    this.contactsForm = this.fb.group({
      criteria: ['criteria1'],
      selCriteria1: [''],
      selCriteria1Value: [''],
      title: [''],
      state: [''],
      country: [''],
      companyName: ['1'],
      contactLastName: ['2'],
      contactFirstName: ['3'],
      preferredTitle: ['ignore'],
      email: ['5'],
      businessTel: ['6'],
      fax: ['7'],
      mailAddr: ['8'],
      suite: ['9'],
      city: ['10'],
      county: ['11'],
      stateOrd: ['12'],
      postalCode: ['13'],
      countryOrd: ['14'],
      altContactName: ['ignore'],
      altContactTel: ['ignore'],
      parentCode: ['15'],
      siteCode: ['16']

    })
  }

  getGsdbContactResults(gsdbContactsRequestBody: GsdbContactsRequestBody) {
    gsdbContactsRequestBody.companyNameOrder = this.contactsForm.get("companyName")?.value !== "ignore" ? parseInt(this.contactsForm.get("companyName")?.value) : -1
    gsdbContactsRequestBody.lastNameOrder = this.contactsForm.get("contactLastName")?.value !== "ignore" ? parseInt(this.contactsForm.get("contactLastName")?.value) : -1
    gsdbContactsRequestBody.firstsNameOrder = this.contactsForm.get("contactFirstName")?.value !== "ignore" ? parseInt(this.contactsForm.get("contactFirstName")?.value) : -1
    gsdbContactsRequestBody.titleOrder = this.contactsForm.get("preferredTitle")?.value !== "ignore" ? parseInt(this.contactsForm.get("preferredTitle")?.value) : -1
    gsdbContactsRequestBody.emailOrder = this.contactsForm.get("email")?.value !== "ignore" ? parseInt(this.contactsForm.get("email")?.value) : -1
    gsdbContactsRequestBody.bussPhoneOrder = this.contactsForm.get("businessTel")?.value !== "ignore" ? parseInt(this.contactsForm.get("businessTel")?.value) : -1
    gsdbContactsRequestBody.faxOrder = this.contactsForm.get("fax")?.value !== "ignore" ? parseInt(this.contactsForm.get("fax")?.value) : -1
    gsdbContactsRequestBody.mailAddrOrder = this.contactsForm.get("mailAddr")?.value !== "ignore" ? parseInt(this.contactsForm.get("mailAddr")?.value) : -1
    gsdbContactsRequestBody.buildingOrder = this.contactsForm.get("suite")?.value !== "ignore" ? parseInt(this.contactsForm.get("suite")?.value) : -1
    gsdbContactsRequestBody.cityTownOrder = this.contactsForm.get("city")?.value !== "ignore" ? parseInt(this.contactsForm.get("city")?.value) : -1
    gsdbContactsRequestBody.countyOrder = this.contactsForm.get("county")?.value !== "ignore" ? parseInt(this.contactsForm.get("county")?.value) : -1
    gsdbContactsRequestBody.stateProvOrder = this.contactsForm.get("stateOrd")?.value !== "ignore" ? parseInt(this.contactsForm.get("stateOrd")?.value) : -1
    gsdbContactsRequestBody.zipOrder = this.contactsForm.get("postalCode")?.value !== "ignore" ? parseInt(this.contactsForm.get("postalCode")?.value) : -1
    gsdbContactsRequestBody.countryOrder = this.contactsForm.get("countryOrd")?.value !== "ignore" ? parseInt(this.contactsForm.get("countryOrd")?.value) : -1
    gsdbContactsRequestBody.altNameOrder = this.contactsForm.get("altContactName")?.value !== "ignore" ? parseInt(this.contactsForm.get("altContactName")?.value) : -1
    gsdbContactsRequestBody.altPhoneOrder = this.contactsForm.get("altContactTel")?.value !== "ignore" ? parseInt(this.contactsForm.get("altContactTel")?.value) : -1
    gsdbContactsRequestBody.parentCodeOrder = this.contactsForm.get("parentCode")?.value !== "ignore" ? parseInt(this.contactsForm.get("parentCode")?.value) : -1
    gsdbContactsRequestBody.siteCodeOrder = this.contactsForm.get("siteCode")?.value !== "ignore" ? parseInt(this.contactsForm.get("siteCode")?.value) : -1
  }

  getCriteriaOneResults() {
    if (this.contactsForm.get('selCriteria1')?.value !== "" && this.contactsForm.get('selCriteria1Value')?.value !== "") {
      // write the functionality for getting results.
      let gsdbContactsRequestBody: GsdbContactsRequestBody = new GsdbContactsRequestBody()
      gsdbContactsRequestBody.criteria = "criteria1"
      gsdbContactsRequestBody.searchFor = this.contactsForm.get("selCriteria1")?.value
      gsdbContactsRequestBody.searchWhat = this.contactsForm.get("selCriteria1Value")?.value
      this.getGsdbContactResults(gsdbContactsRequestBody)
      this.gsdbService.getGsdbContactResults(gsdbContactsRequestBody).subscribe(data => {
        this.gsdbService.setContactResults(data)
        this.router.navigate(["/perlMigrationWeb/gsdbContactsSearchResults"])
      })
    } else {
      this.dialog.open(PopUpComponent, {
        maxWidth: '500px',
        data: {
          message: 'Please select a Company Name, GSDB Parent Code, or a GSDB Site Code from the dropdown list.Also, please type in what you want to search on',
        },
      });
    }

  }

  getCriteriaTwoResults(title: string, state: string, country: string) {
    if (title === "" && state === "" && country === "") {
      this.dialog.open(PopUpComponent, {
        maxWidth: '500px',
        data: {
          message: 'Please select either a title, or a title along with a state or country',
        },
      });
    } else if (state !== "" && country !== "") {
      this.dialog.open(PopUpComponent, {
        maxWidth: '500px',
        data: {
          message: 'You cannot choose a State AND a Country. You must choose either a State OR a Country.',
        },
      });
    } else if ((title !== "" && (state !== "" || country !== ""))) {
      let gsdbContactsRequestBody: GsdbContactsRequestBody = new GsdbContactsRequestBody()
      gsdbContactsRequestBody.title = title
      gsdbContactsRequestBody.stateProvince = state
      gsdbContactsRequestBody.country = country
      gsdbContactsRequestBody.criteria = "criteria2"
      this.getGsdbContactResults(gsdbContactsRequestBody)
      this.gsdbService.getGsdbContactResults(gsdbContactsRequestBody).subscribe(data => {
        this.gsdbService.setContactResults(data)
        this.router.navigate(["/perlMigrationWeb/gsdbContactsSearchResults"])
      })
    }

  }

  displayInBrowser() {
    if (this.contactsForm.get('criteria')?.value === "criteria1") {
      this.getCriteriaOneResults()
    } else if (this.contactsForm.get('criteria')?.value === "criteria2") {
      const title = this.contactsForm.get('title')?.value
      const state = this.contactsForm.get('state')?.value
      const country = this.contactsForm.get('country')?.value
      this.getCriteriaTwoResults(title, state, country)
    }
  }

  generateExcel() {
    const message: string = "Your search results will be put into a Microsoft Excel file, if your search results are not empty. " +
      "If you want to filter the results before downloading to Excel, please click the 'View Results in Browser' button."

    this.matDialog.open(ConfirmationComponent, {
      minWidth: '500px', data: {
        message: message,
        confirm: this.displayInBrowser.bind(this)
      }
    });
  }
}
