import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from "./gtc/common/home/home.component";
import {WelcomeComponent} from "./gtc/common/welcome/welcome.component";
import {ProductionHomeComponent} from './gtc/production/production-home/production-home.component';
import {HeaderComponent} from './gtc/common/header/header.component';
import {
  ProductionLeftSidebarComponent
} from './gtc/production/production-left-sidebar/production-left-sidebar.component';
import {IndirectHomeComponent} from './gtc/indirect/indirect-home/indirect-home.component';
import {IndirectLeftSidebarComponent} from './gtc/indirect/indirect-left-sidebar/indirect-left-sidebar.component';
import {
  ProductionTermsConditionsComponent
} from './gtc/production/production-terms-conditions/production-terms-conditions.component';
import {
  ProductionRightSidebarComponent
} from './gtc/production/production-right-sidebar/production-right-sidebar.component';
import {IndirectRightSidebarComponent} from './gtc/indirect/indirect-right-sidebar/indirect-right-sidebar.component';
import {IndirectTermsComponent} from './gtc/indirect/indirect-terms/indirect-terms.component';
import {SupplierGuidesComponent} from './gtc/production/supplier-guides/supplier-guides.component';
import {CommunicationsComponent} from './gtc/production/communications/communications.component';
import {SupplementsComponent} from './gtc/production/supplements/supplements.component';
import {SummeryComponent} from './gtc/production/summery/summery.component';
import {HistoryComponent} from './gtc/production/history/history.component';
import {
  IndirectCommunicationsComponent
} from './gtc/indirect/indirect-communications/indirect-communications.component';
import {AntiCorruptionComponent} from './gtc/indirect/anti-corruption/anti-corruption.component';
import {IndirectSupplementsComponent} from './gtc/indirect/indirect-supplements/indirect-supplements.component';
import {IndirectHistoryComponent} from './gtc/indirect/indirect-history/indirect-history.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {NgxAdfsModule} from "@wame/ngx-adfs";

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  EverestTokenHomeComponent
} from './perl-migration-web/everest-token-lookup/everest-token-home/everest-token-home.component';
import {
  EverestTokenFileUploadComponent
} from './perl-migration-web/everest-token-lookup/everest-token-file-upload/everest-token-file-upload.component';
import {
  EverestTokenByCodeComponent
} from './perl-migration-web/everest-token-lookup/everest-token-by-code/everest-token-by-code.component';
import {CommonHeaderComponent} from './perl-migration-web/common/common-header/common-header.component';
import {
  EverestTokenFileConfirmationComponent
} from './perl-migration-web/everest-token-lookup/everest-token-file-confirmation/everest-token-file-confirmation.component';
import {
  EverestTokenSelectSitecodeComponent
} from './perl-migration-web/everest-token-lookup/everest-token-select-sitecode/everest-token-select-sitecode.component';
import {
  EverestTokenLookupReportComponent
} from './perl-migration-web/everest-token-lookup/everest-token-lookup-report/everest-token-lookup-report.component';
import {GsdbHierarchyComponent} from './perl-migration-web/gsdb-hierarchy/gsdb-hierarchy.component';
import {BuyerSearchComponent} from "./perl-migration-web/buyer-search/buyer-search.component";
import {IdConverterComponent} from './perl-migration-web/id-converter/id-converter/id-converter.component';
import {HelpdeskComponent} from './perl-migration-web/helpdesk/helpdesk/helpdesk.component';
import {AribaOnlineComponent} from './perl-migration-web/ariba-online/ariba-online/ariba-online.component';
import {AribaHeaderComponent} from './perl-migration-web/common/ariba-header/ariba-header.component';
import {SupplierSearchComponent} from './perl-migration-web/supplier-search/supplier-search.component';
import {FordPeopleSearchComponent} from "./perl-migration-web/ford-people-search/ford-people-search.component";
import {HelpdeskSupportComponent} from './common/helpdesk-support/helpdesk-support.component';
import {
  GsdbOnlineHeaderComponent
} from './perl-migration-web/gsdb-online/gsdb-online-header/gsdb-online-header.component';
import {GsdbOnlineIntroComponent} from './perl-migration-web/gsdb-online/gsdb-online-intro/gsdb-online-intro.component';
import {GsdbRegFailComponent} from './perl-migration-web/gsdb-online/gsdb-reg-fail/gsdb-reg-fail.component';
import {GsdbRegCompleteComponent} from './perl-migration-web/gsdb-online/gsdb-reg-complete/gsdb-reg-complete.component';
import {
  GsdbRegRestrictedComponent
} from './perl-migration-web/gsdb-online/gsdb-reg-restricted/gsdb-reg-restricted.component';
import {
  GsdbRegInprocessComponent
} from './perl-migration-web/gsdb-online/gsdb-reg-inprocess/gsdb-reg-inprocess.component';
import {
  GsdbAuthorizationComponent
} from './perl-migration-web/gsdb-online/gsdb-authorization/gsdb-authorization.component';
import {DataLoaderComponent} from './perl-migration-web/data-loader/data-loader/data-loader.component';
import {GsdbAuthDeniedComponent} from './perl-migration-web/gsdb-online/gsdb-auth-denied/gsdb-auth-denied.component';
import {
  GsdbAuthCompleteComponent
} from './perl-migration-web/gsdb-online/gsdb-auth-complete/gsdb-auth-complete.component';
import {
  GsdbAuthInprocessComponent
} from './perl-migration-web/gsdb-online/gsdb-auth-inprocess/gsdb-auth-inprocess.component';
import {
  FsnRegistrationComponent
} from './perl-migration-web/fsn-registration/fsn-registration/fsn-registration.component';
import {
  GsdbAuthAcceptedComponent
} from './perl-migration-web/gsdb-online/gsdb-auth-accepted/gsdb-auth-accepted.component';
import {
  GsdbAuthNotAcceptComponent
} from './perl-migration-web/gsdb-online/gsdb-auth-not-accept/gsdb-auth-not-accept.component';
import {AppLaunchComponent} from './perl-migration-web/app-launch/app-launch/app-launch.component';
import {AppLaunchHeaderComponent} from './perl-migration-web/common/app-launch-header/app-launch-header.component';
import {
  GsdbContactsSidebarComponent
} from './perl-migration-web/gsdb-contacts/gsdb-contacts-sidebar/gsdb-contacts-sidebar.component';
import {
  GsdbContactsMainComponent
} from './perl-migration-web/gsdb-contacts/gsdb-contacts-main/gsdb-contacts-main.component';
import {
  GsdbContactsChiefComponent
} from './perl-migration-web/gsdb-contacts/gsdb-contacts-chief/gsdb-contacts-chief.component';
import {
  GsdbContactsFormComponent
} from './perl-migration-web/gsdb-contacts/gsdb-contacts-form/gsdb-contacts-form.component';
import {GsdbContactsVpComponent} from './perl-migration-web/gsdb-contacts/gsdb-contacts-vp/gsdb-contacts-vp.component';
import {
  GsdbContactsFordComponent
} from './perl-migration-web/gsdb-contacts/gsdb-contacts-ford/gsdb-contacts-ford.component';
import {
  GsdbContactsCeoComponent
} from './perl-migration-web/gsdb-contacts/gsdb-contacts-ceo/gsdb-contacts-ceo.component';
import {NotFoundComponent} from './common/not-found/not-found.component';
import {
  GsdbCodeInvalidComponent
} from './perl-migration-web/gsdb-contacts/gsdb-code-invalid/gsdb-code-invalid.component';
import {SuccessComponent} from './perl-migration-web/gsdb-contacts/success/success.component';
import {AppLookupComponent} from './perl-migration-web/app-lookup/app-lookup/app-lookup.component';
import {
  AppRegistrationComponent
} from './perl-migration-web/app-registration/app-registration/app-registration.component';
import {EmbededEmailComponent} from './perl-migration-web/embeded-email/embeded-email/embeded-email.component';
import {
  EmbededEmailConfirmationComponent
} from './perl-migration-web/embeded-email/embeded-email-confirmation/embeded-email-confirmation.component';
import {
  GsdbForSitecodeComponent
} from './perl-migration-web/gsdb-for-sitecode/gsdb-for-sitecode/gsdb-for-sitecode.component';
import {AuthInterceptor} from "./interceptor/auth.interceptor";
import {GsdbQueryComponent} from './perl-migration-web/gsdb-online/gsdb-query/gsdb-query.component';
import {
  GsdbFailedSearchComponent
} from './perl-migration-web/gsdb-online/gsdb-failed-search/gsdb-failed-search.component';
import {
  GsdbQueryResultsComponent
} from './perl-migration-web/gsdb-online/gsdb-query-results/gsdb-query-results.component';
import {GsdbDeleteQueryComponent} from './perl-migration-web/gsdb-online/gsdb-delete-query/gsdb-delete-query.component';
import {
  GsdbContactsSearchComponent
} from './perl-migration-web/gsdb-contacts/gsdb-contacts-search/gsdb-contacts-search.component';
import {PopUpComponent} from './common/pop-up/pop-up.component';
import {MatDialogModule} from "@angular/material/dialog";
import {
  ContactSearchResultsComponent
} from './perl-migration-web/gsdb-contacts/contact-search-results/contact-search-results.component';
import {
  UserAccessReviewPopupComponent
} from './paars-web/user-access-review/user-access-review-popup/user-access-review-popup.component';
import {
  UserAccessReviewComponent
} from './paars-web/user-access-review/user-access-review/user-access-review.component';
import {
  UserAccessReviewInstructionsComponent
} from './paars-web/user-access-review/user-access-review-instructions/user-access-review-instructions.component';
import {
  UserAccessReviewSystemsComponent
} from './paars-web/user-access-review/user-access-review-systems/user-access-review-systems.component';
import {
  UserAccessReviewSystemsDescComponent
} from './paars-web/user-access-review/user-access-review-systems-desc/user-access-review-systems-desc.component';
import {
  UserAccessReviewCriticalComponent
} from './paars-web/user-access-review/user-access-review-critical/user-access-review-critical.component';
import {
  UserHierarchyHomeComponent
} from './paars-web/user-hierarchy-review/user-hierarchy-home/user-hierarchy-home.component';
import {
  AnnualReviewPageComponent
} from './paars-web/user-hierarchy-review/annual-review-page/annual-review-page.component';
import {LoadingComponent} from './common/loading/loading.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {BackendCoonectionErrorComponent} from './common/backend-coonection-error/backend-coonection-error.component';
import {NewControllerComponent} from "./paars-web/user-hierarchy-review/new-controller/new-controller.component";
import {NewApproverComponent} from "./paars-web/user-hierarchy-review/new-approver/new-approver.component";
import {ConfirmationComponent} from './common/confirmation/confirmation.component';
import {CsaHomeComponent} from './paars-web/user-csa-review/csa-home/csa-home.component';
import {CsaReviewComponent} from './paars-web/user-csa-review/csa-review/csa-review.component';
import {
  GsdbContactsIsoComponent
} from './perl-migration-web/gsdb-contacts/gsdb-contacts-iso/gsdb-contacts-iso.component';
import {
  GsdbContactsExecutiveComponent
} from './perl-migration-web/gsdb-contacts/gsdb-contacts-executive/gsdb-contacts-executive.component';
import {
  GsdbContactsSalesComponent
} from './perl-migration-web/gsdb-contacts/gsdb-contacts-sales/gsdb-contacts-sales.component';
import {
  GsdbContactsVsmlComponent
} from './perl-migration-web/gsdb-contacts/gsdb-contacts-vsml/gsdb-contacts-vsml.component';
import {
  GsdbContactsKfcComponent
} from './perl-migration-web/gsdb-contacts/gsdb-contacts-kfc/gsdb-contacts-kfc.component';
import {EmptyComponentComponent} from './gtc/empty-component/empty-component.component';
import {ReviewNotCompletedComponent} from './paars-web/common/review-not-completed/review-not-completed.component';
import {ReviewCompletedComponent} from './paars-web/common/review-completed/review-completed.component';
import {environment} from "../environments/environment";
import {UserSelfRemovalComponent} from "./paars-web/user-self-removal/user-self-removal/user-self-removal.component";
import { SelfRemovalSuccessComponent } from './paars-web/user-self-removal/self-removal-success/self-removal-success.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import { CriticalInformationComponent } from './paars-web/user-self-removal/critical-information/critical-information.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    WelcomeComponent,
    ProductionHomeComponent,
    HeaderComponent,
    ProductionLeftSidebarComponent,
    IndirectHomeComponent,
    IndirectLeftSidebarComponent,
    ProductionTermsConditionsComponent,
    ProductionRightSidebarComponent,
    IndirectRightSidebarComponent,
    IndirectTermsComponent,
    SupplierGuidesComponent,
    CommunicationsComponent,
    SupplementsComponent,
    SummeryComponent,
    HistoryComponent,
    IndirectCommunicationsComponent,
    AntiCorruptionComponent,
    IndirectSupplementsComponent,
    IndirectHistoryComponent,
    EverestTokenHomeComponent,
    EverestTokenFileUploadComponent,
    EverestTokenByCodeComponent,
    CommonHeaderComponent,
    EverestTokenFileConfirmationComponent,
    EverestTokenSelectSitecodeComponent,
    EverestTokenLookupReportComponent,
    GsdbHierarchyComponent,
    BuyerSearchComponent,
    IdConverterComponent,
    HelpdeskComponent,
    AribaOnlineComponent,
    AribaHeaderComponent,
    SupplierSearchComponent,
    FordPeopleSearchComponent,
    DataLoaderComponent,
    HelpdeskSupportComponent,
    GsdbOnlineHeaderComponent,
    GsdbOnlineIntroComponent,
    GsdbRegFailComponent,
    GsdbRegCompleteComponent,
    GsdbRegRestrictedComponent,
    GsdbRegInprocessComponent,
    GsdbAuthorizationComponent,
    GsdbAuthDeniedComponent,
    GsdbAuthCompleteComponent,
    GsdbAuthInprocessComponent,
    FsnRegistrationComponent,
    GsdbAuthAcceptedComponent,
    GsdbAuthNotAcceptComponent,
    AppLaunchComponent,
    AppLaunchHeaderComponent,
    AppLookupComponent,
    AppRegistrationComponent,
    GsdbAuthNotAcceptComponent,
    GsdbContactsSidebarComponent,
    GsdbContactsMainComponent,
    GsdbContactsChiefComponent,
    GsdbContactsFormComponent,
    GsdbContactsVpComponent,
    GsdbContactsFordComponent,
    GsdbContactsCeoComponent,
    NotFoundComponent,
    GsdbCodeInvalidComponent,
    SuccessComponent,
    EmbededEmailComponent,
    EmbededEmailConfirmationComponent,
    GsdbForSitecodeComponent,
    GsdbQueryComponent,
    GsdbFailedSearchComponent,
    GsdbQueryResultsComponent,
    GsdbDeleteQueryComponent,
    GsdbContactsSearchComponent,
    PopUpComponent,
    ContactSearchResultsComponent,
    UserAccessReviewComponent,
    UserAccessReviewPopupComponent,
    UserAccessReviewInstructionsComponent,
    UserAccessReviewSystemsComponent,
    UserAccessReviewSystemsDescComponent,
    UserAccessReviewCriticalComponent,
    UserHierarchyHomeComponent,
    AnnualReviewPageComponent,
    LoadingComponent,
    BackendCoonectionErrorComponent,
    NewControllerComponent,
    NewApproverComponent,
    ConfirmationComponent,
    CsaHomeComponent,
    CsaReviewComponent,
    GsdbContactsIsoComponent,
    GsdbContactsExecutiveComponent,
    GsdbContactsSalesComponent,
    GsdbContactsVsmlComponent,
    GsdbContactsKfcComponent,
    EmptyComponentComponent,
    ReviewNotCompletedComponent,
    ReviewCompletedComponent,
    UserSelfRemovalComponent,
    SelfRemovalSuccessComponent,
    CriticalInformationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTooltipModule,
    NgxAdfsModule.forRoot({
      openidUrl: environment.openidUrl,
      openidClientId: environment.openidClientId,
      openidResource: environment.openidResource,
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true // Ensure it's added to the list of interceptors
    }
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
