<app-welcome></app-welcome>
<div class="about-container mt-3">
  <p class="heading">About this site: Production Global Terms & Conditions</p>
  <div class="mt-3 ml-2 mr-2">
    <p>Here is a brief description of the items available on this site via the menu shown on the left side of this
      page:</p>
    <div class="mt-4">
      <a class="production-heading" (click)="getPpgtcPdf()">Production Purchasing Global Terms and Conditions- Jul. 1, 2021</a>
    </div>
    <ul class="list-disc ml-12">
      <li>Ford Production Purchasing Global Terms and Conditions, PPGTC July 1 2021), are accessible in English,
        Spanish, German, Chinese, and Portuguese. The PPGTCs are effective July 1, 2021, replacing PPGTCs dated
        January 1, 2004.
      </li>
    </ul>
    <div class="mt-4">
      <a class="production-heading" routerLink="/gtc/production/guides">GTC SUPPLIER GUIDES</a>
    </div>
    <p>As explained in the GTCs, these guides list specific requirements for matters like packaging, shipping,
      warranty, Service Parts, taxes, environmental, Payment Terms, etc. GTC Supplier Guides listed on this site are
      part of the Purchase Order and are binding on the Supplier and the Buyer.</p>
    <div class="mt-4">
      <a class="production-heading" routerLink="/gtc/production/communications">COMMUNICATIONS</a>
    </div>
    <p>Announcements and communications related to the Ford GTC's</p>
    <div class="mt-4">
      <a class="production-heading" routerLink="/gtc/production/supplements">SUPPLEMENTS</a>
    </div>
    <p>Supplemental Terms and Conditions are sometimes used by the Buyer to address special product, local market or
      legal requirements.'</p>
    <div class="mt-4">
      <a class="production-heading" routerLink="/gtc/production/summary">GTC CHANGE SUMMARY and FAQ's</a>
    </div>
    <ul class="list-disc ml-12">
      <li>Summary document with high-level description of the key changes incorporated into the Ford Global Terms
        and Conditions, PPGTC JAN. 1 2004 vs. the prior version (FGT27, Rev. 9/00). Publication date TBD.
      </li>
      <li>Frequently Asked Questions (FAQ's) – Answers to commonly asked questions regarding the Ford GTC's.</li>
    </ul>
    <div class="mt-4">
      <a class="production-heading" routerLink="/gtc/production/history">HISTORY OF UPDATES</a>
    </div>
    <p class="mb-2">Chronological list of additions and document revisions to the Ford GTC website</p>
  </div>
</div>
