import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {UserAccessList, UserAccessReview} from "../../../models/paars-web/user-access-review.model";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserCsaService {
  private url = environment.paarsWebApiUrl;

  constructor(private http: HttpClient) {
  }

  getCsaReviewDetails(reviewAction: string): Observable<UserAccessReview[]> {
    return this.http.get<UserAccessReview[]>(this.url + '/api/v1/getCsaAccessReview?reviewerAction=' + reviewAction)
  }

  saveCsaRecords(records: UserAccessReview[]): Observable<UserAccessList> {
    return this.http.put<UserAccessList>(this.url + '/api/v1/updateCsaAccessReview', records)
  }
}
