import { Component } from '@angular/core';

@Component({
  selector: 'app-gsdb-code-invalid',
  templateUrl: './gsdb-code-invalid.component.html',
  styleUrls: ['./gsdb-code-invalid.component.scss']
})
export class GsdbCodeInvalidComponent {

}
