import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { PerlMigrationService } from '../service/perl-migration.service';

@Component({
  selector: 'app-supplier-search',
  templateUrl: './supplier-search.component.html',
  styleUrls: ['./supplier-search.component.scss']
})
export class SupplierSearchComponent {
  supplierForm: FormGroup;
  blobUrl!: string;

  constructor(private fb: FormBuilder, private apiService: PerlMigrationService) {
    this.supplierForm = this.fb.group(({
      supplierName: ['', [Validators.required]]
    }))
  }

  onSubmit() {
    const supplierId :String = this.supplierForm.get('supplierName')?.value;
    this.getSupplierDetails(supplierId);
  }

  getSupplierDetails(supplierId:String){
    this.apiService.getSupplierSearch(supplierId).subscribe((data)=>{
      const blob = new Blob([data],{type:'application/xml'});
      this.blobUrl = URL.createObjectURL(blob);
      this.supplierForm.reset();
      window.location.href=this.blobUrl;
        },error=>{
      console.log(error);
    })
  }

}
