import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EverestTokenServiceService } from '../service/everest-token-service.service';
import { SiteCodes } from 'src/app/models/perl-migration-web/everest-token/site-codes.model';

@Component({
  selector: 'app-everest-token-file-upload',
  templateUrl: './everest-token-file-upload.component.html',
  styleUrls: ['./everest-token-file-upload.component.scss']
})
export class EverestTokenFileUploadComponent{

  uploadFileGroup: FormGroup;
  filename:String ='';
  siteCodesModel!: SiteCodes;

  constructor(private fb: FormBuilder,private router: Router, private dataService: EverestTokenServiceService){
      this.uploadFileGroup= this.fb.group({
        file: [null,Validators.required],
        email: ["",[Validators.email,Validators.required]]
      });
  }

  onSubmit(){
    const file: File = this.uploadFileGroup.get('file')!.value;
    this.filename = file.name;
    const email = this.uploadFileGroup.get('email')?.value;
    this.getListOfSiteCode(file,email)
  }

  onFileUpload(event: Event){
      const input = event.target as HTMLInputElement;
      if(input.files && input.files.length>0){
        const file = input.files[0];
        console.log("file uploaded : ", file);
        this.uploadFileGroup.patchValue({file : file})
      }
  }

  getListOfSiteCode(file:File,email:String){
    this.dataService.getListOfSiteCodes(file,email).subscribe(
      (data: SiteCodes)=>{
      this.siteCodesModel = data;
      this.navigateToFileConfirmation();
    },error =>{
      console.error('Error fetching the site codes',error);
    });
  }

  navigateToFileConfirmation(){
    const data= {filename: this.filename, sitecodeModel: this.siteCodesModel}
    this.router.navigate(['/perlMigrationWeb/everstTokenLookup/uploadConfirmation'], {state:{data}})
  }

}
