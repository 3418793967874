import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IdConverterService {

  private url = environment.perlMigrationApiUrl

  constructor(private http: HttpClient) { }

  getIdConverter(id:String,idtype:String):Observable<String>{
      return this.http.get<String>(this.url+'/api/v1/idconverter?name='+id+'&enterid='+idtype,
        {responseType:'text' as 'json'}
      );
  }
}
