import { Component } from '@angular/core';

@Component({
  selector: 'app-backend-coonection-error',
  templateUrl: './backend-coonection-error.component.html',
  styleUrls: ['./backend-coonection-error.component.scss']
})
export class BackendCoonectionErrorComponent {

}
