<app-gsdb-online-header></app-gsdb-online-header>
<div class="body ml-8 mr-8 mt-8">
  <p class="para-title pl-4 mt-14"><b> GSDB Online Steps > <span class="intro"> Introduction</span> :: Nomination ::
    Indemnification</b></p>
  <p class="text-center font-size mt-12"><b>You have already completed Authorization and Indemnification forms for GSDB
    ONLINE.</b></p>
  <p class="text-center font-size"><b>Thank You.</b></p>
  <p class="text-center font-size text-gray-500 mb-0">Questions about GSDB Online?</p>
  <p class="text-center font-size text-gray-500 mt-0 mb-0">Contact <span class="text-decoration-underline">gsdbweb@ford.com</span> or access <a
    href="/support" class="nav-link">Ford Help Desk Support </a>
  </p>
  <p class="text-center font-size text-gray-500 mt-0">The customer support center is available 24 hours each day.</p>
</div>
