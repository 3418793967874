import {Component, inject} from '@angular/core';
import {DIALOG_DATA} from "@angular/cdk/dialog";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent {
  readonly dialogRef = inject(MatDialogRef<ConfirmationComponent>);
  data = inject(DIALOG_DATA);

  constructor() {
  }

  cancel() {
    this.dialogRef.close();
  }

  ok() {
    this.dialogRef.close();
    this.data.confirm()
  }
}
