import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EmbededEmailService } from '../service/embeded-email.service';
import { FileDetails } from 'src/app/models/perl-migration-web/embeded-email/file-details.model';

@Component({
  selector: 'app-embeded-email',
  templateUrl: './embeded-email.component.html',
  styleUrls: ['./embeded-email.component.scss']
})
export class EmbededEmailComponent {

  uploadFileGroup: FormGroup;
  fileName: String = '';
  sendTo:String='';
  emailType:String='';
  fileDetails!: FileDetails;

  constructor(private fb: FormBuilder, private router:Router, private apiService:EmbededEmailService){
    this.uploadFileGroup = this.fb.group({
      file:['',Validators.required],
      sendTo:['',[Validators.required,Validators.email]],
      emailType:['',Validators.required]
    })
  }

  onSubmit(){
    const file : File = this.uploadFileGroup.get('file')?.value;
    this.sendTo= this.uploadFileGroup.get('sendTo')?.value;
    this.emailType = this.uploadFileGroup.get('emailType')?.value;
    this.fileName = file.name;

    this.apiService.uploadEmailFile(this.sendTo,file,this.emailType).subscribe(
      (data)=>{
          this.fileDetails = data;
          this.navigateToFileConfirmation();
      },error=>{
        console.log(error)
        alert("Failed to upload file . Please contact support")
      }
    )
  }

  onFileUpload(event: Event){
    const input = event.target as HTMLInputElement;
    if(input.files && input.files.length>0){
      const file = input.files[0];
      console.log("file uploaded : ", file);
      this.uploadFileGroup.patchValue({file : file})
    }
  }

  navigateToFileConfirmation(){
    const data= {filename: this.fileName, fileDetails:this.fileDetails,emailType:this.emailType}
    this.router.navigate(['perlMigrationWeb/embededEmail/emailBuilder'], {state:{data}})
  }

}
