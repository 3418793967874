import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MemReg } from 'src/app/models/perl-migration-web/fsn-registration/mem-reg.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FsnRegistrationService {

  private url = environment.perlMigrationApiUrl;

  constructor(private http: HttpClient) { }

  getMemRegDetails(code:String): Observable<MemReg>{
    return this.http.get<MemReg>(this.url+'/api/v1/memberReg/'+code);
  }
}
