import {Component, inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {NewControllerComponent} from "../new-controller/new-controller.component";
import {UserHierarchyService} from "../service/user-hierarchy.service";
import {NewApproverComponent} from "../new-approver/new-approver.component";
import {NewApprover, UserHierarchyList, UserHierarchyReview} from "../../../models/paars-web/usrHierarchyReview";
import {PopUpComponent} from "../../../common/pop-up/pop-up.component";
import {ReviewNotCompletedComponent} from "../../common/review-not-completed/review-not-completed.component";
import {ReviewCompletedComponent} from "../../common/review-completed/review-completed.component";

@Component({
  selector: 'app-annual-review-page',
  templateUrl: './annual-review-page.component.html',
  styleUrls: ['./annual-review-page.component.scss']
})
export class AnnualReviewPageComponent implements OnInit {
  userHierarchyDetails: FormGroup;
  records!: FormArray<FormGroup>;
  copyRecords!: FormArray<FormGroup>;
  searchGroup: FormGroup;
  readonly dialog = inject(MatDialog);
  saveFlag: boolean = false
  approverCdsid: string[] = []
  hierarchyRecords: UserHierarchyReview[] = [];
  hasRecords: boolean = false;

  constructor(private fb: FormBuilder, private userHierarchyService: UserHierarchyService) {
    this.searchGroup = this.fb.group({
      cdsId: [''],
      approvalChain: [''],
      divisionOrPlantCode: [''],
    })
    this.userHierarchyDetails = new FormGroup({
      records: new FormArray([]),
      copyRecords: new FormArray([]),

    })
  }

  ngOnInit(): void {
    this.records = this.userHierarchyDetails.get('records') as FormArray;
    this.copyRecords = this.userHierarchyDetails.get('copyRecords') as FormArray;
    this.userHierarchyService.getUserHierarchyReviewDetails("REVIEWED").subscribe(data => {
      this.hierarchyRecords = data
      this.getHierarchyReviewDetails("REVIEWED")
    })
  }

  getHierarchyReviewDetails(reviewAction: string) {
    if (this.hierarchyRecords.length === 0) {
      this.hasRecords = true
      this.records.clear()
    } else {
      this.hasRecords = false
      this.records.clear()
      this.hierarchyRecords.forEach((record) => {
        this.approverCdsid.push(record.userCdsid)
        this.pushFormGroup(record);
      });
      this.approverCdsid = Array.from(new Set(this.approverCdsid));
      if (reviewAction === "REVIEWED") {
        this.copyRecords = this.fb.array(
          this.records.controls.map(record => this.cloneFormGroup(record as FormGroup))
        );
      }
    }
  }

  pushFormGroup(record: UserHierarchyReview) {
    this.records = this.userHierarchyDetails.get('records') as FormArray;
    this.records.push(this.createUserForms(record));
  }

  createUserForms(record: UserHierarchyReview): FormGroup {
    return this.fb.group({
      approve: [record.approveFlag === "Y" ? "Y" : record.removeFlag === "Y" ? "N" : record.notChainOwnerFlag === "Y" ? "ncc" : ""],
      newController: new FormControl({
        value: record.newControllerCdsid,
        disabled: record.notChainOwnerFlag === "Y" ? false : true
      }),
      approverCdsId: [record.userCdsid],
      approverName: [record.userName],
      newApprover: new FormControl({
        value: record.replacementUser, disabled: record.removeFlag === "Y" ? false : true
      }),
      divPlt: [record.controllerOrg],
      approvalChainName: [record.hierarchyName],
      apvId: [record.approvalIdentifier],
      lvl: [record.approvalLevel],
      approvalLimit: [record.expenseApprovalLimit],
      limitOrComments: [record.comments],
      userHierId: [record.userHierId],
    })
  }

  getUncompletedRecords() {
    this.userHierarchyService.getUserHierarchyReviewDetails("NOTREVIEWED").subscribe(data => {
      this.hierarchyRecords = data
      this.getHierarchyReviewDetails("NOTREVIEWED")
    })
  }

  filterRecordsBySearch() {
    this.hasRecords = false
    let cdsId: string = this.searchGroup.get("cdsId")?.value
    let approvalChain: string = this.searchGroup.get("approvalChain")?.value
    let divisionOrPlantCode: string = this.searchGroup.get("divisionOrPlantCode")?.value
    if (cdsId === "" && approvalChain === "" && divisionOrPlantCode === "") {
      this.records.clear()
      this.copyRecords.controls.forEach((formGroup: FormGroup) => {
        this.records.push(formGroup)
      })
    } else {
      let fileterRecords =
        this.records.controls.filter(record => {
          let recordCdsId = record.get("approverCdsId")?.value
          let recordChain = record.get("approverName")?.value
          let recordPlantCode = record.get("divPlt")?.value
          return (
            recordCdsId.match(cdsId) &&
            recordChain.match(approvalChain) &&
            recordPlantCode.match(divisionOrPlantCode)
          );
        }).map(record => this.cloneFormGroup(record as FormGroup))
      this.records.clear()
      this.searchGroup.get("cdsId")?.setValue("")
      this.searchGroup.get("approvalChain")?.setValue("")
      this.searchGroup.get("divisionOrPlantCode")?.setValue("")
      fileterRecords.forEach((formGroup: FormGroup) => {
        this.records.push(formGroup)
      })
    }
  }

  disableNcc(formGroup: FormGroup) {
    const value: string = formGroup.get('approve')?.value;
    if (value === "N") {
      formGroup.get('newApprover')?.enable()
    } else {
      formGroup.get('newApprover')?.reset();
      formGroup.get('newApprover')?.disable();
    }
    formGroup.get('newController')?.reset();
    formGroup.get('newController')?.disable();
  }

  enableNcc(formGroup: FormGroup) {
    formGroup.get('newController')?.enable();
    formGroup.get('newApprover')?.reset();
    formGroup.get('newApprover')?.disable();
  }

  addNewController() {
    const dialogRef = this.dialog.open(NewControllerComponent, {
      minWidth: '650px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.copyRecords = this.fb.array(
        this.records.controls.map(record => this.cloneFormGroup(record as FormGroup))
      );
      const newController: string = this.userHierarchyService.getNewController();
      if (newController !== "") {
        this.saveFlag = true
        this.records.controls.forEach((formGroup: FormGroup) => {
          formGroup.get('newController')?.setValue(newController)
          formGroup.get('newController')?.enable()
          formGroup.get('newApprover')?.reset();
          formGroup.get('newApprover')?.disable();
          formGroup.get('approve')?.setValue("ncc")
        })
        this.userHierarchyService.setNewController("")
      }
    });
  }

  cloneFormGroup(formGroup: FormGroup): FormGroup {
    const clonedGroup = this.fb.group({});
    Object.keys(formGroup.controls).forEach(key => {
      // Get the control from the original group
      const control = formGroup.controls[key];      // Create a new FormControl based on the original's value and validators
      let clonedControl = new FormControl(control.value, control.validator)
      if (control.disabled) {
        clonedControl.disable()
      }
      clonedGroup.addControl(key, clonedControl);
    });
    return clonedGroup;
  }

  displayUpperNcc(formGroup: FormGroup) {
    formGroup.get("newController")?.setValue(formGroup.get("newController")?.value.toUpperCase())
  }

  displayUpperNewApprover(formGroup: FormGroup) {
    formGroup.get("newApprover")?.setValue(formGroup.get("newApprover")?.value.toUpperCase())
  }

  ok() {
    this.saveFlag = false
    this.save()
  }

  getHierarchyRecords(): UserHierarchyReview[] {
    let userAccRecords: UserHierarchyReview[] = []
    this.records.controls.forEach((formGroup: FormGroup) => {
      let approveValue = formGroup.get("approve")?.value
      let comments = formGroup.get("limitOrComments")?.value
      if (approveValue !== "" || comments !== "") {
        let userRecord: UserHierarchyReview = new UserHierarchyReview()
        userRecord.approveFlag = approveValue == "Y" ? "Y" : "N"
        userRecord.removeFlag = approveValue == "N" ? "Y" : "N"
        userRecord.notChainOwnerFlag = approveValue == "ncc" ? "Y" : "N"
        userRecord.newControllerCdsid = formGroup.get("newController")?.value
        userRecord.userCdsid = formGroup.get("approverCdsId")?.value
        userRecord.userName = formGroup.get("approverName")?.value
        userRecord.replacementUser = formGroup.get("newApprover")?.value
        userRecord.controllerOrg = formGroup.get("divPlt")?.value
        userRecord.hierarchyName = formGroup.get("approvalChainName")?.value
        userRecord.approvalIdentifier = formGroup.get("apvId")?.value
        userRecord.approvalLevel = formGroup.get("lvl")?.value
        userRecord.expenseApprovalLimit = formGroup.get("approvalLimit")?.value
        userRecord.expenseApprovalLimit = formGroup.get("approvalLimit")?.value
        userRecord.comments = comments
        userRecord.userHierId = formGroup.get("userHierId")?.value
        userAccRecords.push(userRecord)
      }
    })
    return userAccRecords;
  }

  cancel() {
    this.saveFlag = false
    this.records.clear()
    this.copyRecords.controls.forEach((formGroup: FormGroup) => {
      this.records.push(formGroup)
    })
  }

  addNewApprover() {
    const dialogReference = this.dialog.open(NewApproverComponent, {
      minWidth: '650px', data: {
        approverIds: this.approverCdsid,
      }
    });
    dialogReference.afterClosed().subscribe(result => {
      this.copyRecords = this.fb.array(
        this.records.controls.map(record => this.cloneFormGroup(record as FormGroup))
      );
      const newApprover: NewApprover = this.userHierarchyService.getNewApprover();
      if (newApprover.status === "DELETE") {
        this.deleteApprovers(newApprover)
      } else if (newApprover.status === "REPLACE") {
        this.replaceApprovers(newApprover)
      }
    });
  }

  replaceApprovers(approver: NewApprover) {
    this.records.controls.forEach((formGroup: FormGroup) => {
      if (formGroup.get('approverCdsId')?.value === approver.deleteCdsId) {
        formGroup.get('newController')?.reset();
        formGroup.get('newController')?.disable()
        formGroup.get('newApprover')?.setValue(approver.replaceCdsId)
        formGroup.get('newApprover')?.enable();
        formGroup.get('approve')?.setValue("N")
      }
    })
    this.saveFlag = true
    this.userHierarchyService.setNewApprover(new NewApprover())
  }

  deleteApprovers(approver: NewApprover) {
    this.records.controls.forEach((formGroup: FormGroup) => {
      if (formGroup.get('approverCdsId')?.value === approver.deleteCdsId) {
        formGroup.get('newController')?.reset();
        formGroup.get('newController')?.disable()
        formGroup.get('newApprover')?.reset()
        formGroup.get('newApprover')?.enable();
        formGroup.get('approve')?.setValue("N")
      }
    })
    this.saveFlag = true
    this.userHierarchyService.setNewApprover(new NewApprover())
  }

  save() {
    this.userHierarchyService.saveHierarchyReviewDetails(this.getHierarchyRecords()).subscribe((data: UserHierarchyList) => {
      this.hierarchyRecords = data.userHierarchyReviewList
      this.getHierarchyReviewDetails("REVIEWED")
    }, (error) => {
      this.displayError()
    })
  }

  submitRecords() {
    this.userHierarchyService.saveHierarchyReviewDetails(this.getHierarchyRecords()).subscribe((data) => {
      this.hierarchyRecords = data.userHierarchyReviewList
      this.getHierarchyReviewDetails("REVIEWED")
      if (data.status === "Not Completed") {
        this.dialog.open(ReviewNotCompletedComponent, {
          minWidth: '500px',
          maxWidth: "700px",
          disableClose: true,
          data: {
            title: "ncc"
          },
        })
      } else if (data.status === "Completed") {
        this.dialog.open(ReviewCompletedComponent, {
          minWidth: '500px',
          maxWidth: "700px",
          disableClose: true,
        })
      }
    }, (error) => {
      this.displayError()
    })
  }

  displayError() {
    this.dialog.open(PopUpComponent, {
      maxWidth: '500px',
      data: {
        message: "Please enter valid details and retry again.",
      },
    })
  }
}
