<app-common-header></app-common-header>
<div class="container-fluid">
  <div>
    <div class="row">
        <div class="font1">
            <b> Error occured while processing your request </b>
        </div>
        <div class="font1">
             The following error has occured:
        </div>
    </div>

    <div class="row">
        <div class="col-9 font1">
            <b> -- The following errors were generated while processing your request: </b>
        </div>
    </div>

    <div class="row">
        <div class="col-1"></div>
        <div class="col-7 font1 font2">
            <b>The application has encountered error while trying to connect to backend servers.</b><br>
            please try again later or use the help options listed.
        </div>
    </div>

    <div class="row link-text">
       &nbsp; &nbsp; Please call the SPOC Helpdesk for assistance. <a class="link-text2" routerLink="/support"><b>Click here</b> </a>
        for a list of available contacts.
    </div><br>

    <div class="row">
        <div class="col-9"><hr></div>
    </div>

    <div class="row font1 font3">
        <div><b>Thrown:</b> N/A</div>
           <div> <b>Message:</b> N/A</div>
    </div>
  </div>
</div>
