import {Injectable} from '@angular/core';
import {NewApprover, UserHierarchyList, UserHierarchyReview} from "../../../models/paars-web/usrHierarchyReview";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserHierarchyService {
  private newController: string = ''
  private newApprover: NewApprover = new NewApprover()
  private url = environment.paarsWebApiUrl;

  constructor(private http: HttpClient) {
  }

  getNewController() {
    return this.newController
  }

  setNewController(newController: string) {
    this.newController = newController;
  }

  getNewApprover(): NewApprover {
    return this.newApprover
  }

  setNewApprover(newApprover: NewApprover) {
    this.newApprover = newApprover
  }

  getUserHierarchyReviewDetails(reviewAction: string): Observable<UserHierarchyReview[]> {
    return this.http.get<UserHierarchyReview[]>(this.url + '/api/v1/getUserHierarchyReview?reviewerAction=' + reviewAction)
  }

  saveHierarchyReviewDetails(records: UserHierarchyReview[]): Observable<UserHierarchyList> {
    return this.http.put<UserHierarchyList>(this.url + '/api/v1/updateUserHierarchyReview', records)
  }
}
