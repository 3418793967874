import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {stateOrProvice, countries} from "./../../../constants/perl-migration-web/gsdbContacts"
import { GsdbContactsMain } from 'src/app/models/perl-migration-web/gsdb-contacts-main/gsdb-contacts-main.model';

@Component({
  selector: 'app-gsdb-contacts-form',
  templateUrl: './gsdb-contacts-form.component.html',
  styleUrls: ['./gsdb-contacts-form.component.scss']
})
export class GsdbContactsFormComponent implements OnChanges {

  contactsForm: FormGroup;
  gsdbStateOrProvice = stateOrProvice;
  gsdbCountries = countries
  @Output() update = new EventEmitter<any>();

  @Input() formData!: GsdbContactsMain;
  companyName: String = 'no company data';

  constructor(private fb: FormBuilder) {
    this.contactsForm = this.fb.group(({
      exeFirstName: [this.formData?.firstName,Validators.required],
      exeLastName: [this.formData?.lastName,Validators.required],
      prefTitle: [this.formData?.prefTitle,Validators.required],
      email: [this.formData?.email,Validators.required],
      businessCountryCd: [this.formData?.telCountryCode,Validators.required],
      businessTel: [this.formData?.telephone,Validators.required],
      faxCountryCd: [this.formData?.faxCountryCode,Validators.required],
      faxTel: [this.formData?.fax,Validators.required],
      mailAddr: [this.formData?.mailAddress,Validators.required],
      officeSuite: [this.formData?.buildingSuite],
      city: [this.formData?.cityTown,Validators.required],
      county: [this.formData?.county],
      state: [this.formData?.stateProvince,Validators.required],
      postalCode: [this.formData?.zipPostalCode,Validators.required],
      selCountry: [this.formData?.country,Validators.required],
      emergencyFirstName: [this.formData?.altContactFirstName,Validators.required],
      emergencyLastName: [this.formData?.altContactLastName,Validators.required],
      emeContactCountryCd: [this.formData?.altContactCountryCode,Validators.required],
      emeContactTel: [this.formData?.altContactTelephone,Validators.required]
    }))
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.contactsForm.patchValue({
      exeFirstName: this.formData?.firstName, exeLastName: this.formData?.lastName,
      prefTitle: this.formData?.prefTitle,
      email: this.formData?.email, businessCountryCd: this.formData?.telCountryCode,
      businessTel: this.formData?.telephone, faxCountryCd: this.formData?.faxCountryCode,
      faxTel: this.formData?.fax, mailAddr: this.formData?.mailAddress,
      officeSuite: this.formData?.buildingSuite, city: this.formData?.cityTown,
      country: this.formData?.country, state: this.formData?.stateProvince,
      postalCode: this.formData?.zipPostalCode, selCountry: this.formData?.country,
      emergencyFirstName: this.formData?.altContactFirstName, 
      emergencyLastName: this.formData?.altContactLastName,
      emeContactCountryCd: this.formData?.altContactCountryCode, 
      emeContactTel: this.formData?.altContactTelephone
    });
    this.companyName = this.formData?.companyName;
  }



  reset() {
    this.contactsForm.reset()
  }

  onSubmit() {
    // if(this.contactsForm.invalid){
    //   alert("Incomplete form. Some of the mandatory input fields are missing");
    // }else{
      this.update.emit(this.contactsForm.value);
    //}
    
  }
}
