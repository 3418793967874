import {Component} from '@angular/core';

@Component({
  selector: 'app-gsdb-reg-complete',
  templateUrl: './gsdb-reg-complete.component.html',
  styleUrls: ['./gsdb-reg-complete.component.scss']
})
export class GsdbRegCompleteComponent {

}
