import {Routes} from "@angular/router";
import {AuthenticationGuard} from "@wame/ngx-adfs";
import {UserAccessReviewComponent} from "../user-access-review/user-access-review/user-access-review.component";
import {
  UserAccessReviewPopupComponent
} from "../user-access-review/user-access-review-popup/user-access-review-popup.component";
import {UserAccessReviewGuard} from "../user-access-review/user-access-review.guard";
import {UserHierarchyHomeComponent} from "../user-hierarchy-review/user-hierarchy-home/user-hierarchy-home.component";
import {AnnualReviewPageComponent} from "../user-hierarchy-review/annual-review-page/annual-review-page.component";
import {CsaHomeComponent} from "../user-csa-review/csa-home/csa-home.component";
import {CsaReviewComponent} from "../user-csa-review/csa-review/csa-review.component";
import {UserSelfRemovalComponent} from "../user-self-removal/user-self-removal/user-self-removal.component";

export const paarsWebRoutes: Routes = [
  {
    path: 'PAARS/userAccessReview/reviewAccess',
    component: UserAccessReviewComponent,
    canActivate: [AuthenticationGuard, UserAccessReviewGuard]
  },

  {
    path: 'PAARS/userAccessReview/userAgreement',
    component: UserAccessReviewPopupComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'PAARS/userHierarchy/home',
    component: UserHierarchyHomeComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'PAARS/userHierarchy/annualReview',
    component: AnnualReviewPageComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'PAARS/userCSAReview/home',
    component: CsaHomeComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'PAARS/userCSAReview',
    component: CsaReviewComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'PAARS/userSelfRemoval',
    component: UserSelfRemovalComponent,
    canActivate: [AuthenticationGuard]
  }
]
