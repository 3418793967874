<div class="container-fluid header-container">
  <div class="row parent flex">
    <div class="image1">
      <img src="\assets\top_left.gif">
    </div>
    <div class="image2">
      <div class="row row-height">
        <p class="font1 mb-0">	Global Supplier Database Online Access</p>
        <div class="side-headings">
          <a class="right-menu" [routerLink]="[]" fragment="benefits">Benefits</a> |
          <a class="right-menu" [routerLink]="[]" fragment="supplierInfo">Supplier Info</a> |
          <a class="right-menu" [routerLink]="[]" fragment="security">Security </a> |
          <a class="right-menu" [routerLink]="[]" fragment="additionalInfo">Additional Info</a> |
          <a href="https://web.keyinfo.ford.com/northamerica/policyandprocedures/docs/GSDB/GSDB%20Online.htm#guides">Help</a>
        </div>
      </div>
      <div class="row row-height2">
        <div class="background-color"></div>
      </div>
    </div>
    <div class="image3 flex-1">
      <img class="top-right-image" src="\assets\top_right.gif">
    </div>
  </div>
</div>
