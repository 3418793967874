import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppLaunch } from 'src/app/models/perl-migration-web/app-launch/app-launch.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppLaunchService {
  private url = environment.perlMigrationApiUrl;

  constructor(private http: HttpClient) { }

  postAppLaunchData(appLaunch: AppLaunch): Observable<String>{
    return this.http.post<String>(this.url+'/api/v1/appLaunch',appLaunch,{
      reportProgress: true
    })

  }
}
