<div class="left-sidebar mr-4 p-3 mt-8">
  <div class="indirect text-center">Indirect GTC's</div>
  <div class="text-center mt-2 mb-2">
    <a class="color" routerLink="/gtc/indirect">Home</a>
  </div>
  <hr>
  <ul class="list-disc">
    <li class="color m-2" routerLink="/gtc/indirect/terms">Terms & Conditions</li>
    <li class="color m-2" routerLink="/gtc/indirect/guides">GTC Supplier-Guides</li>
    <li class="color m-2" routerLink="/gtc/indirect/communications">Communications</li>
    <li class="color m-2" (click)="showPiiSupplementPdf()">PII Supplement</li>
    <li class="color m-2" (click)="getInfoSecuritySupplementPdf()">Information Security Supplement</li>
    <li class="color m-2" routerLink="/gtc/indirect/anti">Anti-Corruption Supplement</li>
    <li class="color m-2" (click)="getUkPurchasingTaxPdf()">UK Purchasing Tax Supplement (UK Only)</li>
    <li class="color m-2" routerLink="/gtc/indirect/supplements">Supplements</li>
    <li class="color m-2" (click)="getMarketingServicesSupplementDoc()">Marketing Services Supplement</li>
    <li class="color m-2" (click)="getSupplierTravelExpensePolicyPdf()">Supplier Travel & Expense Policy (NA Only)</li>
    <li class="color m-2" routerLink="/gtc/indirect/history">History of Updates</li>
  </ul>
  <hr>
  <div class="text-center mt-2 mb-2" routerLink="/gtc/production"><a class="color">Production</a></div>
</div>
