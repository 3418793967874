import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EverestTokenServiceService } from '../service/everest-token-service.service';
import { EverestTokenDetails } from 'src/app/models/perl-migration-web/everest-token/everest-token-details.model';

@Component({
  selector: 'app-everest-token-by-code',
  templateUrl: './everest-token-by-code.component.html',
  styleUrls: ['./everest-token-by-code.component.scss']
})
export class EverestTokenByCodeComponent{
  
  siteCodeGroup : FormGroup;
  siteCodeList : String[]= [];
  everestTokenDetails: EverestTokenDetails | null=null;


  constructor(private fb : FormBuilder,private router: Router,private dataService: EverestTokenServiceService){
      this.siteCodeGroup = this.fb.group({
        siteCode:['',[Validators.required,Validators.pattern('^.{4}$|^.{5}$')]]
      })
  }

  onSubmit(){
    const siteCode:String = this.siteCodeGroup.get('siteCode')?.value;
    if(siteCode.length==4){
      this.fetchSiteCodeList(siteCode);
    }
    if(siteCode.length==5){
      this.fetchEverestTokenDetails(siteCode);
      
    }

  }

  fetchSiteCodeList(supplier:String){
      this.dataService.getSiteCodeBySupplierCode(supplier).subscribe(
        (data: String[])=>{
          this.siteCodeList = data;
          this.navigateToSiteSelection();
        },error =>{
          console.error('Error fetching the site codes',error);
        }
      );
  }

  fetchEverestTokenDetails(sitecode:String){
    this.dataService.getEverestTokenDetails(sitecode).subscribe(
      (data: EverestTokenDetails)=>{
      this.everestTokenDetails = data;
      this.navigateToEverestTokeDetails();
    },error =>{
      console.error('Error fetching the site codes',error);
    });
  }

  navigateToSiteSelection(){
    const data = {siteCodes:this.siteCodeList};
    this.router.navigate(["/perlMigrationWeb/everstTokenLookup/siteSelect"],{state:{data}});
  }

  navigateToEverestTokeDetails(){
    const data = {type:'code',tokenDetails: this.everestTokenDetails}
    this.router.navigate(["/perlMigrationWeb/everstTokenLookup/lookupReport"],{state:{data}});
  }

}
