<div class="page-margin">
  <div class="container-fluid content_container pt-2">
    <div class="row">
      <div class="col-2">
        <img class="ford-icon" src="/assets/ford.png" alt="Purchasing Annual Access Review System"/>
      </div>
      <div class="col-10">
        <div class="title-alignment row">
          <p class="mb-0 font-bold">Purchasing & Associated Systems Annual Access Review Manager Home Page</p>
          <p class="mt-0 font-bold mb-1" *ngIf="date !==''"> (Access status as of {{ date | date:'MMM d, y'}})</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="content-align">
          <tr class="tr">
            <td><h6 class="td-font3">
              This review is for User System access.<BR/>
              You may receive other review requests for other systems.</h6>
            </td>
          </tr>
          <tr class="tr">
            <td>
              <h6 class="td-font">Approval of users indicates you accept responsibility for the access
                you are authorizing including the user's ability to appropriately and effectively
                utilize the access they have been granted and,<br> if applicable, the user's delegation of
                authority.</h6>
            </td>
          </tr>
          <tr class="tr">
            <td><h6 class="td-font">Audit requirements stipulate if there are any changes to the user's
              job role prior to the next Annual Access Review, the appropriate access form must be
              completed and forwarded according<br> to the directions on the form.
            </h6>
            </td>
          </tr>
          <br>
          <tr>
            <td>
              <h6 class="td-font">Clicking <b class="text-danger">&quot;I Accept&quot;</b> at the bottom of this page
                indicates acceptance of the
                statements above for the user records you action and will allow you to complete the
                Annual Access Review.
              </h6>
            </td>
          </tr>
          <tr>
            <td>
              <h6 class="td-font2"> Critical Information:</h6>
            </td>
          </tr>
          <tr>
            <ul class="list-style">
              <li>
                <td>If a user still requires access, click on
                  the &quot;Yes&quot; button, if not, click on the &quot;No&quot; button.
                </td>
              </li>
              <li>
                <td>If you are not the user's current manager ( i.e. Authorizer), click the &quot;NCM&quot;
                  check box and enter the CDSID of the current manager in the &quot;Current Manager&quot; box.
                </td>
              </li>
              <li>
                <td>
                  Please complete reassigns as soon as possible so that the newly identified
                  authorizer has significant time to complete the review.
                </td>
              </li>
              <li>
                <td>Use the comments field for any additional information; comments are limited to 250 characters.</td>
              </li>
              <li>
                <td>Please make sure you scroll to the end of the list, review every line, and select
                  &quot;Yes&quot;, &quot;No&quot; or &quot;NCM&quot; (not current manager) for each
                  user/job function and system access.
                </td>
              </li>
              <li>
                <td>Conflict of duties between CORA and CPARS Requisitioners and Approvers has been
                  eliminated based on system controls.
                </td>
              </li>
            </ul>
          </tr>
          <tr>
            <td>
              <h6 class="td-font2"> Attention Supervisors/Managers approving CPARS and
                Part Spec/BOSS90 users: </h6>
            </td>
          </tr>
          <tr>
            <ul class="list-style">
              <li>
                <td>If a user has the ability to transact in all CPARS locations (RAPS access),
                  "All Locations" will be displayed after the type of access in the
                  "Type of Access, Responsibility" column on the Manager Review Page
                  (example: Requisitioning - all locations). If this access is not correct,
                  please notify us using the "Comments" box and indicate which division/plant
                  codes the user should have access to.
                </td>
              </li>
              <li>
                <td>The Unauthorized Commitment Training (UCT) is offered by the Corporate Compliance Office.
                  It is highly recommended that all North American CPARS, Part Spec and Boss90 users complete
                  the training as identified on
                  <a (click)="openIntegrity($event)" href="#">www.integrity.ford.com</a>.
                </td>
              </li>
            </ul>
          </tr>
          <tr>
            <td>
              <h6 class="td-font2"> Click
                <button type="submit" class="btn xml-button" (click)="onAgree()">
                  I Accept
                </button>
                to complete the Annual Access User review.
              </h6>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>

</div>
