import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { stat } from 'fs';
import { FileDetails } from 'src/app/models/perl-migration-web/embeded-email/file-details.model';
import { EmbededEmailService } from '../service/embeded-email.service';
import { SendMailBody } from 'src/app/models/perl-migration-web/embeded-email/send-mail-body.model';

@Component({
  selector: 'app-embeded-email-confirmation',
  templateUrl: './embeded-email-confirmation.component.html',
  styleUrls: ['./embeded-email-confirmation.component.scss']
})
export class EmbededEmailConfirmationComponent {

  filename:String='';
  count:Number = 0;
  sendto:String='';
  emailType:String='';
  fileDetails!:FileDetails;
  sendMailBody!:SendMailBody;

  sendSuccess: boolean = false;
  emailSent: boolean = false;
  formSubmitted: boolean = false;
  emailConfirmed: boolean =true;

  emailBody: FormGroup;

  constructor(private router: Router, private fb: FormBuilder, private apiService: EmbededEmailService){
    
    const navigation = this.router.getCurrentNavigation();
  
    if(navigation!=null){
      const state = navigation.extras.state as {data: {filename: String, fileDetails:FileDetails, emailType:String}} ;
      this.filename = state.data.filename;
      this.count = state.data.fileDetails.emailAddresses.length;
      this.sendto = state.data.fileDetails.sendTo;
      this.emailType = state.data.emailType;
      this.fileDetails = state.data.fileDetails;
    }

    this.emailBody = this.fb.group({
      subject:['',Validators.required],
      emailText:['',Validators.required]
    })

  }

  onSubmit(){
    this.formSubmitted=true;
    this.emailConfirmed=false;
    this.emailBody.disable();
    this.sendMailBody = new SendMailBody(this.sendto,this.emailType,this.emailBody?.get('emailText')?.value,
        this.emailBody?.get('subject')?.value, this.fileDetails.emailAddresses);
  }

  sendMail(){
    this.apiService.sendMail(this.sendMailBody).subscribe((data)=>{
      this.emailSent=true;
      this.emailConfirmed=true
      this.sendSuccess = true;
    },error=>{
      console.log(error);
      this.emailConfirmed= true;
      this.emailSent = true;
      this.sendSuccess = false;
    })
  }

  editMail(){
    this.formSubmitted=false;
    this.emailBody.enable();
    this.emailSent=false;
  }



}
