import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-gsdb-failed-search',
  templateUrl: './gsdb-failed-search.component.html',
  styleUrls: ['./gsdb-failed-search.component.scss']
})
export class GsdbFailedSearchComponent implements OnInit {
  data: string = '';

  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef, private ngZone: NgZone) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.data = params['data'] || '';
    });
  }
}
