<div class="p-4 information-container">
  <p class="text-center font-bold font-size-18"><i>Purchasing & Associated Systems - Self Review with Option to
    Remove</i></p>
  <p class="font-bold">Critical Information on CPARS, PartSpec/Boss90 and CORA:</p>
  <ul class="list-disc font-small">
    <li class="text-decoration-underline">Aurora Reference:</li>
    CPARS and CORA are being replaced by AURORA.
    <li class="text-decoration-underline">CORA:</li>
    <li class="text-danger">If you use CORA to process any purchase documents e.g. negative amendments, please complete
      this Annual Review
      to retain your access
    </li>
    <li>For functionalities not supported by Aurora at this time, users retain their full CORA permissions (e.g. ICBA,
      JV) and can create new requisitions
    </li>
    <li>CORA requisitioning function has been disabled for most EU organizations; however, many users remain in CORA for
      Aurora launched organizations with their existing permissions intact (Approvers, Expense and Project Controllers,
      Key Requisitioners, View, etc.). This is done to allow for functionalities currently not supported and to create
      negative amendments (see above).
    </li>
    <li class="text-decoration-underline">CPARS:</li>
    <li>If you use CPARS in your daily job, please complete this Annual Review to retain your access</li>
    <li>Users are not being deleted from CPARS during Aurora deployment, but some functionality is being disabled</li>
    <li>CPARS requisitioning function has been disabled for most deployed div/plnt codes; however, users remain in CPARS
      with any existing permissions intact (Receive, Approve, Track, View, etc).
    </li>
    <li>For functionalities not supported by Aurora at this time, users retain their full CPARS/CORA permissions (ED&T,
      ICBA, Inventory, HC, JV, & more) – can create new requisitions
    </li>
    <li class="text-decoration-underline">Other Info:</li>
    <li>Conflict of duties between CORA and CPARS Requisitioners and Approvers has been eliminated based on system
      controls
    </li>
    <li>The Unauthorized Commitment Training (UCT) is offered by the Corporate Compliance Office. It is highly
      recommended that all North American CPARS, Part Spec and Boss90 users complete the training as identified on
      www.integrity.ford.com.
    </li>
    <li>If a user has the ability to transact in all CPARS locations (RAPS access), "All Locations" will be displayed
      after the type of access in the "Type of Access, Responsibility" column on the Manager Review Page (example:
      Requisitioning - all locations). If this access is not correct, please notify us using the "Comments" box and
      indicate which division/plant codes the user should have access to.
    </li>
  </ul>
  <button class="exit-btn" (click)="returnToView()">Return to Review</button>
</div>
