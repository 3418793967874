import {Component} from '@angular/core';
import {SupplementsService} from "../service/supplements.service";
import {map} from "rxjs";

@Component({
  selector: 'app-indirect-supplements',
  templateUrl: './indirect-supplements.component.html',
  styleUrls: ['./indirect-supplements.component.scss']
})
export class IndirectSupplementsComponent {
  constructor(private supplementalService: SupplementsService) {
  }

  getGtcSupplementalTermsChinaPdf() {
    this.supplementalService.getGtcSupplementalTermsChinaPdf();
  }

  getGtcSupplementalTermsCanadaPdf() {
    this.supplementalService.getGtcSupplementalTermsCanadaPdf();
  }

  getGtcSupplementalTermsAustraliaDoc() {
    this.supplementalService.getGtcSupplementalTermsAustraliaDoc();
  }

  getGtcSupplementalTermsIndiaPdf() {
    this.supplementalService.getGtcSupplementalTermsIndiaPdf();
  }

  getGtcSupplementalTermsBrazilPdf() {
    this.supplementalService.getGtcSupplementalTermsBrazilPdf();
  }

  getGtcSupplementalTermsMexicoPdf() {
    this.supplementalService.getGtcSupplementalTermsMexicoPdf();
  }

  getGtcSupplementalTermsGreecePdf() {
    this.supplementalService.getGtcSupplementalTermsGreecePdf();
  }

  getIndividualCollectiveLaborRelEng() {
    this.supplementalService.getIndividualCollectiveLaborRelEng();
  }

  getIndividualCollectiveLaborRelSpanish() {
    this.supplementalService.getIndividualCollectiveLaborRelSpanish();
  }

  getGTCSupplementalTermsEnglish() {
    this.supplementalService.getGTCSupplementalTermsEnglish();
  }

  getGTCSupplementalTermsSpanish() {
    this.supplementalService.getGTCSupplementalTermsSpanish();
  }
}
