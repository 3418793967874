import {Component} from '@angular/core';
import {SummeryService} from "../service/summery.service";
import {map} from "rxjs";

@Component({
  selector: 'app-summery',
  templateUrl: './summery.component.html',
  styleUrls: ['./summery.component.scss']
})
export class SummeryComponent {
  constructor(private summeryService: SummeryService) {
  }

  getPPGTCFAQPdf() {
    this.summeryService.getPPGTCFAQPdf();
  }

  getMajorChangesSummeryPdf() {
    this.summeryService.getMajorChangesSummeryPdf();
  }

  getKeyChangesPdf() {
    this.summeryService.getKeyChangesPdf();
  }

  getGtcFaqPdf() {
    this.summeryService.getGtcFaqPdf();
  }
}
