import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {GsdbOnlineIntroService} from "../service/gsdb-online-intro.service";
import {GsdbOnlineIntroResponse} from "../../../models/perl-migration-web/gsdb-online/gsdbOnlineIntroResponse";

@Component({
  selector: 'app-gsdb-online-intro',
  templateUrl: './gsdb-online-intro.component.html',
  styleUrls: ['./gsdb-online-intro.component.scss']
})
export class GsdbOnlineIntroComponent {
  gsdbForm: FormGroup;

  constructor(private fb: FormBuilder, private router: Router, private gsdbIntroService: GsdbOnlineIntroService) {
    this.gsdbForm = this.fb.group(({
      covisintId: ['', [Validators.required, Validators.maxLength(8)]]
    }))
  }


  onSubmit() {
    this.gsdbIntroService.gsdbOnlineRegistration(this.gsdbForm.value.covisintId).subscribe((data: GsdbOnlineIntroResponse) => {
      if (data.status === "COMPLETE") {
        this.router.navigate(['perlMigrationWeb/gsdbOnlineIntroduction/complete'])
      }else if(data.status === "INPROCESS"){
        this.router.navigate(['perlMigrationWeb/gsdbOnlineIntroduction/inprocess'])
      }
    }, error => {
      if (error.error.errorMessage === "User LDAP information is unavailable") {
        this.router.navigate(['perlMigrationWeb/gsdbOnlineIntroduction/denied'])
      }
    })
  }
}
