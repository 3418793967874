import {Component} from '@angular/core';
import {ProductionService} from "../service/production.service";

@Component({
  selector: 'app-production-right-sidebar',
  templateUrl: './production-right-sidebar.component.html',
  styleUrls: ['./production-right-sidebar.component.scss']
})
export class ProductionRightSidebarComponent {

  constructor(private productionService: ProductionService) {
  }

  getPpgtcPdf() {
    this.productionService.getPpgtcPdf();
  }

}
