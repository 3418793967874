import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AribaOnlineService } from '../service/ariba-online.service';
import { error } from 'console';

@Component({
  selector: 'app-ariba-online',
  templateUrl: './ariba-online.component.html',
  styleUrls: ['./ariba-online.component.scss']
})
export class AribaOnlineComponent {

  aribaLoginForm : FormGroup;
  id: string='';

  constructor(private fb: FormBuilder, private router: Router, private apiService: AribaOnlineService){
    this.aribaLoginForm = this.fb.group({
      aribaId : ['', Validators.required],
      emailId : ['',[Validators.required,Validators.email]] ,
      otp     : ['',Validators.required]
    })

  }

  onSubmit(){
    const otp:String = this.aribaLoginForm.get('otp')?.value;
    this.apiService.validateOtp(otp).subscribe((data)=>{
      this.router.navigate(["/gtc"]);
    },error=>{
      console.log(error);
      alert('failed to validate otp.Please try again');
    })
  }

  getOTP(event:Event){
    event.preventDefault();
    if(this.aribaLoginForm.get('emailId')?.invalid){
      alert("please enter email id to receive otp")
    }
   else{
      const emailId = this.aribaLoginForm.get('emailId')?.value;
      const aribaId = this.aribaLoginForm.get('aribaId')?.value;
      this.apiService.getOtp(aribaId,emailId).subscribe((data)=>{
          alert('otp sent to mail successfully');
      },error=>{
        console.log(error);
        alert('failed to send otp : '+ JSON.stringify(error));
      })
   }
  }

}
