import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {map, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SummeryService {
  private url = environment.gtcApiUrl

  constructor(private http: HttpClient) {
  }

  getPPGTCFAQPdf() {
    const safeFile = encodeURIComponent("PPGTCFAQ.pdf");
    const safeUriPath = "/api/v1/files/download";
    const finalUrl = `${this.url}/${safeUriPath}/${safeFile}`;

    this.http.get(finalUrl, {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_blank');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getMajorChangesSummeryPdf(){
    this.http.get(this.url + '/api/v1/files/download/GTCsMajorChangeSummaryOGCFinal11182003_1.doc', {responseType: 'blob'}).
    pipe(map(blob => {
      const url = window.URL.createObjectURL(blob);
      // Create a link element and trigger a click event
      const a = document.createElement('a');
      a.href = url;
      a.download = "GTCsMajorChangeSummaryOGCFinal11182003_1.doc";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      return blob;
    })).subscribe();
  }

  getKeyChangesPdf(){
    this.http.get(this.url + '/api/v1/files/download/July2021PPGTCs_KeyChanges.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcFaqPdf() {
     this.http.get(this.url + '/api/v1/files/download/2021GTCFAQ.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }
}
