<div class="left-sidebar mr-4 p-3 mt-8">
  <div class="production text-center">Production GTC's</div>
  <div class="text-center mt-2 mb-2">
    <a class="color" routerLink="/gtc/production">Home</a>
  </div>
  <hr>
  <ul class="list-disc">
    <li class="color m-2" routerLink="/gtc/production/terms">Terms & Conditions</li>
    <li class="color m-2" routerLink="/gtc/production/guides">GTC Supplier-Guides</li>
    <li class="color m-2" routerLink="/gtc/production/communications">Communications</li>
    <li class="color m-2" (click)="getInfoSecuritySupplementPdf()">Information Security Supplement</li>
    <li class="color m-2" (click)="showPiiSupplementPdf()">PII Supplement</li>
    <li class="color m-2" routerLink="/gtc/production/supplements">Supplements</li>
    <li class="color m-2" routerLink="/gtc/production/summary">GTC Change Summary & FAQ's</li>
    <li class="color m-2" routerLink="/gtc/production/history">History of Updates</li>
  </ul>
  <hr>
  <div class="text-center mt-2 mb-2" routerLink="/gtc/indirect"><a class="color">Indirect</a></div>
</div>
