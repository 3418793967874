import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserAccessService} from "../service/user-access.service";

@Component({
  selector: 'app-user-access-review-popup',
  templateUrl: './user-access-review-popup.component.html',
  styleUrls: ['./user-access-review-popup.component.scss']
})
export class UserAccessReviewPopupComponent implements OnInit {
  date: string = ""

  constructor(private router: Router, private userAccService: UserAccessService) {
  }

  ngOnInit(): void {

    this.userAccService.getTaskDate().subscribe(date => {
      this.date = date.date
    })
  }

  onAgree() {
    localStorage.setItem('userAccessAgreement', 'true');
    this.router.navigate(['/PAARS/userAccessReview/reviewAccess']);
  }

  openIntegrity(event: Event) {
    event.preventDefault();
    window.open('http://www.integrity.ford.com');
  }
}
