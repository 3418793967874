<form class="container" [formGroup]="contactsForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-6 font-medium">Company Name</div>
    <div class="col-6 font-medium">{{companyName}}</div>
  </div>
  <div class="row mb-1">
    <div class="col-12 col-lg-6 font-medium">Executive Name <b class="text-danger">*</b></div>
    <div class="col-12 col-lg-3 mb-1 mb-lg-0">
      <span class="font-small mr-1 mr-lg-0 block">Last</span>
      <input class="input" type="text" formControlName="exeLastName"/>
    </div>
    <div class="col-12 col-lg-3 mb-1 mb-lg-0">
     <span class="font-small mr-1 mr-lg-0 block">First</span>
     <input class="input" type="text" formControlName="exeFirstName"/>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-12 col-md-6">
      <p class="mb-0 mt-0 font-medium">Preferred Job Title <b class="text-danger">*</b></p>
      <p class="mt-0 mb-0 font-small color">Example: Chairperson, Quality Manager, etc.</p>
    </div>
    <div class="col-12 col-md-6"><input class="input" formControlName="prefTitle" type="text"/></div>
  </div>
  <div class="row mb-1">
    <div class="col-12 col-md-6 font-medium">Email Address <b class="text-danger">*</b></div>
    <div class="col-12 col-md-6"><input class="input" type="text" formControlName="email"/></div>
  </div>
  <div class="row mb-1">
    <div class="col-12 col-lg-6 font-medium">
      <p class="mt-0 mb-0 font-medium">Business Telephone <b class="text-danger">*</b></p>
      <p class="mt-0 mb-0 font-small color">Example: Country Code for U.S.A. is 1</p>
    </div>
    <div class="col-12 col-lg-3 mb-1 mb-lg-0">
      <span class="text-sm mr-1 mr-lg-0 font-small block">Country Code <b class="text-danger">*</b></span>
      <input class="input" type="text" formControlName="businessCountryCd"/>
    </div>
    <div class="col-12 col-lg-3 mb-1 mb-lg-0">
      <span class="text-sm mr-1 mr-lg-0 font-small block">Telephone Number <b class="text-danger">*</b></span>
      <input class="input" type="text" formControlName="businessTel"/>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-12 col-lg-6 font-medium">
      <p class="mt-0 mb-0 font-medium">Fax <b class="text-danger">*</b></p>
      <p class="mt-0 mb-0 font-small color">Example: Country Code for U.S.A. is 1</p>
    </div>
    <div class="col-12 col-lg-3 mb-1 mb-lg-0">
      <span class="text-sm mr-1 mr-lg-0 font-small block">Country Code <b class="text-danger">*</b></span>
      <input class="input" type="text" formControlName="faxCountryCd"/>
    </div>
    <div class="col-12 col-lg-3 mb-1 mb-lg-0">
      <span class="text-sm mr-1 mr-lg-0 font-small block">Telephone Number <b class="text-danger">*</b></span>
      <input class="input" type="text" formControlName="faxTel"/>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-12 col-md-6 font-medium">Mailing Address <b class="text-danger">*</b></div>
    <div class="col-12 col-md-6"><input class="input" type="text" formControlName="mailAddr"/></div>
  </div>
  <div class="row mb-1">
    <div class="col-12 col-md-6 font-medium">Building & Office Suite </div>
    <div class="col-12 col-md-6"><input class="input" type="text" formControlName="officeSuite"/></div>
  </div>
  <div class="row mb-1">
    <div class="col-12 col-md-6 font-medium">City/Town <b class="text-danger">*</b></div>
    <div class="col-12 col-md-6"><input class="input" type="text" formControlName="city"/></div>
  </div>
  <div class="row mb-1">
    <div class="col-12 col-md-6 font-medium">County</div>
    <div class="col-12 col-md-6"><input class="input" type="text" formControlName="county"/></div>
  </div>
  <div class="row mb-1">
    <div class="col-12 col-md-6">
      <p class="font-medium m-0">State/Province <b class="text-danger">*</b></p>
      <p class="m-0 font-small color">( If your State/Province is not in the list, please choose "Other/Does Not Apply"
        )</p>
    </div>
    <div class="col-12 col-md-6">
      <select class="input font-size-13" formControlName="state">
        <option value="" selected>Please Select a State/Province <b class="text-danger">*</b></option>
        <option *ngFor="let option of gsdbStateOrProvice" [value]="option">{{ option }}</option>
        <option value="Not Applicable">Other/Does Not Apply</option>
      </select>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-12 col-md-6 font-medium">Zip/Post Code <b class="text-danger">*</b></div>
    <div class="col-12 col-md-6"><input class="input" type="text" formControlName="postalCode"/></div>
  </div>
  <div class="row mb-1">
    <div class="col-12 col-md-6">
      <p class="font-medium m-0 font-medium">Country <b class="text-danger">*</b></p>
      <p class="m-0 font-small color">( If your Country is not in the list, please choose "Other/Does Not Apply" )</p>
    </div>
    <div class="col-12 col-md-6">
      <select class="input font-size-13" formControlName="selCountry">
        <option value="" selected>Please Select a Country <b class="text-danger">*</b></option>
        <option *ngFor="let option of gsdbCountries" [value]="option">{{ option }}</option>
        <option value="Not Applicable">Other/Does Not Apply</option>
      </select>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-12 col-md-6 font-medium">Emergency Business Contact Last Name <b class="text-danger">*</b></div>
    <div class="col-12 col-md-6"><input class="input" type="text" formControlName="emergencyLastName"/></div>
  </div>
  <div class="row mb-1">
    <div class="col-12 col-md-6 font-medium">Emergency Business Contact First Name <b class="text-danger">*</b></div>
    <div class="col-12 col-md-6"><input class="input" type="text" formControlName="emergencyFirstName"/></div>
  </div>
  <div class="row mb-1">
    <div class="col-12 col-lg-6">
      <p class="mt-0 mb-0 font-medium">Emergency Business Contact Telephone <b class="text-danger">*</b></p>
      <p class="mt-0 mb-0 font-small color">Example: Country Code for U.S.A. is 1</p>
    </div>
    <div class="col-12 col-lg-3 mb-1 mb-lg-0">
      <span class="text-sm mr-1 mr-lg-0 font-small block">Country Code <b class="text-danger">*</b></span>
      <input class="input" type="text" formControlName="emeContactCountryCd"/>
    </div>
    <div class="col-12 col-lg-3 mb-1 mb-lg-0">
      <span class="text-sm mr-1 mr-lg-0 font-small block">Telephone Number <b class="text-danger">*</b></span>
      <input class="input" type="text" formControlName="emeContactTel"/>
    </div>
    <div class="row mb-1 mt-4">
      <div class="col-12 flex justify-center flex-column flex-md-row">
        <button type="button" class="contacts-btn mr-2 mb-2 mb-md-0" (click)="reset()">Reset Form</button>
        <button type="submit" class="contacts-btn">Update completed Form</button>
      </div>
    </div>
  </div>
</form>
