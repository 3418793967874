<app-common-header></app-common-header>
<div class="page-title">
  <img src="./assets/gsdb3b.gif"/>
</div>
<div class="main-container"  *ngIf="valid">
  <div class="flex justify-content-between">
    <p class="ml-2 mt-2 color font-bold">Contact Information for Chief Financial Officer</p>
    <p routerLink="/support" class="mr-2 mt-2 help-btn">Help</p>
  </div>
  <div class="body">
    <p class="flex justify-content-between margin-req"><span class="font-bold">Please enter all information for GSDB Code <b class="text-danger"> {{contactDetails?.siteCode}}</b></span>
      <span><b class="text-danger">*</b> = required field</span>
      </p>
    <p class="heading text-center">Contact Information for Chief Financial Officer</p>
    <div class="flex">
      <app-gsdb-contacts-sidebar [formData]="gsdbCode"></app-gsdb-contacts-sidebar>
      <div class="right-side">
        <app-gsdb-contacts-form (update)="update($event)" [formData]="contactDetails"></app-gsdb-contacts-form>
      </div>
    </div>
  </div>
  <hr class="mb-0"/>
  <div class="mb-4 text-sm">
    <a class="mt-0 mr-2 navigation"
       href="https://e3.ford.com/eRoom/fordna7/FSN/Contacts%20Database/HTML%20Screens/template.html#Top">Top</a> © 1998,
    Ford Motor Company
  </div>
</div>
