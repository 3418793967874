import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {GsdbContactsMain} from 'src/app/models/perl-migration-web/gsdb-contacts-main/gsdb-contacts-main.model';
import {GsdbContactsService} from '../service/gsdb-contacts.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  type: string | null = null;

  success: boolean | null = null;

  constructor(private route: ActivatedRoute, private router: Router, private apiService: GsdbContactsService) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation != null) {
      const state = navigation.extras.state as { successData: boolean };
      this.success = state.successData;
    }
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.type = params.get('type'); // Get the path parameter
    });
  }
}
