import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {GsdbQueryService} from "../service/gsdb-query.service";
import {GsdbQueryRequestBody} from "../../../models/perl-migration-web/gsdb-online/gsdbQueryBody";

@Component({
  selector: 'app-gsdb-query',
  templateUrl: './gsdb-query.component.html',
  styleUrls: ['./gsdb-query.component.scss']
})
export class GsdbQueryComponent {
  queryForm: FormGroup;
  isValid:boolean=false

  constructor(private fb: FormBuilder, private router: Router, private gsdbQueryService: GsdbQueryService) {
    this.queryForm = this.fb.group(({
      cdsid: ['', [Validators.required]],
      userPwd: ['', [Validators.required]],
      fsnOrCovisintId: [''],
      gsdbCode: ['']
    }))
  }

  onSubmit() {
    let requestBody = new GsdbQueryRequestBody();
    requestBody.userCdsId = this.queryForm.value.cdsid
    requestBody.userPassword = this.queryForm.value.userPwd
    let searchValue = '';
    if (this.queryForm.value.gsdbCode != "") {
      searchValue = this.queryForm.value.gsdbCode
      requestBody.searchValue = searchValue
      requestBody.searchGsdbCode = searchValue
      searchValue = this.queryForm.value.gsdbCode
    } else if (this.queryForm.value.fsnOrCovisintId != "") {
      searchValue = this.queryForm.value.fsnOrCovisintId
      requestBody.searchValue = searchValue
      requestBody.searchId = searchValue
    }
    if (searchValue != ""){
      this.gsdbQueryService.getGsdbOnlineQueryResults(requestBody).subscribe(data => {
        this.gsdbQueryService.setGsdbQueryResponse(data)
        this.router.navigate(['/perlMigrationWeb/gsdbOnlineQueryResults']);
      }, error => {
        if (error.error === "isUserValid or environment variable check failed - restricted") {
          this.router.navigate(['/perlMigrationWeb/gsdbOnlineRestricted']);
        } else if (error.error === "failedSearch - No data for the given search") {
          this.router.navigate(['/perlMigrationWeb/gsdbOnlineQuery/failedSearch'], {queryParams: {data: searchValue}});
        }
      })
    }else{
        this.isValid=true
    }

  }

  // This function listens for input change and clears the other input field
  onInputChange(changedInput: 'fsnOrCovisintId' | 'gsdbCode'): void {
    if (changedInput === 'fsnOrCovisintId' && this.queryForm.get('fsnOrCovisintId')?.value) {
      // If Input 1 is changed, clear Input 2
      this.queryForm.get('gsdbCode')?.setValue('');
      this.isValid=false
    } else if (changedInput === "gsdbCode" && this.queryForm.get('gsdbCode')?.value) {
      // If Input 2 is changed, clear Input 1
      this.queryForm.get('fsnOrCovisintId')?.setValue('');
      this.isValid=false
    }
  }
}
