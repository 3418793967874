<app-gsdb-online-header></app-gsdb-online-header>
<div class="body ml-8 mr-8 mt-8">
  <p class="para-title pl-4"><b> GSDB Online Steps > <span class="intro"> Introduction</span> :: Nomination ::
    Indemnification</b></p>
  <p class="text-center font-size">You have entered an incorrect name or PIN number.</p>
  <p class="text-center font-size">Please hit the back button and re-enter your Last Name and PIN number.</p>
  <p class="text-center font-size mb-0">If this problem persists, </p>
  <p class="text-center font-size mt-0">Contact <span class="text-decoration-underline">gsdbweb@ford.com</span> or access <a href="/support" class="navigation">Ford Help Desk Support</a>
  </p>
  <p class="text-center font-size"><b>Thank you.</b></p>
</div>
