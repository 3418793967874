import { Component } from '@angular/core';

@Component({
  selector: 'app-indirect-history',
  templateUrl: './indirect-history.component.html',
  styleUrls: ['./indirect-history.component.scss']
})
export class IndirectHistoryComponent {

}
