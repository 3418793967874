<div class="contacts-main-container">
  <div class="flex justify-between m-3">
    <img src="\assets\company_logo.gif" alt="header-logo" class="">
    <img src="\assets\fsp_logo.gif" alt="header-logo2" class="">
  </div>
  <div class="main-container flex flex-column">
    <div class="flex justify-content-between">
      <p class="ml-2 mt-2 color font-bold">GSDB Contacts Search</p>
      <p routerLink="/support" class="mr-2 mt-2 help-btn">Help</p>
    </div>
    <table class="content-align table font-size-12">
      <thead>
      <tr>
        <th class="table-th" *ngFor="let title of titles">{{ headings[title] }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let response of contactResponse">
        <td class="td-border" *ngFor="let columnName of titles">{{ response[titleList[columnName]] }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
