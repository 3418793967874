<app-common-header></app-common-header>
<div class="row">
  <div class="page-title">
    <b>Supplier Search By Name :</b>
  </div>
</div>
<form [formGroup]="supplierForm" (ngSubmit)="onSubmit()">
  <div class="mt-2 ml-2 container">
    <div>
      <p class="supplier-search">Supplier Name</p>
      <input type="text" class="input pl-2 pr-2" formControlName="supplierName"/>
      <div *ngIf="supplierForm.get('supplierName')!.invalid && supplierForm.get('supplierName')!.touched">
        <small class="warning" *ngIf="supplierForm.get('supplierName')?.errors?.['required']">Supplier Name is
          required.</small>
      </div>
    </div>
    <button type="submit" class="block mt-2 xml-button" [disabled]="supplierForm.invalid">Display XML</button>
  </div>
</form>
