<div>
  <div class="content-container">
    <div class="flex">
      <img src="./assets/ford.png" alt="Ford" class="ford-icon"/>
      <p class="heading m-auto">Purchasing & Associated Systems Annual Access Review Page</p>
    </div>
    <div class="flex justify-center check">
      <div class="mr-2">
        <button class="self-removal-btn" (click)="openSystemAnnualReview()">System
          Descriptions
        </button>
      </div>
      <div>
        <button class="self-removal-btn" (click)="displayCriticalInformation()">Critical Information on CPARS, PartSpec/Boss90 and CORA</button>
      </div>
      <div>
        <span class="font-size-12 ml-2 font-bold">
         ( Access status for <input class="ml-1 mr-1 input" type="text"
                                    value="{{userName}}" [disabled]="true"/> as of {{ date | date:'MMM d, y' }} )
        </span>
      </div>
    </div>
    <div class="inner-container font-size-12">
      <p class="font-bold text-decoration-underline mb-0">The purpose of the Self Review is to:</p>
      <ul class="list-disc">
        <li>Display access that you currently have and allow you to remove any access that you no longer require.</li>
        <li>Review the 'Authorizer' and make changes if necessary.</li>
      </ul>
      <p class="font-bold text-decoration-underline mb-0">Action Required:</p>
      <ul class="list-disc">
        <li>Review the access shown below:</li>
        <ul class="list-circle">
          <li>If the Job Function(s) and System Access(es) shown are correct and you still need access to these systems,
            no action is required at this time.
          </li>
          <li>To request that a type of access that you no longer require be removed, check the
            <span class="text-danger font-bold"> "REMOVE ACCESS"</span> box in
            that row.
          </li>
        </ul>
        <li>Review the Authorizers shown in the 'CURRENT AUTHORIZER' column:</li>
        <ul class="list-circle">
          <li>If the Authorizers shown are correct, no action is required at this time.</li>
          <li>To request a change to the 'CURRENT AUTHORIZER' check the 'CHANGE AUTHORIZER' box and provide the correct
            authorizer's CDS ID in the 'NEW AUTHORIZER' text box.
          </li>
        </ul>
      </ul>
      <p class="font-size-12">To ensure you have viewed all of your records, please scroll to the end of the list.</p>
      <p class="font-size-12">When you have reviewed all rows, complete the review by clicking the
        <button class="self-removal-btn" [disabled]="!selfRemovalDetails.dirty"  (click)="submit()">Submit</button>
        button. This information will be sent to your authorizer for concurrence within the next couple of weeks.
      </p>
      <p><i>To search for a New Authorizer CDS ID click <a class="nav-link" href="https://www.sdcds.ford.com/"
                                                           target="_blank">HERE</a></i></p>
      <form [formGroup]="selfRemovalDetails" class="mt-6 form-container">
        <table class="content-align table font-size-12">
          <thead>
          <tr>
            <th class="table-th">
              <div>System</div>
            </th>
            <th class="table-th">
              <div>Job Function</div>
            </th>
            <th class="table-th">
              <div>System Access</div>
            </th>
            <th class="table-th">
              <div>Job Function</div>
            </th>
            <th class="table-th">
              <div>RACF ID</div>
            </th>
            <th class="table-th">
              <div>Div Plt</div>
            </th>
            <th class="table-th warning">
              <div>REMOVE ACCESS</div>
            </th>
            <th class="table-th">
              <div class="flex justify-center items-center"><span>Current Authorizer CDSID</span> <img
                matTooltip="The CDS ID of the person current identified to review your access to this system."
                src="./assets/tooltip_icon.gif" alt="Tool tip"/></div>
            </th>
            <th class="table-th">
              <div class="flex justify-center items-center"><span>Change Authorizer</span> <img
                matTooltip="Click this box if you need to change the Current Authorizer."
                src="./assets/tooltip_icon.gif" alt="Tool tip"/></div>
            </th>
            <th class="table-th">
              <div class="flex justify-center items-center"><span>New Authorizer CDSID</span> <img
                matTooltip="The CDS ID of the correct person to review your access to this system."
                src="./assets/tooltip_icon.gif" alt="Tool tip"/></div>
            </th>
          </tr>
          </thead>
          <tbody formArrayName="records" *ngFor="let user of records.controls;let i=index;">
          <tr [formGroupName]="i">
            <td class="td-border">
              <div>{{ user.get('system')?.value }}</div>
            </td>
            <td class="td-border">
              <div>{{ user.get('jobFunction')?.value }}</div>
            </td>
            <td class="td-border">
              <div>{{ user.get('jobFunction')?.value }}</div>
            </td>
            <td class="td-border">
              <div>{{ user.get('systemAccess')?.value }}</div>
            </td>
            <td class="td-border">
              <div>{{ user.get('racfId')?.value }}</div>
            </td>
            <td class="td-border">
              <div>{{ user.get('divPlt')?.value }}</div>
            </td>
            <td class="td-border">
              <div class="flex justify-center"><input type="checkbox" formControlName="removeAcc"/></div>
            </td>
            <td class="td-border">
              <div>{{ user.get('currentAuthorizer')?.value }}</div>
            </td>
            <td class="td-border">
              <div class="flex justify-center"><input type="checkbox" formControlName="changeAuthorizer"
                                                      (change)="disableAuthorizer(user)"/></div>
            </td>
            <td class="td-border">
              <div><input class="input input-width" type="text" formControlName="newAuthorizer"/></div>
            </td>
          </tr>
          </tbody>
          <tbody *ngIf="!hasRecords">
          <tr>
            <td colspan="12" class="text-center font-bold">No Records Found</td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
