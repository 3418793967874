import {Component} from '@angular/core';
import {ProductionService} from "../service/production.service";

@Component({
  selector: 'app-production-terms-conditions',
  templateUrl: './production-terms-conditions.component.html',
  styleUrls: ['./production-terms-conditions.component.scss']
})
export class ProductionTermsConditionsComponent {
  constructor(private productionService: ProductionService) {
  }


  showChinesTermsPdf(): void {
    this.productionService.getChineseTermsPdf();
  }
}
