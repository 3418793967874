import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  constructor(private router: Router) {
  }

  ngOnInit(): void {
    // Only redirect if not already on the /404 page
    if (this.router.url !== '/404') {
      this.router.navigate(['/404']).then(() => {
        // After navigating to /404, refresh the page.
        window.location.reload();
      });
    }
  }

}
