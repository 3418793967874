<div class="corruption-container">
  <p class="heading pl-1">Anti Corruption Supplement - Indirect</p>
  <div>
    <table class="m-2 table-container" border="1" cellpadding="3" cellspacing="0">
      <tr>
        <th>Effective Date</th>
        <th>Document Name</th>
        <th>Description</th>
      </tr>
      <tr>
        <td>01/01/2003</td>
        <td><a class="color" (click)="getAntiCorruptionEnglishPdf()">Anti-Corruption
          Supplement - English</a></td>
        <td>(Written in English)</td>
      </tr>
      <tr>
        <td>01/01/2003</td>
        <td><a class="color" (click)="getAntiCorruptionChinesePdf()">Anti-Corruption
          Supplement - Chinese</a></td>
        <td>(Written in Chinese)</td>
      </tr>
      <tr>
        <td>01/01/2003</td>
        <td><a class="color" (click)="getAntiCorruptionDutchPdf()">Anti-Corruption
          Supplement - Dutch</a></td>
        <td>(Written in Dutch)</td>
      </tr>
      <tr>
        <td>01/01/2003</td>
        <td><a class="color" (click)="getAntiCorruptionFrenchPdf()">Anti-Corruption
          Supplement - French</a></td>
        <td>(Written in French)</td>
      </tr>
      <tr>
        <td>01/01/2003</td>
        <td><a class="color" (click)="getAntiCorruptionGermanPdf()">Anti-Corruption
          Supplement - German</a></td>
        <td>(Written in German)</td>
      </tr>

      <tr>
        <td>01/01/2003</td>
        <td><a class="color" (click)="getAntiCorruptionPortuguesePdf()">Anti-Corruption
          Supplement - Portuguese</a></td>
        <td>(Written in Portuguese)</td>
      </tr>
      <tr>
        <td>01/01/2003</td>
        <td><a class="color" (click)="getAntiCorruptionRussianPdf()">Anti-Corruption
          Supplement - Russian</a></td>
        <td>(Written in Russian)</td>
      </tr>
      <tr>
        <td>01/01/2003</td>
        <td><a class="color" (click)="getAntiCorruptionSpanishPdf()">Anti-Corruption
          Supplement - Spanish</a></td>
        <td>(Written in Spanish)</td>
      </tr>
      <tr>
        <td>01/01/2003</td>
        <td><a class="color" (click)="getAntiCorruptionRomanianPdf()">Anti-Corruption
          Supplement - Romanian</a></td>
        <td>(Written in Romanian)</td>
      </tr>
      <tr>
        <td>01/01/2003</td>
        <td><a class="color" (click)="getAntiCorruptionThaiPdf()">Anti-Corruption
          Supplement - Thai</a></td>
        <td>(Written in Thai)</td>
      </tr>
      <tr>
        <td>01/01/2003</td>
        <td><a class="color" (click)="getAntiCorruptionVietnamesePdf()">Anti-Corruption
          Supplement - Vietnamese</a></td>
        <td>(Written in Vietnamese)</td>
      </tr>
    </table>
  </div>
</div>
