<app-gsdb-online-header></app-gsdb-online-header>
<form [formGroup]="queryForm" (ngSubmit)="onSubmit()" class="query-container">
  <p class="font-24 text-center"><b>GSDB ONLINE QUERY</b></p>
  <p class="text-center warning">You must be authorized to use this application.</p>
  <div class="flex justify-center mb-1">
    <label class="ml-1 mr-3">Please enter your CDS ID:</label>
    <div>
      <input class="input" type="text" formControlName="cdsid"/>
      <div class="block" *ngIf="queryForm.get('cdsid')!.invalid && queryForm.get('cdsid')!.touched">
        <small class="warning" *ngIf="queryForm.get('cdsid')?.errors?.['required']">CDS ID is
          required.</small>
      </div>
    </div>
  </div>
  <div class="flex justify-center">
    <label class="mr-3">Please enter the password:</label>
    <div>
      <input class="input" type="password" formControlName="userPwd"/>
      <div class="block" *ngIf="queryForm.get('userPwd')!.invalid && queryForm.get('userPwd')!.touched">
        <small class="warning" *ngIf="queryForm.get('userPwd')?.errors?.['required']">Password is
          required.</small>
      </div>
    </div>
  </div>
  <p class="text-center font-bold mt-4">You can search by FSN/Covisint ID OR by GSDB CODE.</p>
  <div class="flex justify-center mb-1">
    <label class="mr-3">Enter the FSN ID or Covisint ID you wish to search on:</label>
    <input class="input" type="text" formControlName="fsnOrCovisintId" (input)="onInputChange('fsnOrCovisintId')"/>
  </div>
  <div class="flex justify-center">
    <label class="mr-3">Enter the GSDB code you wish to search on:</label>
    <input class="input" type="text" formControlName="gsdbCode" (input)="onInputChange('gsdbCode')"/>
  </div>
  <div class="flex justify-center mt-4">
    <button class="submit-btn" type="submit" [disabled]="queryForm.invalid">Submit Query</button>
  </div>
  <div class="flex justify-center">
    <label class="mr-3 warning text-sm font-bold warning" *ngIf="isValid">Please enter FSN ID or Covisint ID OR GSDB
      code any one among of them.</label></div>
</form>
