export class SelfRemovalRecords {
  public system: String = "";
  public jobFunction: String = "";
  public systemAccess: String = "";
  public racfid: String = "";
  public location: String = "";
  public removeFlag: String = "";
  public approverCdsid: String = "";
  public failedAuditFlag: String = "";
  public newApproverCdsid: String = "";
  public stageId: String = "";
  public userCdsid:String | null=""
}

export class UserSelfRemovalDetails {
  public userName: String = ''
  public taskDate: string = ''
  public userSelfRemovalList: SelfRemovalRecords[] = []

}
