<app-ariba-header></app-ariba-header>
<div class="container-fluid">
    <div class="row">
        <div class="col-1 page-top-left">
            <div class="row side-pic">
            </div>
        </div>

        <div class="col-11 page-top">
            <div class="row row-height">
                <div class="font1 background-color">
                    <p>Ariba Login Credential</p>
                </div>
                <div>
                    <form [formGroup]="aribaLoginForm" (ngSubmit)="onSubmit()">
                        <br><br>
                        <div>
                            <span>
                                <label class="font2">Enter Ariba Network ID :&nbsp;
                                    <input type="text" class="input-text" formControlName="aribaId">
                                </label>
                
                            </span> &nbsp; &nbsp; &nbsp;
                            <span>
                                <button class="btn btn-sm btn-primary" (click)="getOTP($event)"
                                [disabled]="aribaLoginForm.controls['aribaId'].invalid" >Generate OTP</button>
                            </span><br>
                            <span id="AribIdError" class="text-danger" *ngIf="aribaLoginForm.controls['aribaId'].invalid &&
                                aribaLoginForm.controls['aribaId'].dirty">Invalid Input for Ariba Network ID</span>
                        </div><br>
                        <div>
                            <span>
                                <label class="font2">Enter GSDB E-Mail ID : &nbsp; 
                                    <input type="text" class="input-text" formControlName="emailId">
                                </label><br>
                                <span id="emailIdError" class="text-danger" *ngIf="aribaLoginForm.controls['emailId'].invalid &&
                                aribaLoginForm.controls['emailId'].dirty">Invalid Input for GSDB E-mail ID</span>
                            </span> 
                        </div><br>
                        <div>
                            <span>
                                <label class="font2">Enter OTP &nbsp;&nbsp;&nbsp;&nbsp; 
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
                                    <input type="password" class="input-text" formControlName="otp">
                                </label><br>
                                <span id="otpError" class="text-danger" *ngIf="aribaLoginForm.controls['otp'].invalid &&
                                aribaLoginForm.controls['otp'].dirty">Invalid Input for OTP</span>
                            </span> 
                        </div><br>
                        <div>
                            <button type="submit" class="btn btn-primary btn-sm button" [disabled]="aribaLoginForm.invalid">Submit</button>
                        </div>
                    </form>
                </div>
                <div>
                    <br><hr>
               </div>
               <div class="font3">
                    Questions about Ariba Online Access?<br>
                    Contact <u>aribaweb@ford.com</u> or access 
                    <A routerLink="/support">
                        Ford Help Desk support</A><br>
                         The customer support center is available 24 hours each day.
               </div>
            </div>
            
        </div>

        

    </div>

</div>
    
