import {Component, OnInit} from '@angular/core';
import {GsdbContactsService} from "../service/gsdb-contacts.service";
import {
  ContactsHeadingKeys,
  contactsHeadings,
  titleList
} from "../../../models/perl-migration-web/gsdb-online/gsdbContacts";

@Component({
  selector: 'app-contact-search-results',
  templateUrl: './contact-search-results.component.html',
  styleUrls: ['./contact-search-results.component.scss']
})
export class ContactSearchResultsComponent implements OnInit {
  titles!: ContactsHeadingKeys[]
  contactResponse!: []
  headings = contactsHeadings
  titleList = titleList

  constructor(private gsdbService: GsdbContactsService) {
    this.titles = []
    this.contactResponse = []
  }

  ngOnInit(): void {
    const contactResponse = this.gsdbService.getContactResults()
    this.titles = contactResponse.titleList
    this.contactResponse = contactResponse.gsdbContactsDTOList
  }
}
