import {HomeComponent} from "../common/home/home.component";
import {AuthenticationGuard} from "@wame/ngx-adfs";
import {ProductionHomeComponent} from "../production/production-home/production-home.component";
import {
  ProductionRightSidebarComponent
} from "../production/production-right-sidebar/production-right-sidebar.component";
import {
  ProductionTermsConditionsComponent
} from "../production/production-terms-conditions/production-terms-conditions.component";
import {SupplierGuidesComponent} from "../production/supplier-guides/supplier-guides.component";
import {CommunicationsComponent} from "../production/communications/communications.component";
import {SupplementsComponent} from "../production/supplements/supplements.component";
import {SummeryComponent} from "../production/summery/summery.component";
import {HistoryComponent} from "../production/history/history.component";
import {Routes} from "@angular/router";
import {IndirectHomeComponent} from "../indirect/indirect-home/indirect-home.component";
import {IndirectRightSidebarComponent} from "../indirect/indirect-right-sidebar/indirect-right-sidebar.component";
import {IndirectTermsComponent} from "../indirect/indirect-terms/indirect-terms.component";
import {IndirectCommunicationsComponent} from "../indirect/indirect-communications/indirect-communications.component";
import {AntiCorruptionComponent} from "../indirect/anti-corruption/anti-corruption.component";
import {IndirectSupplementsComponent} from "../indirect/indirect-supplements/indirect-supplements.component";
import {IndirectHistoryComponent} from "../indirect/indirect-history/indirect-history.component";
import { EmptyComponentComponent } from "../empty-component/empty-component.component";
import { GtcDocumentGuard } from "../gtc-document.guard";

export const gtcRoutes:Routes = [
  {
    path: 'gtc', component: HomeComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'gtc/production', component: ProductionHomeComponent,
    children: [
      {path: '', component: ProductionRightSidebarComponent},
      {path: 'terms', component: ProductionTermsConditionsComponent},
      {path: 'guides', component: SupplierGuidesComponent},
      {path: 'communications', component: CommunicationsComponent},
      {path: 'supplements', component: SupplementsComponent},
      {path: 'summary', component: SummeryComponent},
      {path: 'history', component: HistoryComponent}
    ],
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'gtc/indirect', component: IndirectHomeComponent,
    children: [
      {path: '', component: IndirectRightSidebarComponent},
      {path: 'terms', component: IndirectTermsComponent},
      {path: 'guides', component: SupplierGuidesComponent},
      {path: 'communications', component: IndirectCommunicationsComponent},
      {path: 'anti', component: AntiCorruptionComponent},
      {path: 'supplements', component: IndirectSupplementsComponent},
      {path: 'history', component: IndirectHistoryComponent}
    ],
    canActivate: [AuthenticationGuard]
  },

  // static document routes
  {path:'api/files/supplier-guides/autocurrency.pdf',component:EmptyComponentComponent, 
     canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/automaterials.pdf',component:EmptyComponentComponent,
     canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/bailmenteu2007.pdf',component:EmptyComponentComponent,
     canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/capacityplan.pdf',component:EmptyComponentComponent,
     canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/csip.pdf',component:EmptyComponentComponent,
     canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/claims.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/comanagedbuy.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/customscan.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/customsmex.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/customsusa.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/deltermsfoe.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/digitalmfgdata.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/disputeresolution.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/fmspdisputeresolution.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},  
  {path:'api/files/supplier-guides/envguide.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/exportcontrolwebguide.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/psguide2015.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/psguide2005eu.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/fcsdservicepartsna.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/fsa.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/fordbrandprotect.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/eusuppinvoicingfmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/shipparts.pdf',component:EmptyComponentComponent,
     canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/mplmaterials.pdf',component:EmptyComponentComponent,
     canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/nmpwshipinst2009.pdf',component:EmptyComponentComponent,
     canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/newreleases.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/globalexppkg.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/eu1750pkg.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/napkgguide.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/APpkgguide.pdf',component:EmptyComponentComponent,
   canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/cn1750pkg.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/prodpayguide.pdf',component:EmptyComponentComponent,
     canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/indirectpayguide.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/nonrenewal.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/ptoewarrants.pdf',component:EmptyComponentComponent,
     canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/steelrawmtls.pdf',component:EmptyComponentComponent,
     canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/returnablecontainerASN.pdf',component:EmptyComponentComponent,
      canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/shipmentfoe.pdf',component:EmptyComponentComponent,
      canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/nashipping.pdf',component:EmptyComponentComponent,
      canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/hrandwc.pdf',component:EmptyComponentComponent,
      canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/tax.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/toolingaudit.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/supporttooltagging.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/governmentsubcontractor.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/sdi.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/valuechaintransparency.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/warrantychargeback.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/supplier-guides/warrantyqos.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/communication/2022sgupdates.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/information-supplement/piisupp.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/information-supplement/isp_supplement.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/information-supplement/ppgtc.pdf',component:EmptyComponentComponent,
   canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/information-supplement/ppgtc_chinese.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-supplements/chinesesuppfmsp.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-supplements/canadasuppfmsp.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-supplements/austrailiasuppfmsp.docx',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-supplements/indiasuppfmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-supplements/brazilsuppfmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-supplements/mexicosuppfmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-supplements/greecesuppfmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-supplements/pnpd223.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-supplements/pnpd223_spanish.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-supplements/spainsuppenglish.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-supplements/spainsuppspanish.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/summery/PPGTCFAQ.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/summery/GTCsMajorChangeSummaryOGCFinal11182003_1.doc',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/summery/July2021PPGTCs_KeyChanges.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/summery/2021GTCFAQ.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/production-supplements/pcpd023.doc',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/production-supplements/pcpd023.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/production-supplements/GTCsSuppTerms-Brazil-FinalDec1703-1.doc',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/production-supplements/ppgtcsupptermsbrazil.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/production-supplements/ppgtcssupptermscanada2012.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/production-supplements/ppgtcsupptermscanada.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/production-supplements/GTCsSuppTerms-Mexico-FinalDec11-03-1.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/production-supplements/ppgtcsupptermsmexico.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/production-supplements/GTCsSuppTerms-Russia-Aug-2012.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/production-supplements/ppgtcsupptermsrussia.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/production-supplements/GTCsSuppTerms-Taiwan-FinalDec152003-1.doc',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/production-supplements/ppgtcsupptermstaiwan.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/production-supplements/GTCsSuppTerms-EUFinalApril2-0404.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/production-supplements/ppgtcsupptermseu.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/production-supplements/foasupp.doc',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/production-supplements/ppgtcsupptermsaustralia.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-terms/pcpd018.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-terms/chinesefmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-terms/germanfmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-terms/dutchfmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-terms/hungarianfmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-terms/spanish_argentina_fmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-terms/polishfmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-terms/czechrepublicfmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-terms/russianfmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-terms/italianfmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-terms/portuguesefmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-terms/finnishfmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-terms/greekfmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-terms/danishfmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-terms/norwegianfmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-terms/romanianfmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-terms/swedishfmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-terms/thaifmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-terms/koreanfmsp2007.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-communication/2022sgupdates.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-communication/piisupp.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-communication/isp_supplement.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-communication/pnpd234.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-communication/pnpd232.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-communication/pnpd228.docx',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-communication/pnpd027isa.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-communication/pnpd027asa.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-communication/pnpd224.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/indirect-communication/pnpd229.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/anti-corruption/Anti_Corruption_Supplement_PNPD225.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/anti-corruption/Anti_Corruption_Supplement_Chinese.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/anti-corruption/Anti_Corruption_Supplement_Dutch.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/anti-corruption/Anti_Corruption_Supplement_French.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/anti-corruption/Anti_Corruption_Supplement_German.pdf',component:EmptyComponentComponent,
   canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/anti-corruption/Anti_Corruption_Supplement_Portugese.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/anti-corruption/Anti_Corruption_Supplement_Russian.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/anti-corruption/Anti_Corruption_Supplement_Spanish.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/anti-corruption/Anti_Corruption_Supplement_Romanian.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/anti-corruption/Anti_Corruption_Supplement_Thai.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  {path:'api/files/anti-corruption/Anti_Corruption_Supplement_Vietnamese.pdf',component:EmptyComponentComponent,
    canActivate:[AuthenticationGuard,GtcDocumentGuard], pathMatch: 'full'},
  
    
]



