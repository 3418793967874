import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { SiteCodes } from 'src/app/models/perl-migration-web/everest-token/site-codes.model';
import { EverestTokenServiceService } from '../service/everest-token-service.service';

@Component({
  selector: 'app-everest-token-file-confirmation',
  templateUrl: './everest-token-file-confirmation.component.html',
  styleUrls: ['./everest-token-file-confirmation.component.scss']
})
export class EverestTokenFileConfirmationComponent{

  filename: string|null = null;
  count: number = 0;
  siteCodeModel!: SiteCodes;

  constructor(private router: Router,private dataService: EverestTokenServiceService){
    const navigation = this.router.getCurrentNavigation();
  
    if(navigation!=null){
      const state = navigation.extras.state as {data: {filename: string, sitecodeModel: SiteCodes}} ;
      this.filename = state.data.filename;
      this.siteCodeModel = state.data.sitecodeModel;
      this.count = this.siteCodeModel.siteCodes.length;
      console.log(this.filename);
    }
    
   
  }

  handleSuccess(){
    const data = {type: 'file'}
    this.router.navigate(["/perlMigrationWeb/everstTokenLookup/lookupReport"],{state:{data}});
  }

  getBulkEverestTokenReport(){
      this.dataService.getBulkTokenReport(this.siteCodeModel).subscribe((data:String)=>{
        this.handleSuccess()
      },error=>{
        console.log('error fetching list of records',error);
        alert(JSON.stringify(error)); //alert('error occured will navigate to home page')
        //this.router.navigate(["//perlMigrationWeb/everstTokenLookup"])
      })
  }

}
