import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {TaskDate, UserAccessList, UserAccessReview} from "../../../models/paars-web/user-access-review.model";

@Injectable({
  providedIn: 'root'
})
export class UserAccessService {
  private url = environment.paarsWebApiUrl;

  constructor(private http: HttpClient) {
  }

  getUserAccReviewDetails(reviewAction: string): Observable<UserAccessReview[]> {
    return this.http.get<UserAccessReview[]>(this.url + '/api/v1/getUserAccessReview?reviewerAction=' + reviewAction)
  }

  saveUserAccReviewDetails(records: UserAccessReview[]): Observable<UserAccessList> {
    return this.http.put<UserAccessList>(this.url + '/api/v1/updateUserAccessReview', records)
  }

  getTaskDate(): Observable<TaskDate> {
    return this.http.get<TaskDate>(this.url + '/api/v1/getTaskDate')
  }

  getSystemList(): Observable<string[]> {
    return this.http.get<string[]>(this.url + '/api/v1/getSystemList')
  }
}
