export class GsdbQueryRequestBody {
  public searchValue:string = ''
  public previousProgram:string  = 'gsdbOnlineQuery'
  public userCdsId:string  = ''
  public userPassword:string  = ''
  public searchId:string  = ''
  public searchGsdbCode:string  = ''

  constructor() {
  }
}
