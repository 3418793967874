import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SupplementsServiceProd {
  private url = environment.gtcApiUrl

  constructor(private http: HttpClient) {
  }

  getChinaAddendumDoc(){
    this.http.get(this.url + '/api/v1/files/download/pcpd023.doc', {responseType: 'blob'}).pipe(map(blob => {
      const url = window.URL.createObjectURL(blob);
      // Create a link element and trigger a click event
      const a = document.createElement('a');
      a.href = url;
      a.download = "pcpd023.doc";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      return blob;
    })).subscribe();
  }

  getChinaAddendumPdf() {
    this.http.get(this.url + '/api/v1/files/download/pcpd023.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGTCsSupplementalTermsBrazil() {
    this.http.get(this.url + '/api/v1/files/download/GTCsSuppTerms-Brazil-FinalDec1703-1.doc',
      {responseType: 'blob'}).pipe(map(blob => {
        const url = window.URL.createObjectURL(blob);
        // Create a link element and trigger a click event
        const a = document.createElement('a');
        a.href = url;
        a.download = "GTCsSuppTerms-Brazil-FinalDec1703-1.doc";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        return blob;
      })).subscribe();
  }

  getPpGtcSupptermsBrazilPdf() {
    this.http.get(this.url + '/api/v1/files/download/ppgtcsupptermsbrazil.pdf',
      {responseType: 'blob'}).subscribe(arrayBuffer => {
        const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
      }, error => {
        console.error('Error fetching PDF:', error);
      });
  }

  getGtcSuppTermsCanada() {
    this.http.get(this.url + '/api/v1/files/download/ppgtcssupptermscanada2012.pdf',
      {responseType: 'blob'}).subscribe(arrayBuffer => {
        const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
      }, error => {
        console.error('Error fetching PDF:', error);
      });
  }

  getPpGtcSuppTermsCanada(){
    this.http.get(this.url + '/api/v1/files/download/ppgtcsupptermscanada.pdf',
      {responseType: 'blob'}).subscribe(arrayBuffer => {
        const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
      }, error => {
        console.error('Error fetching PDF:', error);
      });
  }

  getGtcSuppTermsMexico() {
    this.http.get(this.url + '/api/v1/files/download/GTCsSuppTerms-Mexico-FinalDec11-03-1.pdf',
      {responseType: 'blob'}).subscribe(arrayBuffer => {
        const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
      }, error => {
        console.error('Error fetching PDF:', error);
      });
  }

  getPpGtcSuppTermsMexico() {
     this.http.get(this.url + '/api/v1/files/download/ppgtcsupptermsmexico.pdf',
      {responseType: 'blob'}).subscribe(arrayBuffer => {
        const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
      }, error => {
        console.error('Error fetching PDF:', error);
      });
  }

  getGtcSuppTermsRussia() {
     this.http.get(this.url + '/api/v1/files/download/GTCsSuppTerms-Russia-Aug-2012.pdf',
      {responseType: 'blob'}).subscribe(arrayBuffer => {
        const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
      }, error => {
        console.error('Error fetching PDF:', error);
      });
  }

  getPpGtcSuppTermsRussia(){
     this.http.get(this.url + '/api/v1/files/download/ppgtcsupptermsrussia.pdf',
      {responseType: 'blob'}).subscribe(arrayBuffer => {
        const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
      }, error => {
        console.error('Error fetching PDF:', error);
      });
  }

  getGtcSuppTermsTaiwan(){
    this.http.get(this.url + '/api/v1/files/download/GTCsSuppTerms-Taiwan-FinalDec152003-1.doc',
      {responseType: 'blob'}).pipe(map(blob => {
        const url = window.URL.createObjectURL(blob);
        // Create a link element and trigger a click event
        const a = document.createElement('a');
        a.href = url;
        a.download = "GTCsSuppTerms-Taiwan-FinalDec152003-1.doc";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        return blob;
      })).subscribe();
  }

  getGtcSuppTermsTaiwanPdf(){
    this.http.get(this.url + '/api/v1/files/download/ppgtcsupptermstaiwan.pdf',
      {responseType: 'blob'}).subscribe(arrayBuffer => {
        const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
      }, error => {
        console.error('Error fetching PDF:', error);
      });
  }

  getGtcSuppTermsEuropeanUnion() {
    this.http.get(this.url + '/api/v1/files/download/GTCsSuppTerms-EUFinalApril2-0404.pdf',
      {responseType: 'blob'}).subscribe(arrayBuffer => {
        const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
      }, error => {
        console.error('Error fetching PDF:', error);
      });
  }

  getPpGtcSuppTermsEuropeanUnion() {
    this.http.get(this.url + '/api/v1/files/download/ppgtcsupptermseu.pdf',
      {responseType: 'blob'}).subscribe(arrayBuffer => {
        const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
      }, error => {
        console.error('Error fetching PDF:', error);
      });
  }

  getGtcSuppTermsAustralia(){
    this.http.get(this.url + '/api/v1/files/download/foasupp.doc',
      {responseType: 'blob'}).pipe(map(blob => {
        const url = window.URL.createObjectURL(blob);
        // Create a link element and trigger a click event
        const a = document.createElement('a');
        a.href = url;
        a.download = "foasupp.doc";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        return blob;
      })).subscribe();
  }

  getPpGtcSuppTermsAustralia(){
    this.http.get(this.url + '/api/v1/files/download/ppgtcsupptermsaustralia.pdf',
      {responseType: 'blob'}).subscribe(arrayBuffer => {
        const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
      }, error => {
        console.error('Error fetching PDF:', error);
      });
  }
}
