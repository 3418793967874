import {Component} from '@angular/core';
import {IndirectService} from "../service/indirect.service";

@Component({
  selector: 'app-indirect-communications',
  templateUrl: './indirect-communications.component.html',
  styleUrls: ['./indirect-communications.component.scss']
})
export class IndirectCommunicationsComponent {

  constructor(private indirectService: IndirectService) {
  }

  showCommunicationPdf(): void {
    this.indirectService.getCommunicationPdf();
  }

}
