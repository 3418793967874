import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {GsdbOnlineIntroResponse} from "../../../models/perl-migration-web/gsdb-online/gsdbOnlineIntroResponse";

@Injectable({
  providedIn: 'root'
})
export class GsdbOnlineIntroService {
  private url = environment.perlMigrationApiUrl

  constructor(private http: HttpClient) {
  }

  gsdbOnlineRegistration(covisintID: String) {
    return this.http.get<GsdbOnlineIntroResponse>(this.url + '/api/v1/gsdbOnlineRegistration?covisintId=' + covisintID + '&previousProgram=gsdbOnlineIntroduction')
  }
}
