import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {UserCsaService} from "../service/user-csa.service";
import {UserAccessList, UserAccessReview} from "../../../models/paars-web/user-access-review.model";
import {PopUpComponent} from "../../../common/pop-up/pop-up.component";
import {MatDialog} from "@angular/material/dialog";
import {ReviewNotCompletedComponent} from "../../common/review-not-completed/review-not-completed.component";
import {ReviewCompletedComponent} from "../../common/review-completed/review-completed.component";

@Component({
  selector: 'app-csa-review',
  templateUrl: './csa-review.component.html',
  styleUrls: ['./csa-review.component.scss']
})
export class CsaReviewComponent implements OnInit {
  csaDetails: FormGroup;
  records!: FormArray<FormGroup>;
  copyRecords!: FormArray<FormGroup>;
  searchGroup: FormGroup;
  csaRecords: UserAccessReview[] = [];
  hasRecords: boolean = true;
  notReviewed: boolean = false;

  constructor(private router: Router, private fb: FormBuilder, private csaService: UserCsaService, private dialog: MatDialog) {
    this.searchGroup = this.fb.group({
      fspId: [''],
      racfId: [''],
      suplrCode: [''],
      system: [''],
    })
    this.csaDetails = new FormGroup({
      records: new FormArray([]),
      copyRecords: this.fb.array([]),
    })
  }

  exit() {
    window.close();
  }

  ngOnInit(): void {
    this.records = this.csaDetails.get('records') as FormArray;
    this.copyRecords = this.csaDetails.get('copyRecords') as FormArray;
    this.csaService.getCsaReviewDetails("REVIEWED").subscribe(data => {
      this.csaRecords = data
      this.getCsaReviewDetails("REVIEWED")
    })

  }

  viewUncompletedRecords() {
    this.csaService.getCsaReviewDetails("NOTREVIEWED").subscribe(data => {
      this.csaRecords = data
      this.getCsaReviewDetails("NOTREVIEWED")
    })
  }

  getCsaReviewDetails(reviewAction: string) {
    if (this.csaRecords.length === 0) {
      this.hasRecords = false
      if (reviewAction === "NOTREVIEWED") {
        this.notReviewed = true
      }
      this.records.clear()
    } else {
      this.hasRecords = true
      this.notReviewed = false
      this.records.clear()
      this.csaRecords.forEach((record) => {
        this.pushFormGroup(record);
      });
      if (reviewAction === "REVIEWED") {
        this.copyRecords = this.fb.array(
          this.records.controls.map(record => this.cloneFormGroup(record as FormGroup))
        );
      }
    }
  }

  pushFormGroup(record: any) {
    this.records = this.csaDetails.get('records') as FormArray;
    this.records.push(this.createUserForms(record));
  }

  cloneFormGroup(formGroup: FormGroup): FormGroup {
    const clonedGroup = this.fb.group({});
    Object.keys(formGroup.controls).forEach(key => {
      // Get the control from the original group
      const control = formGroup.controls[key];      // Create a new FormControl based on the original's value and validators
      let clonedControl = new FormControl(control.value, control.validator)
      if (control.disabled) {
        clonedControl.disable()
      }
      clonedGroup.addControl(key, clonedControl);
    });
    return clonedGroup;
  }

  createUserForms(record: UserAccessReview): FormGroup {
    return this.fb.group({
      approve: [record.approveFlag === "Y" ? "Y" : record.removeFlag === "Y" ? "N" : record.ncm == "Y" ? "csa" : ""],
      replaceCsa: new FormControl({
        value: record.newApproverCdsid,
        disabled: record.ncm !== "Y"
      }),
      fspId: [record.userCdsid],
      racfId: [record.racfId],
      name: [record.userName],
      suplrCode: [record.supplierCode],
      system: [record.system],
      divPlt: [record.divPlt],
      jobFun: [record.jobFunction],
      systemAcc: [record.systemAccess],
      userAccessId: [record.userAccessId],
      email: [record.suppEmailAddText],
    })
  }

  home() {
    this.router.navigate(["/PAARS/userCSAReview/home"])
  }

  getUserCSARecords(): UserAccessReview[] {
    let userCsaRecords: UserAccessReview[] = []
    let cdsId: string | null = sessionStorage.getItem('userId')
    this.records.controls.forEach((formGroup: FormGroup) => {
      if (formGroup.get("approve")?.value !== "") {
        let userRecord: UserAccessReview = new UserAccessReview()
        userRecord.approveFlag = formGroup.get("approve")?.value == "Y" ? "Y" : "N"
        userRecord.removeFlag = formGroup.get("approve")?.value == "N" ? "Y" : "N"
        userRecord.ncm = formGroup.get("approve")?.value == "csa" ? "Y" : "N"
        userRecord.racfId = formGroup.get("racfId")?.value
        userRecord.userCdsid = formGroup.get("fspId")?.value
        userRecord.userName = formGroup.get("name")?.value
        userRecord.supplierCode = formGroup.get("suplrCode")?.value
        userRecord.system = formGroup.get("system")?.value
        userRecord.jobFunction = formGroup.get("jobFun")?.value
        userRecord.systemAccess = formGroup.get("systemAcc")?.value
        userRecord.divPlt = formGroup.get("divPlt")?.value
        userRecord.suppEmailAddText = formGroup.get("email")?.value
        userRecord.userAccessId = formGroup.get("userAccessId")?.value
        userRecord.newApproverCdsid = formGroup.get("replaceCsa")?.value
        userRecord.approverCdsId = cdsId
        userCsaRecords.push(userRecord)
      }
    })
    return userCsaRecords;
  }

  submitRecords() {
    this.csaService.saveCsaRecords(this.getUserCSARecords()).subscribe((data) => {
      this.csaRecords = data.userAccessReviewList
      this.getCsaReviewDetails("REVIEWED")
      if (data.status === "Not Completed") {
        this.dialog.open(ReviewNotCompletedComponent, {
          minWidth: '500px',
          maxWidth: "700px",
          disableClose: true,
          data: {
            title: "CSA"
          },
        })
      } else if (data.status === "Completed") {
        this.dialog.open(ReviewCompletedComponent, {
          minWidth: '500px',
          maxWidth: "700px",
          disableClose: true
        })
      }
    }, (error) => {
      this.displayError()
    })
  }

  saveRecords() {
    this.csaService.saveCsaRecords(this.getUserCSARecords()).subscribe((data: UserAccessList) => {
      this.csaRecords = data.userAccessReviewList
      this.getCsaReviewDetails("REVIEWED")
    }, (error) => {
      this.displayError()
    })

  }

  displayError() {
    this.dialog.open(PopUpComponent, {
      maxWidth: '500px',
      data: {
        message: "Please enter valid details and retry again.",
      },
    })
  }


  filterRecordsBySearch() {
    this.notReviewed = false
    this.hasRecords = true
    let fspId: string = this.searchGroup.get("fspId")?.value
    let racfId: string = this.searchGroup.get("racfId")?.value
    let suplrCode: string = this.searchGroup.get("suplrCode")?.value
    let system: string = this.searchGroup.get("system")?.value
    if (fspId === "" && racfId === "" && suplrCode === "" && system === "") {
      this.records.clear()
      this.copyRecords.controls.forEach((formGroup: FormGroup) => {
        this.records.push(formGroup)
      })
    } else {
      let fileterRecords =
        this.records.controls.filter(record => {
          let recordFspId = record.get("fspId")?.value
          let recordRacfId = record.get("racfId")?.value
          let recordSuplrCode = record.get("suplrCode")?.value
          let recordSystem = record.get("system")?.value
          return (
            recordFspId.match(fspId) &&
            recordRacfId.match(racfId) &&
            recordSuplrCode.match(suplrCode) &&
            recordSystem.match(system)
          );
        }).map(record => this.cloneFormGroup(record as FormGroup))
      this.records.clear()
      this.searchGroup.get("fspId")?.setValue("")
      this.searchGroup.get("racfId")?.setValue("")
      this.searchGroup.get("suplrCode")?.setValue("")
      this.searchGroup.get("system")?.setValue("")
      fileterRecords.forEach((formGroup: FormGroup) => {
        this.records.push(formGroup)
      })
    }
  }

  disableCSA(record: FormGroup) {
    record.get('replaceCsa')?.reset();
    record.get('replaceCsa')?.disable();
  }

  currentCsaChange(cdsId: String, newValue: String) {
    this.records.controls.forEach((formGroup: FormGroup) => {
      if (formGroup.get('fspId')?.value === cdsId && formGroup.get("approve")?.value === "csa") {
        formGroup.get('replaceCsa')?.setValue(newValue, {emitEvent: false});
        formGroup.get('replaceCsa')?.markAsDirty();
      }
    })
  }

  enableCSA(record: FormGroup) {
    let fspId: string = record.get("fspId")?.value
    this.records.controls.forEach((formGroup: FormGroup) => {
      if (formGroup.get('fspId')?.value === fspId) {
        formGroup.get('approve')?.setValue("csa")
        formGroup.get('replaceCsa')?.enable();
        formGroup.get('replaceCsa')?.valueChanges.subscribe((newValue) => {
          this.currentCsaChange(fspId, newValue);
        })
      }
    })
  }
}
