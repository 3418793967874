import {Component} from '@angular/core';
import {IndirectService} from "../service/indirect.service";

@Component({
  selector: 'app-indirect-right-sidebar',
  templateUrl: './indirect-right-sidebar.component.html',
  styleUrls: ['./indirect-right-sidebar.component.scss']
})
export class IndirectRightSidebarComponent {

  constructor(private indirectService: IndirectService) {
  }

  getPnpd027isaPdf() {
    this.indirectService.getPnpd027isaPdf();
  }

  getPnpd027asaPdf() {
    this.indirectService.getPnpd027asaPdf();
  }
  getPnpd224Pdf(){
    this.indirectService.getPnpd224Pdf();
  }
  getPnpd229Pdf(){
    this.indirectService.getPnpd229Pdf();
  }
  showPiiSupplementPdf(): void {
    this.indirectService.getPiiSupplementPdf();
  }
}
