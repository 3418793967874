import {Component, inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PerlMigrationService} from "../service/perl-migration.service";
import {Dialog} from "@angular/cdk/dialog";
import {PopUpComponent} from "../../common/pop-up/pop-up.component";

@Component({
  selector: 'app-gsdb-hierarchy',
  templateUrl: './gsdb-hierarchy.component.html',
  styleUrls: ['./gsdb-hierarchy.component.scss']
})
export class GsdbHierarchyComponent {
  data: any
  gsdbForm: FormGroup;
  dialog = inject(Dialog);

  constructor(private fb: FormBuilder,
              private perlMigrationService: PerlMigrationService) {
    this.gsdbForm = this.fb.group(({
      parentCode: ['', [Validators.required, Validators.minLength(4),
        Validators.maxLength(5)]]
    }))
  }

  private serializeXml(xmlDocument: Document): string {
    const serializer = new XMLSerializer();
    return serializer.serializeToString(xmlDocument);
  }

  onSubmit() {
    this.perlMigrationService.getGsdbHierarchyData(this.gsdbForm.value.parentCode).subscribe(data => {
      this.data = data
      const serializedXml = this.serializeXml(this.data)
      const blob = new Blob([serializedXml], {type: 'application/xml'});
      this.gsdbForm.reset();
      window.location.href = URL.createObjectURL(blob);
    }, error => {
      const message: string = error.error;
      if (error.status !== 0) {
        this.dialog.open(PopUpComponent, {
          maxWidth: '500px',
          data: {
            message: message,
          },
        })
      }
    })
  }
}
