import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataLoaderService {

  private url = environment.perlMigrationApiUrl

  constructor(private http: HttpClient) { }


  uploadDataLoaderFile(file:File):Observable<string>{
    const formData = new FormData();
    formData.append('file',file,file.name);

    return this.http.post<string>(this.url+'/api/v1/dataloader',formData,{
      reportProgress: true, responseType: 'text' as 'json'
    })
  }
}
