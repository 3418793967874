import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {UserSelfRemovalService} from "../service/user-self-removal.service";
import {SelfRemovalRecords} from "../../../models/paars-web/userSelfRemoval";
import {MatDialog} from "@angular/material/dialog";
import {PopUpComponent} from "../../../common/pop-up/pop-up.component";
import {SelfRemovalSuccessComponent} from "../self-removal-success/self-removal-success.component";
import {CriticalInformationComponent} from "../critical-information/critical-information.component";

@Component({
  selector: 'app-user-self-removal',
  templateUrl: './user-self-removal.component.html',
  styleUrls: ['./user-self-removal.component.scss']
})
export class UserSelfRemovalComponent implements OnInit {
  selfRemovalDetails: FormGroup;
  records!: FormArray<FormGroup>;
  selfRemovalRecords: SelfRemovalRecords[] = [];
  userName: String = ""
  date: string = ""
  hasRecords: boolean = true

  constructor(private fb: FormBuilder, private userSelfService: UserSelfRemovalService, private dialog: MatDialog) {
    this.selfRemovalDetails = new FormGroup({
      records: new FormArray([])
    })
  }

  ngOnInit(): void {
    this.records = this.selfRemovalDetails.get('records') as FormArray;
    this.userSelfService.getSelfRemovalDetails().subscribe(data => {
      this.selfRemovalRecords = data.userSelfRemovalList
      if (this.selfRemovalRecords.length === 0) {
        this.hasRecords = false
      }
      this.userName = data.userName
      this.date = data.taskDate
      this.selfRemovalRecords.forEach((record) => {
        this.pushFormGroup(record);
      });
    }, error => {
    })
  }

  pushFormGroup(record: any) {
    this.records = this.selfRemovalDetails.get('records') as FormArray;
    this.records.push(this.createUserForms(record));
  }

  createUserForms(record: SelfRemovalRecords): FormGroup {
    return this.fb.group({
      system: [record.system],
      systemAccess: [record.systemAccess],
      jobFunction: [record.jobFunction],
      racfId: [record.racfid],
      divPlt: [record.location],
      removeAcc: [record.removeFlag === "Y" ? true : false],
      currentAuthorizer: [record.approverCdsid],
      changeAuthorizer: [record.failedAuditFlag === "Y" ? true : false],
      newAuthorizer: new FormControl({
        value: record.newApproverCdsid,
        disabled: record.failedAuditFlag !== "Y"
      }),
      stageId: [record.stageId],
    })
  }

  openSystemAnnualReview() {
    window.open("https://www.purchasing.ford.com/prch_pims/aar/pims_aar.html")
  }

  getSelfRemovalRecords(): SelfRemovalRecords[] {
    let selfRemovalRecords: SelfRemovalRecords[] = []
    let cdsId: String | null = sessionStorage.getItem('userId')
    this.records.controls.forEach((formGroup: FormGroup) => {
      let userRecord: SelfRemovalRecords = new SelfRemovalRecords()
      userRecord.removeFlag = formGroup.get("removeAcc")?.value ? "Y" : "N"
      userRecord.failedAuditFlag = formGroup.get("changeAuthorizer")?.value ? "Y" : "N"
      userRecord.newApproverCdsid = formGroup.get("newAuthorizer")?.value
      userRecord.stageId = formGroup.get("stageId")?.value
      userRecord.userCdsid = cdsId
      selfRemovalRecords.push(userRecord)
    })
    return selfRemovalRecords;
  }

  submit() {
    this.userSelfService.submitSelfRemovalDetails(this.getSelfRemovalRecords()).subscribe(data => {
      this.selfRemovalDetails.markAsDirty()
      this.dialog.open(SelfRemovalSuccessComponent, {
        minWidth: '500px',
        maxWidth: "700px",
        disableClose: true
      })
    }, error => {
      this.displayError()
    })
  }

  currentCsaChange(cdsId: String, newValue: String) {
    this.records.controls.forEach((formGroup: FormGroup) => {
      if (formGroup.get('currentAuthorizer')?.value === cdsId) {
        formGroup.get('newAuthorizer')?.setValue(newValue, {emitEvent: false});
        formGroup.get('newAuthorizer')?.markAsDirty();
      }
    })
  }

  disableAuthorizer(record: FormGroup) {
    const changeAuthorizer = record.get("changeAuthorizer")?.value
    const currentAuthorizer = record.get("currentAuthorizer")?.value
    this.records.controls.forEach((formGroup: FormGroup) => {
      if (formGroup.get('currentAuthorizer')?.value === currentAuthorizer) {
        formGroup.get("changeAuthorizer")?.setValue(changeAuthorizer)
        if (changeAuthorizer) {
          formGroup.get("newAuthorizer")?.enable()
          formGroup.get('newAuthorizer')?.valueChanges.subscribe((newValue) => {
            this.currentCsaChange(currentAuthorizer, newValue);
          })
        } else {
          formGroup.get("newAuthorizer")?.reset()
          formGroup.get("newAuthorizer")?.disable()
        }

      }
    })
  }

  displayCriticalInformation(){
    this.dialog.open(CriticalInformationComponent, {
      minWidth: '500px',
      maxWidth: "700px",
      disableClose: true
    })
  }

  displayError() {
    this.dialog.open(PopUpComponent, {
      maxWidth: '500px',
      data: {
        message: "Please enter valid details and retry again.",
      },
    })
  }
}
