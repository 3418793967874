import {Component} from '@angular/core';
import {ProductionService} from "../service/production.service";

@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.scss']
})
export class CommunicationsComponent {
  constructor(private productionService: ProductionService) {
  }

  showCommunicationPdf(): void {
    this.productionService.getCommunicationPdf();
  }
}
