<app-common-header></app-common-header>
<div class="container-fluid">
    <div class="row">
        <div class="page-title">
            <b>PCSA Embedded Info Email Application</b>
        </div>
    </div>

    <div class="row">
        <div class="col-10">
            <div class="row font1" [hidden]="formSubmitted">
                <b>
                    &nbsp;&nbsp;<b>
				    	Your file: <b class="text-danger">{{filename}} </b> was successfully uploaded</b>
                </b>
            </div>
            <div class="row font1" [hidden]="formSubmitted">
                <b>
                    &nbsp;&nbsp;The number of users to email is: <b class="text-danger">{{count}}</b>
				     Is this correct?</b><br>
                     <b>If the number of user entries is correct please fill out the email
                    field below using the keys to the left to embed company data in your email 
                    and Click "Yes"</b>
            </div>

            <div class="row font1" [hidden]="emailConfirmed">
                <b>
                    Below is a copy of the first email to go out.
                     Please Check for correctness and click on appropriate link below.</b>
            </div>

            <div class="row font1" [hidden]="!emailSent">
                   <p *ngIf="sendSuccess">
                        <B class="text-danger">Thank You</B><BR>
                        <B>Your email has been sent to all of the listed email addresses</B><BR>
                        <b>To go through this process again you must start at the first file upload page.</b>
                   </p>
                   <p *ngIf="!sendSuccess">
                    <B class="text-danger">We're Sorry</B><BR>
                    <B>We have encountered some technical difficulties. Failed to send the emails</B><BR>
                    <b>Please try again after sometime or contact FSP support.</b>
               </p>
            </div>

            <div class="row" >
                <div class="col-3 table1"[hidden]="emailSent" *ngIf="sendto === 'PCSA'">
                        <tr>
                            <td class="td">&nbsp; &nbsp;For this data &nbsp; &nbsp;</td>
                            <td class="td">&nbsp; &nbsp;PCSA Information Date&nbsp; &nbsp;</td>
                        </tr>
                        <tr>
                            <td class="td">&nbsp; &nbsp;Enter this text &nbsp;<br/>
								Date&nbsp; &nbsp;</td>
                            <td class="td">&nbsp; &nbsp;+pcsaInfo+<br>
								+date+&nbsp; &nbsp;</td>
                        </tr>
                </div>
                <div class="col-9" [hidden]="emailSent">
                    <form class="font4" [formGroup]="emailBody" (ngSubmit)="onSubmit()">  
                        <div class="row" >
                            <div class="col-1">
                                <label for= "Subject">Subject: </label>
                            </div>
                            <div class="col-9">
                                &nbsp; &nbsp; &nbsp; &nbsp; 
                                <input type="text" formControlName="subject" class="input-text">
                            </div>
                        </div>
                        <div class="row row-padding">
                            <div>
                                <label for="emailText">Email Text:</label>
                            </div>
                            <div class="col-11">
                                <textarea class="input-text text-area" formControlName="emailText"></textarea>
                            </div>
                        </div>
                        <div class="row font2" *ngIf="!formSubmitted">
                            <div class="col-4">
                               <button class="link" type="submit" [disabled]="emailBody.invalid"><u><b>Yes, it is correct!</b></u>         
                               </button>
                            </div>   
                            <div class="col-4">
                               <a routerLink = "/perlMigrationWeb/embededEmail/emailFileUpload">
                                   <u><b>No Let's try again!</b></u>
                               </a>
                            </div>
                       </div >
                    </form>
                </div>
                <div class="row font2" [hidden]="emailSent" *ngIf="formSubmitted">
                    <div class="col-3"></div>
                    <div class="col-4">
                        <button class="link" (click)="sendMail()" ><u><b>Yes, it is correct! Send my email</b></u>         
                        </button>
                    </div>   
                    <div class="col-4">
                            <button class="link" (click)="editMail()"><u><b>No, it is not correct! Edit my email</b></u>         
                            </button>
                    </div>
                </div>
                
                <div class="row font2" [hidden]="!emailSent">
                    <div class="col-4"></div>
                    <div class="col-4" *ngIf="sendSuccess">
                        <button routerLink="/perlMigrationWeb/embededEmail/emailFileUpload">
                            <u><b>Send Another batch of emails</b></u>         
                        </button>
                    </div>  
                    <div class="col-4" *ngIf="!sendSuccess">
                        <button routerLink="/perlMigrationWeb/embededEmail/emailFileUpload">
                            <u><b>Try Again</b></u>         
                        </button>
                    </div>   
                </div>
            </div>

            <div class="row">
                <hr>
            </div>

            <div class="row font3">
                <b> Important: The
                    format of your resulting file will be Comma Separeted Values
                    (CSV). This format will allow you to manipulate it with MS Excel.</b>
            </div>

</div>

