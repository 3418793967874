export class NewApprover {
  public deleteCdsId: string = ''
  public replaceCdsId: string = ''
  public status: string = ''
}

export class UserHierarchyReview {
  public approveFlag: string = ""
  public removeFlag: string = ""
  public userCdsid: string = ""
  public userName: string = ""
  public hierarchyName: string = ""
  public approvalIdentifier: string = ""
  public approvalLevel: string = ""
  public expenseApprovalLimit: string = ""
  public userHierId: string = ""
  public controllerOrg: string = ""
  public replacementUser: string = ""
  public comments: string = ""
  public notChainOwnerFlag: string = ""
  public newControllerCdsid: string = ""
}

export class UserHierarchyList {
  public status: string = ''
  public userHierarchyReviewList: UserHierarchyReview[] = []
}
