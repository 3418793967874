<app-gsdb-online-header></app-gsdb-online-header>
<div class="body ml-8 mr-8 mt-8">
  <p class="para-title pl-4 mt-14"><b> GSDB Online Steps > Introduction :: Nomination ::
    Indemnification :: <span class="reject">Refusal to Authorize</span></b></p>
  <p> You have decided to reject approval of the Executive Authorization for your company to access GSDB Online for the
    reasons listed below.</p>
  <ul class="list-disc">
    <li>reject_reasons</li>
  </ul>
  <p class="warning">Please be advised that registration in GSDB Online is a requirement for future business with Ford Motor
    Company.</p>
  <p> To obtain additional information about GSDB Online, please contact your Ford Account Manager for your company or
    your Ford Buyer.</p>
  <hr class="mt-6 mb-4">
  <div class="mb-4 font-size">
    <p class="m-0 text-center">Questions about GSDB Online?</p>
    <p class="m-0 text-center">Contact <span class="text-decoration-underline">gsdbweb@ford.com</span> or access
      <a routerLink="/support" class="nav-link">Ford Help Desk Support</a></p>
    <p class="m-0 text-center">The customer support center is available 24 hours each day.</p>
  </div>
</div>
