<app-common-header></app-common-header>
<div class = "everest-token-home">
    <table class="layout-table">
        <tr>
            <td>
                <div class="page-title">
                    <b>EVEREST Supplier Token Status Application</b>
                </div>
            </td>
        </tr>

        <tr>
            <td colspan=2 class="td1">
                <table class="table1">
                    <tr valign="top"> 
                        <td align="center"> 
                            <table class="table2">
                                <tr> 
                                 <td align="center"> 
                                      <p class="font1"><b>
                                          &nbsp;&nbsp;Type in your GSDB Parent or Site Code<br>
                                      </b></p>    
                                    </td>
                                </tr>
                            </table> 
                            <table class="table2">
                                <tr align="left">
                                    <td class="selection"></td>
                                    <td class="selection">
                                        <form [formGroup]="siteCodeGroup" (ngSubmit)="onSubmit()">
                                            <div class="form-group">
                                                <label for="gsdbCode">
                                                   <b> Please enter the GSDB code : </b>
                                                   <input class = "input-text" formControlName="siteCode" type = "text" maxlength="5" size="5"/>
                                                </label>
                                                <span id="gsdbCodeError" class = "text-danger" *ngIf="siteCodeGroup.controls['siteCode'].invalid && 
                                                siteCodeGroup.controls['siteCode'].dirty"><br>GSDB code size is not valid</span>
                                            </div>
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="selection"></td>
                                    <td class="font2">
                                        <button type="submit" class="btn btn-primary btn-sm" (click)="onSubmit()"
                                        [disabled]="siteCodeGroup.get('siteCode')?.invalid">Check the Status of this GSDB Parent/Supplier Code</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="selection"></td>
                                    <td class="link-text">
                                        <a routerLink = "/perlMigrationWeb/everstTokenLookup"><u>Back to Main Page</u>
                                        </a>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>  
                    <tr>
                        <td>
                            <hr class="hr">
                        </td>
                    </tr>   
                    
                    <tr> 
                        <td align="center" colspan="3"> 
                            <table class="table3" >
                                <tr> 
                                    <td align="center" colspan="3"> 
                                        <ul>
                                            <li class="font2"><b> Note: You can type in the GSDB code as upper
                                                or lower case</b></li>
                                        </ul>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>      
    </table>
</div>