import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthenticationGuard, OauthCallbackComponent, OauthTokenGuard} from "@wame/ngx-adfs";
import {gtcRoutes} from "./gtc/routes/gtcRoutes";
import {perlMigrationRoutes} from "./perl-migration-web/routes/perlMigrationRoutes";
import {HelpdeskSupportComponent} from "./common/helpdesk-support/helpdesk-support.component";
import {NotFoundComponent} from "./common/not-found/not-found.component";
import {paarsWebRoutes} from "./paars-web/routes/paarsWebRoutes";
import { BackendCoonectionErrorComponent } from './common/backend-coonection-error/backend-coonection-error.component';
import { GtcDocumentGuard } from './gtc/gtc-document.guard';
import { EmptyComponentComponent } from './gtc/empty-component/empty-component.component';

const routes: Routes = [
  {
    path: 'oauth',
    component: OauthCallbackComponent,
    canActivate: [OauthTokenGuard]
  },
  {path: '', redirectTo: 'gtc', pathMatch: 'full'},
  {path: 'support', component: HelpdeskSupportComponent, canActivate: [AuthenticationGuard]},
  ...gtcRoutes,
  ...perlMigrationRoutes,
  ...paarsWebRoutes,
  {path: 'server-error', component: BackendCoonectionErrorComponent},
  {path: '404', component: NotFoundComponent},
  {path: '**', redirectTo: '404', pathMatch: 'full'}
  


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
