import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class IndirectService {
  private url = environment.gtcApiUrl

  constructor(private http: HttpClient) {
  }

  getCommunicationPdf() {
    this.http.get(this.url + '/api/v1/files/download/2022sgupdates.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getPiiSupplementPdf() {
    this.http.get(this.url + '/api/v1/files/download/piisupp.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getInfoSecuritySupplementPdf() {
    this.http.get(this.url + '/api/v1/files/download/ISP SUPPLEMENT.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getUkPurchasingTaxPdf() {
    this.http.get(this.url + '/api/v1/files/download/pnpd234.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getSupplierTravelExpensePolicyPdf() {
   this.http.get(this.url + '/api/v1/files/download/pnpd232.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
    const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
  }, error => {
    console.error('Error fetching PDF:', error);
  });
  }

  getMarketingServicesSupplementDoc() {
     this.http.get(this.url + '/api/v1/files/download/pnpd228.docx', {responseType: 'blob'}).pipe(map(blob => {
      const url = window.URL.createObjectURL(blob);
      // Create a link element and trigger a click event
      const a = document.createElement('a');
      a.href = url;
      a.download = "pnpd228.docx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      return blob;
    })).subscribe();
  }
  getPnpd027isaPdf() {
   this.http.get(this.url + '/api/v1/files/download/pnpd027isa.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
    const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
  }, error => {
    console.error('Error fetching PDF:', error);
  });
  }
  getPnpd027asaPdf() {
    this.http.get(this.url + '/api/v1/files/download/pnpd027asa.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }
  getPnpd224Pdf() {
    this.http.get(this.url + '/api/v1/files/download/pnpd224.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }
  getPnpd229Pdf(){
    this.http.get(this.url + '/api/v1/files/download/pnpd229.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }
}
