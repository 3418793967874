import {Component, ElementRef, ViewChild} from '@angular/core';
import {SupplierGuidesService} from "../service/supplier-guides.service";

@Component({
  selector: 'app-supplier-guides',
  templateUrl: './supplier-guides.component.html',
  styleUrls: ['./supplier-guides.component.scss']
})
export class SupplierGuidesComponent {
  constructor(private supplierGuidesService: SupplierGuidesService) {
  }

  getAutoCurrencyPdf() {
    this.supplierGuidesService.getAutoCurrencyPdf();
  }

  getAutomaterialsPdf() {
    this.supplierGuidesService.getAutomaterialsPdf();
  }

  getBailmenteu2007Pdf() {
    this.supplierGuidesService.getBailmenteu2007Pdf();
  }

  getCapacityPlanPdf() {
    this.supplierGuidesService.getCapacityPlanPdf();
  }

  getCSIPPdf() {
    this.supplierGuidesService.getCSIPPdf();
  }

  getClaimsPdf() {
    this.supplierGuidesService.getClaimsPdf();
  }

  getComanagedBuyPdf() {
    this.supplierGuidesService.getComanagedBuyPdf();
  }

  getCustomScanPdf() {
    this.supplierGuidesService.getCustomScanPdf();
  }

  getCustomsMexicoPdf() {
    this.supplierGuidesService.getCustomsMexicoPdf();
  }

  getCustomsUsaPdf() {
    this.supplierGuidesService.getCustomsUsaPdf();
  }

  getDeleveryTermsFOEPdf() {
    this.supplierGuidesService.getDeleveryTermsFOEPdf();
  }

  getDigitalManufacturingDataPdf() {
    this.supplierGuidesService.getDigitalManufacturingDataPdf();
  }

  getProdDisputeResolutionPdf() {
    this.supplierGuidesService.getProdDisputeResolutionPdf();
  }

  getIndirectDisputeResolutionPdf() {
    this.supplierGuidesService.getIndirectDisputeResolutionPdf();
  }

  getEnvGuidePdf() {
    this.supplierGuidesService.getEnvGuidePdf();
  }

  getExportControlWebGuidePdf() {
    this.supplierGuidesService.getExportControlWebGuidePdf();
  }

  getFCSDNorthAmericaPdf() {
    this.supplierGuidesService.getFCSDNorthAmericaPdf();
  }

  getFCSDEuropePdf() {
    this.supplierGuidesService.getFCSDEuropePdf();
  }

  getFCSDGlobalPdf() {
    this.supplierGuidesService.getFCSDGlobalPdf();
  }

  getFSAPdf() {
    this.supplierGuidesService.getFSAPdf();
  }

  getFordBrandProtectPdf() {
    this.supplierGuidesService.getFordBrandProtectPdf();
  }

  getInvoicingGuidelinesPdf() {
    this.supplierGuidesService.getInvoicingGuidelinesPdf();
  }

  getShipPartsPdf() {
    this.supplierGuidesService.getShipPartsPdf();
  }

  getMplmaterialsPdf() {
    this.supplierGuidesService.getMplmaterialsPdf();
  }

  getWareHouseInstructionPdf() {
    this.supplierGuidesService.getWareHouseInstructionPdf();
  }

  getNewsReleasesPdf() {
    this.supplierGuidesService.getNewsReleasesPdf();
  }

  getGlobalexppkgPdf() {
    this.supplierGuidesService.getGlobalexppkgPdf();
  }

  getEu1750pkgPdf() {
    this.supplierGuidesService.getEu1750pkgPdf();
  }

  getNapkgguidePdf() {
    this.supplierGuidesService.getNapkgguidePdf();
  }

  getAPpkgguidePdf() {
    this.supplierGuidesService.getAPpkgguidePdf();
  }

  getCN1750pkgPdf() {
    this.supplierGuidesService.getCN1750pkgPdf();
  }

  getProdPayGuidePdf() {
    this.supplierGuidesService.getProdPayGuidePdf();
  }

  getIndirectPayGuidePdf() {
    this.supplierGuidesService.getIndirectPayGuidePdf();
  }

  getNonrenewalPdf() {
    this.supplierGuidesService.getNonrenewalPdf();
  }

  getPtoewarrantsPdf() {
    this.supplierGuidesService.getPtoewarrantsPdf();
  }

  getSteelrawmtlsPdf() {
    this.supplierGuidesService.getSteelrawmtlsPdf();
  }

  getReturnableContainerASNPdf() {
    this.supplierGuidesService.getReturnableContainerASNPdf();
  }

  getShipmentsfoe() {
    this.supplierGuidesService.getShipmentsfoe();
  }

  getNashippingPdf() {
    this.supplierGuidesService.getNashippingPdf();
  }

  getHrandwcPdf() {
    this.supplierGuidesService.getHrandwcPdf();
  }

  getTaxPdf() {
    this.supplierGuidesService.getTaxPdf();
  }

  getToolingPurchaseGuidePdf() {
    this.supplierGuidesService.getToolingPurchaseGuidePdf();
  }

  getSupptooltaggingPdf() {
    this.supplierGuidesService.getSupptooltaggingPdf();
  }

  getGovernmentSubContractorPdf() {
    this.supplierGuidesService.getGovernmentSubContractorPdf();
  }

  getSdiPdf() {
    this.supplierGuidesService.getSdiPdf();
  }

  getValueChainTransparencyPdf() {
    this.supplierGuidesService.getValueChainTransparencyPdf();
  }

  getWarrantyChargebackPdf() {
    this.supplierGuidesService.getWarrantyChargebackPdf();
  }

  getWarrantyQosPdf() {
    this.supplierGuidesService.getWarrantyQosPdf();
  }
}
