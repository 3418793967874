import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppLaunch } from 'src/app/models/perl-migration-web/app-launch/app-launch.model';
import { AppLaunchService } from '../service/app-launch.service';

@Component({
  selector: 'app-app-launch',
  templateUrl: './app-launch.component.html',
  styleUrls: ['./app-launch.component.scss']
})
export class AppLaunchComponent {

  appLaunchForm: FormGroup;
  formHidden:boolean= false;
  appLaunch!: AppLaunch ;

  constructor(private fb:FormBuilder,private appLaunchService: AppLaunchService){
    this.appLaunchForm=this.fb.group({
      appName:['',[Validators.required,Validators.maxLength(60)]],
      appNameOnPortal:['',[Validators.required,Validators.maxLength(60)]],
      prodURL:['',[Validators.required,Validators.maxLength(60)]],
      appCategory:['Quality',[Validators.required]],
      helpDeskContact:['',[Validators.required,Validators.maxLength(60)]],
      businessContact:['',[Validators.required,Validators.maxLength(10)]],
      primaryContact:['',[Validators.required,Validators.maxLength(10)]],
      technicalContact:['',[Validators.required,Validators.maxLength(10)]],
      aCRNumber:['',[Validators.required,Validators.maxLength(10)]],
      financialSwitch:['',[Validators.required]],
      criticalSwitch:['',[Validators.required]],
      privateSwitch:['',[Validators.required]],
      securityLevel:['Restricted to Subset of Suppliers and Users by Token',[Validators.required]],
      tokenName:['',[Validators.maxLength(20)]],
      siteCodeSwitch:[''],
      defaultAppSwitch:[''],
      siteConcept:['',[Validators.required,Validators.maxLength(1000)]],
      siteDetail:['',[Validators.required,Validators.maxLength(1000)]],
      additionalRegistration:['',[Validators.required,Validators.maxLength(1000)]]
    })
  }

  onSubmit(){
    this.creatReqBody(this.appLaunchForm.value);
    this.getAppLaunchData();
    }

  getAppLaunchData(){
      this.appLaunchService.postAppLaunchData(this.appLaunch).subscribe((data:String)=>{
        this.formHidden=true;
      },error=>{
        console.log(error)
      })
  }

  creatReqBody(formValues: any){
     this.appLaunch = new AppLaunch(
      formValues.appName, formValues.appNameOnPortal,
       formValues.prodURL, formValues.appCategory,
       formValues.helpDeskContact, formValues.businessContact,
        formValues.primaryContact, formValues.technicalContact,
        formValues.financialSwitch, formValues.criticalSwitch,
        formValues.privateSwitch, formValues.securityLevel,
        formValues.tokenName, formValues.siteCodeSwitch,
        formValues.defaultAppSwitch, formValues.siteConcept,
        formValues.siteDetail, formValues.additionalRegistration, formValues.aCRNumber
     );
  }

}
