<app-common-header></app-common-header>
<div class="container-fluid">
    <div class="row">
        <div class="page-title">
            <b>EVEREST Supplier Token Status Application</b>
        </div>
    </div>

    <div class="row">
        <div class="col-9">
            <div class="row font1">
                <b>
                    &nbsp;&nbsp;<b>
				    	Your file: <b class="text-danger">{{filename}} </b> was successfully uploaded</b>
                </b>
            </div>
            <div class="row font1">
                <b>
                    &nbsp;&nbsp;The number of Supplier/Parent Company Codes counted was <b class="text-danger">{{count}}</b>
				    	
				    	 Is this correct?</b>
            </div>

            <div class="row font1">
                <b class="text-danger">	***Attention: </b> 
                <b>
                Do NOT close this browser window after you click on the "Yes, It is correct!" button.
                </b>
                <b>
                 Closing this browser window would end any processing. Please wait until the next
                 web page is displayed confirming the end of the process.
                 </b>
            </div>
            <div class="row font2">
                 <div class="col-4">
                    <button class="link" (click)="getBulkEverestTokenReport()"><u><b>Yes, it is correct!</b></u>         
                    </button>
                 </div>   
                 <div class="col-4">
                    <a routerLink = "/perlMigrationWeb/everstTokenLookup/uploadFile">
                        <u><b>No Let's try again!</b></u>
                    </a>
                 </div>
            </div >
            <div class="row">
                <hr>
            </div>

            <div class="row font3">
                <b> Important: The
                    format of your resulting file will be Comma Separeted Values
                    (CSV). This format will allow you to manipulate it with MS Excel.</b>
            </div>

</div>
