import { Component } from '@angular/core';

@Component({
  selector: 'app-user-access-review-critical',
  templateUrl: './user-access-review-critical.component.html',
  styleUrls: ['./user-access-review-critical.component.scss']
})
export class UserAccessReviewCriticalComponent {

}
