import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {
  GsdbOnlineAuthResponse,
  GsdbOnlineInprocesRes
} from "../../../models/perl-migration-web/gsdb-online/gsdbOnlineAuthorization";
import {GsdbOnlineAuthRequestBody} from "../../../models/perl-migration-web/gsdb-online/gsdbOnlineAuthRequestBody";

@Injectable({
  providedIn: 'root'
})
export class GsdbOnlineAuthService {
  private url = environment.perlMigrationApiUrl

  private gsdbOnlineAuthResponse: GsdbOnlineAuthResponse = new GsdbOnlineAuthResponse();

  constructor(private http: HttpClient) {
  }

  setGSDBOnlinAuthResponse(gsdbOnlineAuthResponse: GsdbOnlineAuthResponse) {
    this.gsdbOnlineAuthResponse = gsdbOnlineAuthResponse
  }

  authoriseGsdbOnlineApplication(lastName: String, pinNumber: String) {
    return this.http.get<GsdbOnlineAuthResponse>(this.url + '/api/v1/gsdbOnlineAuthorization/lastName/' + lastName + '/pin/' + pinNumber)
  }

  authGsdbOnlinInprocessRequest(requestBody: GsdbOnlineAuthRequestBody) {
    requestBody.lastname = this.gsdbOnlineAuthResponse.lastname
    requestBody.pin = this.gsdbOnlineAuthResponse.pin
    return this.http.post<GsdbOnlineInprocesRes>(this.url + '/api/v1/gsdbAuthorizationData', requestBody)
  }
}
