<div class="terms-conditions-container">
  <p class="heading pl-1">Terms and Conditions - Indirect</p>
  <div>
    <table class="m-2 table-container" border="1" cellpadding="3" cellspacing="0">
      <tr>
        <th>Effective Date</th>
        <th>Document Name</th>
        <th>Language</th>
        <th>Contact Name</th>
        <th>Description</th>
      </tr>
      <tr>
        <td>12/01/2007</td>
        <td><a class="color" (click)="getGtcTermsEnglishPdf()">Global Terms and Conditions</a></td>
        <td>English</td>
        <td>Your Buyer</td>
        <td>FGT30, Rev. 12/07</td>
      </tr>
      <tr>
        <td>12/01/2007</td>
        <td><a class="color" (click)="getGtcTermsChinesePdf()">Global Terms and Conditions</a></td>
        <td>Chinese</td>
        <td>Your Buyer</td>
        <td>FGT30, Rev. 12/07</td>
      </tr>
      <tr>
        <td>12/01/2007</td>
        <td><a class="color" (click)="getGtcTermsDeutschPdf()">Global Terms and Conditions</a></td>
        <td>Deutsch</td>
        <td>Your Buyer</td>
        <td>FGT30, Rev. 12/07</td>
      </tr>
      <tr>
        <td>01/23/2008</td>
        <td><a class="color" (click)="getGtcTermsDutchPdf()">Global Terms and Conditions</a>
        </td>
        <td>Dutch</td>
        <td>Your Buyer</td>
        <td>FGT30, Rev. 12/07</td>
      </tr>
      <tr>
        <td>01/23/2008</td>
        <td><a class="color" (click)="getGtcTermsHungarianPdf()">Global Terms and Conditions</a>
        </td>
        <td>Hungarian</td>
        <td>Your Buyer</td>
        <td height="19" width="128">FGT30, Rev. 12/07</td>
      </tr>
      <tr>
        <td>01/23/2008</td>
        <td><a class="color" (click)="getGtcTermsSpanishPdf()">Global Terms and
          Conditions</a></td>
        <td>Spanish (Argentina)</td>
        <td>Your Buyer</td>
        <td>FGT30, Rev. 12/07</td>
      </tr>
      <tr>
        <td>06/01/2008</td>
        <td><a class="color" (click)="getGtcTermsPolishPdf()">Global Terms and Conditions</a>
        </td>
        <td>Polish</td>
        <td>Your Buyer</td>
        <td>FGT30, Rev. 12/07</td>
      </tr>

      <tr>
        <td>06/01/2008</td>
        <td><a class="color" (click)="getGtcTermsCzechPdf()">Global Terms and
          Conditions</a></td>
        <td>Czech Republic</td>
        <td>Your Buyer</td>
        <td>FGT30, Rev. 12/07</td>
      </tr>
      <tr>
        <td>06/01/2008</td>
        <td><a class="color" (click)="getGtcTermsRussianPdf()">Global Terms and Conditions</a>
        </td>
        <td>Russian</td>
        <td>Your Buyer</td>
        <td>FGT30, Rev. 12/07</td>
      </tr>

      <tr>
        <td>07/01/2008</td>
        <td><a class="color" (click)="getGtcTermsItalianPdf()">Global Terms and Conditions</a>
        </td>
        <td>Italian</td>
        <td>Your Buyer</td>
        <td>FGT30, Rev. 12/07</td>
      </tr>
      <tr>
        <td>07/01/2008</td>
        <td><a class="color" (click)="getGtcTermsPortuguesePdf()">Global Terms and
          Conditions</a></td>
        <td>Portuguese</td>
        <td>Your Buyer</td>
        <td>FGT30, Rev. 12/07</td>
      </tr>


      <tr>
        <td>10/15/2008</td>
        <td><a class="color" (click)="getGtcTermsFinnishPdf()">Global Terms and Conditions</a>
        </td>
        <td>Finnish</td>
        <td>Your Buyer</td>
        <td>FGT30, Rev. 12/07</td>
      </tr>
      <tr>
        <td>10/15/2008</td>
        <td><a class="color" (click)="getGtcTermsGreekPdf()">Global Terms and Conditions</a>
        </td>
        <td>Greek</td>
        <td>Your Buyer</td>
        <td>FGT30, Rev. 12/07</td>
      </tr>

      <tr>
        <td>12/01/2008</td>
        <td><a class="color" (click)="getGtcTermsDanishPdf()">Global Terms and Conditions</a>
        </td>
        <td>Danish</td>
        <td>Your Buyer</td>
        <td>FGT30, Rev. 12/07</td>
      </tr>
      <tr>
        <td>12/01/2008</td>
        <td><a class="color" (click)="getGtcTermsNorwegianPdf()">Global Terms and Conditions</a>
        </td>
        <td>Norwegian</td>
        <td>Your Buyer</td>
        <td>FGT30, Rev. 12/07</td>
      </tr>
      <tr>
        <td>12/01/2008</td>
        <td><a class="color" (click)="getGtcTermsRomanianPdf()">Global Terms and Conditions</a>
        </td>
        <td>Romanian</td>
        <td>Your Buyer</td>
        <td>FGT30, Rev. 12/07</td>
      </tr>
      <tr>
        <td>05/01/2009</td>
        <td><a class="color" (click)="getGtcTermsSwedishPdf()">Global Terms and Conditions</a>
        </td>
        <td>Swedish</td>
        <td>Your Buyer</td>
        <td>FGT30, Rev. 12/07</td>
      </tr>
      <tr>
        <td>15/02/2022</td>
        <td><a class="color" (click)="getGtcTermsThaiPdf()">Global Terms and Conditions</a></td>
        <td>Thai</td>
        <td>Your Buyer</td>
        <td>FGT30, Rev. 12/07</td>
      </tr>
      <tr>
        <td>05/01/2022</td>
        <td><a class="color" (click)="getGtcTermsKoreanPdf()">Global Terms and Conditions</a>
        </td>
        <td>Korean</td>
        <td>Your Buyer</td>
        <td>FGT30, Rev. 12/07</td>
      </tr>
    </table>
  </div>
</div>
