<app-gsdb-online-header></app-gsdb-online-header>
<div class="body ml-8 mr-8 mt-8">
  <p class="para-title pl-4 mt-14"><b> GSDB Online Steps > <span class="intro"> Introduction</span> :: Nomination ::
    Indemnification</b></p>
  <p class="font-size warning">Please read and print the following agreement after you have completed
    the authorization form by answering the 4 questions listed at the bottom of this page.</p>
  <p class="font-size"> You have been named as the Executive Sponsor to authorize access to Ford GSDB Online. You have
    been named as the
    Executive Sponsor as you hold the position of Chief Executive Officer/President or Chief Financial Officer of . Your
    authorization is required to allow to act as the Corporate Security Administrator (CSA) for the GSDB Online
    Application, which is available through the Ford Supplier Portal.</p>
  <p class="font-size"><b>Please provide the correct response for each of the following 4 statements:</b></p>
  <form [formGroup]="gsdbForm" (ngSubmit)="onSubmit()">
    <ul class="list-decimal font-size">
      <li class="pl-4 mb-3">
        <p class="mb-1">I am the CEO/President, Chief Financial Officer or VP of Finance of</p>
        <div class="flex font-bold">
          <div class="ml-4" *ngFor="let item of authorized">
            <label>
              <input
                type="radio"
                [value]="item.value"
                formControlName="selAuthorized"
              />
              {{ item.label }}
            </label>
          </div>
        </div>
      </li>
      <li class="pl-4 mb-3">
        <p class="mb-1">Is authorized to act as the Corporate Security Administrator for</p>
        <div class="flex font-bold">
          <div class="ml-4" *ngFor="let item of corpSecAdmin">
            <label>
              <input
                type="radio"
                [value]="item.value"
                formControlName="selCorpSecAdmin"
              />
              {{ item.label }}
            </label>
          </div>
        </div>
      </li>
      <li class="pl-4 mb-3">
        <p class="mb-1">I understand that Ford Motor Company will not make changes to the information we provide in GSDB
          Online. Maintenance of accurate information is the responsibility of authorized users of GSDB Online.</p>
        <div class="flex font-bold">
          <div class="ml-4" *ngFor="let item of isAccepted">
            <label>
              <input
                type="radio"
                [value]="item.value"
                formControlName="selAccepted"
              />
              {{ item.label }}
            </label>
          </div>
        </div>
      </li>
      <li class="pl-4 mb-3">
        <p class="mb-1">I understand that Ford Motor Company will not change information in data fields available to
          suppliers in GSDB Online. I acknowledge that any change and/or revision to our data in the GSDB is solely
          within our responsibility and hereby confirm to indemnify your company and any other companies belonging to
          the Ford-Group from any liability resulting from misuse or incorrect additions and revisions of such data
          unless damages are solely caused by willful acts or gross negligence of Ford.</p>
        <div class="flex font-bold">
          <div class="ml-4" *ngFor="let item of ackAccepted">
            <label>
              <input
                type="radio"
                [value]="item.value"
                formControlName="selAckAccepted"
              />
              {{ item.label }}
            </label>
          </div>
        </div>
      </li>
    </ul>
    <div class="flex items-center justify-center">
      <button class="identification" type="submit" [disabled]="!gsdbForm.dirty">Complete Identification Form</button>
    </div>
  </form>
  <hr class="mt-6 mb-4">
  <div class="mb-4 font-size">
    <p class="m-0 text-center">Questions about GSDB Online?</p>
    <p class="m-0 text-center">Contact <span class="text-decoration-underline">gsdbweb@ford.com</span> or access
      <a routerLink="/support" class="nav-link">Ford Help Desk Support</a></p>
    <p class="m-0 text-center">The customer support center is available 24 hours each day.</p>
  </div>
</div>
