import { Component } from '@angular/core';

@Component({
  selector: 'app-gsdb-auth-not-accept',
  templateUrl: './gsdb-auth-not-accept.component.html',
  styleUrls: ['./gsdb-auth-not-accept.component.scss']
})
export class GsdbAuthNotAcceptComponent {

}
