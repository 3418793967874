<div class="supplements-container">
  <p class="heading pl-1">Supplements - Production</p>
  <div>
    <table class="m-2 table-container" border="1" cellpadding="3" cellspacing="0">
      <tr>
        <th>Effective Date</th>
        <th>Document Name</th>
      </tr>
      <tr>
        <td>As Needed</td>
        <td><a class="color" (click)="getChinaAddendumDoc()">China Addendum</a></td>
      </tr>
      <tr>
        <td>07/01/2021</td>
        <td><a class="color" (click)="getChinaAddendumPdf()">China Addendum</a></td>
      </tr>
      <tr>
        <td>01/0120/04</td>
        <td><a class="color" (click)="getGTCsSupplementalTermsBrazil()">GTCs Supplemental Terms - Brazil</a>
        </td>
      </tr>
      <tr>
        <td>07/01/2021</td>
        <td><a class="color" (click)="getPpGtcSupptermsBrazilPdf()">PPGTCs Supplemental Terms - Brazil</a></td>
      </tr>
      <tr>
        <td>04/01/2012</td>
        <td><a class="color" (click)="getGtcSuppTermsCanada()">GTCs Supplemental Terms - Canada</a></td>
      </tr>
      <tr>
        <td>07/01/2021</td>
        <td><a class="color" (click)="getPpGtcSuppTermsCanada()">PPGTCs Supplemental Terms - Canada</a></td>
      </tr>
      <tr>
        <td>01/01/2004</td>
        <td><a class="color" (click)="getGtcSuppTermsMexico()">GTCs Supplemental Terms -
          Mexico</a></td>
      </tr>

      <tr>
        <td>07/01/2021</td>
        <td><a class="color" (click)="getPpGtcSuppTermsMexico()">PPGTCs Supplemental Terms - Mexico</a></td>
      </tr>
      <tr>
        <td>08/01/2012</td>
        <td><a class="color" (click)="getGtcSuppTermsRussia()">GTCs Supplemental Terms - Russia</a></td>
      </tr>
      <tr>
        <td>07/01/2021</td>
        <td><a class="color" (click)="getPpGtcSuppTermsRussia()">PPGTCs Supplemental Terms - Russia</a></td>
      </tr>
      <tr>
        <td>01/01/2004</td>
        <td><a class="color" (click)="getGtcSuppTermsTaiwan()">GTCs Supplemental Terms -
          Taiwan</a></td>
      </tr>
      <tr>
        <td>07/01/2021</td>
        <td><a class="color" (click)="getGtcSuppTermsTaiwanPdf()">GTCs Supplemental Terms - Taiwan</a></td>
      </tr>
      <tr>
        <td>04/21/2004</td>
        <td><a class="color" (click)="getGtcSuppTermsEuropeanUnion()">GTCs Supplemental Terms and Conditions
          - Supply of
          Goods in the European Union</a></td>
      </tr>
      <tr>
        <td>07/01/2021</td>
        <td><a class="color" (click)="getPpGtcSuppTermsEuropeanUnion()">PPGTCs Supplemental Terms and Conditions -
          Supply of
          Goods in the
          European Union</a></td>
      </tr>
      <tr>
        <td>09/18/2013</td>
        <td><a class="color" (click)="getGtcSuppTermsAustralia()">GTCs Supplemental Terms - Australia</a></td>
      </tr>
      <tr>
        <td>10/01/2023</td>
        <td><a class="color" (click)="getPpGtcSuppTermsAustralia()">PPGTCs Supplemental Terms - Australia</a></td>
      </tr>
    </table>
  </div>
</div>
