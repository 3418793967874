import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-gsdb-contacts-sidebar',
  templateUrl: './gsdb-contacts-sidebar.component.html',
  styleUrls: ['./gsdb-contacts-sidebar.component.scss']
})
export class GsdbContactsSidebarComponent implements OnChanges{

  @Input() formData!: String;

  gsdbCode:String ='N/A';


  ngOnChanges(changes: SimpleChanges): void {
    this.gsdbCode = this.formData;
  }

  
}
