import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {GsdbOnlineAuthService} from "../service/gsdb-online-auth.service";
import {GsdbOnlineAuthResponse} from "../../../models/perl-migration-web/gsdb-online/gsdbOnlineAuthorization";

@Component({
  selector: 'app-gsdb-authorization',
  templateUrl: './gsdb-authorization.component.html',
  styleUrls: ['./gsdb-authorization.component.scss']
})
export class GsdbAuthorizationComponent {
  gsdbForm: FormGroup;
  gsdbOnlineAuthResponse: GsdbOnlineAuthResponse = new GsdbOnlineAuthResponse()

  constructor(private fb: FormBuilder, private gsdbAuthService: GsdbOnlineAuthService, private router: Router) {
    this.gsdbForm = this.fb.group(({
      lastName: ['', [Validators.required]],
      pinNumber: ['', [Validators.required]]
    }))
  }


  onSubmit() {
    this.gsdbAuthService.authoriseGsdbOnlineApplication(this.gsdbForm.value.lastName, this.gsdbForm.value.pinNumber).subscribe((data: GsdbOnlineAuthResponse) => {
      this.gsdbOnlineAuthResponse = data
      if (this.gsdbOnlineAuthResponse.status === "COMPLETE" || this.gsdbOnlineAuthResponse.status === "Complete") {
        this.router.navigate(["/perlMigrationWeb/gsdbOnlineAuthorization/complete"]);
      } else if (this.gsdbOnlineAuthResponse.status === "INPROCESS") {
        this.gsdbAuthService.setGSDBOnlinAuthResponse(this.gsdbOnlineAuthResponse)
        this.router.navigate(["/perlMigrationWeb/gsdbOnlineAuthorization/inprocess"]);
      }
    }, error => {
      if (error.error === "Please provide valid deatils") {
        this.router.navigate(["/perlMigrationWeb/gsdbOnlineAuthorization/denied"]);
      }
    })
  }
}
