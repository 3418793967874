import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileDetails } from 'src/app/models/perl-migration-web/embeded-email/file-details.model';
import { SendMailBody } from 'src/app/models/perl-migration-web/embeded-email/send-mail-body.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmbededEmailService {

  private url = environment.perlMigrationApiUrl

  constructor(private http: HttpClient) { }

  uploadEmailFile(sendTo:String, file:File, emailType:String):Observable<FileDetails>{
    const formData = new FormData();
    formData.append('file',file,file.name);
    return this.http.post<FileDetails>(this.url+'/api/v1/embededEmailFileUpload?sendTo='+sendTo+'&emailType='+emailType,formData,{
      reportProgress: true
    })
  }

  sendMail(sendMailBody:SendMailBody):Observable<string>{
    return this.http.post<string>(this.url+'/api/v1/sendEmbededMail',sendMailBody,{responseType: 'text' as 'json'});
  }
}
