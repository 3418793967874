<app-common-header></app-common-header>
<div class="row">
  <div class="page-title">
    <b>Ford Supplier Portal (FSP) Helpdesk Support</b>
  </div>
</div>
<ul class="list-decimal container m-4">
  <li>
    <p class="ml-3"><b class="text-decoration-underline">Issue Type:</b> Can not Log into <a
      href="https://am.svcs.entsvcs.com/servicesportal/eua2/index.html#/home">Covisint Support Portal</a>,
      Covisint Password Resets Contact:
      <a href="https://portal.covisint.com/web/supportauto/cca_kbase#access">Covisint Helpdesk</a></p>
  </li>
  <li><p class="ml-3"><b class="text-decoration-underline">Issue Type:</b>
    Covisint How-To's, How to request access to an Application Contact:
    <a href="https://portal.covisint.com/web/supportauto/cca_kbase#access">Covisint Helpdesk</a></p>
  </li>
  <li><p class="ml-3"><b class="text-decoration-underline">Issue Type:</b>
    CAN log into Covisint AND have access to an application but CANNOT access an application
    Contact the Ford Help desk either by:</p>
    <div class="ml-8">
      <p class="mb-1">Telephoning the Ford Help Desk at one of the numbers shown below. This option should be used for
        high priority
        issues.</p>
      <span>-OR-</span>
      <p class="mt-1">Using the FSP feedback (Ford Helpdesk) link <a
        href="https://www.itconnect.ford.com/dwp/app/#/catalog">FSP feedback
        (Ford Help Desk)</a>. This option should be
        used for medium
        to low priority issues. This link can also be found on the FSP Home Page under the "Links" section. All form
        submissions will be converted into a Ford Help Desk ticket with a unique identifier for tracking purposes. Help
        Desk tickets will be handled by the Ford Corporate Helpdesk or escalated to the appropriate party. Be sure to
        select the application you are having issues with while filling out the Feedback Form.
      </p>
    </div>
  </li>
</ul>
<p class="helpdesk-title pl-1 mb-1">Ford Helpdesk Phone Numbers</p>
<div class="ml-2 mb-4">
  <div class="contacts-container">
    <b>US and Canada:</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 74957</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: 1-313-31-74957 or 1-888-31-74957</p>
  </div>
  <div class="contacts-container">
    <b>Argentina:</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 9898</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: +54-11-47569898</p>
  </div>
  <div class="contacts-container">
    <b>Australia:</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 8686</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: +61 3 9359 8686</p>
  </div>
  <div class="contacts-container">
    <b>Brazil:</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 8400</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: +55-11-4174 8400</p>
  </div>
  <div class="contacts-container">
    <b>Chile :</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 5860</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: +56-2-29405860</p>
  </div>
  <div class="contacts-container">
    <b>China-Beijing :</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 83100818</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: +86-10-8507-0818</p>
  </div>
  <div class="contacts-container">
    <b>China-Nanjing :</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 83067818</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: +86-25-5118-7818</p>
  </div>
  <div class="contacts-container">
    <b>China-Shanghai :</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 83141818</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: +86-21-3858-1818</p>
  </div>
  <div class="contacts-container">
    <b>Colombia :</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: +58-241-874-6123</p>
  </div>
  <div class="contacts-container">
    <b>Dubai :</b>
    <p class="ml-3 mt-0 mb-0"> Externally Dialed: 971-4-3566222 </p>
    <p class="ml-3 mt-0 mb-0">FordNet: 3686222</p>
  </div>
  <div class="contacts-container">
    <b>Germany :</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 87013322</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: +49 (0)221 9013322</p>
  </div>
  <div class="contacts-container">
    <b>India Chennai :</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 5555</p>
    <p class="ml-3 mt-0 mb-0">FordNet: 8 357 5555</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: +91-44-66465555 or +91-44-39545555</p>
  </div>
  <div class="contacts-container">
    <b>India Delhi :</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 5555</p>
    <p class="ml-3 mt-0 mb-0"> FordNet: 8 357 5555</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: +91 124 387 3334</p>
  </div>
  <div class="contacts-container">
    <b>India Coimbatore :</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 5555</p>
    <p class="ml-3 mt-0 mb-0"> FordNet: 8 357 5555</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: +91 422 306 4555</p>
  </div>
  <div class="contacts-container">
    <b>India Sanand :</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 5555</p>
    <p class="ml-3 mt-0 mb-0"> FordNet: 8 357 5555</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: +91 271 767 5555</p>
  </div>
  <div class="contacts-container">
    <b>Indonesia :</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 8375</p>
    <p class="ml-3 mt-0 mb-0">FordNet: 3088375</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: +62-21-2758-8375</p>
  </div>
  <div class="contacts-container">
    <b>Morocco :</b>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: 971-4-3566222</p>
    <p class="ml-3 mt-0 mb-0">FordNet: 368622</p>
  </div>
  <div class="contacts-container">
    <b>Mexico :</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 6096</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: +52-55-1103-3930</p>
  </div>
  <div class="contacts-container">
    <b>New Zealand :</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 8031</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: NB: there is no externally dialed number for FNZ</p>
  </div>
  <div class="contacts-container">
    <b>Peru :</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 5860</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: +56-2-29405860</p>
  </div>
  <div class="contacts-container">
    <b>Thailand :</b>
    <p class="ml-3 mt-0 mb-0">Internal: 5999 or 8888</p>
    <p class="ml-3 mt-0 mb-0">Fordnet: 473-5999 or 473-8888</p>
    <p class="ml-3 mt-0 mb-0">External: +66 (0)2-686-5999</p>
  </div>
  <div class="contacts-container">
    <b>Taiwan :</b>
    <p class="ml-3 mt-0 mb-0">Internal: 0168</p>
    <p class="ml-3 mt-0 mb-0">Fordnet: 4530168</p>
    <p class="ml-3 mt-0 mb-0">External: +886-3-4530168</p>
  </div>
  <div class="contacts-container">
    <b>Saudi Arabia :</b>
    <p class="ml-3 mt-0 mb-0"> Externally Dialed: 971-4-3566222</p>
    <p class="ml-3 mt-0 mb-0">FordNet: 3686222</p>
  </div>
  <div class="contacts-container">
    <b>UK :</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 8734 3322</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: +44 (0)1277 253322</p>
  </div>
  <div class="contacts-container">
    <b>Venezuela :</b>
    <p class="ml-3 mt-0 mb-0"> Internally Dialed: 6123</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: +58-241-874-6123</p>
  </div>
  <div class="contacts-container">
    <b>Ford Credit North America :</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 52262</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: 1-313-84-52262</p>
  </div>
  <div class="contacts-container">
    <b>Ford Credit Europe :</b>
    <p class="ml-3 mt-0 mb-0">Internally Dialed: 87302001</p>
    <p class="ml-3 mt-0 mb-0">Externally Dialed: +44 (0) 1277 692001</p>
  </div>
  <div class="contacts-container">
    <b>Ford Credit Europe :</b>
    <p class="ml-3 mt-0 mb-0">Fordnet: 31-74957 Externally Dialed: +1-313-31-74957 or +1-888-31-74957</p>
  </div>
</div>
