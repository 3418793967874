<div class="guides-container">
  <p class="heading pl-1">GTC Supplier-Guides - Production & Indirect</p>
  <div>
    <p class="mt-6 mb-6 ml-2">
      The following list shows the current Supplier Guides. Supplier Guide content may be updated from time to time. For
      any questions or concerns, please contact GTCHELP@ford.com.</p>
    <table class="m-2 table-container" border="1" cellpadding="3" cellspacing="0">
      <tr>
        <th height="38">Document Name</th>
        <th height="38">Language</th>
      </tr>
      <tr>
        <td height="38"><a class="color" (click)="getAutoCurrencyPdf()">Autocurrency Overview & Operating Policy</a>
        </td>
        <td height="38">English</td>
      </tr>
      <tr>
        <td height="19"><a class="color" (click)="getAutomaterialsPdf()">Automaterials: Market-Based Raw Material
          Commodity Price
          Management System,<br>
          Overview and Operating Policy</a></td>
        <td height="19">English</td>
      </tr>
      <tr>
        <td height="19"><a class="color" (click)="getBailmenteu2007Pdf()">Bailment of Production Parts for Ford of
          Europe Plants and
          Export Distribution Centres</a></td>
        <td height="19">English</td>
      </tr>
      <tr>
        <td height="19"><a class="color" (click)="getCapacityPlanPdf()">Capacity Planning</a></td>
        <td height="19">English</td>
      </tr>
      <tr>
        <td height="19"><a class="color" (click)="getCSIPPdf()">Chronic Supplier Improvement Process (CSIP)</a></td>
        <td height="19">English</td>
      </tr>
      <tr>
        <td height="76"><a class="color" (click)="getClaimsPdf()">Claims</a> (See also Tooling Guide)</td>
        <td height="76">English</td>
      </tr>
      <tr>
        <td height="19"><a class="color" (click)="getComanagedBuyPdf()">Co-Managed Buy Program for Resins</a></td>
        <td height="19">English</td>
      </tr>
      <tr>
        <td height="19">Customs, Supplier Customs Import & Export Guide
          <BR><a class="color" (click)="getCustomScanPdf()">- Canada</a>
          <BR><a class="color" (click)="getCustomsMexicoPdf()">- Mexico</a>
          <BR><a class="color" (click)="getCustomsUsaPdf()">- United States</a></td>
        <td height="19">English</td>
      </tr>
      <tr>
        <td height="19"><a class="color" (click)="getDeleveryTermsFOEPdf()">Delivery Terms & Customs Guide for
          Production
          Part Sourcing (FoE)</a></td>
        <td height="19">English</td>
      </tr>
      <tr>
        <td height="76"><a class="color" (click)="getDigitalManufacturingDataPdf()">Digital Manufacturing Data</a></td>
        <td height="76">English</td>
      <tr>
        <td height="76">Dispute Resolution, GTC
          <br><a class="color" (click)="getProdDisputeResolutionPdf()">Production</a>
          <br><a class="color" (click)="getIndirectDisputeResolutionPdf()">Indirect</a>
        </td>
        <td height="76">English</td>
      </tr>
      <tr>
        <td height="76"><a class="color" (click)="getEnvGuidePdf()">Environmental Requirements, Supplier</a></td>
        <td height="76">English</td>
      </tr>
      <tr>
        <td height="76"><a class="color" (click)="getExportControlWebGuidePdf()">Export Controls and Sanctions
          Compliance</a></td>
        <td height="76">English</td>
      </tr>
      <tr>
        <td height="116"><u>Ford Customer Service Division</u> - FCSD suppliers of production material to Ford must be
          in compliance with or adhere to the additional following FCSD requirements/guidelines.
        </td>
        <td height="116">&nbsp;</td>
      </tr>
      <tr>
        <td height="13"><a class="color" (click)="getFCSDNorthAmericaPdf()">FCSD Packaging & Shipping -
          North America</a></td>
        <td height="13">English</td>
      </tr>
      <tr>
        <td height="13"><a class="color" (click)="getFCSDEuropePdf()">FCSD Packaging &amp; Supply
          Manual - Europe</a></td>
        <td height="13">English</td>
      </tr>
      <tr>
        <td height="57"><p><a class="color" (click)="getFCSDGlobalPdf()">FCSD Service Parts Guide - Global</a>
          <br>&nbsp;</p></td>
        <td height="57">English</td>
      </tr>
      <tr>
        <td height="13"><a class="color" (click)="getFSAPdf()">Field Service Action Cost Recovery</a></td>
        <td height="13">English</td>
      </tr>

      <tr>
        <td height="13"><a class="color" (click)="getFordBrandProtectPdf()">Ford Parts Brand Protection</a></td>
        <td height="13">English</td>
      </tr>
      <tr>
        <td height="85"><a class="color" (click)="getInvoicingGuidelinesPdf()">Invoicing
          Guidelines, Indirect -
          Europe</a></td>
        <td height="85">English</td>
      </tr>
      <tr>
        <td height="85"><a class="color" (click)="getShipPartsPdf()">Label Standards, Shipping Parts/Identification</a>
          (Global)&nbsp;
        </td>
        <td height="85">English</td>
      </tr>
      <tr>
        <td height="121"><a class="color" (click)="getMplmaterialsPdf()"><u>MP&L Requirements for Materials Management
          &
          Communication, North America</u></a></td>
        <td height="121">English</td>
      </tr>
      <tr>
        <td height="121"><u><a class="color" (click)="getWareHouseInstructionPdf()">New Model Programs Warehouse
          Shipping
          Instructions</a></u></td>
        <td height="121">English</td>
      </tr>
      <tr>
        <td height="57"><a class="color" (click)="getNewsReleasesPdf()">News Release & Publicity Guidelines,
          Supplier</a></td>
        <td height="57">English</td>
      </tr>
      <tr>
        <td height="85">
          <a class="color" (click)="getGlobalexppkgPdf()">Packaging Terms & Conditions (Global 1750 Export) - All
            Regions</a>
        </td>
        <td height="42">English</td>
      </tr>
      <tr>
        <td height="57">
          <a class="color" (click)="getEu1750pkgPdf()">Packaging Terms and Conditions (EU1750) - Europe</a>
        </td>
        <td height="57">English</td>
      </tr>
      <tr>
        <td height="85"><a class="color" (click)="getNapkgguidePdf()">Packaging Guidelines for Production Parts - North
          America</a>
        </td>
        <td height="57">English</td>
      </tr>
      <tr>
        <td height="86"><a class="color" (click)="getAPpkgguidePdf()">Packaging Guidelines for Production Parts,
          IMG</a></td>
        <td height="86">English</td>
      </tr>
      <tr>
        <td height="42"><a class="color" (click)="getCN1750pkgPdf()">Packaging Guidelines for Production Parts,
          China</a></td>
        <td height="42">English</td>
      </tr>
      <tr>
        <td height="86"><a class="color" (click)="getProdPayGuidePdf()">Production Payment Schedule</a></td>
        <td height="86">English</td>
      </tr>
      <tr>
        <td height="86"><a class="color" (click)="getIndirectPayGuidePdf()">Indirect Payment Schedule</a></td>
        <td height="86">English</td>
      </tr>
      <tr>
        <td height="86"><a class="color" (click)="getNonrenewalPdf()">Production Purchase Order Non-Renewal Process</a>
        </td>
        <td height="86">English</td>
      </tr>
      <tr>
        <td height="86"><a class="color" (click)="getPtoewarrantsPdf()">Powertrain Prototype E-Warrants</a></td>
        <td height="86">English</td>
      </tr>
      <tr>
        <td height="19"><a class="color" (click)="getSteelrawmtlsPdf()">Raw Material Sales Program Overview and
          Operating Policy</a></td>
        <td height="19">English</td>
      </tr>
      <tr>
        <td height="86"><a class="color" (click)="getReturnableContainerASNPdf()">Returnable Containers ASN
          Guidelines</a></td>
        <td height="86">English</td>
      </tr>
      <tr>
        <td height="38"><a class="color" (click)="getShipmentsfoe()">Shipments for Prod Parts to FoE Plants &
          Export Distribution Centres
        </a></td>
        <td height="38">English</td>
      </tr>
      <tr>
        <td height="81"><a class="color" (click)="getNashippingPdf()">Shipping and Routing Guide for NA Assembly,
          Manufacturing and Prototype Plants</a></td>
        <td height="81">English</td>
      </tr>
      <tr>
        <td height="19"><a class="color" (click)="getHrandwcPdf()">Social Responsibility and Anti-Corruption
          Requirements</a></td>
        <td height="19">English</td>
      </tr>
      <tr>
        <td height="19"><a class="color" (click)="getTaxPdf()">Tax</a></td>
        <td height="19">English</td>
      </tr>
      <tr>
        <td height="76"><a class="color" (click)="getToolingPurchaseGuidePdf()">Tooling Purchase and Audit Supplier
          Guide</a>
          <br>
          -Including: <a class="color" (click)="getSupptooltaggingPdf()">Enhanced Supplier Tool Tagging/Marking
            Requirements and Revised Payment Approval Process</a>
          &nbsp;<a href="../docs/supplierprogpay.pdf "></a>
        </td>
        <td height="76">English</td>
      </tr>
      <tr>
        <td height="85">
          <a class="color" (click)="getGovernmentSubContractorPdf()">Supplier Requirements for U.S. Government
            Subcontractors</a>
        </td>
        <td height="19">English</td>
      </tr>
      <tr>
        <td height="85">
          <a class="color" (click)="getSdiPdf()">Supply Chain Inclusivity and Diversity</a>
        </td>
        <td height="19">English</td>
      </tr>
      <tr>
        <td height="85">
          <a class="color" (click)="getValueChainTransparencyPdf()">Value Chain Transparency</a>
        </td>
        <td height="19">English</td>
      </tr>
      <tr>
        <td height="85">
          <a class="color" (click)="getWarrantyChargebackPdf()">Warranty Chargeback</a>
        <td height="19">English</td>
      <tr>
      <tr>
        <td height="85">
          <a class="color" (click)="getWarrantyQosPdf()">Warranty Quality Operating System (QOS)</a></td>
        <td height="19">English</td>
      </tr>
    </table>
  </div>
</div>
