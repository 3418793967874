<app-gsdb-online-header></app-gsdb-online-header>
<div class="results-container">
  <p class="font-size-24 text-center mt-4 font-bold">GSDB ONLINE QUERY RESULTS</p>
  <form class="container-fluid p-4" [formGroup]="resultsForm" (ngSubmit)="onSubmit()">
    <div class="row mb-1">
      <div class="col-12">
        <label class="font-size-13 font-bold mr-2">GSDB CODE: </label>
        <input class="input" type="text" formControlName="gsdbCode"/>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-12">
        <label class="font-size-13 font-bold mr-2">COMPANY NAME: </label>
        <input class="input" type="text" formControlName="companyName"/>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-12">
        <label class="font-size-13 font-bold mr-2">PARENT GSDB CODE: </label>
        <input class="input" type="text" formControlName="parentGsdbCode"/>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-12">
        <label class="font-size-13 font-bold mr-2">PARENT COMPANY NAME: </label>
        <input class="input" type="text" formControlName="parentCompanyName"/>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-12">
        <label class="font-size-13 font-bold mr-2 text-danger">REGISTRATION STATUS: </label>
        <input class="input" type="text" formControlName="regStatus"/>
      </div>
    </div>
    <div class="row mt-4">
      <p class="font-size-16 text-decoration-underline font-bold">CSA INFORMATION FROM CONTACTS DATABASE:</p>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CSA NAME: </label></div>
      <div class="col-6"><input class="input" type="text" formControlName="csaName"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CSA FSN or Covisint ID: </label></div>
      <div class="col-6"><input class="input" type="text" formControlName="fsnOrCovisintId"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CSA EMAIL: </label></div>
      <div class="col-6"><input class="input" type="text" formControlName="csaEmail"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CSA PHONE: </label></div>
      <div class="col-6"><input class="input" type="text" formControlName="csaPhone"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CSA TITLE: </label></div>
      <div class="col-6"><input class="input" type="text" formControlName="csaTitle"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CSA ADDRESS: </label></div>
      <div class="col-4"><input class="input" type="text" formControlName="csaAddress"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CSA CITY: </label></div>
      <div class="col-6"><input class="input" type="text" formControlName="csaCity"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CSA STATE:</label></div>
      <div class="col-6"><input class="input" type="text" formControlName="csaState"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CSA ZIP CODE: </label></div>
      <div class="col-6"><input class="input" type="text" formControlName="csaZipCode"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CSA COUNTRY:</label></div>
      <div class="col-6"><input class="input" type="text" formControlName="csaCountry"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CSA SUPERVISOR: </label></div>
      <div class="col-6"><input class="input" type="text" formControlName="csaSupervisor"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CSA SUP PHONE:</label></div>
      <div class="col-6"><input class="input" type="text" formControlName="csaSupPhone"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CSA SUP EMAIL: </label></div>
      <div class="col-6"><input class="input" type="text" formControlName="csaSupEmail"/></div>
    </div>
    <div class="row mt-4">
      <p class="font-size-16 text-decoration-underline font-bold">EXECUTIVE SPONSOR INFORMATION FROM CONTACTS
        DATABASE:</p>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CES NAME: </label></div>
      <div class="col-6"><input class="input" type="text" formControlName="cesName"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CES EMAIL: </label></div>
      <div class="col-6"><input class="input" type="text" formControlName="cesEmail"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CES PHONE:</label></div>
      <div class="col-6"><input class="input" type="text" formControlName="cesPhone"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CES TITLE: </label></div>
      <div class="col-6"><input class="input" type="text" formControlName="cesTitle"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CES ADDRESS: </label></div>
      <div class="col-6"><input class="input" type="text" formControlName="cesAddress"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CES CITY: </label></div>
      <div class="col-6"><input class="input" type="text" formControlName="cesCity"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CES STATE: </label></div>
      <div class="col-6"><input class="input" type="text" formControlName="cesState"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CES ZIP CODE: </label></div>
      <div class="col-6"><input class="input" type="text" formControlName="cesZipCode"/></div>
    </div>
    <div class="row mb-1">
      <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CES COUNTRY: </label></div>
      <div class="col-6"><input class="input" type="text" formControlName="cesCountry"/></div>
    </div>
    <div class="row mt-4 mb-0">
      <p class="font-size-16 text-decoration-underline font-bold">AUTHORIZATION DATABASE INFORMATION:</p>
    </div>
    <div class="row">
      <div class="col-12 mb-1">
        <label class="font-size-13 font-bold mr-2">GSDB CODE: </label>
        <input class="input" type="text" formControlName="authGsdbCode"/>
      </div>
      <div class="row">
        <div class="col-12 mb-1">
          <label class="font-size-13 font-bold mr-2">COMPANY NAME: </label>
          <input class="input" type="text" formControlName="authCompanyName"/>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-1">
          <label class="font-size-13 font-bold mr-2">LAST UPDATED: </label>
          <input class="input" type="text" formControlName="authLastUpdated"/>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CES NAME: </label></div>
        <div class="col-6"><input class="input" type="text" formControlName="authCesName"/></div>
      </div>
      <div class="row mb-1">
        <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CES PHONE: </label></div>
        <div class="col-6"><input class="input" type="text" formControlName="authCesPhone"/></div>
      </div>
      <div class="row mb-1">
        <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CES ACCESS ID: </label></div>
        <div class="col-6"><input class="input" type="text" formControlName="authCesAccessId"/></div>
      </div>
      <div class="row mb-1">
        <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CES PIN: </label></div>
        <div class="col-6"><input class="input" type="text" formControlName="authCesPin"/></div>
      </div>
      <div class="row mb-1">
        <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CES TITLE FLAG: </label></div>
        <div class="col-6"><input class="input" type="text" formControlName="cesTitleFlag"/></div>
      </div>
      <div class="row mb-1">
        <div class="col-4 align-right"><label class=" font-size-13 font-bold color">CSA APPROVAL FLAG: </label></div>
        <div class="col-6"><input class="input" type="text" formControlName="csaApproval"/></div>
      </div>
      <div class="row mb-1">
        <div class="col-4 align-right"><label class=" font-size-13 font-bold color">UPDATE FLAG: </label></div>
        <div class="col-6"><input class="input" type="text" formControlName="cesUpdateFlag"/></div>
      </div>
      <div class="row mb-1">
        <div class="col-4 align-right"><label class=" font-size-13 font-bold color">INDEMNITY FLAG: </label></div>
        <div class="col-6"><input class="input" type="text" formControlName="indemnityFlag"/></div>
      </div>
    </div>
    <div class="row">
      <p class="text-center font-bold time-new-roman font-size-18 mt-4">CLICK HERE TO VIEW INDEMNIFICATION FORM</p>
      <p class="font-bold text-center font-size-16 mb-0"
         *ngIf="gsdbQueryResponse.registrationContacts.gsdbStatus==='INPROCESS'">You will be able to view the
        Indemnification Form after the Executive Sponsor completes the approval process and the registration status is
        complete.</p>
      <p class="font-bold text-center font-size-16 mt-0">Thank you.</p>
      <p class="text-center time-new-roman font-bold text-danger font-size-18">To delete this record, click on Delete
        Record.</p>
      <div class="flex justify-center">
        <button type="submit" class="delete-button">Delete Record</button>
      </div>
    </div>
  </form>
</div>
