<app-common-header></app-common-header>
<div class="container-fluid">
    <div class="row">
        <div class="page-title">
            <b>PCSA Embedded Info Email Application</b>
        </div>
    </div>

    <div class="row">
        <div class="col-11">
            <div class="row font1">
                <b>
                    &nbsp;&nbsp;Upload Your File for Processing
                </b>
            </div>
            <div class="row">
            </div>
            <div class="row font1">
                    <b>Click the "Browse" button to select a file from your computer and<BR>
                        Please enter your Email account to which the email log will be sent
                    </b>
            </div>
            <div class="row">
                <div class="col-4"></div>
                <div class="col-7">
                        <form [formGroup]="uploadFileGroup" (ngSubmit)="onSubmit()">
                            <div class="font2">
                                <input class="input-file" type="file" (change)="onFileUpload($event)">
                            </div>
                            <div class="font3">
                                <label for="email"><b>E-mail Address : &nbsp;</b></label>
                                <input type="text" formControlName="sendTo" class="input-text">
                                <br><span id="fileError" class="text-danger" *ngIf="uploadFileGroup.controls['sendTo'].invalid && 
                                uploadFileGroup.controls['sendTo'].dirty">Please provide email id to receive report</span>
                            </div>
                            <div class="font3">
                                <label for="email"><b>Choose Email Recipient Type : &nbsp;</b></label><br>
                                <input type="radio" formControlName="emailType" value="PCSA" class="input-text">&nbsp; PCSA embedded email <br>
                                <input type="radio" formControlName="emailType" value="generic" class="input-text">&nbsp; Generic email to a list of email addresses <br>
                                <br>                        
                            </div>
                            <div class="font3">
                                <label for="continue"><b>Click the upload file button to continue : &nbsp;</b></label>
                                <button type="submit" class="btn btn-primary btn-sm" [disabled]="uploadFileGroup.invalid">upload file </button>&nbsp; &nbsp;
                                <button type="reset" class="btn btn-primary btn-sm">start over </button>
                            </div>
                        </form>
                    
                </div>
            </div>
            <div class="row">
                <div>
                    <hr>
                </div>
            </div>
            <div class="row">
                <div class="text-danger font4">
                    <b> To send out the PCSA embedded email</b>
                </div>
                <div>
                   <ul class="ul text-danger">
                    <li>
                         <b>Be sure to choose "PCSA embedded email" as the type of email you want to send out Upload a file which contains a list of companies and their PCSA information. 
                            </b>
                    </li>
                    <li>
                       <b>The format must be as follows:</b><br>
                       &nbsp; &nbsp; <b>Company Name"|"PCSA First Name"|"PCSA LastName"|"PCSA Phone"|"PCSA Email"|"User Email"</b>
                    </li><br>
                    
                    
                   </ul>
                </div>
            </div>
        </div>
            

    </div>

</div>
