import { Component } from '@angular/core';

@Component({
  selector: 'app-gsdb-online-header',
  templateUrl: './gsdb-online-header.component.html',
  styleUrls: ['./gsdb-online-header.component.scss']
})
export class GsdbOnlineHeaderComponent {

}
