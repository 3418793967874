import { Component } from '@angular/core';

@Component({
  selector: 'app-gsdb-auth-complete',
  templateUrl: './gsdb-auth-complete.component.html',
  styleUrls: ['./gsdb-auth-complete.component.scss']
})
export class GsdbAuthCompleteComponent {

}
