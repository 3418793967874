export class AppLaunch{
    constructor( 
        public appName:String,
        public appNameOnPortal: String,
        public prodUrl: string,
        public appCategory: string,
        public helpDeskContact: string,
        public businessContact: string,
        public primaryContact: string,
        public technicalContact: string,
        public financialSwitch: string,
        public criticalSwitch: string,
        public privateSwitch: string,
        public securityLevel: string,
        public tokenName: string,
        public siteCodeSwitch: string,
        public defaultAppSwitch: string,
        public siteConcept: string,
        public siteDetail: string,
        public additionalRegistration: string,
        public acrnumber: string 
        ){
       
    }
}