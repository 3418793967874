<app-gsdb-online-header></app-gsdb-online-header>
<div class="flex flex-col items-center justify-center mt-8">
  <div class="fail-container">
    <p class="warning text-center">WARNING!!</p>
    <p class="text-center mb-0">Your GSDB CODE on your Ford FSN Profile is incorrect or missing.</p>
    <p class="text-center mt-0">You must contact FSN to have this problem corrected before you can register for GSDB Online.</p>
    <p class="text-center mb-0">Contact <span class="text-decoration-underline">gsdbweb@ford.com</span> or access <a
      routerLink="/support" class="nav-link">Ford Help Desk Support</a></p>
    <p class="text-center mt-0 mb-0">The customer support center is available 24hours
      each day.</p>
    <p class="text-center mt-0">Thank you</p>
  </div>
</div>
