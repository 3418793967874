export class UserAccessReview {
  public removeFlag: String = "";
  public ncm: String = "";
  public newApproverCdsid: String = "";
  public userCdsid: String = "";
  public userName: String = "";
  public racfId: String = "";
  public system: String = "";
  public jobFunction: String = "";
  public systemAccess: String = "";
  public divPlt: String = "";
  public delegation: String = "";
  public comments: String = "";
  public approveFlag: string = "";
  public supplierCode: string = "";
  public suppEmailAddText: string = ""
  public userAccessId: number = 0;
  public approverCdsId: string | null = ""
}

export class TaskDate {
  public date: string = ""
}

export class UserAccessList {
  public status: string = ""
  public userAccessReviewList: UserAccessReview[] = []

}
