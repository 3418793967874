import { Component } from '@angular/core';

@Component({
  selector: 'app-user-access-review-systems-desc',
  templateUrl: './user-access-review-systems-desc.component.html',
  styleUrls: ['./user-access-review-systems-desc.component.scss']
})
export class UserAccessReviewSystemsDescComponent {

}
