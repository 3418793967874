import {Component} from '@angular/core';
import {ProductionService} from "../service/production.service";

@Component({
  selector: 'app-production-left-sidebar',
  templateUrl: './production-left-sidebar.component.html',
  styleUrls: ['./production-left-sidebar.component.scss']
})
export class ProductionLeftSidebarComponent {

  constructor(private productionService: ProductionService) {
  }

  showPiiSupplementPdf(): void {
    this.productionService.getPiiSupplementPdf();
  }
  getInfoSecuritySupplementPdf(){
    this.productionService.getInfoSecuritySupplementPdf();
  }
}
