import { Component } from '@angular/core';

@Component({
  selector: 'app-ariba-header',
  templateUrl: './ariba-header.component.html',
  styleUrls: ['./ariba-header.component.scss']
})
export class AribaHeaderComponent {

}
