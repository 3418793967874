<app-common-header></app-common-header>
<div class="container-fluid">
    <div class="row">
        <div class="page-title">
            <b>FSP Feedback</b>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="row">
                <div>
                    <p class="font1"><b>FSP Feedback and Problem Submission Form</b></p>
                </div>
                <div>
                    <p class="font2">We 
                        welcome all comments and suggestions pertaining to this site. 
                        Please fill out the Feedback Form and we will forward your 
                        comments to the appropriate parties. You should expect a response
                        within one business day.</p>
                </div>
                <div>
                    <p class="font2"><span class="font1"><b>Please note</b></span>: All fields marked in 
                        <span class="text-danger"><b>RED</b></span>
                         are <span class="text-danger"><b>REQUIRED</b></span> fields.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-1"></div>
                <div class="col-9">
                    <div>
                        <p class="font3"><b>Please enter your contact information : </b></p>
                    </div>
                    <div>
                        <form>
                           <div class="row">
                                <div class="col-3">
                                    <label class="font2"><b>User Id</b></label><br>
                                    <input class="input-text" type="text">
                                </div>
                                <div class="col-3">
                                    <label class="font2 text-danger"><b>First name</b></label><br>
                                    <input class="input-text" type="text">
                                </div>
                                <div class="col-3">
                                    <label class="font2 text-danger"><b>Last name</b></label><br>
                                    <input class="input-text" type="text">
                                </div>
                           </div><br>

                           <div class="row">
                                <div class="col-3">
                                    <label class="font2 text-danger"><b>Company Name</b></label><br>
                                    <input class="input-text2" type="text">
                                </div>
                                <div class="col-4">
                                </div>
                                <div class="col-3">
                                    <label class="font2 text-danger"><b>Phone number</b></label><br>
                                    <input class="input-text" type="text">
                                </div>
                            </div><br>

                            <div class="row">
                                <div class="col-3">
                                    <label class="font2 text-danger"><b>E-mail Address</b></label><br>
                                    <input class="input-text2" type="text">
                                </div>
                                <div class="col-2"></div>
                                <div class="col-7">
                                    <label class="font3"><b>Please Note:</b></label><br>
                                    <p class="font2">
                                        if your issue will be submitted to a help desk in the United States, please be sure to include country code, 
                                        city code and extension number in your phone number.</p>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
