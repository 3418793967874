<app-common-header></app-common-header>
<div class="container-fluid">
    <div class="row">
        <div class="page-title">
            <b>ID Converter Application</b>
        </div>
    </div>

    <div class="row" [hidden]="!formEnabled">
        <div class="col-9">
            <div class="row font1">
               <div>
                    Please enter a FSN or Covisint Id: 
               </div>
            </div>

            <div class="row font3">
                <div>
                    <form [formGroup]="idConverterForm" (ngSubmit)="onSubmit()">
                        <div>
                            <label>
                                <b>Enter Id</b> : 
                                &nbsp;<input type="text" formControlName="id" class="input-text">
                            </label>
                        </div><br>
                        <div>
                            <label>
                                <b>Type Of Id</b> : 
                                <input type="radio" id="fsn" formControlName="idtype" name="idtype" value="F"> FSN Id &nbsp;&nbsp;
                                <input type="radio" id="covisintid" formControlName="idtype" name="idtype" value="C"> Covisint ID
                            </label>
                        </div><br>
                        <div>
                            <button type="submit" class="btn btn-primary" [disabled]="idConverterForm.invalid">Submit! </button>
                        </div>
                    </form>
                </div>    
            </div>
        </div>
    </div>
    <div class="row" [hidden]="formEnabled">
        <div class="col-9">
            <div class="row font1">
                <div>
                    <p></p>CovisintId : {{coviid === null? 'null':coviid}} &nbsp; &lt;------------&gt; 
                    FsnId : {{fsnid===null?'null':fsnid}}
                </div>
            </div>
            <div class="row font2">
                <div *ngIf="coviid === null || coviid === undefined" >
                     There is currently no Covisint Id associated with this FSN Id.<br>  
                        The user has not migrated to Covisint or has not requested the FSP service
                </div>
            </div>
            <div class="row font2">
                <div *ngIf="fsnid === null || fsnid === undefined" >
                    Can't find an FSN Id that is associated with the <br> Covisint Id you entered.
                </div>
            </div>
            <div class="row font2">
                <div>
                    <a href="#" (click)="enableForm($event)">
                        <br><u><b>Convert another Id</b></u>
                    </a>
                 </div>
            </div>
        </div>
    </div>
    <div >
        <hr class="hr">
    </div>
</div>
