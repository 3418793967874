import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppRegistration } from 'src/app/models/perl-migration-web/app-registration/app-registration.model';
import { AppRegistrationService } from '../service/app-registration.service';

@Component({
  selector: 'app-app-registration',
  templateUrl: './app-registration.component.html',
  styleUrls: ['./app-registration.component.scss']
})
export class AppRegistrationComponent {

  formSubmitted: boolean =false;
  

  appRegistrationForm: FormGroup;
  appRegistration!: AppRegistration;

  constructor(private fb: FormBuilder, private apiService:AppRegistrationService){
    this.appRegistrationForm = this.fb.group(
      {
        appName:['',Validators.required],
        appNameOnPortal:['',Validators.required],
        prodURL:['',Validators.required],
        dev_qa_Url:['',Validators.required],
        purpose_Application:['',Validators.required],
        type_of_Information:[''],
        information_details:['',Validators.required],
        appCategory:['',Validators.required],
        ka_instruction:['',Validators.required],
        businessContact:['',Validators.required],
        primaryContact:['',Validators.required],
        bca_Application:[''],
        aCRNumber:['',Validators.required],
        application_Avlble:[''],
        application_Admin:[''],
        tokenName:[''],
        gsdb_code_Token:[''],
        aps_access:[''],
        supplier_Access:['',Validators.required],
        gsdb_code_status:['',Validators.required],
        supplier_Org:['',Validators.required],
        est_user_Access:['',Validators.required],
        inter_ford_Emp:['',Validators.required],
        est_project_launch:['',Validators.required]
      }
    );
  }

  onSubmit(){
      this.generateRequestBody(this.appRegistrationForm);
      this.fetchAppRegistrationData(this.appRegistration);

  }

  back(event:Event){
    event.preventDefault();
    this.appRegistrationForm.reset();
    this.appRegistrationForm.invalid;
    this.formSubmitted = false;
    this.appRegistrationForm.enable();
  }

  fetchAppRegistrationData(appRegistration:AppRegistration){
      this.apiService.applicationRegistration(appRegistration).subscribe((data:String)=>{
        alert("application registration form submitted");
        this.appRegistrationForm.disable();
        this.formSubmitted=true;
      },error =>{
        console.log(error);
        alert(JSON.stringify(error));
      })
  }
  generateRequestBody(appRegistrationForm: FormGroup<any>){
    this.appRegistration = new AppRegistration(
      this.appRegistrationForm.get('appName')?.value,this.appRegistrationForm.get('appNameOnPortal')?.value,
      this.appRegistrationForm.get('prodURL')?.value,this.appRegistrationForm.get('dev_qa_Url')?.value,
      this.appRegistrationForm.get('purpose_Application')?.value,this.appRegistrationForm.get('type_of_Information')?.value,
      this.appRegistrationForm.get('information_details')?.value,this.appRegistrationForm.get('appCategory')?.value,
      this.appRegistrationForm.get('ka_instruction')?.value,this.appRegistrationForm.get('businessContact')?.value,
      this.appRegistrationForm.get('primaryContact')?.value, this.appRegistrationForm.get('bca_Application')?.value,
      this.appRegistrationForm.get('aCRNumber')?.value,this.appRegistrationForm.get('application_Avlble')?.value,
      this.appRegistrationForm.get('application_Admin')?.value,this.appRegistrationForm.get('tokenName')?.value,
      this.appRegistrationForm.get('gsdb_code_Token')?.value,this.appRegistrationForm.get('aps_access')?.value,
      this.appRegistrationForm.get('supplier_Access')?.value,this.appRegistrationForm.get('gsdb_code_status')?.value,
      this.appRegistrationForm.get('supplier_Org')?.value,this.appRegistrationForm.get('est_user_Access')?.value,
      this.appRegistrationForm.get('inter_ford_Emp')?.value,this.appRegistrationForm.get('est_project_launch')?.value

      )
  }

}
