<div class="page-margin">
    <div class="container-fluid content_container ">
        <div class="row">
            <div class="col-2 image-alignment">
                <img src="/assets/ford.png" alt="Purchasing Annual Access Review System" />
            </div>
            <div class="col-10">
                <div class="title-alignment row">
                    <b>
                        Purchasing & Associated Systems Annual Access Review Manager Home Page
                    </b>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <b class="header-font">You may receive independent review emails from other systems not included in PAARS<br>
                Systems List : </b>
                <table class="content-align" *ngIf="systemList.length !== 0">
                    <tr><br>
                        <ul class="list-style" *ngFor="let system of systemList">
                            <li>{{system}}</li>
                        </ul>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>

