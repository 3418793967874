import {Routes} from "@angular/router";
import {EverestTokenHomeComponent} from "../everest-token-lookup/everest-token-home/everest-token-home.component";
import {AuthenticationGuard} from "@wame/ngx-adfs";
import {
  EverestTokenByCodeComponent
} from "../everest-token-lookup/everest-token-by-code/everest-token-by-code.component";
import {
  EverestTokenFileUploadComponent
} from "../everest-token-lookup/everest-token-file-upload/everest-token-file-upload.component";
import {
  EverestTokenFileConfirmationComponent
} from "../everest-token-lookup/everest-token-file-confirmation/everest-token-file-confirmation.component";
import {
  EverestTokenLookupReportComponent
} from "../everest-token-lookup/everest-token-lookup-report/everest-token-lookup-report.component";
import {
  EverestTokenSelectSitecodeComponent
} from "../everest-token-lookup/everest-token-select-sitecode/everest-token-select-sitecode.component";
import {GsdbHierarchyComponent} from "../gsdb-hierarchy/gsdb-hierarchy.component";
import {BuyerSearchComponent} from "../buyer-search/buyer-search.component";
import {IdConverterComponent} from "../id-converter/id-converter/id-converter.component";
import {HelpdeskComponent} from "../helpdesk/helpdesk/helpdesk.component";
import {SupplierSearchComponent} from "../supplier-search/supplier-search.component";
import {FordPeopleSearchComponent} from "../ford-people-search/ford-people-search.component";
import {AribaOnlineComponent} from "../ariba-online/ariba-online/ariba-online.component";
import {GsdbOnlineIntroComponent} from "../gsdb-online/gsdb-online-intro/gsdb-online-intro.component";
import {GsdbRegFailComponent} from "../gsdb-online/gsdb-reg-fail/gsdb-reg-fail.component";
import {GsdbRegCompleteComponent} from "../gsdb-online/gsdb-reg-complete/gsdb-reg-complete.component";
import {GsdbRegRestrictedComponent} from "../gsdb-online/gsdb-reg-restricted/gsdb-reg-restricted.component";
import {GsdbRegInprocessComponent} from "../gsdb-online/gsdb-reg-inprocess/gsdb-reg-inprocess.component";
import {DataLoaderComponent} from "../data-loader/data-loader/data-loader.component";
import {GsdbAuthorizationComponent} from "../gsdb-online/gsdb-authorization/gsdb-authorization.component";
import {GsdbAuthDeniedComponent} from "../gsdb-online/gsdb-auth-denied/gsdb-auth-denied.component";
import {GsdbAuthCompleteComponent} from "../gsdb-online/gsdb-auth-complete/gsdb-auth-complete.component";
import {FsnRegistrationComponent} from "../fsn-registration/fsn-registration/fsn-registration.component";
import {GsdbAuthInprocessComponent} from "../gsdb-online/gsdb-auth-inprocess/gsdb-auth-inprocess.component";
import {GsdbAuthAcceptedComponent} from "../gsdb-online/gsdb-auth-accepted/gsdb-auth-accepted.component";
import {GsdbAuthNotAcceptComponent} from "../gsdb-online/gsdb-auth-not-accept/gsdb-auth-not-accept.component";
import {AppLaunchComponent} from "../app-launch/app-launch/app-launch.component";
import {GsdbContactsMainComponent} from "../gsdb-contacts/gsdb-contacts-main/gsdb-contacts-main.component";
import {GsdbContactsChiefComponent} from "../gsdb-contacts/gsdb-contacts-chief/gsdb-contacts-chief.component";
import {GsdbContactsVpComponent} from "../gsdb-contacts/gsdb-contacts-vp/gsdb-contacts-vp.component";
import {GsdbContactsFordComponent} from "../gsdb-contacts/gsdb-contacts-ford/gsdb-contacts-ford.component";
import {GsdbContactsCeoComponent} from "../gsdb-contacts/gsdb-contacts-ceo/gsdb-contacts-ceo.component";
import {GsdbCodeInvalidComponent} from "../gsdb-contacts/gsdb-code-invalid/gsdb-code-invalid.component";
import {SuccessComponent} from "../gsdb-contacts/success/success.component";
import {AppLookupComponent} from "../app-lookup/app-lookup/app-lookup.component";
import {AppRegistrationComponent} from "../app-registration/app-registration/app-registration.component";
import {EmbededEmailComponent} from "../embeded-email/embeded-email/embeded-email.component";
import {
  EmbededEmailConfirmationComponent
} from "../embeded-email/embeded-email-confirmation/embeded-email-confirmation.component";
import {GsdbForSitecodeComponent} from "../gsdb-for-sitecode/gsdb-for-sitecode/gsdb-for-sitecode.component";
import {GsdbQueryComponent} from "../gsdb-online/gsdb-query/gsdb-query.component";
import {GsdbFailedSearchComponent} from "../gsdb-online/gsdb-failed-search/gsdb-failed-search.component";
import {GsdbQueryResultsComponent} from "../gsdb-online/gsdb-query-results/gsdb-query-results.component";
import {GsdbDeleteQueryComponent} from "../gsdb-online/gsdb-delete-query/gsdb-delete-query.component";
import {GsdbContactsSearchComponent} from "../gsdb-contacts/gsdb-contacts-search/gsdb-contacts-search.component";
import {ContactSearchResultsComponent} from "../gsdb-contacts/contact-search-results/contact-search-results.component";
import {GsdbContactsIsoComponent} from "../gsdb-contacts/gsdb-contacts-iso/gsdb-contacts-iso.component";
import {
  GsdbContactsExecutiveComponent
} from "../gsdb-contacts/gsdb-contacts-executive/gsdb-contacts-executive.component";
import {GsdbContactsSalesComponent} from "../gsdb-contacts/gsdb-contacts-sales/gsdb-contacts-sales.component";
import {GsdbContactsVsmlComponent} from "../gsdb-contacts/gsdb-contacts-vsml/gsdb-contacts-vsml.component";
import {GsdbContactsKfcComponent} from "../gsdb-contacts/gsdb-contacts-kfc/gsdb-contacts-kfc.component";

export const perlMigrationRoutes: Routes = [
  {
    path: 'perlMigrationWeb/everstTokenLookup',
    component: EverestTokenHomeComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/everstTokenLookup/gsdbCode',
    component: EverestTokenByCodeComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/everstTokenLookup/uploadFile',
    component: EverestTokenFileUploadComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/everstTokenLookup/uploadConfirmation',
    component: EverestTokenFileConfirmationComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/everstTokenLookup/lookupReport',
    component: EverestTokenLookupReportComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/everstTokenLookup/siteSelect',
    component: EverestTokenSelectSitecodeComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbHierarchy',
    component: GsdbHierarchyComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/buyerSearch',
    component: BuyerSearchComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/idConverter',
    component: IdConverterComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/helpdesk',
    component: HelpdeskComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/supplierSearch',
    component: SupplierSearchComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/fordPeopleSearch',
    component: FordPeopleSearchComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/aribaLogin',
    component: AribaOnlineComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbOnlineIntroduction',
    component: GsdbOnlineIntroComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbOnlineIntroduction/denied',
    component: GsdbRegFailComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbOnlineIntroduction/complete',
    component: GsdbRegCompleteComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbOnlineIntroduction/restricted',
    component: GsdbRegRestrictedComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbOnlineIntroduction/inprocess',
    component: GsdbRegInprocessComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/dataLoader',
    component: DataLoaderComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbOnlineAuthorization',
    component: GsdbAuthorizationComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbOnlineAuthorization/denied',
    component: GsdbAuthDeniedComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbOnlineAuthorization/complete',
    component: GsdbAuthCompleteComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbOnlineAuthorization/inprocess',
    component: GsdbAuthInprocessComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbOnlineAuthorization/accepted',
    component: GsdbAuthAcceptedComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbOnlineAuthorization/not-accepted',
    component: GsdbAuthNotAcceptComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/memreg',
    component: FsnRegistrationComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/appLaunch',
    component: AppLaunchComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbContactsMain',
    component: GsdbContactsMainComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbContactsChief',
    component: GsdbContactsChiefComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbContactsVP',
    component: GsdbContactsVpComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbContactSecOfficer',
    component: GsdbContactsIsoComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbContactsExeCeo',
    component: GsdbContactsExecutiveComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbContactsVpSales',
    component: GsdbContactsSalesComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbContactsVsml',
    component: GsdbContactsVsmlComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbContactsKfc',
    component: GsdbContactsKfcComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbContactsFord',
    component: GsdbContactsFordComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbContactsCeo',
    component: GsdbContactsCeoComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbContactsError',
    component: GsdbCodeInvalidComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbContactUpdate/:type',
    component: SuccessComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/appLookup',
    component: AppLookupComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/appRegistration',
    component: AppRegistrationComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/embededEmail/emailFileUpload',
    component: EmbededEmailComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/embededEmail/emailBuilder',
    component: EmbededEmailConfirmationComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbSiteCodesforCDSID',
    component: GsdbForSitecodeComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbOnlineQuery',
    component: GsdbQueryComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbOnlineRestricted',
    component: GsdbRegRestrictedComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbOnlineQuery/failedSearch',
    component: GsdbFailedSearchComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbOnlineQueryResults',
    component: GsdbQueryResultsComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbDeleteQueryResults',
    component: GsdbDeleteQueryComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbContacts',
    component: GsdbContactsSearchComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'perlMigrationWeb/gsdbContactsSearchResults',
    component: ContactSearchResultsComponent,
    canActivate: [AuthenticationGuard]
  }
]
