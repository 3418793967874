import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate,Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { SupplierGuidesService } from "./production/service/supplier-guides.service";
import { ProductionService } from "./production/service/production.service";
import { SupplementsService } from "./indirect/service/supplements.service";
import { SupplementsServiceProd } from "./production/service/supplements.service";
import { SummeryService } from "./production/service/summery.service";
import { TermsService } from "./indirect/service/terms.service";
import { IndirectService } from "./indirect/service/indirect.service";
import { AntiCorruptionService } from "./indirect/service/anti-corruption.service";

@Injectable({
    providedIn:'root',
})
export class GtcDocumentGuard implements CanActivate{
    constructor(private router:Router, private supplier: SupplierGuidesService, private productionService: ProductionService,
        private indirectSupplements: SupplementsService, private summery: SummeryService , private prodSupplements: SupplementsServiceProd
        , private terms: TermsService, private indirect:IndirectService, private anitCor:AntiCorruptionService){}
    canActivate(route: ActivatedRouteSnapshot ): boolean{
        const url = route.url.map(segment => segment.path).join('/');
            if(url === "api/files/supplier-guides/autocurrency.pdf"){
                this.supplier.getAutoCurrencyPdf(); return false;
            }
            
            if(url === "api/files/supplier-guides/automaterials.pdf"){
                this.supplier.getAutomaterialsPdf(); return false;
            }

            if(url === "api/files/supplier-guides/bailmenteu2007.pdf"){
                this.supplier.getBailmenteu2007Pdf(); return false;
            }
            
            if(url === "api/files/supplier-guides/capacityplan.pdf"){
                this.supplier.getCapacityPlanPdf(); return false;
            }

            if(url === "api/files/supplier-guides/csip.pdf"){
                this.supplier.getCSIPPdf(); return false;
            }

            if(url === "api/files/supplier-guides/claims.pdf"){
                this.supplier.getClaimsPdf(); return false;
            }

            if(url === "api/files/supplier-guides/comanagedbuy.pdf"){
                this.supplier.getComanagedBuyPdf(); return false;
            }

            if(url === "api/files/supplier-guides/customscan.pdf"){
                this.supplier.getCustomScanPdf(); return false;
            }

            if(url === "api/files/supplier-guides/customsmex.pdf"){
                this.supplier.getCustomsMexicoPdf(); return false;
            }

            if(url === "api/files/supplier-guides/customsusa.pdf"){
                this.supplier.getCustomsUsaPdf(); return false;
            }

            if(url === "api/files/supplier-guides/deltermsfoe.pdf"){
                this.supplier.getDeleveryTermsFOEPdf(); return false;
            }

            if(url === "api/files/supplier-guides/digitalmfgdata.pdf"){
                this.supplier.getDigitalManufacturingDataPdf(); return false;
            }
            if(url === "api/files/supplier-guides/disputeresolution.pdf"){
                this.supplier.getProdDisputeResolutionPdf(); return false;
            }
            if(url === "api/files/supplier-guides/fmspdisputeresolution.pdf"){
                this.supplier.getIndirectDisputeResolutionPdf(); return false;
            }
            if(url === "api/files/supplier-guides/envguide.pdf"){
                this.supplier.getEnvGuidePdf(); return false;
            }
            if(url === "api/files/supplier-guides/exportcontrolwebguide.pdf"){
                this.supplier.getExportControlWebGuidePdf(); return false;
            }
            if(url === "api/files/supplier-guides/psguide2015.pdf"){
                this.supplier.getFCSDNorthAmericaPdf(); return false;
            }
            if(url === "api/files/supplier-guides/psguide2005eu.pdf"){
                this.supplier.getFCSDEuropePdf(); return false;
            }
            if(url === "api/files/supplier-guides/fcsdservicepartsna.pdf"){
                this.supplier.getFCSDGlobalPdf(); return false;
            }
            if(url === "api/files/supplier-guides/fsa.pdf"){
                this.supplier.getFSAPdf(); return false;
            }
            if(url === "api/files/supplier-guides/fordbrandprotect.pdf"){
                this.supplier.getFordBrandProtectPdf(); return false;
            }
            if(url === "api/files/supplier-guides/eusuppinvoicingfmsp2007.pdf"){
                this.supplier.getInvoicingGuidelinesPdf(); return false;
            }
            if(url === "api/files/supplier-guides/shipparts.pdf"){
                this.supplier.getShipPartsPdf(); return false;
            }
            if(url === "api/files/supplier-guides/mplmaterials.pdf"){
                this.supplier.getMplmaterialsPdf(); return false;
            }
            if(url === "api/files/supplier-guides/nmpwshipinst2009.pdf"){
                this.supplier.getWareHouseInstructionPdf(); return false;
            }
            if(url === "api/files/supplier-guides/newreleases.pdf"){
                this.supplier.getWareHouseInstructionPdf(); return false;
            }
            if(url === "api/files/supplier-guides/globalexppkg.pdf"){
                this.supplier.getGlobalexppkgPdf(); return false;
            }
            if(url === "api/files/supplier-guides/eu1750pkg.pdf"){
                this.supplier.getEu1750pkgPdf(); return false;
            }
            if(url === "api/files/supplier-guides/napkgguide.pdf"){
                this.supplier.getNapkgguidePdf(); return false;
            }
            if(url === "api/files/supplier-guides/APpkgguide.pdf"){
                this.supplier.getAPpkgguidePdf(); return false;
            }
            if(url === "api/files/supplier-guides/cn1750pkg.pdf"){
                this.supplier.getCN1750pkgPdf(); return false;
            }
            if(url === "api/files/supplier-guides/prodpayguide.pdf"){
                this.supplier.getProdPayGuidePdf(); return false;
            }
            if(url === "api/files/supplier-guides/indirectpayguide.pdf"){
                this.supplier.getIndirectPayGuidePdf(); return false;
            }
            if(url === "api/files/supplier-guides/nonrenewal.pdf"){
                this.supplier.getNonrenewalPdf(); return false;
            }
            if(url === "api/files/supplier-guides/ptoewarrants.pdf"){
                this.supplier.getPtoewarrantsPdf(); return false;
            }
            if(url === "api/files/supplier-guides/steelrawmtls.pdf"){
                this.supplier.getSteelrawmtlsPdf(); return false;
            }
            if(url === "api/files/supplier-guides/returnablecontainerASN.pdf"){
                this.supplier.getReturnableContainerASNPdf(); return false;
            }
            if(url === "api/files/supplier-guides/shipmentfoe.pdf"){
                this.supplier.getShipmentsfoe(); return false;
            }
            if(url === "api/files/supplier-guides/nashipping.pdf"){
                this.supplier.getNashippingPdf(); return false;
            }
            if(url === "api/files/supplier-guides/hrandwc.pdf"){
                this.supplier.getHrandwcPdf(); return false;
            }
            if(url === "api/files/supplier-guides/tax.pdf"){
                this.supplier.getTaxPdf(); return false;
            }
            if(url === "api/files/supplier-guides/toolingaudit.pdf"){
                this.supplier.getToolingPurchaseGuidePdf(); return false;
            }
            if(url === "api/files/supplier-guides/supporttooltagging.pdf"){
                this.supplier.getSupptooltaggingPdf(); return false;
            }
            if(url === "api/files/supplier-guides/governmentsubcontractor.pdf"){
                this.supplier.getGovernmentSubContractorPdf(); return false;
            }
            if(url === "api/files/supplier-guides/sdi.pdf"){
                this.supplier.getSdiPdf(); return false;
            }
            if(url === "api/files/supplier-guides/valuechaintransparency.pdf"){
                this.supplier.getValueChainTransparencyPdf(); return false;
            }
            if(url === "api/files/supplier-guides/warrantychargeback.pdf"){
                this.supplier.getWarrantyChargebackPdf(); return false;
            }
            if(url === "api/files/supplier-guides/warrantyqos.pdf"){
                this.supplier.getWarrantyQosPdf(); return false;
            }
            if(url === "api/files/communication/2022sgupdates.pdf"){
                this.productionService.getCommunicationPdf(); return false;
            }
            if(url === "api/files/information-supplement/piisupp.pdf"){
                this.productionService.getPiiSupplementPdf(); return false;
            }
            if(url === "api/files/information-supplement/isp_supplement.pdf"){
                this.productionService.getInfoSecuritySupplementPdf(); return false;
            }
            if(url === "api/files/information-supplement/ppgtc.pdf"){
                this.productionService.getPpgtcPdf(); return false;
            }
            if(url === "api/files/information-supplement/ppgtc_chinese.pdf"){
                this.productionService.getChineseTermsPdf(); return false;
            }
            if(url === "api/files/indirect-supplements/chinesesuppfmsp.pdf"){
                this.indirectSupplements.getGtcSupplementalTermsChinaPdf(); return false;
            }
            if(url === "api/files/indirect-supplements/canadasuppfmsp.pdf"){
                this.indirectSupplements.getGtcSupplementalTermsCanadaPdf(); return false;
            }
            if(url === "api/files/indirect-supplements/austrailiasuppfmsp.docx"){
                this.indirectSupplements.getGtcSupplementalTermsAustraliaDoc(); return false;
            }
            if(url === "api/files/indirect-supplements/indiasuppfmsp2007.pdf"){
                this.indirectSupplements.getGtcSupplementalTermsIndiaPdf(); return false;
            }
            if(url === "api/files/indirect-supplements/brazilsuppfmsp2007.pdf"){
                this.indirectSupplements.getGtcSupplementalTermsBrazilPdf(); return false;
            }
            if(url === "api/files/indirect-supplements/mexicosuppfmsp2007.pdf"){
                this.indirectSupplements.getGtcSupplementalTermsMexicoPdf(); return false;
            }
            if(url === "api/files/indirect-supplements/greecesuppfmsp2007.pdf"){
                this.indirectSupplements.getGtcSupplementalTermsGreecePdf(); return false;
            }
            if(url === "api/files/indirect-supplements/pnpd223.pdf"){
                this.indirectSupplements.getIndividualCollectiveLaborRelEng(); return false;
            }
            if(url === "api/files/indirect-supplements/pnpd223_spanish.pdf"){
                this.indirectSupplements.getIndividualCollectiveLaborRelSpanish(); return false;
            }
            if(url === "api/files/indirect-supplements/spainsuppenglish.pdf"){
                this.indirectSupplements.getGTCSupplementalTermsEnglish(); return false;
            }
            if(url === "api/files/indirect-supplements/spainsuppspanish.pdf"){
                this.indirectSupplements.getGTCSupplementalTermsSpanish(); return false;
            }
            if(url === "api/files/summery/PPGTCFAQ.pdf"){
                this.summery.getPPGTCFAQPdf(); return false;
            }
            if(url === "api/files/summery/GTCsMajorChangeSummaryOGCFinal11182003_1.doc"){
                this.summery.getMajorChangesSummeryPdf(); return false;
            }
            if(url === "api/files/summery/July2021PPGTCs_KeyChanges.pdf"){
                this.summery.getKeyChangesPdf(); return false;
            }
            if(url === "api/files/summery/2021GTCFAQ.pdf"){
                this.summery.getGtcFaqPdf(); return false;
            }
            if(url === "api/files/production-supplements/pcpd023.doc"){
                this.prodSupplements.getChinaAddendumDoc(); return false;
            }
            if(url === "api/files/production-supplements/pcpd023.pdf"){
                this.prodSupplements.getChinaAddendumPdf(); return false;
            }
            if(url === "api/files/production-supplements/GTCsSuppTerms-Brazil-FinalDec1703-1.doc"){
                this.prodSupplements.getGTCsSupplementalTermsBrazil(); return false;
            }
            if(url === "api/files/production-supplements/ppgtcsupptermsbrazil.pdf"){
                this.prodSupplements.getPpGtcSupptermsBrazilPdf(); return false;
            }
            if(url === "api/files/production-supplements/ppgtcssupptermscanada2012.pdf"){
                this.prodSupplements.getGtcSuppTermsCanada(); return false;
            }
            if(url === "api/files/production-supplements/ppgtcssupptermscanada.pdf"){
                this.prodSupplements.getPpGtcSuppTermsCanada(); return false;
            }
            if(url === "api/files/production-supplements/GTCsSuppTerms-Mexico-FinalDec11-03-1.pdf"){
                this.prodSupplements.getGtcSuppTermsMexico(); return false;
            }
            if(url === "api/files/production-supplements/ppgtcsupptermsmexico.pdf"){
                this.prodSupplements.getPpGtcSuppTermsMexico(); return false;
            }
            if(url === "api/files/production-supplements/GTCsSuppTerms-Russia-Aug-2012.pdf"){
                this.prodSupplements.getGtcSuppTermsRussia(); return false;
            }
            if(url === "api/files/production-supplements/ppgtcsupptermsrussia.pdf"){
                this.prodSupplements.getPpGtcSuppTermsRussia(); return false;
            }
            if(url === "api/files/production-supplements/GTCsSuppTerms-Taiwan-FinalDec152003-1.doc"){
                this.prodSupplements.getGtcSuppTermsTaiwan(); return false;
            }
            if(url === "api/files/production-supplements/ppgtcsupptermstaiwan.pdf"){
                this.prodSupplements.getGtcSuppTermsTaiwanPdf(); return false;
            }
            if(url === "api/files/production-supplements/GTCsSuppTerms-EUFinalApril2-0404.pdf"){
                this.prodSupplements.getGtcSuppTermsEuropeanUnion(); return false;
            }
            if(url === "api/files/production-supplements/ppgtcsupptermseu.pdf"){
                this.prodSupplements.getPpGtcSuppTermsEuropeanUnion(); return false;
            }
            if(url === "api/files/production-supplements/foasupp.pdf"){
                this.prodSupplements.getGtcSuppTermsAustralia(); return false;
            }
            if(url === "api/files/production-supplements/ppgtcsupptermsaustralia.pdf"){
                this.prodSupplements.getPpGtcSuppTermsAustralia(); return false;
            }
            if(url === "api/files/indirect-terms/pcpd018.pdf"){
                this.terms.getGtcTermsEnglishPdf(); return false;
            }
            if(url === "api/files/indirect-terms/chinesefmsp2007.pdf"){
                this.terms.getGtcTermsChinesePdf(); return false;
            }
            if(url === "api/files/indirect-terms/germanfmsp2007.pdf"){
                this.terms.getGtcTermsDeutschPdf(); return false;
            }
            if(url === "api/files/indirect-terms/dutchfmsp2007.pdf"){
                this.terms.getGtcTermsDutchPdf(); return false;
            }
            if(url === "api/files/indirect-terms/hungarianfmsp2007.pdf"){
                this.terms.getGtcTermsHungarianPdf(); return false;
            }
            if(url === "api/files/indirect-terms/spanish_argentina_fmsp2007.pdf"){
                this.terms.getGtcTermsSpanishPdf(); return false;
            }
            if(url === "api/files/indirect-terms/polishfmsp2007.pdf"){
                this.terms.getGtcTermsPolishPdf(); return false;
            }
            if(url === "api/files/indirect-terms/czechrepublicfmsp2007.pdf"){
                this.terms.getGtcTermsCzechPdf(); return false;
            }
            if(url === "api/files/indirect-terms/russianfmsp2007.pdf"){
                this.terms.getGtcTermsRussianPdf(); return false;
            }
            if(url === "api/files/indirect-terms/italianfmsp2007.pdf"){
                this.terms.getGtcTermsItalianPdf(); return false;
            }
            if(url === "api/files/indirect-terms/portuguesefmsp2007.pdf"){
                this.terms.getGtcTermsPortuguesePdf(); return false;
            }
            if(url === "api/files/indirect-terms/finnishfmsp2007.pdf"){
                this.terms.getGtcTermsFinnishPdf(); return false;
            }
            if(url === "api/files/indirect-terms/greekfmsp2007.pdf"){
                this.terms.getGtcTermsGreekPdf(); return false;
            }
            if(url === "api/files/indirect-terms/danishfmsp2007.pdf"){
                this.terms.getGtcTermsDanishPdf(); return false;
            }
            if(url === "api/files/indirect-terms/norwegianfmsp2007.pdf"){
                this.terms.getGtcTermsNorwegianPdf(); return false;
            }
            if(url === "api/files/indirect-terms/romanianfmsp2007.pdf"){
                this.terms.getGtcTermsRomanianPdf(); return false;
            }
            if(url === "api/files/indirect-terms/swedishfmsp2007.pdf"){
                this.terms.getGtcTermsRomanianPdf(); return false;
            }
            if(url === "api/files/indirect-terms/thaifmsp2007.pdf"){
                this.terms.getGtcTermsThaiPdf(); return false;
            }
            if(url === "api/files/indirect-terms/koreanfmsp2007.pdf"){
                this.terms.getGtcTermsKoreanPdf(); return false;
            }
            if(url === "api/files/indirect-communication/2022sgupdates.pdf"){
                this.indirect.getCommunicationPdf(); return false;
            }
            if(url === "api/files/indirect-communication/piisupp.pdf"){
                this.indirect.getPiiSupplementPdf(); return false;
            }
            if(url === "api/files/indirect-communication/isp_supplement.pdf"){
                this.indirect.getInfoSecuritySupplementPdf(); return false;
            }
            if(url === "api/files/indirect-communication/pnpd234.pdf"){
                this.indirect.getUkPurchasingTaxPdf(); return false;
            }
            if(url === "api/files/indirect-communication/pnpd232.pdf"){
                this.indirect.getSupplierTravelExpensePolicyPdf(); return false;
            }
            if(url === "api/files/indirect-communication/pnpd228.docx"){
                this.indirect.getMarketingServicesSupplementDoc(); return false;
            }
            if(url === "api/files/indirect-communication/pnpd027isa.pdf"){
                this.indirect.getPnpd027isaPdf(); return false;
            }
            if(url === "api/files/indirect-communication/pnpd027asa.pdf"){
                this.indirect.getPnpd027asaPdf(); return false;
            }
            if(url === "api/files/indirect-communication/pnpd0224.pdf"){
                this.indirect.getPnpd224Pdf(); return false;
            }
            if(url === "api/files/indirect-communication/pnpd229.pdf"){
                this.indirect.getPnpd229Pdf(); return false;
            }
            if(url === "api/files/anti-corruption/Anti_Corruption_Supplement_PNPD225.pdf"){
                this.anitCor.getAntiCorruptionEnglishPdf(); return false;
            }
            if(url === "api/files/anti-corruption/Anti_Corruption_Supplement_Chinese.pdf"){
                this.anitCor.getAntiCorruptionChinesePdf(); return false;
            }
            if(url === "api/files/anti-corruption/Anti_Corruption_Supplement_Dutch.pdf"){
                this.anitCor.getAntiCorruptionDutchPdf(); return false;
            }
            if(url === "api/files/anti-corruption/Anti_Corruption_Supplement_French.pdf"){
                this.anitCor.getAntiCorruptionFrenchPdf(); return false;
            }
            if(url === "api/files/anti-corruption/Anti_Corruption_Supplement_German.pdf"){
                this.anitCor.getAntiCorruptionGermanPdf(); return false;
            }
            if(url === "api/files/anti-corruption/Anti_Corruption_Supplement_Portugese.pdf"){
                this.anitCor.getAntiCorruptionPortuguesePdf(); return false;
            }
            if(url === "api/files/anti-corruption/Anti_Corruption_Supplement_Russian.pdf"){
                this.anitCor.getAntiCorruptionRussianPdf(); return false;
            }
            if(url === "api/files/anti-corruption/Anti_Corruption_Supplement_Spanish.pdf"){
                this.anitCor.getAntiCorruptionSpanishPdf(); return false;
            }
            if(url === "api/files/anti-corruption/Anti_Corruption_Supplement_Romanian.pdf"){
                this.anitCor.getAntiCorruptionRomanianPdf(); return false;
            }
            if(url === "api/files/anti-corruption/Anti_Corruption_Supplement_Thai.pdf"){
                this.anitCor.getAntiCorruptionRomanianPdf(); return false;
            }if(url === "api/files/anti-corruption/Anti_Corruption_Supplement_Vietnamese.pdf"){
                this.anitCor.getAntiCorruptionRomanianPdf(); return false;
            }
            

            
            return true;
    }
        
    
   
}