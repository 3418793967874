import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-buyer-search',
  templateUrl: './buyer-search.component.html',
  styleUrls: ['./buyer-search.component.scss']
})
export class BuyerSearchComponent {

  buyerForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.buyerForm = this.fb.group(({
      buyerCode: ['', [Validators.required, Validators.minLength(4),
        Validators.maxLength(5)]],
      buyerName: [''],
      commodityDesc: ['']
    }))
  }

  onSubmit() {
  }
}
