import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup , ReactiveFormsModule, Validators} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-everest-token-home',
  templateUrl: './everest-token-home.component.html',
  styleUrls: ['./everest-token-home.component.scss']
})
export class EverestTokenHomeComponent{

  tokenLookupTypeForm : FormGroup;

  constructor(private fb: FormBuilder, private router: Router){
    this.tokenLookupTypeForm = this.fb.group({
      typeSelect : ['',Validators.required]
    });
  }

  onSubmit(){
    const typeSelect = this.tokenLookupTypeForm.get('typeSelect')?.value;
    if(typeSelect == 'siteCode'){
      this.router.navigate(["/perlMigrationWeb/everstTokenLookup/gsdbCode"]);
    }
    if(typeSelect == 'uploadFile'){
      this.router.navigate(["/perlMigrationWeb/everstTokenLookup/uploadFile"]);
    }
  }

}
