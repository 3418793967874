import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {UserAccessService} from "../../user-access-review/service/user-access.service";

@Component({
  selector: 'app-csa-home',
  templateUrl: './csa-home.component.html',
  styleUrls: ['./csa-home.component.scss']
})
export class CsaHomeComponent implements OnInit{
  date: string = ""
  constructor(private router: Router, private userAccService: UserAccessService) {
  }

  ngOnInit(): void {

    this.userAccService.getTaskDate().subscribe(date => {
      this.date = date.date
    })
  }

  openCsaRecords() {
    this.router.navigate(["/PAARS/userCSAReview"])
  }

}
