<app-common-header></app-common-header>
<div class="container-fluid">
    <div class="row">
        <div class="page-title">
            <b>EVEREST Supplier Token Status Application</b>
        </div>
    </div>

    <div class="row" [hidden]="!type">
        <div class="col-9">
            <div class="row font1">
                <div>
                    Your file has been
				successfully processed. Please check your inbox for a new E-Mail.
                </div>
            </div>
            <div class="row">
                <div class="col-6 font2">
                    <a routerLink = "/perlMigrationWeb/everstTokenLookup">
                        <u>Go back to the Main Page</u>
                    </a>
                 </div>
                <div class="col-5 font3">
                    <a routerLink = "/perlMigrationWeb/everstTokenLookup/uploadFile">
                        <u>Upload another set of GSDB Codes</u>
                    </a>
                 </div>
            </div>
        </div>
    </div>

    <div class="row spacing" [hidden]="type">
        <div class="col-12">
            <div class="row">
                <div class="col-1"></div>
                <div class="col-9">
                    <table class="table1">
                        <thead class="table-font1">
                            <tr>
                                <th class="th">GSDB Supplier Code</th>
                                <th class="th">eVEREST Token</th>
                                <th class="th">GSDB On-Line Registered?</th>
                                <th class="th"> &nbsp;FSP Registered?&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-primary">{{tokenDetails?.siteCode}}</td>
                                <td>{{tokenDetails?.everestToken}}</td>
                                <td class="text-danger">{{tokenDetails?.gsdbRegistered}}</td>
                                <td class="text-danger">{{tokenDetails?.fspRegistered}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-1"></div>
            </div>
            <div class="row">
                <div class="col-6 font2">
                    <a routerLink = "/perlMigrationWeb/everstTokenLookup">
                        <u><b>Go back to the Main Page</b></u>
                    </a>
                 </div>
                <div class="col-5 font3">
                    <a routerLink = "/perlMigrationWeb/everstTokenLookup/gsdbCode">
                        <u><b>Process another code</b></u>
                    </a>
                 </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="hr">
            <hr>
        </div>
    </div>
</div>