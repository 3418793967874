<div class="page-margin">
    <div class="container-fluid content_container ">
        <div class="row">
            <div class="col-2 image-alignment">
                <img src="/assets/ford.png" alt="Purchasing Annual Access Review System" /> 
            </div>
            <div class="col-10"> 
                <div class="title-alignment row">
                    <b>
                        Purchasing & Associated Systems Annual Access Review Manager Home Page
                    </b>  
                </div>  
            </div>
        </div>
        
        <div class="row">
            <div class="col-12">
                <b>Instructions : </b><br>
                <table class="content-align">
                    <tr>
                        <ul class="list-style"><br>
                            <li>The records assigned to you for review are displayed on your review screen. Please select Yes, No or NCM (Not Current Manager) for each record.</li>															
                            <li>If a user still requires access, click on the "Yes" button, if not, click on the "No" button.</li>															
                            <li>If you are not the user's current manager ( i.e. Authorizer), click the "NCM" check box and enter the CDSID of the current manager in the "Current Manager" box.</li>															
                            <li>Please complete reassigns as soon as possible so that the newly identified authorizer has significant time to complete the review.</li>															
                            <li>Use the comments field for any additional information; comments are limited to 250 characters.</li>															
                            <li >Please make sure you scroll to the end of the list, review every line, and select "Yes", "No" or "NCM" for each user/job function and system access.</li>
                        </ul>
                    </tr>

                </table>
            </div>
        </div>
    </div>
</div>    

