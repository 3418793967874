<app-common-header></app-common-header>
<div class="page-title">
  <img src="./assets/gsdb3b.gif"/>
</div>
<div class="mt-14">
  <p class="text-center warning"><b>WARNING!!</b></p>
  <p class="text-center"><b>YOUR GSDB CODE IS NOT VALID.</b></p>
  <p class="text-center">
    <b>Please Contact <a class="nav-color" href="mailto:gsdbweb@ford.com?Subject=Error on GSDB Online Registration.">gsdbweb@ford.com</a>
      or access <a class="color" routerLink="/support">Ford Help Desk Support</a></b>
  </p>
  <p class="text-center">
    <b>To check for other contacts for your GSDB code,
      Please Navigate to <a class="color" routerLink="/perlMigrationWeb/gsdbContactsMain">Contacts Home</a></b>
  </p>
</div>
