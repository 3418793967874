<div class="history-container">
  <p class="heading pl-1">History of Updates - FM&SP</p>
  <div>
    <table class="m-2 table-container" border="1" cellpadding="3" cellspacing="0">
      <tr>
        <th class="text-center font-size-16">Date</th>
        <th class="text-center font-size-16">Update Description</th>
      <tr>
        <td>10/1/2003</td>
        <td>Chronological list of GTC website adds/revisions go here</td>
      </tr>

      <tr>
        <td>8/30/2005</td>
        <td>Updates to Non-Production Global Terms and Conditions including Supplier Communication Letter</td>
      </tr>
      <tr>
        <td>12/15/2005</td>
        <td>GTC Vehicle Parts Branding Supplier-Guide has been renamed to &quot;Global Automotive Parts Trademarks&quot;
          and the policy directive has been updated
        </td>
      </tr>
      <tr>
        <td>9/29/2006</td>
        <td>Updates to Non-Production Global Terms and Conditions (FGT29, Rev. 10/06).</td>
      </tr>
      <tr>
        <td>01/01/2007</td>
        <td>GTC translations available: Chinese, Spanish (Argentina), Spanish (Mexico) and French.</td>
      </tr>
      <tr>
        <td>11/01/2007</td>
        <td>Updates to Non-Production Global Terms and Conditions (FGT30, Rev. 12/07); added PII Supplement; deleted GTC
          Change Summary; updated Payment Supplier-Guide.
        </td>
      <tr>
        <td>01/23/2008</td>
        <td>GTC translations available: Dutch, Hungarian, and Spanish (Argentina)</td>
      </tr>
      <tr>
        <td>06/01/2008</td>
        <td>GTC translations available: Polish, Czech Republic & Russian</td>
      </tr>

      <tr>
        <td>07/01/2008</td>
        <td>GTC translations available: Italian and Portuguese</td>
      </tr>

      <tr>
        <td>08/01/2008</td>
        <td>The following GTC Supplier-Guide is new: Invoicing Guidelines, Non-Production - Europe</td>
      </tr>
      <tr>
        <td>10/15/2008</td>
        <td>GTC translations available: Finnish and Greek</td>
      </tr>
      <tr>
        <td>12/01/2008</td>
        <td>GTC translations available: Danish, Norwegian and
          Romanian
        </td>
      </tr>
      <tr>
        <td>05/01/2009</td>
        <td>GTC translation available: Swedish</td>
      </tr>
      <tr>
        <td>09/09/2009</td>
        <td>Updated PII Supplement and is now globally applicable.</td>
      </tr>
      <tr>
        <td>12/11/2009</td>
        <td>Added Individual and Collective Labor Relations Supplement (English and Spanish).</td>
      </tr>
      <tr>
        <td>05/07/2010</td>
        <td>Added Supplemental Terms ÃÂ Purchase Orders Related to Grant DE-EE0002026 and Invoice Template.</td>
      </tr>
      <tr>
        <td>05/17/2011</td>
        <td>
          <p>The following GTC Supplier-Guides have been revised. Details of the revisions are contained within each
            document:</p>
          <p>- Automaterials: Market-Based Raw Material Commodity Price Management System</p>
          <p>- Co-Managed Buy Program for Resins</p>
          <p>- Customs, Supplier & Import Guide for Shipping to: Canada, Mexico, United States</p>
          <p>- Delivery Terms & Customs Guide for Production Part Sourcing (FoE)</p>
          <p style="margin-top: 0; margin-bottom: 0">- Environmental
            Requirements, Supplier</p>
          <p>- FCSD Service Parts Guide ÃÂ North America</p>
          <p>- Human Rights & Working Conditions</p>
          <p>- Label Standards, Shipping Parts/Identification</p>
          <p>- MP&L Requirements for Materials Management & Communications, North America</p>
          <p>- Packaging Terms & Conditions (Global 1750 Export) ÃÂ All Regions</p>
          <p>- Packaging Terms & Conditions (EU1750) ÃÂ Europe</p>
          <p>- Packaging Guidelines for Production Parts ÃÂ North America (MFG 1750NA)</p>
          <p>- Shipping & Routing Guide, North America</p>
          <p>- Tax</p>
          <p>- Tooling Purchase and Audit Supplier Guide</p>
          <p></p>
          <p>Deleted Guides: Volvo Container Selection Process, Volvo MP&L Guide, Volvo Part Trademarks & Marking
            Directive, Volvo Requirements for Conformity of Production, Volvo Supplemental Requirements for Service and
            Component Parts, Volvo Warranty Cost Recover Program</p>
        </td>
      </tr>

      <tr>
        <td>07/18/2011</td>
        <td>
          <p>The following GTC Supplier-Guides have been revised. Details of the revisions are contained within each
            document:</p>
          <p>- News Release & Publicity Guidelines, Supplier</p>
          <p>- Enhanced Supplier Tool Tagging/Marking Requirements and Revised Payment Approval Process</p>
          <p>- Warranty Chargeback</p>
        </td>
      </tr>
      <tr>
        <td>04/01/2012</td>
        <td>
          <p>Revised Supplemental Terms ÃÂ Canada for Production and FM&SP</p>
        </td>
      </tr>
      <tr>
        <td>5/1/2012</td>
        <td>The following GTC Supplier-Guides have been revised.
          Details of the revisions are contained within each document:
          <ul class="list-disc ml-8 mt-2">
            <li>Automaterials</li>
            <li>Co-managed Buy Program for Resins</li>
            <li>Customs Import And Export
              <ul class="list-circle ml-8">
                <li>Canada</li>
                <li>Mexico</li>
                <li>United States</li>
              </ul>
            </li>
            <li>Environmental</li>
            <li>FCSD Packaging - North America</li>
            <li>FCSD Service Parts Guide - North America</li>
            <li>FCSD Service Parts Guide - Asia Pacific And Africa</li>
            <li>Label Standards, Shipping Parts/Identification
              (Global)
            </li>
            <li>MP&L Requirements for Materials Management and
              Communication, North America
            </li>
            <li>Packaging Terms And Conditions (Global 1750 Export) -
              All Regions
            </li>
            <li>Packaging Terms And Conditions (Europe EU1750) Europe</li>
            <li>Packaging Guidelines for Production Parts, North
              America(MFG 1750NA)
            </li>
            <li>Payment</li>
            <li>Shipments for Production Parts to FOE Plants And
              Export Distribution Center's
            </li>
            <li>Social Responsibility (including Anti-Bribery)
              (Supplier-Guide formerly titled Human Rights and Working
              Conditions)
              <ul class="list-circle ml-8">
                <li>Associated embedded documents Policy Letter No. 24
                  (Code of Human Rights, Basic Working Conditions, and
                  Corporate Responsibility), Summary: Giving Gifts, Favors &
                  Entertainment , Summary: Accepting Gifts, Favors &
                  Entertainment at Ford
                </li>
              </ul>
            </li>
            <li>Steel Raw Materials Sales Program</li>
            <li>Tooling Purchase and Audit
              <ul class="list-circle ml-8">
                <li>Including Tagging/Marking Requirements and Revised
                  Payment Approval Process
                </li>
              </ul>
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>08/01/2012</td>
        <td>
          <p>Updated documents posted</p>
          <p>- Tax Supplier-Guide</p>
          <p>- Production Supplemental Terms - Russia</p>
        </td>
      </tr>
      <tr>
        <td>02/01/2013</td>
        <td>
          <p>Added Supplemental Terms - Anti-Corruption</p>
        </td>
      </tr>
      <tr>
        <td>09/09/2013</td>
        <td>
          <p>Revised Ford of Australia Supplemental Terms for FM & SP</p>
          <p>Revised Payment Terms Supplier-guide.</p>
        </td>
      </tr>
      <tr>
        <td>11/01/2014</td>
        <td>
          <p>Revised Ford of
            Canada Supplemental terms for FM&SP to include accessability
            legislation</p>
        </td>
      </tr>
      <tr>
        <td>05/01/2015</td>
        <td>The following GTC Supplements have been updated
          <ul class="list-disc ml-8">
            <li>PII Supplement</li>
            <li>Information Security Supplement (added)</li>
            <li>Payment Supplier Guide updated</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>06/05/2015</td>
        <td>The following GTC Supplier-Guides have been revised.
          Details of the revisions are contained within each document:
          <ul class="list-disc ml-8">
            <li>Customs, Supplier Customs Import And Export
              <ul class="list-circle ml-8">
                <li>Canada</li>
                <li>Mexico</li>
                <li>United States</li>
              </ul>
            </li>
            <li>Environmental Requirements, Supplier</li>
            <li>FCSD Service Parts Guide - Global</li>
            <li>MP&L Requirements for Materials Management &
              Communication, North America
            </li>
            <li>Packaging Terms & Conditions (Global 1750 Export) -
              All Regions
            </li>
            <li>Packaging Terms And Conditions (Europe EU1750) Europe</li>
            <li>Packaging Guidelines for Production Parts, North
              America(MFG 1750NA)
            </li>
            <li>Packaging Guidelines for Production Parts, Asia
              Pacific (ADDED)
            </li>
            <li>Social Responsibility and Anti-Corruption
              Requirements
            </li>

          </ul>
        </td>
      </tr>
      <tr>
        <td>09/01/2015</td>
        <td>The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>FCSD Packaging and Shipping- North America</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>10/01/2015</td>
        <td>The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Payment</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>12/01/2015</td>
        <td>The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Payment</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>01/01/2016</td>
        <td>The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Payment</li>
            <li>Supplier Requirements for U.S. Government
              Subcontractors Supplier-Guide
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>05/01/2016</td>
        <td>The following GTC Supplier-Guides have been revised.
          Details of the revisions are contained within each document:
          <ul class="list-disc ml-8">
            <li>Payment</li>
            <li>Environmental Requirements, Supplier</li>
            <li>Social Responsibility and Anti-Corruption
              Requirements
            </li>
            <li>Global Export Market Packaging Terms and Conditions</li>
            <li>Steel Raw Material Sales Program</li>
            <li>Label Standards, Shipping Parts/Identification Global</li>
            <li>MP&L Requirements for Materials Management &
              Communication, North America
            </li>
            <li>FCSD Service Parts Supplier Guide- Global</li>
            <li>Bailment of Production Parts for Ford of Europe
              Plants and Export Distribution Centres
            </li>
            <li>Shipments to Ford of Europe for Production Part
              Plants and Export Distribution Centres
            </li>
            <li>Customs Import And Export</li>
            <ul class="list-circle ml-8">
              <li>Canada</li>
              <li>Mexico</li>
              <li>United States</li>
            </ul>
            <li>Packaging Guidelines for Production Parts, North
              America(MFG 1750NA
            </li>
            <li>Enhanced Supplier Tool Tagging/Marking Requirements
              and Revised Payment Approval Process
              <ul class="list-circle ml-8">
                <li>Supplier instruction for Progress Payments</li>
              </ul>
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>06/01/2016</td>
        <td>The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Dispute Resolution, GTC (Non Production)</li>
            <li>Tax</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>07/01/2016</td>
        <td>The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Payment</li>
            <li>Tool Purchase and Audit</li>
            <li>Enhanced Supplier Tool Tagging / Marking Requirements</li>
            <li>FCSD Pack and Supply Manual (EU)</li>
            <li>Export Control Notification Supplier Submission
              Guidelines (New)
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>08/01/2016</td>
        <td>The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Social Responsibility and Anti-Corruption</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>11/01/2016</td>
        <td>The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Payment</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>12/01/2016</td>
        <td>The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Enhanced Supplier Tool Tagging / Marking Requirements and Revised Payment Approval Process</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>5/01/2017</td>
        <td>The following GTC Supplier-Guides have been revised.
          Details of the revisions are contained within each document:
          <ul class="list-disc ml-8">
            <li>Payment</li>
            <li>Environmental Requirements, Supplier</li>
            <li>Packaging Guidelines for Production Parts, Asia
              Pacific
            </li>
            <li>Packaging Terms and Condition (EU1750) Europe</li>
            <li>Social Responsibility and Anti-Corruption
              Requirements
            </li>
            <li>Global Export Market Packaging Terms and Conditions</li>
            <li>MP&L Requirements for Materials Management &
              Communication, North America
            <li>FCSD Service Parts Supplier Guide- Global</li>
            <li>News Release & Publicity Guidelines, Supplier</li>
            <li>Shipments to Ford of Europe for Production Part
              Plants and Export Distribution Centres
            </li>
            <li>Packaging Guidelines for Production Parts, North
              America (MFG 1750NA)
            </li>
            <li>Enhanced Supplier Tool Tagging/Marking Requirements
              and Revised Payment Approval Process
            </li>
            <ul class="list-circle ml-8">
              <li>Acceptability or Unacceptability of Tooling Costs
                (download file for examples)
              </li>
            </ul>
            <li>Updated Indirect Terms and Conditions to reflect
              addition of Health care and Indirect Solutions
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>8/01/2017</td>
        <td>The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Payment</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>11/01/2017</td>
        <td>The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Label Standards, Shipping Parts/Identification
              (Global)
            </li>
            <li>Payment</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>12/01/2017</td>
        <td>The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Payment (Changed to Indirect Payment Schedule and Production Payment Schedule</li>
            <li>PII Supplement</li>
            <li>Enhanced Supplier Tool Tagging/Marking Requirements and Revised Payment Approval Process</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>02/01/2018</td>
        <td>The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>GTC Supplemental Terms - Australia</li>
            <li>Indirect Solutions Terms and Conditions</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>03/01/2018</td>
        <td>The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Added Spanish Supplemental Terms in Spanish and English</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>05/01/2018</td>
        <td>The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Environmental Requirements, Supplier</li>
            <li>Social Responsibility and Anti-Corruption
              Requirements
            </li>
            <li>Delivery Terms & Customs Guide for Production Part
              Sourcing (FoE)
            </li>
            <li>Packaging Terms and Condition (EU1750) Europe</li>
            <li>Packaging Terms & Conditions (Global 1750 Export) -
              All Regions
            </li>
            <li>Supplier Requirements for U.S. Government
              Subcontractors Supplier-Guide
            </li>
            <li>Packaging Guidelines for Production Parts, North
              America (MFG 1750NA)
            </li>
            <li>Shipments to Ford of Europe for Production Part
              Plants and Export Distribution Centres
            </li>
            <li>Tax</li>
            <li>Customs, Supplier Customs Import & Export Guide
              <ul class="list-circle ml-8">
                <li>Canada</li>
                -
                <li>United States</li>
              </ul>
            </li>
            <li>Ford Parts Brand Protection</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>06/01/2018</td>
        <td>The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Production Payment Schedule
            <li>Social Responsibility and Anti-Corruption Requirements</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>08/01/2018</td>
        <td>The following Supplier Guide have been updated
          <ul class="list-disc ml-8">
            <li>Enhanced Supplier Tool Tagging/Marking Requirements and Revised Payment Approval Process
            <li>Supplier Instructions for Tooling Progress Payments
              (Located in the Supplier Guide above)
            <li>FCSD Service Parts Guide - Global
            <li>Tooling Purchase and Audit Supplier-Guide</li>
            <ul class="list-circle ml-8">
              <li>Acceptability or Unacceptability of Tooling Costs</li>
            </ul>
          </ul>
        </td>
      </tr>
      <tr>
        <td>09/01/2018</td>
        <td>The following GTC Supplier-Guides have been revised.
          Details of the revisions are contained within each document:
          <ul class="list-disc ml-8 mt-2">
            <li>Supplier Instructions for Tooling Progress Payments (Located in Enhanced Supplier Tool Tagging/Marking
              Requirements and Revised Payment Approval Process Supplier Guide)
            </li>
            <li>FCSD Service Parts Guide - Global</li>
            <li>Label Standards, Shipping Parts/Identification - Global</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>10/01/2018</td>
        <td>The following GTC Supplier-Guides have been revised. Details of the revisions are contained within each
          document:
          <ul class="list-disc ml-8 mt-2">
            <li>Production Payment Schedule</li>
            <li>Indirect Payment Schedule</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td valign="top">11/01/2018</td>
        <td>The following GTC Supplier-Guides have been revised.
          Details of the revisions are contained within each document:
          <ul>
            <li>Advertising Guidelines, Supplier
            <li>News Release & Publicity Guidelines, Supplier
          </ul>
        </td>
      </tr>
      <tr>
        <td>5/01/2019</td>
        <td>The following GTC Supplier-Guides have been revised. Details of the revisions are contained within each
          document:
          <ul class="list-disc ml-8">
            <li>Dispute Resolution, GTC - Non-Production</li>
            <li>Environmental Requirements, Supplier</li>
            <li>FCSD Service Parts Guide - Global</li>
            <li>Indirect Payment Schedule</li>
            <li>Indirect Solutions Terms and Conditions</li>
            <li>News Release & Publicity Guidelines, Supplier</li>
            <li>Packaging Terms and Conditions (EU1750) - Europe</li>
            <li>Packaging Terms and Conditions (Global 1750 Export) - All Regions</li>
            <li>Production Payment Schedule</li>
            <li>Shipments for Prod Parts to FoE Plants & Export Distribution Centres</li>
            <li>Supplier Responsibility and Anti-Corruption Requirements</li>
            <li>Personally Identifiable Information (PII) Supplemental Terms and Conditions</li>
            <li>Indirect Payment Schedule and Production Payment Schedule, effective date was updated from May 2019 to
              July 2019 due to legal reasons
            </li>
            <li>Social Responsibility and Anti-Corruption Requirements</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>06/01/2019</td>
        <td>The following GTC Supplier-Guides have been revised.
          Details of the revisions are contained within each document:
          <ul class="list-disc ml-8">
            <li>Software as a Service (SaaS) Terms and Conditions</li>
            <li>Ford Motor Credit Supplemental Terms and Conditions (U.S. ONLY)</li>
            <li>Insurance Supplemental Terms</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>12/01/2019</td>
        <td>The following GTC Supplier-Guides have been revised. Details of the revisions are contained within each
          document:
          <ul class="list-disc ml-8">
            <li>Indirect Payment Schedule</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>06/01/2020</td>
        <td>The following GTC Supplier Guides have been revised. Details of the revisions are contained within each
          document
          (highlighted in yellow). Administrative changes (such as updated contact information or links) have been made
          to certain
          Supplier Guides, but are not included in the list below:
          <ul class="list-disc ml-8">
            <li>Customs, Supplier Customs Import & Export Guide
              <ul class="list-circle ml-8">
                <li>Canada</li>
                <li>Mexico</li>
                <li>United States</li>
              </ul>
            <li>Environmental Requirements, Supplier</li>
            <li>FCSD Service Parts Guide - Global</li>
            <li>Label Standards, Shipping Parts/Identification (Global)</li>
            <li>MP&L Requirements for Materials Management & Communication, North America</li>
            <li>Packaging Terms & Conditions (Global 1750 Export) - All Regions</li>
            <li>Packaging Terms and Conditions (EU1750) - Europe</li>
            <li>Packaging Guidelines for Production Parts - North America</li>
            <li>Social Responsibility and Anti-Corruption Requirements</li>
            <li>Tooling Purchase and Audit Supplier Guide
              <ul class="list-circle ml-8">
                <li>Including: Enhanced Supplier Tool Tagging/Marking Requirements and Revised Payment Approval
                  Process
                </li>
              </ul>
            <li>Supplier Requirements for U.S. Government Subcontractors Supplier-Guide</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>10/09/2020</td>
        <td>The following GTC Supplier Guide has been revised. Details of the revisions are contained within each
          document (highlighted in yellow):
          <ul class="list-disc ml-8">
            <li>Production Payment Schedule - Effective October 1, 2020</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>11/21/2020</td>
        <td>The following GTC Supplier Guide has been revised. Details of the revisions are contained within each
          document
          (highlighted in yellow):
          <ul class="list-disc ml-8">
            <li>Supplier Requirements for U.S. Government Subcontractors Supplier Guide - Effective November 21, 2020
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>12/1/2020</td>
        <td>The following GTC Supplier Guide has been revised. Details of the revisions are contained within each
          document (highlighted in yellow):
          <ul class="list-disc ml-8">
            <li>Indirect Payment Schedule
          </ul>
        </td>
      </tr>
      <tr>
        <td>1/21/2021</td>
        <td>The Supplier Requirements for U.S. Government Subcontractors Supplier Guide has been revised to remove
          sections referring to Executive Order 13950 as it is no longer in effect as of January 21, 2021.
        </td>
      </tr>
      <tr>
        <td>4/7/2021</td>
        <td>Updates to the Ford Production Purchasing Global Terms & Conditions (PPGTCs) have been posted, with updates
          effective
          July 1, 2021. Ford PPGTCs rev. Jan 1, 2004 will remain posted until July 1, 2021, when new terms become
          effective.
        </td>
      </tr>
      <tr>
        <td>5/3/2021</td>
        <td>PPGTC Supplier Guides have been updated. Details of the revisions are contained within each document.</td>
      </tr>
      <tr>
        <td>9/1/2021</td>
        <td>Indirect Only: Supplier Travel & Expense Policy (NA Only) posted, effective September 1, 2021.</td>
      </tr>
      <tr>
        <td>12/8/2021</td>
        <td>Indirect Only: Ford Healthcare Terms and Conditions for Insured Services -- Fully Insured Plans (ISA) and
          Ford
          Healthcare Terms and Conditions for Administrative Services Only -- Self Insured Plans (ASA) have been
          updated.
        </td>
      </tr>
      <tr>
        <td>04/01/2022</td>
        <td>Indirect Only: The Indirect Solutions and SaaS Global Terms have been updated to include new privacy
          language to
          address changes in the law in California, Colorado and Virginia. They have also been updated to change the
          reference
          for Ã¢ÂÂWeb GuidesÃ¢ÂÂ to Ã¢ÂÂSupplier GuidesÃ¢ÂÂ. Additional language was added to clarify that an
          assignment by a Seller
          does not release the Seller from liability.
        </td>
      </tr>
      <tr>
        <td>05/02/2022</td>
        <td>The following GTC Supplier Guides have been revised. Details of the revisions are contained within each
          document
          (highlighted in yellow). Administrative changes (such as updated contact information or links) have been made
          to certain
          Supplier Guides, but are not included in the list below:
          <ul class="list-disc ml-8">
            <li>Claims
            <li>Customs, Supplier Customs Import & Export Guide
              <ul class="list-circle ml-8">
                <li>Canada
                <li>Mexico
                <li>United States
              </ul>
            <li>Environmental Requirements, Supplier
            <li>FCSD Service Parts Guide - Global
            <li>Label Standards, Shipping Parts/Identification
              (Global)
            <li>Packaging Terms & Conditions (Global 1750 Export) -
              All Regions
            <li>Packaging Terms and Conditions (EU1750) - Europe
            <li>Shipments for Prod Parts to FoE Plants & Export
              Distribution Centres
            <li>Shipping and Routing Guide for NA Assembly,
              Manufacturing and Prototype Plants
            <li>Social Responsibility and Anti-Corruption
            <li>Supply Chain Inclusivity and Diversity
          </ul>
        </td>
      </tr>
      <tr>
        <td>05/11/2022</td>
        <td>The following documents have been updated:<br>
          <ul class="list-disc ml-8">
            <li>Global Terms and Conditions for Executive Search
              Services (Indirect Only)
            <li>FGT30, Rev. 12/07 has been translated to Korean
              (Indirect Only)
            <li>Personal Information (PI) Supplement
              (Direct/Production and Indirect)
          </ul>
        </td>
      </tr>
      <tr>
        <td>07/14/2022</td>
        <td>The following documents have been updated:<br>
          <ul class="list-disc ml-8">
            <li>HEALTHCARE TERMS AND CONDITIONS FOR HEALTHCARE AND RELATED SERVICES ISA (Fully Insured) (Indirect
              Only)
            </li>
            <li>HEALTHCARE TERMS AND CONDITIONS FOR HEALTHCARE AND RELATED SERVICES ASA (Self Insured) (Indirect Only)
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>5/1/2023</td>
        <td>Two Supplier Guides have been added.<br>
          <ul class="list-disc ml-8">
            <li>Digital Manufacturing Data</li>
            <li>Value Chain Transparency</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>5/1/2023</td>
        <td>Two Regional Supplements have been added.
          <ul class="list-disc ml-8">
            <li>UK Purchasing Tax Supplement (UK Only)
            <li>ERISA Supplement (US Only)
          </ul>
          <p>Update of Healthcare Terms and Conditions for Healthcare and Related Services ASA</p>
          <p>Update of Healthcare Terms and Conditions for Healthcare and Related Services ISA</p>
        </td>
      </tr>
      <tr>
        <td>7/1/2023</td>
        <td>
          The following GTC Supplier Guides have been revised. Details of the revisions are contained within each
          document:
          <ul class="list-disc ml-8">
            <li>Autocurrency Overview & Operating Policy</li>
            <li>Automaterials: Market-Based Raw Material Commodity Price Management System, Overview and Operating
              Policy
            </li>
            <li>Chronic Supplier Improvement Process (CSIP)</li>
            <li>Environmental Requirements, Supplier</li>
            <li>FCSD Service Parts Guide â Global</li>
            <li>MP&L Requirements for Materials Management & Communication, North America</li>
            <li>News Release & Publicity Guidelines, Supplier</li>
            <li>Packaging Guidelines for Production Parts, China</li>
            <li>Packaging Guidelines for Production Parts - North America</li>
            <li>Packaging Terms and Conditions (EU1750) â Europe</li>
            <li>Production Purchase Order Non-Renewal Process</li>
            <li>Social Responsibility and Anti-Corruption Requirements</li>
            <li>Tooling Purchase and Audit</li>
            <li>Warranty Chargeback</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>7/1/2023</td>
        <td>
          <li>Update to the Ford Supplier Portal - UK Purchasing Tax Supplement (UK Only) has been added to the Ford
            Supplier Facing Portal
          </li>
          <li>Minor update of the Global Terms & Conditions for Indirect Solutions</li>
        </td>
      </tr>
      <tr>
        <td>10/01/2023</td>
        <td>
          <li>Minor updates to Healthcare Terms and Conditions for Healthcare and Related Services ASA (Self Insured)
            <ul class="list-disc ml-8">
              <li>Updates to Supplier Code of Conduct language to align with Ford Corporate guidelines</li>
              <li>Updates to Diversity and Inclusion language to align with Corporate guidelines</li>
              <li>Revised references to PII to PI (Personal Information) which is a broader tern relating to any
                identifiable information tied to a person
              </li>
              <li>Removed language allowing TPA to terminate without cause</li>
            </ul>
          <li>Minor update of the Healthcare Terms and Conditions for Healthcare and Related Services ISA (Fully
            Insured)
            <ul class="list-disc ml-8">
              <li>Updates to Working Conditions definition to align with corporate guidelines.</li>
              <li>Updates to Supplier Code of Conduct language to align with Ford Corporate guidelines.</li>
              <li>Updates to Supplier Diversity and Inclusion language to align with corporate guidelines.</li>
              <li>Removed language allowing Carrier to terminate without cause.</li>
              <li>Updated PI language to align with the California Consumer Privacy Act (CCPA)</li>
              <li>Revised references to PII to PI (Personal Information) which is a broader tern relating to any
                identifiable information tied to a person
              </li>
            </ul>
          <li>Minor update to the Indirect GTC Supplemental Terms – Australia to reflect legally required changes due to
            unfair contract terms laws in Australia.
          </li>
        </td>
      </tr>
      <tr>
        <td>01/04/2024</td>
        <td>
          <li>Administrative updates have been made to the Tax Supplier Guide for Production and Non-Production Goods
          </li>
        </td>
      </tr>
      <tr>
        <td>15/04/2024</td>
        <td>
          <li>
            The following GTC Supplier Guides have been revised. Details of the revisions are contained within each
            document:
            <ul class="list-disc ml-8 mt-2">
              <li>Advertising Guidelines, Supplier (Guide remove as the language was merged with News Release,
                Publicity & Advertising Guidelines, Supplier)
              </li>
              <li>Customs, Supplier Customs Import & Export Guide â€“ United States</li>
              <li>Delivery Terms & Customs Guide for Production Part Sourcing (FoE)</li>
              <li>Environmental Requirements</li>
              <li>Export Controls and Sanctions Compliance</li>
              <li>MP&L Requirements for Materials Management & Communication, North America</li>
              <li>New Model Programs Warehouse Shipping Instructions</li>
              <li>News Release, Publicity & Advertising Guidelines, Supplier</li>
              <li>Packaging Terms & Conditions (Global 1750 Export) - All Regions</li>
              <li>Packaging Terms and Conditions (EU1750) - Europe</li>
              <li>Packaging Guidelines for Production Parts - North America</li>
              <li>Packaging Guidelines for Production Parts, China</li>
              <li>Shipping and Routing Guide for NA Assembly, Manufacturing and Prototype Plants</li>
              <li>Social Responsibility and Anti-Corruption Requirements</li>
              <li>Supplier Requirements for U.S. Government Subcontractors</li>
              <li>Supply Chain Inclusivity and Diversity</li>
              <li>Tax</li>
              <li>Value Chain Transparency</li>
            </ul>
          </li>
        </td>
      </tr>
      <tr>
        <td>04/01/2024</td>
        <td>
          Minor Changes to adapt three types of GTCs
          <ul class="list-disc ml-8">
            <li>Chinese/Bilingual Addendum updated to consolidate the Chinese translation into the English version.</li>
            <li>Wording consistency changes to be in line with the non-production, Indirect Solutions and SaaS GTCs
              (i.e.,
              Web Guide to Supplier Guide), update cited provisions number based on current GTC versions (FGT30, Rev
              12/07, Indirect Solutions, Saas) expanded the GTCs scope to include the new Indirect GTC and SaaS GTC in
              addition to non-production GTC
            </li>
            <li>Defined PRC to exclude HK, Macau and TW region for this Addendum purpose (same as those under PPGTC
              Addendum)
            </li>
            <li>Clearly outline which document will take precedence when there is any inconsistency, the documents will
              be
              construed accordingly: (a) this Addendum; (b) GTCs (or Revised GTCs); (c) Supplier Guides (same as those
              under PPGTC Addendum)
            </li>
            <li>Replaced part of the Dispute Resolution section of GTCs with the version tailored for China; the
              Singapore
              International Arbitration Centre shall be selected if the Buyer is located outside of PRC, and China
              Internal Economic and Trade Arbitration Commission should be selected if Buyer is located in China; Sole
              arbitrator will be jointly appointed (same as those under PPGTC Addendum)
            </li>
            <li>Clarified that the minimum age of employment should be as permitted by relevant Chinese laws, rather
              than
              15 as required in GTC (same as those under PPGTC Addendum)
            </li>
            <li>Clause 9 added to re-emphasize compliance with law (similar to PPGTC section 35.01)</li>
            <li>Removed signature request for the addendum (same as those under PPGTC Addendum)</li>
          </ul>
        </td>
      </tr>
    </table>
  </div>
</div>
