<app-welcome></app-welcome>
<div class="about-container mt-3">
  <p class="heading">About this site: Production Global Terms & Conditions</p>
  <div class="mt-3 ml-2 mr-2">
    <p>Here is a brief description of the items available on this site via the menu shown on the left side of this
      page:</p>
    <p class="font-bold mt-4 mb-2">TERMS AND CONDITIONS</p>
    <ul class="list-disc ml-12">
      <li><a class="color" routerLink="/gtc/indirect/terms">Ford Global Terms and Conditions, FGT30, Rev. 12/07.</a></li>
      <li><a class="color" (click)="getPnpd027isaPdf()">Ford Healthcare Terms and Conditions for Insured Services -- Fully Insured Plans
        (ISA)</a></li>
      <li>
        <a class="color" (click)="getPnpd027asaPdf()">Ford Healthcare Terms and Conditions for Administrative Services Only -- Self Insured Plans
          (ASA)</a>
      </li>
      <li><a class="color" (click)="getPnpd224Pdf()">Indirect Solutions Terms and Conditions</a></li>
      <li><a class="color" (click)="getPnpd229Pdf()">Software as a Service (SaaS) Terms and Conditions</a></li>
    </ul>
    <div class="mt-3">
      <a class="indirect-heading" routerLink="/gtc/indirect/guides">GTC WEB-GUIDES</a>
    </div>
    <p>As explained in the GTCs, these guides list specific requirements for matters like packaging, shipping,
      warranty, Service Parts, taxes, environmental, Payment Terms, etc. GTC Web-Guides listed on this site are part
      of the Purchase Order and are binding on the Supplier and the Buyer.</p>
    <div class="mt-3">
      <a class="indirect-heading" routerLink="/gtc/indirect/communications">COMMUNICATIONS</a>
    </div>
    <p>Announcements and communications related to the Ford GTC's.</p>
    <div class="mt-3">
      <a class="indirect-heading" (click)="showPiiSupplementPdf()">PII Supplement</a>
    </div>
    <p>Personally Identifiable Information (PII) Supplement.</p>
    <div class="mt-3">
      <a class="indirect-heading" routerLink="/gtc/indirect/supplements">SUPPLEMENTS</a>
    </div>
    <p>Supplemental Terms and Conditions are sometimes used by the Buyer to address special product, local market or
      legal requirements.</p>
    <div class="mt-3">
      <a class="indirect-heading" routerLink="/gtc/indirect/history">HISTORY OF UPDATES</a>
    </div>
    <p class="mb-2">Chronological list of additions and document revisions to the Ford GTC website</p>
  </div>
</div>
