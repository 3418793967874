<app-common-header></app-common-header>
<div class="row">
  <div class="page-title">
    <b>Buyer Search :</b>
  </div>
</div>
<form [formGroup]="buyerForm" (ngSubmit)="onSubmit()">
  <div class="mt-2 ml-4 container">
    <div>
      <p class="heading">Buyer Code</p>
      <input type="text" class="input pl-2 pr-2" formControlName="buyerCode"/>
    </div>
    <div>
      <p class="heading">Buyer Name</p>
      <input type="text" class="input pl-2 pr-2" formControlName="buyerName"/>
    </div>
    <div>
      <p class="heading">Commodity Description</p>
      <input type="text" class="input pl-2 pr-2" formControlName="commodityDesc"/>
    </div>
    <button type="submit" class="block mt-2 xml-button" [disabled]="buyerForm.invalid">Display XML</button>
  </div>
</form>
