import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppLookupService } from '../service/app-lookup.service';
import { AppLookup } from 'src/app/models/perl-migration-web/app-lookup/app-lookup.model';
import { error } from 'console';

@Component({
  selector: 'app-app-lookup',
  templateUrl: './app-lookup.component.html',
  styleUrls: ['./app-lookup.component.scss']
})
export class AppLookupComponent implements OnInit{

  appList:String[] = [];
  appLookup!: AppLookup;

  formHidden: boolean = false;
  securityTwo: boolean = false;
  siteSpecificSwitch: boolean = true;

  appLookupForm: FormGroup;

  constructor(private fb: FormBuilder, private apiService: AppLookupService){
    this.appLookupForm = this.fb.group({
      selectedApp:['',Validators.required],
      applicationName:['',Validators.required]

    })
  }
  ngOnInit(): void {
    this.getAppList();
  }

  onSubmit(){
    const appName: String = this.appLookupForm.get('selectedApp')?.value;
      this.formHidden=true;
      this.getAppDetails(appName);
  }

  handleSelection(){
      const input:String= this.appLookupForm.get('applicationName')?.value;
      const matchingOptions = this.appList.find(app =>
        app.toUpperCase().startsWith(input.toUpperCase())
      );

      if(matchingOptions && input.length!=0){
        this.appLookupForm.get('selectedApp')?.setValue(matchingOptions);
      }
      else{
        this.appLookupForm.get('selectedApp')?.setValue(null);
      }
  }

  redirect(event:Event){
    event.preventDefault();
    this.appLookupForm.reset();
    this.formHidden=false;
  }

  getAppList(){
    this.apiService.getAppList().subscribe((data: String[])=>{
      this.appList = data;
    },error =>{
      alert(JSON.stringify(error));
    })
  }

  getAppDetails(appName: String){
    this.apiService.getAppDetails(appName).subscribe((data:AppLookup)=>{
      this.appLookup = data;
      if(this.appLookup.fsnSecurityLevelRqmnts=='1'){
        this.appLookup.fsnSecurityLevelRqmnts = 'Open to the public';
      }
      if(this.appLookup.fsnSecurityLevelRqmnts=='2'){
        this.securityTwo=true;
        if(this.appLookup.siteSpecificSwitch=='N'){
          this.siteSpecificSwitch=false;
        }
        this.appLookup.fsnSecurityLevelRqmnts = 'Restricted to subset of suppliers and users by token';
      }else{
        this.appLookup.fsnSecurityLevelRqmnts = 'Available to users that have the FSP service';
      }

    }, error =>{
      alert(JSON.stringify(error));
    })
  }


}
