<app-common-header></app-common-header>
<div class="container-fluid">
    <div class="row">
        <div class="page-title">
            <b>EVEREST Supplier Token Status Application</b>
        </div>
    </div>

    <div class="row">
        <div class="col-9">
            <div class="row font1">
                <b>
                    &nbsp;&nbsp;Upload Your File for Processing
                </b>
            </div>
            <div class="row">
            </div>
            <div class="row font1">
                    <b>Click the "Browse" button to select a file from your computer and<BR>
                        Please enter your Email account to which the resulting report will be sent
                    </b>
            </div>
            <div class="row">
                <div>
                    <form [formGroup]="uploadFileGroup" (ngSubmit)="onSubmit()">
                        <div class="font2">
                            <input class="input-file" type="file" (change)="onFileUpload($event)">
                        </div>
                        <div class="font3">
                            <label for="email"><b>E-mail Address : &nbsp;</b></label>
                            <input type="text" formControlName="email" class="input-text">
                            <br><span id="fileError" class="text-danger" *ngIf="uploadFileGroup.controls['email'].invalid && 
                            uploadFileGroup.controls['email'].dirty">Please provide email id to receive report</span>
                        </div>
                        <div class="font3">
                            <label for="continue"><b>Click the upload file button to continue : &nbsp;</b></label>
                            <button type="submit" class="btn btn-primary btn-sm" [disabled]="uploadFileGroup.invalid">upload file </button>&nbsp; &nbsp;
                            <button type="reset" class="btn btn-primary btn-sm">start over </button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row">
                <div>
                    <hr>
                </div>
            </div>
            <div class="row">
                <div class="text-danger font4">
                    <b>You must upload a file which contains a list of 5-character GSDB site codes to tell if 
					specific FSP users underneath the site code possess the eVEREST Token. The file must meet the following requirements:</b>
                </div>
                <div>
                   <ul class="ul">
                    <li>
                         <b>A plain ASCII text file. This includes .CSV and .TXT files. Microsoft Excel files 
                        (.xls) or Microsoft Word Documents (.doc) <i>cannot</i> be accepted. If you have your file in MS Excel format, 
                        save it as a .TXT or .CSV file first using the Excel &quot;Save As&quot; 
                        command. Your file should be saved as an ASCII formatted file. <u><i>Do not use Unicode format
                        as this will introduce strange characters into your uploaded file and your results may never get processed
                        or the results file will be strange or incorrect</i></u></b>
                    </li>
                    <li>
                       <b>The first 5 characters on each line must contain the GSDB site code</b>
                    </li>
                    <li>
                        <b>Each site code needs to be on its own line</b>
                    </li>
                    <li>
                        <b>Information can follow the 5-character site code on the same line, but will be ignored.<br>
                        </b>
                    </li>
                    <li>
                        <b> Contact the FSP Administrator (<a href="mailto:fsp@ford.com">fsp@ford.com</a>) if you have any questions about this process
                    </b>
                    </li>
                   </ul>
                </div>
            </div>
            <div class="row">
                <div class="link-text">
                    <a routerLink = "/perlMigrationWeb/everstTokenLookup"><u><b>Back to Main Page</b></u>
                    </a>
                </div>
            </div>
        </div>
            

    </div>

</div>
