import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";

@Injectable({
    providedIn:'root',
})
export class UserAccessReviewGuard implements CanActivate{
    constructor(private router:Router){}
    canActivate(): boolean{
            const hasAgreed = localStorage.getItem('userAccessAgreement');
            if(hasAgreed){
                return true;
            }else{
                this.router.navigate(['/PAARS/userAccessReview/userAgreement']);
                return false;
            }
    }
}
