<app-gsdb-online-header></app-gsdb-online-header>
<div class="flex items-center justify-center mt-8">
  <div class="complete-container">
    <p class="text-center mb-0">You have already registered for GSDB ONLINE.</p>
    <p class="text-center mt-0">Thank You.</p>
    <p class="text-center mt-4 mb-0">Questions about GSDB Online?</p>
    <p class="text-center mt-0 mb-0">Contact <span class="text-decoration-underline">gsdbweb@ford.com</span> or access
      <a routerLink="/support" class="nav-link">Ford Help Desk Support</a></p>
    <p class="text-center mt-0">The customer support center is available 24hours each day.</p>
  </div>
</div>
