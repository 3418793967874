<div class="communications-container">
  <p class="heading pl-1">Communications - Production</p>
  <div>
    <table class="m-2 table-container" border="1" cellpadding="3" cellspacing="0">
      <tr>
        <th>Effective Date</th>
        <th>Document Name</th>
        <th>Language</th>
        <th>Contact Name</th>
        <th>Description</th>
      </tr>
      <tr>
        <td>05/02/2021</td>
        <td><a class="color"  (click)="showCommunicationPdf()">Supplier Announcement - 2022 Annual Supplier Guide
          Updates</a>
        </td>
        <td>English</td>
        <td>Your Buyer</td>
        <td>2022 Annual Supplier Guide Updates</td>
      </tr>
    </table>
  </div>
</div>
