import { Component } from '@angular/core';

@Component({
  selector: 'app-gsdb-reg-inprocess',
  templateUrl: './gsdb-reg-inprocess.component.html',
  styleUrls: ['./gsdb-reg-inprocess.component.scss']
})
export class GsdbRegInprocessComponent {

}
