<div>
  <div class="content-container">
    <div class="flex">
      <img src="./assets/ford.png" alt="Ford" class="ford-icon"/>
      <div class="m-auto text-center">
        <p class="heading mb-0">Purchasing & Associated Systems Annual Access Review Home Page</p>
        <p class="mt-0 mb-0 font-size-14 font-bold" *ngIf="date !== ''">(Access status as of {{date | date:'MMM d, y'}})</p>
      </div>
    </div>
    <div class="inner-container">
      <p class="font-size-12 text-center mb-0 pt-2">This review is for <b>VPP or MPP(CMMS), CPARS, eRMA, FPC and
        PartSpec/Boss90.</b></p>
      <p class="font-size-12 text-center mt-0 mb-0">You may receive other review notifications for other applications,
        tokens, FSP IDs, etc; you must complete each review as they pertain to different security and/or access
        levels.</p>
      <p class="font-size-12 font-bold mt-1 mb-0">Approval of users indicates you accept responsibility for the access
        you are authorizing including the user's ability to appropriately and effectively utilize the access they have
        been granted.</p>
      <p class="font-size-12 font-bold mt-0">Audit requirements stipulate if there are any changes to the user's status
        prior to the next annual access review, the existing user access procedures for each system must be
        followed.</p>
      <p class="font-size-12 mt-3 mb-1">Clicking 'Continue' indicates acceptance of the statements above and will allow
        you
        to complete the Purchasing Annual Access review.</p>
      <p class="font-size-12 font-bold mt-0 mb-1">Critical Information:</p>
      <ul class="list-disc font-size-12">
        <li>If a user should keep their system access displayed click 'Yes'. If their access should be removed click
          'No'.
        </li>
        <li>If you are not the Corporate Security Administrator (CSA) that should review these records, click the 'Not
          CSA' check box and select the Covisint ID of the correct CSA for your company in the 'Replacement CSA' box.
        </li>
        <li>
          If you are not the correct CSA and cannot find a ment CSA, please send an email to AARCONT@ford.com with the
          correct CSA's name.
        </li>
        <li>Please complete the reassignment of user records to another CSA as soon as possible so that the newly
          identified CSA has significant time to complete the review.
        </li>
        <li>If pop-ups are blocked by your browser, you will not receive the 'Completed' message even though you have
          completed the review.
        </li>
        <li>
          To ensure that you have completed all the user records, please click the 'Save' button and then the
          'Uncompleted' button. If any records are not complete they will be displayed.
        </li>
        <li>Please make sure to scroll to the end of the list, review every line and select 'Yes', 'No' or 'Not CSA' for
          each user.
        </li>
      </ul>
      <div class="font-size-12 font-bold"> Click
        <button class="continue-btn ml-1 mr-1" (click)="openCsaRecords()">Continue</button>
        to complete the Annual Access User review.
      </div>
      <p class="font-size-12 font-bold mt-3 mb-1">System Descriptions:</p>
      <ul class="list-disc font-size-12">
        <li>VPP or MPP(CMMS) - MP&L System for tracking part quality and PPAP status</li>
        <li> CPARS (ims5 only) - Non-Production Requisitioning and Purchasing System</li>
        <li> eRMA - Raw Materials Purchasing System</li>
        <li> FPC - Finance Productivity Center (GIMMIE Reports)</li>
        <li> Part Spec/BOSS90 - Non-Production Inventory Item Part Specification/Blanker Order System</li>
      </ul>
      <p class="font-size-12 font-bold mt-3 mb-1">Helpful Hints:</p>
      <ul class="list-disc font-size-12">
        <li>You must use the scroll bar on the right side of the screen or the page up and page down keys to see all of
          your users.
        </li>
        <li> Do not close your browser using the X in the upper right hand corner of the screen before clicking [Submit]
          or [Save] or you will lose all of your changes.
        </li>
        <li>Click [Save] to save your changes and remain on the screen. If you click [Save] you will be taken back to
          the top of the page.
        </li>
        <li> Click [Submit] to save and submit your changes. After clicking [Submit] you will receive a message that you
          have completed your review or that you have partially completed review. Note: If your browser pop-ups are
          blocked, you will not receive a 'Completed' message.
        </li>
        <li>The review will be available for updates during the entire annual access review process.</li>
        <li>You will receive an email every time another CSA reassigns a user to you.</li>
      </ul>
    </div>
  </div>
</div>
