<app-app-launch-header></app-app-launch-header>
<div class="container-fluid">
    <div class="row">
        <div class="col-12 page-title">
           <b> Ford Application Information Tool</b><br>
        </div>
    </div>

    <div class="row form-text" [hidden]="formHidden">
        <div class="col-12">
            <form [formGroup]="appLookupForm" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-5">
                        <label>
                            Please input the beginning characters of the Application Name:
                        </label>
                    </div>
                    <div class="col-5">
                        <input type="text" formControlName="applicationName" (keyup)="handleSelection()" class="input-text">
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-5">
                        <label>
                            And pick up a full Application Name from the list below.<br>
                            Keep in mind that this list may contain some applications that <br>are not on the Ford Supplier Portal.
                        </label>
                    </div>
                    <div class="col-5">
                        <select name="selectedApp" formControlName="selectedApp" class="select-text" size="5">
                            <option *ngFor="let fspApp of appList" [value]="fspApp">{{fspApp}}</option>
                        </select>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-9 btn-style">
                        <button type="submit" class="btn btn-primary btn-sm btn-size" [disabled]="appLookupForm.invalid">submit Form!</button>&nbsp; &nbsp;
                        &nbsp; &nbsp; <button type="reset" class="btn btn-primary btn-sm btn-size">reset Form!</button>
                    </div>
                </div><br>
                <div style="width: 80%;">
                    <hr>
                </div>
                <div class="row">
                    <div class="col-9">
                        <ul class="help-text text-danger">
                            <li> &nbsp; This tool is for the purpose of application specific escalations to Ford.</li>
                            <li> &nbsp; If there is a problem with this tool, or you find that some information is out of date, please contact mthirum2@ford.com.</li>
                        </ul>
                    </div>
                </div><br>
            </form>
        </div>
    </div>

    <div class="row spacing" [hidden]="!formHidden" *ngIf = "appLookup">
        <div class="col-12 table-padding">
                <table class="table1">
                    <tr>
                        <td class="td"><b>Application Name: </b></td>
                        <td class="td">{{appLookup.applicationName}}</td>
                    </tr>
                    <tr>
                        <td class="td"><b>URL: </b></td>
                        <td class="td">{{appLookup.prodURL}}</td>
                    </tr>
                    <tr>
                        <td class="td"><b>Security: </b></td>
                        <td class="td">{{appLookup.fsnSecurityLevelRqmnts}}</td>
                    </tr>
                    <tr *ngIf="securityTwo">
                        <td class="td"><b>Ford Token Name: </b></td>
                        <td class="td">{{appLookup.tokenName}}</td>
                    </tr>
                    <tbody *ngIf="securityTwo">
                        <tr *ngIf="siteSpecificSwitch">
                            <td class="td"><b>Site Specific: </b></td>
                            <td class="td">Yes</td>
                        </tr>
                        <tr *ngIf="!siteSpecificSwitch">
                            <td class="td"><b>Site Specific: </b></td>
                            <td class="td">No</td>
                        </tr>
                    </tbody>
                    <tr>
                        <td class="td"><b>FAQ: </b></td>
                        <td class="td">None</td>
                    </tr>
                    <tbody *ngIf="securityTwo">
                        <tr *ngIf="siteSpecificSwitch">
                            <td class="td"><b>Site Specific: </b></td>
                            <td class="td">Yes</td>
                        </tr>
                        <tr *ngIf="!siteSpecificSwitch">
                            <td class="td"><b>Site Specific: </b></td>
                            <td class="td">No</td>
                        </tr>
                    </tbody>
                    <tr>
                        <td class="td"><b>Steps before Escalation: &nbsp; </b></td>
                        <td class="td">None</td>
                    </tr>
                    <tr>
                        <td class="td"><b>Escalation process: </b></td>
                        <td class="td">{{appLookup.helpDeskContact}}</td>
                    </tr>
                </table><br>

                <p>&nbsp; &nbsp; &nbsp; &nbsp; <a href="#" (click)="redirect($event)">
                    <b>Click Here</b></a>  &nbsp;to fetch details for another application name.
                </p>
        </div>
    </div>
</div>
