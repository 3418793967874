<div>
  <div class="content-container">
    <div class="flex">
      <img src="./assets/ford.png" alt="Ford" class="ford-icon"/>
      <p class="heading m-auto">Purchasing & Associated Systems Annual Access Review Page</p>
    </div>
    <div class="flex justify-center items-center">
      <p class="csa-btn home-btn" routerLink="/PAARS/userHierarchy/home" (click)="home()">Home Page</p>
    </div>
    <div class="inner-container">
      <div class="flex justify-center">
        <div class="mr-2">
          <button class="csa-btn" (click)="submitRecords()">SUBMIT your responses and exit the screen</button>
        </div>
        <div class="ml-2 mr-2">
          <button class="csa-btn" (click)="saveRecords()">SAVE your responses and remain on the screen</button>
        </div>
        <div class="ml-2">
          <button class="csa-btn" (click)="viewUncompletedRecords()">View UNCOMPLETED records</button>
        </div>
      </div>
      <div>
        <i class="font-size-12">To SEARCH for an ID, supplier code and/or system in your records use the search boxes
          provided and click Search.</i>
      </div>
      <form class="font-size-12" [formGroup]="searchGroup">
        <label>CDS ID / FSP ID</label> <input type="text" class="input ml-2 mr-2 mb-1" formControlName="fspId"/>
        <label>User ID / RACF ID </label> <input type="text" class="input ml-2 mr-2 mb-1" formControlName="racfId"/>
        <label>Suplr Code </label> <input type="text" class="input ml-2 mr-2 mb-1" formControlName="suplrCode"/>
        <label>System </label> <input type="text" class="input ml-2 mr-2 mb-1" formControlName="system"/>
        <button class="csa-btn mb-1 mr-4" (click)="filterRecordsBySearch()">Search</button>
        <label class="ml-2">To redisplay ALL RECORDS click on the Search button.</label>
      </form>
    </div>
    <p class="font-size-12 font-bold mt-2 mb-2">The records assigned to you for review are displayed below. Please
      select Yes,
      No or Not CSA for each record.</p>
    <form [formGroup]="csaDetails" class="mt-6 form-container">
      <table class="content-align table font-size-12">
        <thead>
        <tr>
          <th colspan="2" class="table-th">
            <p class="m-0">&nbsp;Approve&nbsp;</p>
            <span><td>Yes&nbsp;</td></span>
            <span><td>&nbsp;No</td></span>
          </th>
          <th class="table-th">
            <div>Not CSA</div>
          </th>
          <th class="table-th">
            <div>Replace CSA (Covisint ID)</div>
          </th>
          <th class="table-th">
            <div>CDS ID / FSP ID</div>
          </th>
          <th class="table-th">
            <div>User ID / RACFID</div>
          </th>
          <th class="table-th">
            <div>Name</div>
          </th>
          <th class="table-th">
            <div>Suplr Code</div>
          </th>
          <th class="table-th">
            <div>System</div>
          </th>
          <th class="table-th">
            <div>Div Plt/Loc</div>
          </th>
          <th class="table-th">
            <div>JobFunction</div>
          </th>
          <th class="table-th">
            <div>System Access</div>
          </th>
          <th class="table-th">
            <div>EmailAddress</div>
          </th>
        <tr>
        </thead>
        <tbody formArrayName="records" *ngFor="let user of records.controls;let i=index;">
        <tr [formGroupName]="i">
          <td class="td-border">
            <div><input type="radio" formControlName="approve" value="Y" (change)="disableCSA(user)"></div>
          </td>
          <td class="td-border">
            <div><input type="radio" formControlName="approve" value="N" (change)="disableCSA(user)"></div>
          </td>
          <td class="td-border">
            <div><input type="radio" formControlName="approve" value="csa" (change)="enableCSA(user)"></div>
          </td>
          <td>
            <input class="input-text" formControlName="replaceCsa">
          </td>
          <td class="td-border">
            <div>{{ user.get('fspId')?.value }}</div>
          </td>
          <td class="td-border">
            <div>{{ user.get('racfId')?.value }}</div>
          </td>
          <td class="td-border">
            <div>{{ user.get('name')?.value }}</div>
          </td>
          <td class="td-border">
            <div>{{ user.get('suplrCode')?.value }}</div>
          </td>
          <td class="td-border">
            <div>{{ user.get('system')?.value }}</div>
          </td>
          <td class="td-border">
            <div>{{ user.get('divPlt')?.value }}</div>
          </td>
          <td class="td-border">
            <div>{{ user.get('jobFun')?.value }}</div>
          </td>
          <td class="td-border">
            <div>{{ user.get('systemAcc')?.value }}</div>
          </td>
          <td class="td-border">
            <div>{{ user.get('email')?.value }}</div>
          </td>
        </tr>
        </tbody>
        <tbody *ngIf="notReviewed">
        <tr>
          <td colspan="12" class="text-center font-bold">No Records Found</td>
        </tr>
        </tbody>
      </table>
    </form>
    <div class="row" *ngIf="!hasRecords && (notReviewed !== true)">
      <div class="col-12">
        <table class="no-records">
          <tr>
            <td class="no-records-padding">
              You do not have any records to approve.<br>
              Please Exit to leave the application.<br><br>
              <button type="submit" class="btn xml-button" (click)="exit()">
                Exit
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
