<div class="summary-container">
  <p class="heading pl-1">GTC Change Summary & FAQ's - Production</p>
  <div>
    <table class="m-2 table-container" border="1" cellpadding="3" cellspacing="0">
      <tr>
        <th>Document Release Date</th>
        <th>Document Name</th>
        <th>Language</th>
        <th>Contact Name</th>
      </tr>
      <tr>
        <td>9/30/2004</td>
        <td><a class="color" (click)="getPPGTCFAQPdf()">2004 PPGTC Supplier Frequently Asked Questions (FAQ's)</a></td>
        <td>English</td>
        <td>Your Buyer</td>
      </tr>
      <tr>
        <td>1/1/2004</td>
        <td><a class="color" (click)="getMajorChangesSummeryPdf()">2004 PPGTC GTC Major Change Summary</a></td>
        <td>English</td>
        <td>Your Buyer</td>
      </tr>
      <tr>
        <td>4/19/2021</td>
        <td><a class="color" (click)="getKeyChangesPdf()">July 2021 PPGTCs - Key Changes</a></td>
        <td>English</td>
        <td>PURCHGTC@ford.com</td>
      </tr>
      <tr>
        <td>10/5/2021</td>
        <td><a class="color" (click)="getGtcFaqPdf()">2021 PPGTC Supplier Frequently Asked Questions (FAQ's)</a></td>
        <td>English</td>
        <td>PURCHGTC@ford.com</td>
      </tr>
    </table>
  </div>
</div>
