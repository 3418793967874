import { Component } from '@angular/core';

@Component({
  selector: 'app-gsdb-for-sitecode',
  templateUrl: './gsdb-for-sitecode.component.html',
  styleUrls: ['./gsdb-for-sitecode.component.scss']
})
export class GsdbForSitecodeComponent {

  errorPage: boolean =true;

}
