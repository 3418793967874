import { Component } from '@angular/core';

@Component({
  selector: 'app-user-access-review-instructions',
  templateUrl: './user-access-review-instructions.component.html',
  styleUrls: ['./user-access-review-instructions.component.scss']
})
export class UserAccessReviewInstructionsComponent {

}
