import {Component, inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {UserHierarchyService} from "../service/user-hierarchy.service";

@Component({
  selector: 'app-new-controller',
  templateUrl: './new-controller.component.html',
  styleUrls: ['./new-controller.component.scss']
})
export class NewControllerComponent {
  readonly dialogRef = inject(MatDialogRef<NewControllerComponent>);
  newControllerForm: FormGroup;

  constructor(private fb: FormBuilder, private userHierarchyService: UserHierarchyService) {
    this.newControllerForm = this.fb.group(({
      newController: ['', [Validators.required]]
    }))
  }

  replace() {
    this.userHierarchyService.setNewController(this.newControllerForm.value.newController)
    this.dialogRef.close();

  }

  cancel() {
    this.dialogRef.close();
  }

  displayUpperNcc() {
    this.newControllerForm.get("newController")?.setValue(this.newControllerForm.get("newController")?.value.toUpperCase())
  }
}
