import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PerlMigrationService {
  private url = environment.perlMigrationApiUrl

  constructor(private http: HttpClient) {
  }

  getGsdbHierarchyData(parentCode: String): Observable<any> {
    return this.http.get(this.url + '/api/v1/gsdbHeirarchy/parentCode/' + parentCode, {
      responseType: 'text', // Specify response type as text
      headers: new HttpHeaders
      ({
        'Accept': 'application/xml' // Optional: set appropriate headers
      })
    }).pipe(map(xmlString => this.parseXml(xmlString)));
  }

  private parseXml(xmlString: string): Document {
    const parser = new DOMParser();
    return parser.parseFromString(xmlString, 'application/xml');
  }

  getFordPeopleSearch(cdsId:String):Observable<string>{
    return this.http.get(this.url+'/api/v1/fordPeople/search/'+cdsId,{responseType:'text'});
  }

  getSupplierSearch(supplierId:String):Observable<string>{
    return this.http.get(this.url+'/api/v1/supplier/search/'+supplierId,{responseType:'text'});
  }
}
