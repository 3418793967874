import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AntiCorruptionService {
  private url = environment.gtcApiUrl

  constructor(private http: HttpClient) {
  }

  getAntiCorruptionEnglishPdf() {
    this.http.get(this.url + '/api/v1/files/download/Anti_Corruption_Supplement_PNPD225.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getAntiCorruptionChinesePdf() {
    this.http.get(this.url + '/api/v1/files/download/Anti Corruption Supplement Chinese.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getAntiCorruptionDutchPdf() {
    this.http.get(this.url + '/api/v1/files/download/Anti Corruption Supplement Dutch.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getAntiCorruptionFrenchPdf(){
   this.http.get(this.url + '/api/v1/files/download/Anti Corruption Supplement French.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
    const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
  }, error => {
    console.error('Error fetching PDF:', error);
  });
  }

  getAntiCorruptionGermanPdf() {
    this.http.get(this.url + '/api/v1/files/download/Anti Corruption Supplement German.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getAntiCorruptionPortuguesePdf(){
    this.http.get(this.url + '/api/v1/files/download/Anti Corruption Supplement Portuguese.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getAntiCorruptionRussianPdf() {
    this.http.get(this.url + '/api/v1/files/download/Anti Corruption Supplement Russian.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getAntiCorruptionSpanishPdf(){
    this.http.get(this.url + '/api/v1/files/download/Anti Corruption Supplement Spanish.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getAntiCorruptionRomanianPdf(){
    this.http.get(this.url + '/api/v1/files/download/Anti Corruption Supplement Romanian.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getAntiCorruptionThaiPdf() {
    return this.http.get(this.url + '/api/v1/files/download/Anti Corruption Supplement Thai.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getAntiCorruptionVietnamesePdf() {
    this.http.get(this.url + '/api/v1/files/download/Anti Corruption Supplement Vietnamese.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }
}
