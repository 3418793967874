<app-gsdb-online-header></app-gsdb-online-header>
<div class="body ml-8 mr-8 mt-8 mb-8">
  <p class="para-title pl-4"><b> GSDB Online Steps > <span class="intro"> Introduction</span> :: Nomination ::
    Indemnification</b></p>
  <p class="text-center font-size mb-0">Welcome to GSDB ONLINE: Executive Sponsor Information Screen</p>
  <p class="text-center warning mt-0">Your Authorization is Required - Access to Ford GSDB Online Application</p>
  <p class="font-size"> Please follow the 2 steps below and click <b>NEXT</b> to access the final registration form.</p>
  <form [formGroup]="gsdbForm" (ngSubmit)="onSubmit()">
    <div class="mb-0">
      <p class="inline-block mb-1">Step 1. Please enter your last name</p>
      <input type="text" class="input pl-2 pr-2 ml-4" formControlName="lastName"/>
    </div>
    <div>
      <p class="inline-block mt-0">Step 2. Please enter you PIN number </p>
      <input type="text" class="input pl-2 pr-2 ml-2" formControlName="pinNumber"/>
    </div>
    <div class="flex items-center justify-center next-container mb-4">
      <button type="submit" class="next-button" [disabled]="gsdbForm.invalid">NEXT</button>
    </div>
  </form>
  <p id="benefits" class="para-title pl-4 font-size">(top) <b>Benefits of GSDB Online:</b></p>
  <p class="font-size mt-0">The Global Supplier Database (GSDB) Online application allows users the ability to validate,
    update, and directly
    control the quality of their company data held by Ford. GSDB Online provides access to data that was previously
    unavailable without Ford intervention.</p>
  <p id="supplierInfo" class="para-title pl-4 font-size">(top) <b> GSDB Online Supplier Information:</b></p>
  <ul class="list-disc font-size">
    <li>Company address, status and key personnel contact information</li>
    <li>Bank routing details required to send Electronic Funds Transfer (EFT) payments</li>
    <li> Q1 Capability Standards for contract consideration</li>
    <li>Tax details to ensure accurate records and government reporting</li>
  </ul>
  <p id="security" class="para-title pl-4 font-size">(top) <b>Security and GSDB Online</b></p>
  <ul class="list-disc font-size">
    <li>Only authorized users may view or update company information.</li>
    <li>All updates to your company data will be recorded for quality assurance purposes.</li>
    <li>Notifications of changes to your company data are sent to a designated person in your company.</li>
  </ul>
  <p id="additionalInfo" class="para-title pl-4 font-size mt-4"><span class="font-size">(top)</span> <b> Additional
    Information</b>
  <ul class="list-disc font-size">
    <li>Registration is required before your company can be granted access to the GSDB Online application.</li>
    <li>There is <b>no cost</b> to your company to use GSDB Online.</li>
  </ul>
  <hr class="mt-4 mb-4">
  <div class="font-size">
    <p class="m-0 text-center">Questions about GSDB Online?</p>
    <p class="m-0 text-center">Contact <span class="text-decoration-underline">gsdbweb@ford.com</span> or access
      <a class="navigation" routerLink="/support">Ford Help Desk Support</a></p>
    <p class="m-0 text-center">The customer support center is available 24 hours each day.</p>
  </div>
</div>
