import {Component, OnInit} from '@angular/core';
import {UserAccessService} from "../service/user-access.service";

@Component({
  selector: 'app-user-access-review-systems',
  templateUrl: './user-access-review-systems.component.html',
  styleUrls: ['./user-access-review-systems.component.scss']
})
export class UserAccessReviewSystemsComponent implements OnInit{


  systemList: String[] = [];
  constructor(private userAccService:UserAccessService){
  }

  ngOnInit(): void {
    this.userAccService.getSystemList().subscribe(data =>{
      this.systemList = data
    })
  }

}
