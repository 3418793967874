<div class="image-container">
    <img src="\assets\company_logo.gif" alt="header-logo" class="left-image">
    <img src="\assets\fsp_logo.gif" alt="header-logo2" class="right-image">
</div>


        <div class="row g-0 text-container">
            <div class="col-6 text-left">
                <span><a href="https://portal.covisint.com/wps/private/lang/en/?pan=covisint.private.page.Home" class="font-style">
                    <b>Covisint</b></a> 
                </span>
                    
                <span><a href="https://fsp.covisint.com" class="font-style font-space">
                     <b>Ford Supplier Portal</b></a>
                </span>
                
                <span>
                    <a href="https://portal.covisint.com/wps/private/lang/en/_pagr/108/_pa.108/121" class='font-style font-space'>
                        <b>Applications</b></a>
                </span>
        
                <span>
                    <a href="https://portal.covisint.com/wps/private/lang/en/?pan=covisint.private.page.MyPage" class='font-style font-space'>
                        <b>My Workspace</b></a>
                </span>
                  
            </div>
        
            <div class="col-6 text-right">
                <span>
                    <a href="https://portal.covisint.com/wps/private/lang/en/?pan=covisint.private.page.Help/wps/private/lang/en/?pan=covisint.private.page.Help" class="font-style font-space">
                        Help</a>
                </span>
                <span>
                    <a href="https://portal.covisint.com/wps/private/lang/en/?pan=covisint.private.page.Home&PortletName=covisint.everyone.portlet.WebSearch&type=advanced&displayResults=false" class="font-style font-space">
                        Search</a>
                </span>
                <span>
                    <a href="https://portal.covisint.com/wps/private/lang/en/?pan=covisint.private.page.Help&PortletName=covisint.everyone.portlet.EditProfile" class="font-style font-space">
                        Edit My Profile</a>
                </span>
                <span>
                    <a href="https://portal.covisint.com/wps/logout.jsp" class="font-style font-space">Log Out</a>
                </span>
            </div>
        </div>

