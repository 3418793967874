import { Component } from '@angular/core';

@Component({
  selector: 'app-gsdb-reg-restricted',
  templateUrl: './gsdb-reg-restricted.component.html',
  styleUrls: ['./gsdb-reg-restricted.component.scss']
})
export class GsdbRegRestrictedComponent {

}
