<div class="flex justify-between m-3">
  <img src="\assets\company_logo.gif" alt="header-logo" class="">
  <img src="\assets\fsp_logo.gif" alt="header-logo2" class="">
</div>
<div class="page-title">
  <img src="./assets/gsdb3b.gif"/>
</div>
<div class="main-container">
  <div class="flex justify-content-between">
    <p class="ml-2 mt-2 color font-bold">GSDB Contacts Search</p>
    <p routerLink="/support" class="mr-2 mt-2 help-btn">Help</p>
  </div>
  <form [formGroup]="contactsForm" class="body">
    <div>
      <input type="radio" formControlName="criteria" value="criteria1"/>
      <label class="font-bold ml-3">Search Criteria 1 - Company Name, GSDB Parent Code, or GSDB Site Code
        Search:</label>
      <div class="mt-2 mb-2">
        <select class="input font-size-13" formControlName="selCriteria1">
          <option value=""> Please Select Company Name, Parent Code, Or Site Code From the List
          </option>
          <option value="NA_SUPPLR_PARENT">Company Name</option>
          <option value="PARENT_CODE">GSDB Parent Code</option>
          <option value="SITE_CODE">GSDB Site Code</option>
        </select>
        <label class="ml-4">for:</label>
        <input class="pl-2 ml-2 input" type="text" formControlName="selCriteria1Value"/>
      </div>
    </div>
    <hr/>
    <div>
      <input type="radio" formControlName="criteria" value="criteria2"/>
      <label class="font-bold ml-3">Search Criteria 2 - Title, State/Province, Country Search</label>
      <div class="font-size-13 ml-3 mt-2">
        <label>Title:</label>
        <select class="input ml-2" formControlName="title">
          <option value="" SELECTED>Select a Position (Optional)</option>
          <option value="President/CEO">President/CEO</option>
          <option value="Chief Financial Officer">Chief Financial Officer</option>
          <option value="Ford Account Manager">Ford Account Manager</option>
          <option value="VP Quality">Vice President Of Quality</option>
        </select>
      </div>
      <div class="font-size-13 ml-3 mt-2">
        <label>State:</label>
        <select class="input ml-2" formControlName="state">
          <option value="" selected>Please Select a State/Province (Optional)</option>
          <option *ngFor="let option of gsdbStateOrProvice" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="font-size-13 ml-3 mt-2">
        <label>Country:</label>
        <select class="input ml-2" formControlName="country">
          <option value="" selected>Please Select a Country (Optional)</option>
          <option *ngFor="let option of gsdbCountries" [value]="option">{{ option }}</option>
        </select>
      </div>
    </div>
    <hr/>
    <p class="font-size-13 font-bold">Choose the data elements to include in your list</p>
    <p class="font-size-13"> Choose the order that you would like results displayed (e.g. 1=Contact Last Name, 2=Contact
      First Name, 3=Preferred Title, etc.)</p>
    <p class="note-point font-bold">NOTE: Items that you select as 'Ignore' will not appear in the list.</p>
    <p class="note-point font-bold">The returned results will be ordered by whatever you choose as '1' below:</p>
    <div class="flex flex-wrap justify-evenly">
      <div class="order flex items-center mr-8 mb-1">
        <label class="flex-1">Company Name</label>
        <select class="input" formControlName="companyName">
          <option *ngFor="let option of order" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="order flex items-center mr-8 mb-1">
        <label  class="flex-1">Contact Last Name</label>
        <select class="input " formControlName="contactLastName">
          <option *ngFor="let option of order" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="order flex items-center mr-8 mb-1">
        <label class="flex-1">Contact First Name</label>
        <select class="input" formControlName="contactFirstName">
          <option *ngFor="let option of order" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="order flex items-center mr-8 mb-1">
        <label class="flex-1">Preferred Title</label>
        <select class="input" formControlName="preferredTitle">
          <option *ngFor="let option of order" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="order flex items-center mr-8 mb-1">
        <label class="flex-1">Email Address</label>
        <select class="input" formControlName="email">
          <option *ngFor="let option of order" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="order flex items-center mr-8 mb-1">
        <label class="flex-1">Business Telephone</label>
        <select class="input" formControlName="businessTel">
          <option *ngFor="let option of order" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="order flex items-center mr-8 mb-1">
        <label class="flex-1">Fax</label>
        <select class="input" formControlName="fax">
          <option *ngFor="let option of order" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="order flex items-center mr-8 mb-1">
        <label class="flex-1">Mailing Address</label>
        <select class="input" formControlName="mailAddr">
          <option *ngFor="let option of order" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="order flex items-center mr-8 mb-1">
        <label class="flex-1">Bldg./Off. Suite</label>
        <select class="input" formControlName="suite">
          <option *ngFor="let option of order" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="order flex items-center mr-8 mb-1">
        <label class="flex-1">City/Town</label>
        <select class="input" formControlName="city">
          <option *ngFor="let option of order" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="order flex items-center mr-8 mb-1">
        <label class="flex-1">County</label>
        <select class="input" formControlName="county">
          <option *ngFor="let option of order" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="order flex items-center mr-8 mb-1">
        <label class="flex-1">State/Prov.</label>
        <select class="input" formControlName="stateOrd">
          <option *ngFor="let option of order" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="order flex items-center mr-8 mb-1">
        <label class="flex-1">Zip/Postal Code</label>
        <select class="input" formControlName="postalCode">
          <option *ngFor="let option of order" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="order flex items-center mr-8 mb-1">
        <label class="flex-1">Country</label>
        <select class="input" formControlName="countryOrd">
          <option *ngFor="let option of order" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="order flex items-center mr-8 mb-1">
        <label class="flex-1">Alternate Contact Name</label>
        <select class="input" formControlName="altContactName">
          <option *ngFor="let option of order" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="order flex items-center mr-8 mb-1">
        <label class="flex-1">Alternate Contact Telephone</label>
        <select class="input" formControlName="altContactTel">
          <option *ngFor="let option of order" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="order flex items-center mr-8 mb-1">
        <label class="flex-1">GSDB Parent Code</label>
        <select class="input" formControlName="parentCode">
          <option *ngFor="let option of order" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="order flex items-center mr-8 mb-1">
        <label class="flex-1">GSDB Site Code</label>
        <select class="input" formControlName="siteCode">
          <option *ngFor="let option of order" [value]="option">{{ option }}</option>
        </select>
      </div>
    </div>
    <div class="flex justify-center mt-4 mb-4">
      <button type="button" class="results-btn mr-4" (click)="generateExcel()">Download Results to Excel</button>
      <button type="button" class="results-btn" (click)="displayInBrowser()">View Results in Browser</button>
    </div>
  </form>
  <hr class="mb-0"/>
  <div class="text-sm pt-1 pb-2">
    <a class="mt-0 mr-2 navigation"
       href="https://e3.ford.com/eRoom/fordna7/FSN/Contacts%20Database/HTML%20Screens/template.html#Top">Top</a> © 1998,
    Ford Motor Company
  </div>
</div>
