<div class="sidebar">
  <p class=" text-center font-size font-bold" *ngIf="gsdbCode!=='N/A'"><b>GSDB code : </b><b class="text-danger">{{gsdbCode}}</b></p>
  <p class="page-title text-center font-size font-bold">Select Title</p>
  <ul class="sidebar-content">
    <li routerLink="/perlMigrationWeb/gsdbContactsCeo" class="pl-4 hover:underline cursor-pointer font-medium">President/CEO</li>
    <li routerLink="/perlMigrationWeb/gsdbContactsChief" class="pl-4 hover:underline cursor-pointer font-medium">Chief Financial
      Officer
    </li>
    <li routerLink="/perlMigrationWeb/gsdbContactSecOfficer" class="pl-4 hover:underline cursor-pointer font-small font-medium">Chief Information Security Officer
    </li>
    <li routerLink="/perlMigrationWeb/gsdbContactsExeCeo" class="pl-4 hover:underline cursor-pointer font-medium">Executive Assistant to CEO</li>
    <li routerLink="/perlMigrationWeb/gsdbContactsVP" class="pl-4 hover:underline cursor-pointer font-medium">VP Quality</li>
    <li routerLink="/perlMigrationWeb/gsdbContactsVpSales" class="pl-4 hover:underline cursor-pointer font-medium">Vice President of Sales</li>
    <li routerLink="/perlMigrationWeb/gsdbContactsVsml" class="pl-4 hover:underline cursor-pointer font-medium">Value Stream Mapping Lead</li>
    <li routerLink="/perlMigrationWeb/gsdbContactsFord" class="pl-4 hover:underline cursor-pointer font-medium">Ford Account
      Manager
    </li>
    <li routerLink="/perlMigrationWeb/gsdbContactsKfc" class="pl-4 hover:underline cursor-pointer font-medium">Key Ford Contact</li>
  </ul>
</div>
