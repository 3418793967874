<app-app-launch-header></app-app-launch-header>
<div class="container-fluid">
    <div class="row" [hidden]="formHidden">
        <div class="col-12 page-title">
           <b> FSP APPLICATION LAUNCH FORM</b><br>
           <span class="page-title-size-1">NOTE: this form is also used as the FSP Application Site Description Maintenance Form.</span><br>
           <span class="page-title-size-2 text-danger"><b>Fields 1-12 are required for all applications.<br>
            *Fields 13-15 are required for applications restricted by tokens.</b></span>
        </div>
    </div>

    <div class="row form-text" [hidden]="formHidden">
        <div class="col-12">
            <form [formGroup]="appLaunchForm" (ngSubmit)="onSubmit()">
                <ol class="list-style">
                    <div class="row">
                        <div class="col-5">
                           <li><label>&nbsp; &nbsp; Application Name </label></li>
                        </div>
                        <div class="col-4">
                            <input type="text" formControlName="appName" class="input-text"><br>
                            <span id="appNameError" *ngIf="appLaunchForm.controls['appName'].invalid
                            && (appLaunchForm.controls['appName'].dirty ||
                            appLaunchForm.controls['appName'].touched)"
                             class="text-danger">appName is required and the size must not exceed 60 characters</span>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-5">
                           <li><label>&nbsp; &nbsp; Application Name-As displayed on Portal </label></li>
                        </div>
                        <div class="col-4">
                            <input type="text" class="input-text" formControlName="appNameOnPortal"><br>
                            <span id="appNameError1" *ngIf="appLaunchForm.controls['appNameOnPortal'].invalid
                            && (appLaunchForm.controls['appNameOnPortal'].dirty ||
                            appLaunchForm.controls['appNameOnPortal'].touched)"
                             class="text-danger">appNameOnPortal is required and the size must not exceed 60 characters</span>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-5">
                           <li><label>&nbsp; &nbsp; Production URL </label></li>
                        </div>
                        <div class="col-4">
                            <input type="text" formControlName="prodURL" class="input-text"><br>
                            <span id="appNameError2" *ngIf="appLaunchForm.controls['prodURL'].invalid
                            && (appLaunchForm.controls['prodURL'].dirty ||
                            appLaunchForm.controls['prodURL'].touched)"
                             class="text-danger">prodURL is required and the size must not exceed 60 characters</span>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-5">
                           <li><label>&nbsp; &nbsp; What category will the site be listed under? </label></li>
                        </div>
                        <div class="col-4">
                            <select name="appCategory" formControlName="appCategory" class="select-text" size="5">
                                <option value="Customer Service">Customer Service</option>
                                <option value="Environmental">Environmental</option>
                                <option value="Finance">Finance</option>
                                <option value="Manufacturing">Manufacturing</option>
                                <option value="Marketing">Marketing</option>
                                <option value="Product Development">Product Development</option>
                                <option value="Purchasing">Purchasing</option>
                                <option value="Quality" selected>Quality</option>
                                <option value="Vehicle Programs">Vehicle Programs</option>
                                <option value="Supplier Development">Supplier Development</option>
                            </select>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-5">
                           <li><label>&nbsp; &nbsp; Application Helpdesk Contact (email or phone number): </label></li>
                        </div>
                        <div class="col-4">
                            <input type="text" formControlName="helpDeskContact" class="input-text"><br>
                            <span id="appNameError3" *ngIf="appLaunchForm.controls['helpDeskContact'].invalid
                            && (appLaunchForm.controls['helpDeskContact'].dirty ||
                            appLaunchForm.controls['helpDeskContact'].touched)"
                             class="text-danger">helpDeskContact is required and the size must not exceed 60 characters</span>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-5">
                           <li><label>&nbsp; &nbsp; Billable Business Owner CDS ID </label></li>
                        </div>
                        <div class="col-4">
                            <input type="text" formControlName="businessContact" class="input-text"><br>
                            <span id="appNameError4" *ngIf="appLaunchForm.controls['businessContact'].invalid
                            && (appLaunchForm.controls['businessContact'].dirty ||
                            appLaunchForm.controls['businessContact'].touched)"
                             class="text-danger">businessContact is required and the size must not exceed 10 characters</span>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-5">
                           <li><label>&nbsp; &nbsp; Primary Contact CDS ID </label></li>
                        </div>
                        <div class="col-4">
                            <input type="text" formControlName="primaryContact" class="input-text">
                            <br>
                            <span id="appNameError5" *ngIf="appLaunchForm.controls['primaryContact'].invalid
                            && (appLaunchForm.controls['primaryContact'].dirty ||
                            appLaunchForm.controls['primaryContact'].touched)"
                             class="text-danger">primaryContact is required and the size must not exceed 10 characters</span>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-5">
                           <li><label>&nbsp; &nbsp; Technical Systems Contact CDS ID </label></li>
                        </div>
                        <div class="col-4">
                            <input type="text" formControlName="technicalContact" class="input-text"><br>
                            <span id="appNameError6" *ngIf="appLaunchForm.controls['technicalContact'].invalid
                            && (appLaunchForm.controls['technicalContact'].dirty ||
                            appLaunchForm.controls['technicalContact'].touched)"
                             class="text-danger">technicalContact is required and the size must not exceed 10 characters</span>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-5">
                           <li><label>&nbsp; &nbsp; ACR 552 #</label></li>
                        </div>
                        <div class="col-4">
                            <input type="text" formControlName="aCRNumber" class="input-text"><br>
                            <span id="appNameError7" *ngIf="appLaunchForm.controls['aCRNumber'].invalid
                            && (appLaunchForm.controls['aCRNumber'].dirty ||
                            appLaunchForm.controls['aCRNumber'].touched)"
                             class="text-danger">aCRNumber is required and the size must not exceed 10 characters</span>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-5">
                           <li><label>&nbsp; &nbsp; Financial</label></li>
                        </div>
                        <div class="col-4">
                            <input type="radio" value="Y" formControlName="financialSwitch">&nbsp; Yes &nbsp; &nbsp;
                            <input type="radio" value="N" formControlName="financialSwitch">&nbsp; No &nbsp; &nbsp;<br>
                            <span id="appNameError8" *ngIf="appLaunchForm.controls['financialSwitch'].invalid
                            && (appLaunchForm.controls['financialSwitch'].dirty ||
                            appLaunchForm.controls['financialSwitch'].touched)"
                             class="text-danger">financialSwitch is required</span>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-5">
                           <li><label>&nbsp; &nbsp; Critical</label></li>
                        </div>
                        <div class="col-4">
                            <input type="radio" value="Y" formControlName="criticalSwitch">&nbsp; Yes &nbsp; &nbsp;
                            <input type="radio" value="N" formControlName="criticalSwitch">&nbsp; No &nbsp; &nbsp;<br>
                            <span id="appNameError9" *ngIf="appLaunchForm.controls['criticalSwitch'].invalid
                            && (appLaunchForm.controls['criticalSwitch'].dirty ||
                            appLaunchForm.controls['criticalSwitch'].touched)"
                             class="text-danger">criticalSwitch is required</span>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-5">
                           <li><label>&nbsp; &nbsp; Private</label></li>
                        </div>
                        <div class="col-4">
                            <input type="radio" value="Y" formControlName="privateSwitch">&nbsp; Yes &nbsp; &nbsp;
                            <input type="radio" value="N" formControlName="privateSwitch">&nbsp; No &nbsp; &nbsp;<br>
                            <span id="appNameError10" *ngIf="appLaunchForm.controls['privateSwitch'].invalid
                            && (appLaunchForm.controls['privateSwitch'].dirty ||
                            appLaunchForm.controls['privateSwitch'].touched)"
                             class="text-danger">privateSwitch is required</span>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-5">
                           <li><label>&nbsp; &nbsp; What level of security is being used? </label></li>
                        </div>
                        <div class="col-4">
                            <select name="securityLevel" formControlName="securityLevel" class="select-text">
                                <option value="Open to the Public">Open to the Public</option>
                                <option value="All FSP Users">All FSP Users</option>
                                <option value="Restricted to Subset of Suppliers and Users by Token"
                                selected>Restricted to Subset of Suppliers and Users by Token</option>
                            </select>
                        </div>
                    </div><br>
                    <div class="text-danger">*If your site is restricted by a token please answer 13-15.
                        All others scroll down and click the submit button now.</div><br>

                    <div class="row">
                        <div class="col-5">
                           <li><label>&nbsp; &nbsp; Token Name*</label></li>
                        </div>
                        <div class="col-4">
                            <input type="text" formControlName="tokenName" class="input-text"><br>
                            <span id="appNameError11" *ngIf="appLaunchForm.controls['tokenName'].invalid
                            && (appLaunchForm.controls['tokenName'].dirty ||
                            appLaunchForm.controls['tokenName'].touched)"
                             class="text-danger">tokenName  size must not exceed 20 characters</span>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-5">
                           <li><label>&nbsp; &nbsp; Are there site codes associated with your token?*</label></li>
                        </div>
                        <div class="col-4">
                            <input type="radio" value="Y" formControlName="siteCodeSwitch">&nbsp; Yes &nbsp; &nbsp;
                            <input type="radio" value="N" formControlName="siteCodeSwitch">&nbsp; No &nbsp; &nbsp;
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-5">
                           <li><label>&nbsp; &nbsp; Should your token automatically be granted to all CSAs?*</label></li>
                        </div>
                        <div class="col-4">
                            <input type="radio" value="Y" formControlName="defaultAppSwitch">&nbsp; Yes &nbsp; &nbsp;
                            <input type="radio" value="N" formControlName="defaultAppSwitch">&nbsp; No &nbsp; &nbsp;
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-5">
                           <li><label>&nbsp; &nbsp; Site Concept - What type of information is on your site and who is this information meant for?</label></li>
                        </div>
                        <div class="col-4">
                            <textarea formControlName="siteConcept" class="input-text"></textarea><br>
                            <span id="appNameError12" *ngIf="appLaunchForm.controls['siteConcept'].invalid
                            && (appLaunchForm.controls['siteConcept'].dirty ||
                            appLaunchForm.controls['siteConcept'].touched)"
                             class="text-danger">siteConcept is required </span>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-5">
                           <li><label>&nbsp; &nbsp; Detailed Description</label></li>
                        </div>
                        <div class="col-4">
                            <textarea formControlName="siteDetail" class="input-text"></textarea><br>
                            <span id="appNameError13" *ngIf="appLaunchForm.controls['siteDetail'].invalid
                            && (appLaunchForm.controls['siteDetail'].dirty ||
                            appLaunchForm.controls['siteDetail'].touched)"
                             class="text-danger">siteDetail is required </span>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-5">
                           <label><li>&nbsp; &nbsp; Additional Information</li>
                            (Once inside your application, does a user need additional
                            <br>permissions to access your site?)</label>
                        </div>
                        <div class="col-4">
                            <textarea formControlName="additionalRegistration" class="input-text"></textarea><br>
                            <span id="appNameError14" *ngIf="appLaunchForm.controls['additionalRegistration'].invalid
                            && (appLaunchForm.controls['additionalRegistration'].dirty ||
                            appLaunchForm.controls['additionalRegistration'].touched)"
                             class="text-danger">additionalRegistration is required </span>
                        </div>
                    </div><br>

                    <div class="row">
                        <div class="col-9 btn-style">
                            <button type="submit" class="btn btn-primary btn-sm btn-size" [disabled]="appLaunchForm.invalid">submit changes!</button>&nbsp; &nbsp;
                            &nbsp; &nbsp; <button type="reset" class="btn btn-primary btn-sm btn-size">reset form!</button>
                        </div>
                    </div>

                </ol>
            </form>
        </div>
    </div>

    <div class="row form-text" [hidden]="!formHidden">
        <div>
            <b>App Launch Form Submitted Successfully...................</b>
        </div>

    </div>

</div>
