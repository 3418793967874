<div class="body">
  <div class="content-container">
    <div class="flex">
      <img src="./assets/ford.png" alt="Ford" class="ford-icon"/>
      <p class="heading m-auto">Annual Approval Chain Review Page</p>
    </div>
    <div class="inner-container">
      <p class="font-size-12 font-bold">You have been identified as a Controller or a Controller Designee (with the
        Delegation of Authority from the
        Controller) responsible for approval chains in your area.</p>
      <p class="font-size-12 font-bold">Approval of the users and the associated approval chains indicates that you
        accept responsibility for the
        delegations you are authorizing as well as the user's ability to appropriately and effectively utilize the
        delegations they have been granted.</p>
      <p class="m-0 font-size-12">Clicking on "I Accept" at the bottom of this page:</p>
      <ul class="list-disc font-size-12">
        <li>Indicates acceptance of the statements above for the approval chains you action.</li>
        <li>Allows you to view the approval chains currently assigned to you.</li>
        <li>Enables you to indicate your decisions or actions for the chains and complete the Approval Chain review.
        </li>
      </ul>
      <p class="m-0 font-size-12 font-bold">Critical Information:</p>
      <ul class="list-disc font-size-12">
        <li>If all approval chains displayed should be reassigned to a different controller, select the 'Click here to
          Reassign All Approval Chains' button. A pop-up window will display asking you to confirm this action and to
          designate the CDS ID of the correct controller
        </li>
        <li>If a specific approval chain should be reassigned to a different controller, please select the 'Not Chain
          Controller' button and enter the CDS ID of the correct controller in the 'New Controller' field for that
          chain. If you are not sure who the correct controller is select the 'Not Chain Controller' button, but leave
          the 'New Controller' field blank.
        </li>
        <li>If a user should remain in the approval chain, click on the 'Appr' button to approve them.</li>
        <li>If a user should be removed from the approval chain, click on the 'Del' button to delete them.</li>
        <li>If a current approver on a chain should be replaced with a new approver, select the 'Del' button and then
          enter the CDS ID of the new approver in the 'New Approver' field. If the replacement approver does not have a
          RACF ID, the AAR Analyst will request one on behalf of the user.
        </li>
        <li>Use the New Approval Limit/Comments field to change approval limits or enter additional information.</li>
        <li>Please make sure you scroll to the end of the records, review every line, and record a decision for each
          user.
        </li>
      </ul>
      <p class="font-bold font-size-12">Click <span class="accept-btn" routerLink="/PAARS/userHierarchy/annualReview">I Accept</span> to complete the Approval Chain review.</p>
    </div>
  </div>
</div>
