import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SupplierGuidesService {
  private url = environment.gtcApiUrl

  constructor(private http: HttpClient) {
  }

  getAutoCurrencyPdf() {
    const safeFile = encodeURIComponent("autocurrency.pdf");
    const safeUriPath = "/api/v1/files/download";
    const finalUrl = `${this.url}/${safeUriPath}/${safeFile}`;
    this.http.get(finalUrl, {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getAutomaterialsPdf() {
    this.http.get(this.url + '/api/v1/files/download/automaterials.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'});
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getBailmenteu2007Pdf(){
    this.http.get(this.url + '/api/v1/files/download/bailmenteu2007.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getCapacityPlanPdf() {
    this.http.get(this.url + '/api/v1/files/download/capacityplan.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getCSIPPdf() {
    this.http.get(this.url + '/api/v1/files/download/csip.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getClaimsPdf() {
    this.http.get(this.url + '/api/v1/files/download/claims.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getComanagedBuyPdf() {
    this.http.get(this.url + '/api/v1/files/download/comanagedbuy.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getCustomScanPdf(){
    return this.http.get(this.url + '/api/v1/files/download/customscan.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getCustomsMexicoPdf() {
    this.http.get(this.url + '/api/v1/files/download/customsmex.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getCustomsUsaPdf(){
    this.http.get(this.url + '/api/v1/files/download/customsusa.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getDeleveryTermsFOEPdf() {
    this.http.get(this.url + '/api/v1/files/download/deltermsfoe.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getDigitalManufacturingDataPdf(){
    this.http.get(this.url + '/api/v1/files/download/digitalmfgdata.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getProdDisputeResolutionPdf() {
    this.http.get(this.url + '/api/v1/files/download/disputeresolution.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getIndirectDisputeResolutionPdf() {
    this.http.get(this.url + '/api/v1/files/download/fmspdisputeresolution.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getEnvGuidePdf(){
    this.http.get(this.url + '/api/v1/files/download/envguide.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getExportControlWebGuidePdf(){
    this.http.get(this.url + '/api/v1/files/download/exportcontrolwebguide.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getFCSDNorthAmericaPdf() {
    this.http.get(this.url + '/api/v1/files/download/psguide2015.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_self');
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getFCSDEuropePdf() {
    this.http.get(this.url + '/api/v1/files/download/psguide2005eu.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_self');
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getFCSDGlobalPdf(){
    this.http.get(this.url + '/api/v1/files/download/fcsdservicepartsna.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getFSAPdf() {
    this.http.get(this.url + '/api/v1/files/download/FSA.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getFordBrandProtectPdf() {
    this.http.get(this.url + '/api/v1/files/download/fordbrandprotect.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getInvoicingGuidelinesPdf(){
    this.http.get(this.url + '/api/v1/files/download/eusuppinvoicingfmsp2007.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_self');
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getShipPartsPdf(){
     this.http.get(this.url + '/api/v1/files/download/shipparts.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getMplmaterialsPdf() {
    this.http.get(this.url + '/api/v1/files/download/mplmaterials.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getWareHouseInstructionPdf() {
    this.http.get(this.url + '/api/v1/files/download/nmpwshipinst2009.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getNewsReleasesPdf(){
    this.http.get(this.url + '/api/v1/files/download/newsreleases.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getGlobalexppkgPdf() {
    this.http.get(this.url + '/api/v1/files/download/globalexppkg.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getEu1750pkgPdf() {
    this.http.get(this.url + '/api/v1/files/download/eu1750pkg.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getNapkgguidePdf() {
     this.http.get(this.url + '/api/v1/files/download/napkgguide.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getAPpkgguidePdf() {
     this.http.get(this.url + '/api/v1/files/download/APpkgguide.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getCN1750pkgPdf(){
     this.http.get(this.url + '/api/v1/files/download/CN1750pkg.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getProdPayGuidePdf(){
     this.http.get(this.url + '/api/v1/files/download/prodpayguide.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getIndirectPayGuidePdf() {
    this.http.get(this.url + '/api/v1/files/download/indirectpayguide.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getNonrenewalPdf() {
    this.http.get(this.url + '/api/v1/files/download/nonrenewal.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getPtoewarrantsPdf() {
     this.http.get(this.url + '/api/v1/files/download/ptoewarrants.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getSteelrawmtlsPdf(){
     this.http.get(this.url + '/api/v1/files/download/steelrawmtls.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getReturnableContainerASNPdf(){
     this.http.get(this.url + '/api/v1/files/download/ReturnableContainerASN.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getShipmentsfoe() {
    this.http.get(this.url + '/api/v1/files/download/shipmentsfoe.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getNashippingPdf() {
     this.http.get(this.url + '/api/v1/files/download/nashipping.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getHrandwcPdf() {
    this.http.get(this.url + '/api/v1/files/download/hrandwc.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getTaxPdf() {
     this.http.get(this.url + '/api/v1/files/download/tax.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getToolingPurchaseGuidePdf() {
    this.http.get(this.url + '/api/v1/files/download/toolingaudit.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getSupptooltaggingPdf() {
     this.http.get(this.url + '/api/v1/files/download/supptooltagging.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGovernmentSubContractorPdf() {
     this.http.get(this.url + '/api/v1/files/download/governmentsubcontractor.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getSdiPdf() {
    this.http.get(this.url + '/api/v1/files/download/sdi.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getValueChainTransparencyPdf() {
   this.http.get(this.url + '/api/v1/files/download/valuechaintransparency.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
    const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
  }, error => {
    console.error('Error fetching PDF:', error);
  });
  }

  getWarrantyChargebackPdf(){
     this.http.get(this.url + '/api/v1/files/download/warrantychgbck.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getWarrantyQosPdf(){
    this.http.get(this.url + '/api/v1/files/download/warrantyqos.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }
}
