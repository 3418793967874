import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IdConverterService } from '../service/id-converter.service';

@Component({
  selector: 'app-id-converter',
  templateUrl: './id-converter.component.html',
  styleUrls: ['./id-converter.component.scss']
})
export class IdConverterComponent {

  formEnabled:boolean = true;
  coviid:String|null=null;
  fsnid:String| null=null;

  idConverterForm: FormGroup;

  constructor( private fb: FormBuilder, private dataService:IdConverterService){
      this.idConverterForm = this.fb.group({
        id:['',Validators.required],
        idtype:['',Validators.required]
      })
  }

  enableForm(event:Event){
    event.preventDefault();
    this.idConverterForm.reset();
    this.formEnabled=true;
  }

  onSubmit(){
    this.fsnid = null
    this.coviid = null
    const idtype: String = this.idConverterForm.get('idtype')?.value;
    const id: String = this.idConverterForm.get('id')?.value;
    if(idtype.match('F')){
      this.fsnid = id;
    }
    if(idtype.match('C')){
      this.coviid = id;
    }
    this.getIdConverter(id,idtype);
  }

  getIdConverter(id:String,idtype:String){
      this.dataService.getIdConverter(id,idtype).subscribe(
        (data:String)=>{
          if(idtype.match('F') && !data.includes("not found")){
            this.coviid = data
          }if(idtype.match('C') && !data.includes("not found")){
            this.fsnid = data;
          }
          this.formEnabled=false;
        },error =>{
          alert(JSON.stringify(error));
          this.formEnabled=false;
        }
      )
  }

}
