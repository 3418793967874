import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {map, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SupplementsService {
  private url = environment.gtcApiUrl

  constructor(private http: HttpClient) {
  }

  getGtcSupplementalTermsChinaPdf(){
     this.http.get(this.url + '/api/v1/files/download/chinesesuppfmsp.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcSupplementalTermsCanadaPdf() {
     this.http.get(this.url + '/api/v1/files/download/canadasuppfmsp.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcSupplementalTermsAustraliaDoc(){
    this.http.get(this.url + '/api/v1/files/download/australiasuppfmsp.docx', {responseType: 'blob'}).pipe(map(blob => {
      const url = window.URL.createObjectURL(blob);
      // Create a link element and trigger a click event
      const a = document.createElement('a');
      a.href = url;
      a.download = "australiasuppfmsp.docx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      return blob;
    })).subscribe();
  }

  getGtcSupplementalTermsIndiaPdf(){
    this.http.get(this.url + '/api/v1/files/download/indiasuppfmsp2007.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcSupplementalTermsBrazilPdf() {
    this.http.get(this.url + '/api/v1/files/download/brazilsuppfmsp2007.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcSupplementalTermsMexicoPdf(){
    this.http.get(this.url + '/api/v1/files/download/mexicosuppfmsp2007.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcSupplementalTermsGreecePdf() {
    this.http.get(this.url + '/api/v1/files/download/greecesuppfmsp2007.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getIndividualCollectiveLaborRelEng() {
    this.http.get(this.url + '/api/v1/files/download/pnpd223.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getIndividualCollectiveLaborRelSpanish() {
    this.http.get(this.url + '/api/v1/files/download/pnpd223_spanish.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGTCSupplementalTermsEnglish() {
     this.http.get(this.url + '/api/v1/files/download/spainsuppenglish.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGTCSupplementalTermsSpanish() {
   this.http.get(this.url + '/api/v1/files/download/spainsuppspanish.pdf', {responseType: 'blob'}).subscribe(arrayBuffer => {
    const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
  }, error => {
    console.error('Error fetching PDF:', error);
  });
  }
}
