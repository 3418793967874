/**This file contains a constant object that has properties that are necessary
 * for the build found in the `dev` configuration in `angular.json`.
 * The contents of this file will be overwritten for other configurations.
 */

export const environment = {
  production: false,
  gtcApiUrl: 'https://api-dev.erp.ford.com/purchasinglegacy',
  perlMigrationApiUrl : 'https://api.d01e.gcp.ford.com/perlmigration',
  paarsWebApiUrl:'https://api.d01e.gcp.ford.com/paarsweb',
  openidUrl: 'https://corpqa.sts.ford.com/adfs/oauth2',
  openidClientId: 'urn:fspgcp:clientid:web_fsp_adfs:dev',
  openidResource: 'urn:fspgcp:resource:web_fsp_adfs:dev',
  openidRedirectUrl: 'https://fsp-dev-angular-qd2njozn5q-uc.a.run.ap/oauth'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
