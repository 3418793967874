import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import { GsdbContactsMain } from 'src/app/models/perl-migration-web/gsdb-contacts-main/gsdb-contacts-main.model';
import { GsdbContactsService } from '../service/gsdb-contacts.service';

@Component({
  selector: 'app-gsdb-contacts-chief',
  templateUrl: './gsdb-contacts-chief.component.html',
  styleUrls: ['./gsdb-contacts-chief.component.scss']
})
export class GsdbContactsChiefComponent implements OnInit{
  contactDetails!: GsdbContactsMain;
  valid:boolean = false;
  gsdbCode:String = '';
  
  constructor(private router: Router,private apiService: GsdbContactsService) {
  }
  ngOnInit(): void {
    this.getcontactDetails();
  }

  update(values: any) {
    this.createUpdateRequest(values);
    this.apiService.updateContactDetails(this.contactDetails).subscribe((data: any) => {
      const successData = true;
      this.router.navigate(['/perlMigrationWeb/gsdbContactUpdate', "chief"], {state:{successData}});
    }, error => {
      const successData = false;
      this.router.navigate(['/perlMigrationWeb/gsdbContactUpdate', "chief"], {state:{successData}});
    })
  }

  getcontactDetails(){
    this.apiService.getContactDetails("cfo").subscribe((data:GsdbContactsMain)=>{
      this.valid= true;
      this.contactDetails = data;
      this.gsdbCode = this.contactDetails.siteCode;
    },error=>{
      console.log(error);
      this.valid=false;
      this.router.navigate(['/perlMigrationWeb/gsdbContactsError']);
    })
  }

  createUpdateRequest(values:any){
    this.contactDetails.firstName =values?.exeFirstName;  
    this.contactDetails.lastName=values?.exeLastName; 
    this.contactDetails.prefTitle=values?.prefTitle;  
    this.contactDetails.email=values?.email;  
    this.contactDetails.telCountryCode=values?.businessCountryCd; 
    this.contactDetails.telephone= values?.businessTel; 
    this.contactDetails.faxCountryCode=values?.faxCountryCd;  
    this.contactDetails.fax=values?.faxTel;  
    this.contactDetails.mailAddress=values?.mailAddr; 
    this.contactDetails.buildingSuite=values?.officeSuite;  
    this.contactDetails.cityTown=values?.city;  
    this.contactDetails.country=values?.country; 
    this.contactDetails.stateProvince=values?.state;  
    this.contactDetails.zipPostalCode=values?.postalCode;  
    this.contactDetails.country=values?.selCountry;   
    this.contactDetails.altContactFirstName=values?.emergencyFirstName;
    this.contactDetails.altContactLastName=values?.emergencyLastName;
    this.contactDetails.altContactCountryCode=values?.emeContactCountryCd;
    this.contactDetails.altContactTelephone=values?.emeContactTel;
    if(this.contactDetails.id.positionCode.length==0){
      this.contactDetails.id.positionCode="2";
    }
  }
}
