export type ContactsHeadingKeys =
  | "NA_SUPPLR_PARENT AS companyName"
  | "LAST_NAME AS lastName"
  | "FIRST_NAME AS firstName"
  | "PREF_TITLE AS title"
  | "EMAIL_ADDR AS email"
  | "TELEPHONE AS phone"
  | "FAX AS fax"
  | "MAIL_ADDR AS address"
  | "BLDG_SUITE AS buildingSuite"
  | "CITY_TOWN AS city"
  | "COUNTY AS county"
  | "STATE_PROV AS stateProv"
  | "ZIP_POSTAL_CD AS zip"
  | "COUNTRY AS country"
  | "ALT_CONTACT_FIRST_NME AS altLastName"
  | "ALT_CONTACT_TELEPHONE AS altPhone"
  | "PARENT_CODE AS parentCode"
  | "SITE_CODE AS siteCode";

export const titleList: { [key in ContactsHeadingKeys]: string } = {
  "NA_SUPPLR_PARENT AS companyName": "companyName",
  "LAST_NAME AS lastName": "lastName",
  "FIRST_NAME AS firstName": "firstName",
  "PREF_TITLE AS title": "title",
  "EMAIL_ADDR AS email": "email",
  "TELEPHONE AS phone": "phone",
  "FAX AS fax": "fax",
  "MAIL_ADDR AS address": "address",
  "BLDG_SUITE AS buildingSuite": "buildingSuite",
  "CITY_TOWN AS city": "city",
  "COUNTY AS county": "county",
  "STATE_PROV AS stateProv": "stateProv",
  "ZIP_POSTAL_CD AS zip": "zip",
  "COUNTRY AS country": "country",
  "ALT_CONTACT_FIRST_NME AS altLastName": "altLastName",
  "ALT_CONTACT_TELEPHONE AS altPhone": "altPhone",
  "PARENT_CODE AS parentCode": "parentCode",
  "SITE_CODE AS siteCode": "siteCode"
}

export const contactsHeadings: { [key in ContactsHeadingKeys]: string } = {
  "NA_SUPPLR_PARENT AS companyName": "Company Name",
  "LAST_NAME AS lastName": "Last Name",
  "FIRST_NAME AS firstName": "First Name",
  "PREF_TITLE AS title": "Preffered Title",
  "EMAIL_ADDR AS email": "Email Address",
  "TELEPHONE AS phone": "Business Telephone",
  "FAX AS fax": "Fax",
  "MAIL_ADDR AS address": "Mailing Address",
  "BLDG_SUITE AS buildingSuite": "Bldg/Suite",
  "CITY_TOWN AS city": "City/Town",
  "COUNTY AS county": "County",
  "STATE_PROV AS stateProv": "State/Prov",
  "ZIP_POSTAL_CD AS zip": "Zip/Postal Code",
  "COUNTRY AS country": "Country",
  "ALT_CONTACT_FIRST_NME AS altLastName": "Alt Contact Last Name",
  "ALT_CONTACT_TELEPHONE AS altPhone": "Alt Contact Telephone",
  "PARENT_CODE AS parentCode": "Parent Code",
  "SITE_CODE AS siteCode": "Site Code"
}

export class GsdbContactsRequestBody {
  public criteria: string = ''
  public searchFor: string = ''
  public searchWhat: string = ''
  public title: string = ''
  public stateProvince: string = ''
  public country: string = ''
  public companyNameOrder: number = -1
  public lastNameOrder: number = -1
  public firstsNameOrder: number = -1
  public titleOrder: number = -1
  public emailOrder: number = -1
  public bussPhoneOrder: number = -1
  public faxOrder: number = -1
  public mailAddrOrder: number = -1
  public buildingOrder: number = -1
  public cityTownOrder: number = -1
  public countyOrder: number = -1
  public stateProvOrder: number = -1
  public zipOrder: number = -1
  public countryOrder: number = -1
  public altNameOrder: number = -1
  public altPhoneOrder: number = -1
  public parentCodeOrder: number = -1
  public siteCodeOrder: number = -1
}
