import { Component } from '@angular/core';

@Component({
  selector: 'app-gsdb-reg-fail',
  templateUrl: './gsdb-reg-fail.component.html',
  styleUrls: ['./gsdb-reg-fail.component.scss']
})
export class GsdbRegFailComponent {

}
