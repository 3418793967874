import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TermsService {
  private url = environment.gtcApiUrl

  constructor(private http: HttpClient) {
  }

  getGtcTermsEnglishPdf() {
    this.http.get(this.url + '/api/v1/files/download/pcpd018.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });;
  }

  getGtcTermsChinesePdf() {
    this.http.get(this.url + '/api/v1/files/download/chinesefmsp2007.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcTermsDeutschPdf() {
    this.http.get(this.url + '/api/v1/files/download/germanfmsp2007.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcTermsDutchPdf() {
    this.http.get(this.url + '/api/v1/files/download/dutchfmsp2007.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcTermsHungarianPdf() {
    this.http.get(this.url + '/api/v1/files/download/hungarianfmsp2007.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcTermsSpanishPdf(){
    this.http.get(this.url + '/api/v1/files/download/spanish_argentina_fmsp2007.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcTermsPolishPdf(){
    this.http.get(this.url + '/api/v1/files/download/polishfmsp2007.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcTermsCzechPdf() {
    this.http.get(this.url + '/api/v1/files/download/czechrepublicfmsp2007.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcTermsRussianPdf(){
    this.http.get(this.url + '/api/v1/files/download/russianfmsp2007.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcTermsItalianPdf() {
    this.http.get(this.url + '/api/v1/files/download/italianfmsp2007.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcTermsPortuguesePdf() {
    this.http.get(this.url + '/api/v1/files/download/portuguesefmsp2007.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcTermsFinnishPdf() {
    this.http.get(this.url + '/api/v1/files/download/finnishfmsp2007.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcTermsGreekPdf() {
     this.http.get(this.url + '/api/v1/files/download/greekfmsp2007.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcTermsDanishPdf() {
    this.http.get(this.url + '/api/v1/files/download/danishfmsp2007.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcTermsNorwegianPdf(){
    this.http.get(this.url + '/api/v1/files/download/norwegianfmsp2007.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcTermsRomanianPdf() {
    this.http.get(this.url + '/api/v1/files/download/romanianfmsp2007.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcTermsSwedishPdf() {
    this.http.get(this.url + '/api/v1/files/download/swedishfmsp2007.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcTermsThaiPdf() {
    this.http.get(this.url + '/api/v1/files/download/thaifmsp2007.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getGtcTermsKoreanPdf() {
    this.http.get(this.url + '/api/v1/files/download/koreanfmsp2007.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }
}
