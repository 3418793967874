import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ProductionService {
  private url = environment.gtcApiUrl

  constructor(private http: HttpClient) {
  }

  getCommunicationPdf() {
    this.http.get(this.url + '/api/v1/files/download/2022sgupdates.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getPiiSupplementPdf() {
    this.http.get(this.url + '/api/v1/files/download/piisupp.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getChineseTermsPdf() {
     this.http.get(this.url + '/api/v1/files/download/ppgtc_chinese.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getInfoSecuritySupplementPdf() {
    this.http.get(this.url + '/api/v1/files/download/ISP SUPPLEMENT.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }

  getPpgtcPdf() {
    this.http.get(this.url + '/api/v1/files/download/ppgtc.pdf', {responseType: 'arraybuffer'}).subscribe(arrayBuffer => {
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_self');
      URL.revokeObjectURL(blobUrl);
    }, error => {
      console.error('Error fetching PDF:', error);
    });
  }
}
