<div class="home-page-container">
  <p class="heading">Welcome to Ford Motor Company Global Terms & Conditions</p>
  <div class="mt-8 p-2">
    <p>This site provides access to the Ford Global Terms and Conditions (GTC's) and related information. The Ford
      Purchasing Global Terms and Conditions apply to the purchase by Ford of goods and services from the Supplier.</p>
    <div class="production-navigation mt-2">
      <a class="color" routerLink="/gtc/production">Production </a>
      &nbsp;| &nbsp;
      <a class="color" routerLink="/gtc/indirect">Indirect</a>
    </div>
  </div>
</div>
