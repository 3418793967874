import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppRegistration } from 'src/app/models/perl-migration-web/app-registration/app-registration.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppRegistrationService {

  private url = environment.perlMigrationApiUrl

  constructor(private http: HttpClient) {
  }

  applicationRegistration(appRegistration : AppRegistration):Observable<String>{
    return this.http.post<String>(this.url + '/api/v1/appRegistration', appRegistration, {
      reportProgress: true
    });
  }

}
