<app-common-header></app-common-header>
<div class="container-fluid">
    <div class="row">
        <div class="page-title">
            <b>EVEREST Supplier Token Status Application</b>
        </div>
    </div>

    <div class="row">
        <div class="col-9">
            <div class="row font1">
                <div>
                    <b>You have entered a 4-character GSDB code. Please choose one of the following
                        site codes below:</b>
                </div>
            </div>
            <div class="row">
                <div class="font2">
                    <form [formGroup]="SiteCodeForm" (ngSubmit)="onSubmit()">
                        <div>
                            <select id="siteCode" formControlName="site" class="dropdown">
                                <option *ngFor = "let siteCode of siteCodes" [value]="siteCode" >{{siteCode}}</option>
                            </select>
                        </div>
                        <div class="font3">
                            <button type="submit" class="btn btn-primary btn-sm">Lookup Selected Code </button>&nbsp; &nbsp;
                        </div>
                    </form>
                </div>
            </div>
            
            <div class="row">
                <div class="link">
                    <a routerLink = "/perlMigrationWeb/everstTokenLookup"><u class="link-text"><b>Back to Main Page</b></u>
                    </a>
                </div>
            </div>
            <div class="row">
                <div>
                    <hr>
                </div>
            </div>
        </div>
    </div>    
</div>