<app-gsdb-online-header></app-gsdb-online-header>
<div class="body ml-8 mr-8 mt-8 mb-8">
  <p class="para-title pl-4"><b> GSDB Online Steps > <span class="intro"> Introduction</span> :: Nomination ::
    Indemnification</b></p>
  <p>Welcome to GSDB ONLINE. Once you gain access to this tool, you will be able to update most of your company's
    information.
    The registration process is a two step process which includes:</p>
  <ul class="list-disc">
    <li>Completing and submitting the GSDB Online Registration Form.</li>
    <li>Executive Sponsor approving and submitting the Authorization Form.</li>
    <li>Link: <a class="text-decoration-underline">GDPR Fair Collection Notice</a></li>
  </ul>
  <form [formGroup]="gsdbForm" (ngSubmit)="onSubmit()">
    <div class="flex">
      <p class="covisint-heading inline-block">Enter your Covisint ID to begin the registration process:</p>
      <div>
        <input type="text" class="input pl-2 pr-2 ml-2" formControlName="covisintId"/>
        <div *ngIf="gsdbForm.get('covisintId')!.invalid && gsdbForm.get('covisintId')!.touched">
          <small class="warning" *ngIf="gsdbForm.get('covisintId')?.errors?.['required']">Covisint ID required.</small>
          <small class="warning"
                 *ngIf="gsdbForm.get('covisintId')?.errors?.['covisintId'] || gsdbForm.get('covisintId')?.errors?.['maxlength']">
            Covisint ID should not exceed 8 characters.</small>
        </div>
      </div>
    </div>
    <p class="warning">NOTE: Only Ford Supplier Portal (FSP) Company Service Administrators can request access.</p>
    <div class="flex items-center justify-center registration-container">
      <button type="submit" class="xml-button" [disabled]="gsdbForm.invalid">Begin Registration</button>
    </div>
  </form>
  <p class="para-title pl-4 mt-4 mb-1" id="benefits"><span class="font-size">(top)</span> <b> Benefits of GSDB
    Online:</b></p>
  <p> The Global Supplier Database (GSDB) Online application allows users the ability to validate, update, and directly
    control the quality of their company data held by Ford. GSDB Online provides access to data that was previously
    unavailable without Ford intervention.</p>
  <p id="supplierInfo" class="para-title pl-4 mt-4 mb-1"><span class="font-size">(top)</span> <b> GSDB Online Supplier
    Information:</b>
  </p>
  <ul class="list-disc">
    <li>Company address, status and key personnel contact information</li>
    <li>Bank routing details required to send Electronic Funds Transfer (EFT) payments</li>
    <li>Q1 Capability Standards for contract consideration</li>
    <li>Tax details to ensure accurate records and government reporting</li>
  </ul>
  <p id="security" class="para-title pl-4 mt-4 mb-1"><span class="font-size">(top)</span> <b> Security and GSDB
    Online:</b>
  <ul class="list-disc">
    <li>Only authorized users may view or update company information.
    <li>All updates to your company data will be recorded for quality assurance purposes.</li>
    <li> Notifications of changes to your company data are sent to a designated person in your company.</li>
  </ul>
  <p id="additionalInfo" class="para-title pl-4 mt-4 mb-1"><span class="font-size">(top)</span> <b> Additional
    Information</b>
  <ul class="list-disc">
    <li>Registration is required before your company can be granted access to the GSDB Online application.</li>
    <li>There is <b>no cost</b> to your company to use GSDB Online.</li>
  </ul>
  <hr class="mt-6 mb-4">
  <div>
    <p class="m-0 text-center">Questions about GSDB Online?</p>
    <p class="m-0 text-center">Contact <span class="text-decoration-underline">gsdbweb@ford.com</span> or access
      <a routerLink="/support" class="nav-link"> Ford Help Desk support</a></p>
    <p class="m-0 text-center">The customer support center is available 24 hours each day.</p>
  </div>
</div>
