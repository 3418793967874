import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppLookup } from 'src/app/models/perl-migration-web/app-lookup/app-lookup.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppLookupService {

  private url = environment.perlMigrationApiUrl;

  constructor(private http: HttpClient) { }

  getAppList(): Observable<String[]>{
    return this.http.get<String[]>(this.url+'/api/v1/appList');
  }

  getAppDetails(appName: String):Observable<AppLookup>{
    return this.http.get<AppLookup>(this.url+'/api/v1/appList/'+appName);
  }
}
