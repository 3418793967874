import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SelfRemovalRecords, UserSelfRemovalDetails} from "../../../models/paars-web/userSelfRemoval";

@Injectable({
  providedIn: 'root'
})
export class UserSelfRemovalService {

  private url = environment.paarsWebApiUrl;

  constructor(private http: HttpClient) {
  }

  getSelfRemovalDetails(): Observable<UserSelfRemovalDetails> {
    return this.http.get<UserSelfRemovalDetails>(this.url + '/api/v1/getUserSelfRemoval')
  }
  submitSelfRemovalDetails(selfRemovalRecords:SelfRemovalRecords[]): Observable<UserSelfRemovalDetails> {
    return this.http.put<UserSelfRemovalDetails>(this.url + '/api/v1/updateUserSelfRemoval', selfRemovalRecords)
  }
}
