import {Component} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {GsdbOnlineAuthService} from "../service/gsdb-online-auth.service";
import {GsdbOnlineAuthRequestBody} from "../../../models/perl-migration-web/gsdb-online/gsdbOnlineAuthRequestBody";
import {GsdbOnlineInprocesRes} from "../../../models/perl-migration-web/gsdb-online/gsdbOnlineAuthorization";
import {Router} from "@angular/router";

@Component({
  selector: 'app-gsdb-auth-inprocess',
  templateUrl: './gsdb-auth-inprocess.component.html',
  styleUrls: ['./gsdb-auth-inprocess.component.scss']
})
export class GsdbAuthInprocessComponent {

  gsdbForm: FormGroup;
  authorized = [
    {value: 'Y', label: 'Yes'},
    {value: 'N', label: 'No'}
  ];
  corpSecAdmin = [
    {value: 'Y', label: 'Approve'},
    {value: 'N', label: 'Disapprove  '}
  ];
  isAccepted = [
    {value: 'Y', label: 'Accept'},
    {value: 'N', label: 'Do Not Accept '}
  ];
  ackAccepted = [
    {value: 'Y', label: 'Accept'},
    {value: 'N', label: 'Do Not Accept '}
  ];

  constructor(private fb: FormBuilder, private authService: GsdbOnlineAuthService, private router: Router) {
    this.gsdbForm = this.fb.group({
      selAuthorized: [''],
      selCorpSecAdmin: [''],
      selAccepted: [''],
      selAckAccepted: ['']
    });
  }

  onSubmit() {
    let requestBody: GsdbOnlineAuthRequestBody = new GsdbOnlineAuthRequestBody()
    requestBody.positionConfirm = this.gsdbForm.get('selAuthorized')?.value
    requestBody.authorizedCSA = this.gsdbForm.get('selCorpSecAdmin')?.value
    requestBody.acceptResponsibility = this.gsdbForm.get('selAccepted')?.value
    requestBody.acceptLiability = this.gsdbForm.get('selAckAccepted')?.value
    this.authService.authGsdbOnlinInprocessRequest(requestBody).subscribe((data: GsdbOnlineInprocesRes) => {
      if (data.status === "accepted") {
        this.router.navigate(['/perlMigrationWeb/gsdbOnlineAuthorization/accepted']);
      } else if (data.status === "badStatus" || data.status === "notAccepted") {
        this.router.navigate(['/perlMigrationWeb/gsdbOnlineAuthorization/not-accepted']);
      }
    })
  }

}
