import { Component } from '@angular/core';

@Component({
  selector: 'app-gsdb-contacts-main',
  templateUrl: './gsdb-contacts-main.component.html',
  styleUrls: ['./gsdb-contacts-main.component.scss']
})
export class GsdbContactsMainComponent {

}
