export class GsdbOnlineAuthRequestBody {
  public positionConfirm: string = ''
  public authorizedCSA: string = ''
  public acceptResponsibility: string = ''
  public acceptLiability: string = ''
  public pin: string = ''
  public lastname: string = ''

  constructor() {
  }
}
