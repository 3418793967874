<div>
  <div class="content-container">
    <div class="flex">
      <img src="./assets/ford.png" alt="Ford" class="ford-icon"/>
      <p class="heading m-auto">Annual Approval Chain Review Page</p>
    </div>
    <div class="flex justify-center items-center">
      <p class="annual-btn" routerLink="/PAARS/userHierarchy/home">Home Page</p>
    </div>
    <div class="inner-container">
      <div class="alert-container" *ngIf="saveFlag">
        <p class="text-center mb-0 font-bold">Displayed records can be unselected or changed. Click on "OK" to save
          these actions.</p>
        <p class="text-center mt-0 font-bold mb-1">Click on "Cancel" to ignore any changes and return to the prior
          screen.</p>
        <div class="flex justify-content-center">
          <button class="confirm-btn mr-2" (click)="ok()">OK</button>
          <button class="confirm-btn ml-2" (click)="cancel()">Cancel</button>
        </div>
      </div>
      <div *ngIf="!saveFlag">
        <p class="font-bold font-size-12 text-center">The records assigned to you for review are displayed below. Please
          select Appr, Del or Not Chain Controller for
          each record.</p>
        <div class="flex items-center mb-1">
          <p class="annual-btn m-0 btn-width text-center" (click)="submitRecords()">Submit</p>
          <p class="ml-2 mb-0 font-size-12"> Click the Submit button to save/send z responses and exit the screen.</p>
        </div>
        <div class="flex items-center mb-1">
          <p class="annual-btn m-0 btn-width text-center" (click)="save()">Save</p>
          <p class="ml-2 mb-0 font-size-12">Click the Save button to save your responses and remain on the screen.</p>
        </div>
        <div class="flex items-center mb-1">
          <p class="annual-btn m-0 btn-width text-center" (click)="getUncompletedRecords()">Uncompleted</p>
          <p class="ml-2 mb-0 font-size-12"> To view the uncompleted records click the Uncompleted button.</p>
        </div>
        <div class="flex width-450 mb-1" (click)="addNewController()"><p class="annual-btn inline-block flex-1">Click
          here to Reassign All approval
          Chains to a different Controller</p></div>
        <div class="flex width-450">
          <div class="annual-btn inline-block flex-1" (click)="addNewApprover()">Click here to Delete or Replace an
            Approve multiple chains
          </div>
        </div>
        <p class="font-size-12 mt-1"><i>To <b>SEARCH</b> for a CDS ID, Approval Chain and/or Div/Plt Code in your
          records
          use the search boxes provided and click Search.</i></p>
        <div class="font-size-12 mb-4" [formGroup]="searchGroup">
          <label>CDS ID</label>
          <input type="text" class="input" formControlName="cdsId"/>
          <label>Approval Chain</label>
          <input type="text" class="input" formControlName="approvalChain"/>
          <label>Division/Plant Code</label>
          <input type="text" class="input" formControlName="divisionOrPlantCode"/>
          <span class="annual-btn" type="submit" (click)="filterRecordsBySearch()">Search</span>
          <span class="ml-2">To redisplay ALL RECORDS click on the Search button.</span>
        </div>
        <i class="font-size-12">To search for a new controller or new approver CDS ID in Super Duper CDS, click
          <a class="nav-link" rel="external" target="_blank" href="http://www.sdcds.ford.com">HERE</a></i>
      </div>
      <form [formGroup]="userHierarchyDetails" class="mt-6">
        <table class="content-align table font-size-12">
          <tr>
            <th class="table-th">Not Chain Controller</th>
            <th class="table-th">New Controller</th>
            <th class="table-th">Appr</th>
            <th class="table-th">Del</th>
            <th class="table-th">Approver CDSID</th>
            <th class="table-th">Approver Name</th>
            <th class="table-th">New Approver</th>
            <th class="table-th">Div Plt</th>
            <th class="table-th">Approval Chain Name</th>
            <th class="table-th">Apv ID</th>
            <th class="table-th">Lvl</th>
            <th class="table-th">Approval Limit</th>
            <th class="table-th">Approval Limit/Comments</th>
          </tr>
          <tbody formArrayName="records" *ngFor="let user of records.controls;let i=index;">
          <tr [formGroupName]="i">
            <td class="td-border">
              <div><input type="radio" formControlName="approve" value="ncc" (change)="enableNcc(user)"></div>
            </td>
            <td class="td-border">
              <div><input class="input-text" formControlName="newController" (input)="displayUpperNcc(user)"></div>
            </td>
            <td class="td-border">
              <div><input type="radio" formControlName="approve" value="Y" (change)="disableNcc(user)"></div>
            </td>
            <td class="td-border">
              <div><input type="radio" formControlName="approve" value="N" (change)="disableNcc(user)"/></div>
            </td>
            <td class="td-border">
              <div>{{ user.get('approverCdsId')?.value }}</div>
            </td>
            <td class="td-border">
              <div>{{ user.get('approverName')?.value }}</div>
            </td>
            <td class="td-border">
              <div><input type="text" class="input-text" formControlName="newApprover"
                          (input)="displayUpperNewApprover(user)"/></div>
            </td>
            <td class="td-border">
              <div>{{ user.get('divPlt')?.value }}</div>
            </td>
            <td class="td-border">
              <div>{{ user.get('approvalChainName')?.value }}</div>
            </td>
            <td class="td-border">
              <div>{{ user.get('apvId')?.value }}</div>
            </td>
            <td class="td-border">
              <div>{{ user.get('lvl')?.value }}</div>
            </td>
            <td class="td-border">
              <div>{{ user.get('approvalLimit')?.value }}</div>
            </td>
            <td class="td-border">
              <div><textarea class="input-text-area" formControlName="limitOrComments"></textarea></div>
            </td>
          </tr>
          </tbody>
          <tbody *ngIf="hasRecords">
          <tr>
            <td colspan="12" class="text-center font-bold">No Records Found</td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
