import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataLoaderService } from '../service/data-loader.service';
import { error } from 'console';

@Component({
  selector: 'app-data-loader',
  templateUrl: './data-loader.component.html',
  styleUrls: ['./data-loader.component.scss']
})
export class DataLoaderComponent {

  uploadFileGroup: FormGroup;
  filename:String ='';

  formDisabled:boolean = true;

  constructor(private fb: FormBuilder,private apiService:DataLoaderService ){
      this.uploadFileGroup= this.fb.group({
        file: [null,Validators.required]
      });
  }

  onSubmit(){
    const file: File = this.uploadFileGroup.get('file')!.value;
    this.filename = file.name;
    this.uploadDataLoaderFile(file);
  }

  onFileUpload(event:Event){
    if(this.formDisabled  == false){
      this.formDisabled=true;
    }
    const input = event.target as HTMLInputElement;
      if(input.files && input.files.length>0){
        const file = input.files[0];
        this.uploadFileGroup.patchValue({file : file})
      }
  }


  uploadDataLoaderFile(file:File){
    this.apiService.uploadDataLoaderFile(file).subscribe((data)=>{
      console.log('uploaded file with file name : ' +this.filename);
      this.formDisabled=false;
    },error =>{
        console.log(error);
    })
  }

}
