<div class="container-fluid">
    <div class="row parent">
        <div class="col-4 image1">
            <img src="\assets\top_left_ariba.gif">
        </div>
        <div class="col-8 image2">
            <div class="row row-height">
                <p class="font1">Ariba Online Access</p>
            </div>
            <div class="row row-height2">
                <div class="background-color"></div>
            </div>
        </div>
    </div>
</div>