<app-gsdb-online-header></app-gsdb-online-header>
<div class="flex flex-col items-center justify-center mt-8">
  <div class="restricted-container">
    <p class="warning text-center">WARNING!!</p>
    <p class="text-center">Access to this page is restricted.</p>
    <p class="text-center">You have entered a Covisint (FSP) user ID that is not designated as the Primary CSA of your company.</p>
    <p class="text-center">Use the <a class="color" href="/perlMigrationWeb/memreg">CSA look up tool</a> to see a list of CSA's in your company. Or access
      <a class="color" routerLink="/support">Ford Help Desk Support</a></p>
  </div>
</div>
