<div class="terms-conditions-container">
  <p class="heading pl-1">Production Terms and Conditions</p>
  <div>
    <table class="m-2 table-container" border="1" cellpadding="3" cellspacing="0">
      <tr>
        <th>Effective Date</th>
        <th>Document Name</th>
        <th>Language</th>
        <th>Contact Name</th>
        <th>Description</th>
      </tr>
      <tr>
        <td>7/1/2021</td>
        <td><a
          class="color"
          href="https://corporate.ford.com/operations/governance-and-policies/production-purchasing-global-terms-and-conditions.html">Production
          Purchasing Global Terms and Conditions</a></td>
        <td>English</td>
        <td>Your Buyer</td>
        <td>PPGTC JUL. 1 2021</td>
      </tr>
      <tr>
        <td>7/1/2021</td>
        <td><a
          class="color"
          href="https://corporate.ford.com/operations/governance-and-policies/production-purchasing-global-terms-and-conditions.html">Production
          Purchasing Global Terms and Conditions</a></td>
        <td>Spanish</td>
        <td>Your Buyer</td>
        <td>PPGTC JUL. 1 2021</td>
      </tr>
      <tr>
        <td>7/1/2021</td>
        <td><a
          class="color"
          href="https://corporate.ford.com/operations/governance-and-policies/production-purchasing-global-terms-and-conditions.html">Production
          Purchasing Global Terms and Conditions</a></td>
        <td>German</td>
        <td>Your Buyer</td>
        <td>PPGTC JUL. 1 2021</td>
      </tr>
      <tr>
        <td>7/1/2021</td>
        <td><a
          class="color"
          (click)="showChinesTermsPdf()">Production Purchasing Global Terms and Conditions</a></td>
        <td>Chinese</td>
        <td>Your Buyer</td>
        <td>PPGTC JUL. 1 2021</td>
      </tr>
      <tr>
        <td>7/1/2021</td>
        <td><a
          class="color"
          href="https://corporate.ford.com/operations/governance-and-policies/production-purchasing-global-terms-and-conditions.html">Production
          Purchasing Global Terms and Conditions</a></td>
        <td>Portuguese</td>
        <td>Your Buyer</td>
        <td>PPGTC JUL. 1 2021</td>
      </tr>
    </table>
  </div>
</div>
