export class AppRegistration{
    constructor( public applicationName:string,
        public appNameOnPortal:string,
        public prodUrl:string,
        public devQaUrl:string,
        public purposeApplication:string,
        public typeOfInformation:string,
        public informationDetails:string,
        public appCategory:string,
        public kaInstruction:string,
        public businessContact:string,
        public primaryContact:string,
        public bcaApplication:string,
        public acrNumber:string,
        public applicationAvailable:string,
        public applicationAdmin:string,
        public tokenName:string,
        public gsdbCodeToken:string,
        public apsAccess:string,
        public supplierAccess:string,
        public gsdbCodeStatus:string,
        public supplierOrg:string,
        public estUserAccess:string,
        public interFordEmp:string,
        public estProjectLaunch:string){}
}