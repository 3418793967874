<app-app-launch-header></app-app-launch-header>
<div class="container-fluid page-color">
    <div class="row">
        <div class="col-12 page-title">
           <b> FSP Application Registration Form</b><br>
        </div>
    </div>
    <div class="row row-overflow"  [hidden]="formSubmitted">
        <div class="col-11 text-danger warning ">
           <b> Application Launches Require at Least 30 Days Notice After Approval</b>
        </div>
        <div class="col-1"></div>
    </div>

    <div class="row">
        <div class="col-12 page-title text-danger" [hidden]="!formSubmitted">
           <b> FSP Application Registration Form Submitted Successfully</b><br>
        </div>
    </div>

    <div class="row">
        <div class="col-12 form-text">
            <div class="row">
                <div class="col-5">
                    <span class="text-danger">* &nbsp;</span>
                    <span><b>Required Fields have a red asterisk &nbsp;</b></span>
                    <span class="text-danger">*</span>
                </div>
            </div><br>

            <form [formGroup] = "appRegistrationForm" (ngSubmit)="onSubmit()">

                <div class="row spacing">
                    <div class="col-5">
                        <label class="label-padding">Application Name:<span class="text-danger">*</span></label>
                    </div>
                    <div class="col-5">
                        <textarea class="input-text" formControlName="appName"></textarea><br>
                        <span id="appNameError" *ngIf="appRegistrationForm.controls['appName'].invalid 
                        && (appRegistrationForm.controls['appName'].dirty ||
                         appRegistrationForm.controls['appName'].touched)" class="text-danger">Required Field</span>
                    </div>
                </div>

                <div class="row form-color">
                    <div class="col-5">
                        <label class="label-padding">ITMS #:<span class="text-danger">*</span></label>
                    </div>
                    <div class="col-5 ">
                        <input type="text" formControlName="appNameOnPortal" class="input-text"><br>
                        <span id="appNameError" *ngIf="appRegistrationForm.controls['appNameOnPortal'].invalid 
                        && (appRegistrationForm.controls['appNameOnPortal'].dirty ||
                         appRegistrationForm.controls['appNameOnPortal'].touched)" 
                         class="text-danger">Required Field</span>
                    </div>
                </div>

                <div class="row spacing">
                    <div class="col-5">
                        <label class="label-padding">Production URL (ex. http://www.ford.com):
                            <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-5 ">
                        <textarea class="input-text" formControlName="prodURL"></textarea><br>
                        <span id="appNameError" *ngIf="appRegistrationForm.controls['prodURL'].invalid 
                        && (appRegistrationForm.controls['prodURL'].dirty ||
                         appRegistrationForm.controls['prodURL'].touched)" 
                         class="text-danger">Required Field</span>
                    </div>
                </div> 

                <div class="row form-color">
                    <div class="col-5">
                        <label class="label-padding">DEV and QA URLS, if applicable:
                            </label>
                    </div>
                    <div class="col-5 ">
                        <textarea class="input-text" formControlName="dev_qa_Url"></textarea><br>
                        <span id="appNameError" *ngIf="appRegistrationForm.controls['dev_qa_Url'].invalid 
                        && (appRegistrationForm.controls['dev_qa_Url'].dirty ||
                         appRegistrationForm.controls['dev_qa_Url'].touched)" 
                         class="text-danger">Required Field</span>
                    </div>
                </div> 

                <div class="row spacing">
                    <div class="col-5">
                        <label class="label-padding">What is the purpose of this application? 
                            <span class="text-danger">*</span>
                            </label>
                    </div>
                    <div class="col-5 ">
                        <textarea class="input-text" formControlName="purpose_Application"></textarea><br>
                        <span id="appNameError" *ngIf="appRegistrationForm.controls['purpose_Application'].invalid 
                        && (appRegistrationForm.controls['purpose_Application'].dirty ||
                         appRegistrationForm.controls['purpose_Application'].touched)" 
                         class="text-danger">Required Field</span>
                    </div>
                </div> 

                <div class="row form-color">
                    <div class="col-5">
                        <label class="label-padding">What type of information is in the application and who is this information meant for? 
                            </label>
                    </div>
                    <div class="col-5 ">
                        <textarea class="input-text" formControlName="type_of_Information"></textarea>
                    </div>
                </div>
                
                <div class="row spacing">
                    <div class="col-5">
                        <label >Information about your application will be required for the 'Details' link that will be associated with it. Who will provide this information?
                            <span class="text-danger">*</span>
                            </label>
                    </div>
                    <div class="col-5 ">
                        <textarea class="input-text" formControlName="information_details"></textarea><br>
                        <span id="appNameError" *ngIf="appRegistrationForm.controls['information_details'].invalid 
                        && (appRegistrationForm.controls['information_details'].dirty ||
                         appRegistrationForm.controls['information_details'].touched)" 
                         class="text-danger">Required Field</span>
                    </div>
                </div> 

                <div class="row form-color">
                    <div class="col-5">
                        <label >Application Category - What category will the site be listed under?                            <span class="text-danger">*</span>
                            </label>
                    </div>
                    <div class="col-5 ">
                        <select name="appCategory" formControlName="appCategory" class="input-text">
                            <option value="0" selected></option>
                            <option value="Customer Service">Customer Service</option>
                            <option value="Environmental">Environmental</option>
                            <option value="Finance">Finance</option>
                            <option value="Manufacturing">Manufacturing</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Product Development">Product Development</option>
                            <option value="Purchasing">Purchasing</option>
                            <option value="Quality" selected>Quality</option>
                            <option value="Vehicle Programs">Vehicle Programs</option>
                            <option value="Supplier Development">Supplier Development</option> 
                        </select>
                    </div>
                </div>

                <div class="row spacing">
                    <div class="col-5">
                        <label >Who will be responsible (must be CDSID) for providing the Ford Help Desk
                             with a Knowledge Article (KA) providing instructions on how a user should be 
                             handled if they contact the Ford Help Desk regarding this application? 
                            <span class="text-danger">*</span>
                            </label>
                    </div>
                    <div class="col-5">
                        <textarea class="input-text" formControlName="ka_instruction" style="height: 100%;"></textarea>
                        <br>
                        <span id="appNameError" *ngIf="appRegistrationForm.controls['ka_instruction'].invalid 
                        && (appRegistrationForm.controls['ka_instruction'].dirty ||
                        appRegistrationForm.controls['ka_instruction'].touched)" 
                        class="text-danger">Required Field</span>
                        </div>
                </div>

                <div class="row form-color">
                    <div class="col-5">
                        <label >Who is the Business Owner of this application (CDS ID)? 
                            <span class="text-danger">*</span>
                            </label>
                    </div>
                    <div class="col-5">
                        <input type="text" formControlName="businessContact" class="input-text"><br>
                        <span id="appNameError" *ngIf="appRegistrationForm.controls['businessContact'].invalid 
                        && (appRegistrationForm.controls['businessContact'].dirty ||
                        appRegistrationForm.controls['businessContact'].touched)" 
                        class="text-danger">Required Field</span>
                    </div>
                </div>

                <div class="row spacing">
                    <div class="col-5">
                        <label >Who is the Primary Contact for this application (CDS ID)? 
                            <span class="text-danger">*</span>
                            </label>
                    </div>
                    <div class="col-5">
                        <input type="text" class="input-text" formControlName="primaryContact"><br>
                        <span id="appNameError" *ngIf="appRegistrationForm.controls['primaryContact'].invalid 
                        && (appRegistrationForm.controls['primaryContact'].dirty ||
                        appRegistrationForm.controls['primaryContact'].touched)" 
                        class="text-danger">Required Field</span>
                    </div>
                </div>

                <div class="row form-color">
                    <div class="col-5">
                        <label >What is the Business Criticality Assessment (BCA) rating for this application?                            <span class="text-danger">*</span>
                            </label>
                    </div>
                    <div class="col-5 ">
                        <select name="bca_Application" formControlName="bca_Application" class="input-text">
                            <option value="0" selected></option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                            <option value="E">E</option>
                        </select>
                    </div>
                </div>
                <div class="row spacing">
                    <div class="col-5">
                        <label >What is the Application Control Review (ACR) number for this application?  
                            <span class="text-danger">*</span>
                            </label>
                    </div>
                    <div class="col-5">
                        <input type="text" class="input-text" formControlName="aCRNumber"><br>
                        <span id="appNameError" *ngIf="appRegistrationForm.controls['aCRNumber'].invalid 
                        && (appRegistrationForm.controls['aCRNumber'].dirty ||
                        appRegistrationForm.controls['aCRNumber'].touched)" 
                        class="text-danger">Required Field</span>
                    </div>
                </div>
                <div class="row form-color">
                    <div class="col-5">
                        <label >How should this application be made available on FSP? <span class="text-danger">*</span>
                            <br><ol style="list-style: decimal;">
                                <li> <u>Granted to all users</u> with FSP access
                                <br><li><u>Granted to all SAO</u> organizations with FSP access. This will require that the organization's Covisint CSA grant the application to the appropriate users.
                                <br><li><u>Requested</u> - the Organization's CSA must request the application on behalf of their organization. This request will need to be approved by the Ford Application Administrator before it will be available to the organization and the CSA can grant it to users.
                            </ol>                           
                            </label>
                    </div>
                    <div class="col-5" style="padding-top: 75px;">
                        <select name="application_Avlble" formControlName="application_Avlble" class="input-text">
                            <option value="0" selected></option>
                            <option value="1">1. Granted to all</option>
                            <option value="2">2. Granted to SAO</option>
                            <option value="3">3. Request</option>
                        </select>
                    </div>
                </div>

                <div class="row spacing">
                    <div class="col-5">
                        <label>If option 3 was selected who will be the Application Administrator(s) for your application (CDS ID)?
                            </label>
                    </div>
                    <div class="col-5 ">
                        <textarea class="input-text" formControlName="application_Admin"></textarea>
                    </div>
                </div>

                <div class="row form-color">
                    <div class="col-5">
                        <label class="label-padding">If option 2 or 3 were selected what is the 'Token Name' for your application?
                            </label>
                    </div>
                    <div class="col-5 ">
                        <textarea class="input-text" formControlName="tokenName"></textarea>
                    </div>
                </div>

                <div class="row spacing">
                    <div class="col-5">
                        <label>Will GSDB codes need to be included in the Token?
                            </label>
                    </div>
                    <div class="col-5 ">
                       <Span> Yes &nbsp;<input type="radio" id="gsdbCode" formControlName="gsdb_code_Token" value="Y"></Span>
                       &nbsp;&nbsp;
                       <Span> NO &nbsp;<input type="radio" id="gsdbCode"  formControlName="gsdb_code_Token" value="N"></Span>
                    </div>
                </div>
                <div class="row spacing">
                    <div class="col-5">
                        <label>Will your application be using APS (Application Policy Services)?
                            </label>
                    </div>
                    <div class="col-5 ">
                       <Span> Yes &nbsp;<input type="radio" id="aps" formControlName="aps_access" value="Y"></Span>
                       &nbsp;&nbsp;
                       <Span> NO &nbsp;<input type="radio" id="aps" formControlName="aps_access" value="N"></Span>
                    </div>
                </div>
                <div class="row form-color">
                    <div class="col-5">
                        <label>Will the suppliers that need to access this application have GSDB codes? 
                            <span class="text-danger">*</span>
                            </label>
                    </div>
                    <div class="col-5 ">
                       <Span> Yes &nbsp;<input type="radio" id="supplierSwitch" formControlName="supplier_Access" value="Y"></Span>
                       &nbsp;&nbsp;
                       <Span> NO &nbsp;<input type="radio" id="supplierSwitch" formControlName="supplier_Access" value="N"></Span>
                    </div><br>
                    <span id="appNameError" *ngIf="appRegistrationForm.controls['supplier_Access'].invalid 
                    && (appRegistrationForm.controls['supplier_Access'].dirty ||
                    appRegistrationForm.controls['supplier_Access'].touched)" 
                    class="text-danger">Required Field</span>
                </div>
                <div class="row spacing">
                    <div class="col-5">
                        <label>Will the GSDB code status of the suppliers meet the 
                            requirement below? <span class="text-danger">*</span><br>
                            Requirement: only suppliers whose GSDB code is 'Active for
                             Purchasing' (code 90) or 'Active Tier 2' (Toxicology - code 73) 
                             will be granted access to FSP. 
                            
                            </label>
                    </div>
                    <div class="col-5" style="padding-top: 2%;">
                       <Span> Yes &nbsp;<input type="radio" id="reqSwitch" formControlName="gsdb_code_status" value="Y"></Span>
                       &nbsp;&nbsp;
                       <Span> NO &nbsp;<input type="radio" id="reqSwitch" formControlName="gsdb_code_status" value="N"></Span>
                    </div><br>
                    <span id="appNameError" *ngIf="appRegistrationForm.controls['gsdb_code_status'].invalid 
                    && (appRegistrationForm.controls['gsdb_code_status'].dirty ||
                    appRegistrationForm.controls['gsdb_code_status'].touched)" 
                    class="text-danger">Required Field</span>
                </div>

                <div class="row form-color">
                    <div class="col-5">
                        <label>Would the supplier organizations that need access to this application 
                            already have FSP access for other applications?  
                            <span class="text-danger">*</span>
                            </label>
                    </div>
                    <div class="col-5" style="padding-top: 1%;">
                       <Span> Yes &nbsp;<input type="radio" id="fspSwitch" formControlName="supplier_Org" value="Y"></Span>
                       &nbsp;&nbsp;
                       <Span> NO &nbsp;<input type="radio" id="fspSwitch" formControlName="supplier_Org" value="N"></Span>
                    </div><br>
                    <span id="appNameError" *ngIf="appRegistrationForm.controls['supplier_Org'].invalid 
                    && (appRegistrationForm.controls['supplier_Org'].dirty ||
                    appRegistrationForm.controls['supplier_Org'].touched)" 
                    class="text-danger">Required Field</span>
                    
                </div>

                <div class="row spacing">
                    <div class="col-5">
                        <label>Would the supplier organizations that need access to this application 
                            already have FSP access for other applications?  
                            <span class="text-danger">*</span>
                            </label>
                    </div>
                    <div class="col-5" style="padding-top: 0.5%;" >
                       <input type="textb" class="input-text" formControlName="est_user_Access">
                    </div><br>
                    <span id="appNameError" *ngIf="appRegistrationForm.controls['est_user_Access'].invalid 
                    && (appRegistrationForm.controls['est_user_Access'].dirty ||
                    appRegistrationForm.controls['est_user_Access'].touched)" 
                    class="text-danger">Required Field</span>
                </div>

                <div class="row form-color">
                    <div class="col-5">
                        <label>Will Ford employees require access to the application? 
                            If yes, how will internal Ford employee access be supported?
                            <span class="text-danger">*</span><br>
                            Note: Please be aware that Ford employees are typically not 
                            granted access to FSP and are expected to get access to FSP applications internally. 
                            </label>
                    </div>
                    <div class="col-5" style="padding-top: 2.5%;" >
                       <textarea class="input-text" formControlName="inter_ford_Emp"></textarea>
                    </div><br>
                    <span id="appNameError" *ngIf="appRegistrationForm.controls['inter_ford_Emp'].invalid 
                    && (appRegistrationForm.controls['inter_ford_Emp'].dirty ||
                    appRegistrationForm.controls['inter_ford_Emp'].touched)" 
                    class="text-danger">Required Field</span>
                </div>

                <div class="row spacing">
                    <div class="col-5">
                        <label>What is the estimated Production launch date for your application?
                            </label>
                    </div>
                    <div class="col-5"  >
                        <input type="textb" class="input-text" formControlName="est_project_launch">
                    </div>
                </div>

                <div class="row spacing" *ngIf="!formSubmitted">
                    <div class="col-5" >
                        <button class="btn btn-primary btn-sm btn-size" [disabled]="appRegistrationForm.invalid"
                        type="submit"> Submit form !</button>
                    </div>
                    <div class="col-5">
                        <button class="btn btn-primary btn-sm btn-size" type="reset"> Reset form !</button>
                    </div>
                </div>


                <div class="row spacing" style="text-align: center;" *ngIf="formSubmitted">
                    <div class="col-9">
                        <button class="btn btn-primary btn-sm btn-size" (click)="back($event)"> Back to FSP Registration</button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    

</div>