<app-common-header></app-common-header>
<div class="page-title">
  <img src="./assets/gsdb3b.gif"/>
</div>
<div class="main-container">
  <div class="flex justify-content-between">
    <p class="ml-2 mt-2 color font-bold">Supplier Company Contacts</p>
    <p routerLink="/support" class="mr-2 mt-2 help-btn">Help</p>
  </div>
  <div class="body">
    <app-gsdb-contacts-sidebar></app-gsdb-contacts-sidebar>
    <div class="right-side">
      <p class="text-center font-bold mb-0"><b>Notice to Ford Supplier Network CSAs</b></p>
      <p class="text-center font-bold mt-0"><b>Complete Executive Contact Information Forms</b></p>
      <p>In efforts to improve communication initiatives, Ford Motor Company is requiring all Ford Supplier Portal (FSP)
        registered suppliers to update their executive contact information.</p>
      <p>All contact information for supplier executives will be used exclusively for communications from Ford Motor
        Company executives to supplier company executives. The contact information for these executives will not be
        distributed.</p>
      <p>Update and submit contact information for each executive title listed in the left hand column of this page.<b>
        The
        executive titles should be matched to the parent company (4-character GSDB code) of your company</b>. Additional
        forms
        to submit executive contact information for subsidiary companies and divisions will be made available at a
        future
        date.</p>
      <p>Required contact information includes contact information for a designated<b> 'Emergency Business Contact'</b>.
        This
        person should have the authority to make business decisions if the primary executive in the listed position is
        unavailable. Therefore, the Emergency Contact will be considered 'second in command' to the named executive.
        Also, the forms provide a<b> 'Preferred Job Title'</b> for each executive (just in case their title is different
        than the position titles, President/CEO, Chief Financial Officer, etc., listed in the left hand column).</p>
      <p><b>If you are not the primary CSA for your company </b>, please notify Ford by accessing the
        <a routerLink="/support" class="navigation">Ford Help Desk support</a> to
        obtain help desk phone and email contact information. The information required to designate a Primary CSA will
        include your GSDB code, the person's name, telephone number, email address and FSP User ID (if they already have
        an existing ID).</p>
      <p>Your cooperation in submitting this information is greatly appreciated.</p>
    </div>
  </div>
  <hr class="mb-0"/>
  <div class="mb-4 text-sm">
    <a class="mt-0 mr-2 navigation"
       href="https://e3.ford.com/eRoom/fordna7/FSN/Contacts%20Database/HTML%20Screens/template.html#Top">Top</a> © 1998,
    Ford Motor Company
  </div>
</div>
