import {Component} from '@angular/core';

@Component({
  selector: 'app-gsdb-delete-query',
  templateUrl: './gsdb-delete-query.component.html',
  styleUrls: ['./gsdb-delete-query.component.scss']
})
export class GsdbDeleteQueryComponent {

  message: string = 'Deleted successfully.'

}
