<app-common-header></app-common-header>
<div class="row">
  <div class="page-title">
    <b>Ford People Search :</b>
  </div>
</div>
<form [formGroup]="fordDetailsForm" (ngSubmit)="onSubmit()">
  <div class="mt-4 ml-2 container">
    <div>
      <p class="people-search">Ford People Name</p>
      <input type="text" class="input pl-2 pr-2" formControlName="peopleName"/>
      <div *ngIf="fordDetailsForm.get('peopleName')!.invalid && fordDetailsForm.get('peopleName')!.touched">
        <small class="warning" *ngIf="fordDetailsForm.get('peopleName')?.errors?.['required']">FordPeople Name is
          required.</small>
      </div>
    </div>
    <button type="submit" class="block mt-2 xml-button" [disabled]="fordDetailsForm.invalid">Display XML</button>
  </div>
</form>

