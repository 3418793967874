import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {GsdbQueryService} from "../service/gsdb-query.service";
import {GsdbQueryResults} from "../../../models/perl-migration-web/gsdb-online/gsdbQueryResults";

@Component({
  selector: 'app-gsdb-query-results',
  templateUrl: './gsdb-query-results.component.html',
  styleUrls: ['./gsdb-query-results.component.scss']
})
export class GsdbQueryResultsComponent implements OnInit {
  resultsForm: FormGroup;
  gsdbQueryResponse!: GsdbQueryResults

  constructor(private fb: FormBuilder, private router: Router, private gsdbQueryService: GsdbQueryService) {
    this.resultsForm = this.fb.group(({
      gsdbCode: [''], companyName: [''], parentGsdbCode: [''], parentCompanyName: [''],
      regStatus: [''], csaName: [''], fsnOrCovisintId: [''], csaEmail: [''],
      csaPhone: [''], csaTitle: [''], csaAddress: [''], csaCity: [''],
      csaState: [''], csaZipCode: [''], csaCountry: [''], csaSupervisor: [''],
      csaSupPhone: [''], csaSupEmail: [''], cesName: [''], cesEmail: [''],
      cesPhone: [''], cesTitle: [''], cesAddress: [''], cesCity: [''],
      cesState: [''], cesZipCode: [''], cesCountry: [''], authGsdbCode: [''],
      authCompanyName: [''], authLastUpdated: [''], authCesName: [''], authCesPhone: [''],
      authCesAccessId: [''], authCesPin: [''], cesTitleFlag: [''],
      csaApproval: [''], cesUpdateFlag: [''], indemnityFlag: ['']
    }))
  }

  onSubmit() {
    this.gsdbQueryService.deleteGsdbOnlineQueryDetails().subscribe((data) => {
      this.router.navigate(['perlMigrationWeb/gsdbDeleteQueryResults'])
    })
  }

  ngOnInit(): void {
    this.gsdbQueryResponse = this.gsdbQueryService.getGsdbQueryResponse();
    this.loadInitialValues()
  }

  loadInitialValues() {
    this.resultsForm.get('gsdbCode')?.setValue(this.gsdbQueryResponse.registrationContacts.gsdbCode)
    this.resultsForm.get('companyName')?.setValue(this.gsdbQueryResponse.registrationContacts.companyName)
    this.resultsForm.get('parentGsdbCode')?.setValue(this.gsdbQueryResponse.registrationContacts.gsdbCode)
    this.resultsForm.get('parentCompanyName')?.setValue(this.gsdbQueryResponse.registrationContacts.companyName)
    this.resultsForm.get('regStatus')?.setValue(this.gsdbQueryResponse.registrationContacts.gsdbStatus)
    this.resultsForm.get('csaName')?.setValue(this.gsdbQueryResponse.registrationContacts.csaName)
    this.resultsForm.get('fsnOrCovisintId')?.setValue(this.gsdbQueryResponse.registrationContacts.csaUserid)
    this.resultsForm.get('csaEmail')?.setValue(this.gsdbQueryResponse.registrationContacts.csaEmail)
    this.resultsForm.get('csaPhone')?.setValue(this.gsdbQueryResponse.registrationContacts.csaPhone)
    this.resultsForm.get('csaTitle')?.setValue(this.gsdbQueryResponse.registrationContacts.csaTitle)
    this.resultsForm.get('csaAddress')?.setValue(this.gsdbQueryResponse.registrationContacts.csaAddress)
    this.resultsForm.get('csaCity')?.setValue(this.gsdbQueryResponse.registrationContacts.csaCity)
    this.resultsForm.get('csaState')?.setValue(this.gsdbQueryResponse.registrationContacts.csaState)
    this.resultsForm.get('csaZipCode')?.setValue(this.gsdbQueryResponse.registrationContacts.csaPostalCode)
    this.resultsForm.get('csaCountry')?.setValue(this.gsdbQueryResponse.registrationContacts.csaCountry)
    this.resultsForm.get('csaSupervisor')?.setValue(this.gsdbQueryResponse.registrationContacts.csaSupervisorName)
    this.resultsForm.get('csaSupPhone')?.setValue(this.gsdbQueryResponse.registrationContacts.csaSupervisorPhone)
    this.resultsForm.get('csaSupEmail')?.setValue(this.gsdbQueryResponse.registrationContacts.csaSupervisorEmail)
    this.resultsForm.get('cesName')?.setValue(this.gsdbQueryResponse.registrationContacts.execName)
    this.resultsForm.get('cesEmail')?.setValue(this.gsdbQueryResponse.registrationContacts.execEmail)
    this.resultsForm.get('cesPhone')?.setValue(this.gsdbQueryResponse.registrationContacts.execPhone)
    this.resultsForm.get('cesTitle')?.setValue(this.gsdbQueryResponse.registrationContacts.execTitle)
    this.resultsForm.get('cesAddress')?.setValue(this.gsdbQueryResponse.registrationContacts.execAddress)
    this.resultsForm.get('cesCity')?.setValue(this.gsdbQueryResponse.registrationContacts.execCity)
    this.resultsForm.get('cesState')?.setValue(this.gsdbQueryResponse.registrationContacts.execState)
    this.resultsForm.get('cesZipCode')?.setValue(this.gsdbQueryResponse.registrationContacts.execPostalCode)
    this.resultsForm.get('cesCountry')?.setValue(this.gsdbQueryResponse.registrationContacts.execCountry)
    this.resultsForm.get('authGsdbCode')?.setValue(this.gsdbQueryResponse.authentication.gsdbCode)
    this.resultsForm.get('authLastUpdated')?.setValue(this.gsdbQueryResponse.authentication.updateDate)
    this.resultsForm.get('authCesName')?.setValue(this.gsdbQueryResponse.authentication.execName)
    this.resultsForm.get('authCesPhone')?.setValue(this.gsdbQueryResponse.authentication.execPhone)
    this.resultsForm.get('cesAccessId')?.setValue(this.gsdbQueryResponse.authentication.execAccessId)
    this.resultsForm.get('authCesPin')?.setValue(this.gsdbQueryResponse.authentication.execPin)
    this.resultsForm.get('cesTitleFlag')?.setValue(this.gsdbQueryResponse.authentication.execTitle)
    this.resultsForm.get('csaApproval')?.setValue(this.gsdbQueryResponse.authentication.csaFlag)
    this.resultsForm.get('cesUpdateFlag')?.setValue(this.gsdbQueryResponse.authentication.updateFlag)
    this.resultsForm.get('indemnityFlag')?.setValue(this.gsdbQueryResponse.authentication.indemFlag)
  }
}
