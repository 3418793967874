import {Component} from '@angular/core';
import {SupplementsServiceProd} from "../service/supplements.service";
import {map} from "rxjs";

@Component({
  selector: 'app-supplements',
  templateUrl: './supplements.component.html',
  styleUrls: ['./supplements.component.scss']
})
export class SupplementsComponent {

  constructor(private supplementsService: SupplementsServiceProd) {
  }

  getChinaAddendumDoc(): void {
    this.supplementsService.getChinaAddendumDoc();
  }

  getChinaAddendumPdf(): void {
    this.supplementsService.getChinaAddendumPdf();
  }

  getGTCsSupplementalTermsBrazil() {
    this.supplementsService.getGTCsSupplementalTermsBrazil();
  }

  getPpGtcSupptermsBrazilPdf() {
    this.supplementsService.getPpGtcSupptermsBrazilPdf();
  }

  getGtcSuppTermsCanada() {
    this.supplementsService.getGtcSuppTermsCanada();
  }

  getPpGtcSuppTermsCanada() {
    this.supplementsService.getPpGtcSuppTermsCanada();
  }

  getGtcSuppTermsMexico() {
    this.supplementsService.getGtcSuppTermsMexico();
  }

  getPpGtcSuppTermsMexico() {
    this.supplementsService.getPpGtcSuppTermsMexico();
  }

  getGtcSuppTermsRussia() {
    this.supplementsService.getGtcSuppTermsRussia();
  }

  getPpGtcSuppTermsRussia() {
    this.supplementsService.getPpGtcSuppTermsRussia();
  }

  getGtcSuppTermsTaiwan() {
    this.supplementsService.getGtcSuppTermsTaiwan();
  }

  getGtcSuppTermsTaiwanPdf() {
    this.supplementsService.getGtcSuppTermsTaiwanPdf();
  }

  getGtcSuppTermsEuropeanUnion() {
    this.supplementsService.getGtcSuppTermsEuropeanUnion();
  }

  getPpGtcSuppTermsEuropeanUnion() {
    this.supplementsService.getPpGtcSuppTermsEuropeanUnion();
  }

  getGtcSuppTermsAustralia() {
    this.supplementsService.getGtcSuppTermsAustralia();
  }

  getPpGtcSuppTermsAustralia() {
    this.supplementsService.getPpGtcSuppTermsAustralia();
  }
}
