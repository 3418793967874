import { Component } from '@angular/core';

@Component({
  selector: 'app-gsdb-auth-accepted',
  templateUrl: './gsdb-auth-accepted.component.html',
  styleUrls: ['./gsdb-auth-accepted.component.scss']
})
export class GsdbAuthAcceptedComponent {

}
