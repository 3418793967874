<div class="page-margin">
    <div class="container-fluid content_container ">
        <div class="row">
            <div class="col-2 image-alignment">
                <img src="/assets/ford.png" alt="Purchasing Annual Access Review System" /> 
            </div>
            <div class="col-10"> 
                <div class="title-alignment row">
                    <b>
                        Purchasing & Associated Systems Annual Access Review Manager Home Page
                    </b>  
                </div>  
            </div>
        </div>
        
        <div class="row">
            <div class="col-12">
                <b class="header-font">
                    Systems Descriptions : </b>
                <table class="content-align"><br>
                    <TR>
                       <TD><b>ANNUAL ACCESS REVIEW SYSTEMS</b></TD>
                        <TD><b>DESCRIPTION OF SYSTEM</b></TD> 
                    </TR>
                    <TR>
                        <TD>CMMS</TD>
                        <TD>MP&amp;L System for tracking quality,part status</TD>
                    </TR>
                    <TR>
                        <TD>CPARS</TD>
                        <TD>Non-Production purchasing-requisitions,purchasing and blanket PO's
                        </TD>
                    </TR>
                    <TR>
                        <TD>DOCMAN</TD>
                        <TD>Purchasing-PO Document storage</TD>
                    </TR>
                    <TR>
                        <TD>DOLVOL</TD>
                        <TD>Non-Production purchasing queries, and reports for cost data</TD>
                    </TR>
                    <TR>
                        <TD>eRMA</TD>
                        <TD>Raw Materials purchasing system</TD>
                    </TR>
                    <TR>
                        <TD>eVEREST</TD>
                        <TD>Production Purchasing -Inquiry only<BR>
                             Non-Production purchasing-requisitions,purchasing and blanket PO's
                        </TD>
                    </TR>
                    <TR>
                        <TD>MTOS</TD>
                        <TD>Production purchasing tool order system</TD>
                    </TR>
                    <TR>
                        <TD>PART SPEC/BOSS90</TD>
                        <TD>Non-Production inventory to maintain items</TD>
                    </TR>
                    <TR>
                        <TD>VCI</TD>
                        <TD>Purchasing reporting tool</TD>
                    </TR>
                    <TR>
                        <TD>WERS</TD>
                        <TD>Engineering release system to release parts</TD>
                    </TR>
                    <TR>
                        <TD>WIPS ADHOC</TD>
                        <TD>Production purchasing queries and reports for cost data</TD>
                    </TR>
                </table>
            </div>
        </div>
    </div>
</div>    

