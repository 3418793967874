import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FsnRegistrationService } from '../service/fsn-registration.service';
import { MemReg } from 'src/app/models/perl-migration-web/fsn-registration/mem-reg.model';
import { error } from 'console';

@Component({
  selector: 'app-fsn-registration',
  templateUrl: './fsn-registration.component.html',
  styleUrls: ['./fsn-registration.component.scss']
})
export class FsnRegistrationComponent {

  formDisabled: boolean = false;
  resultStatus: String = "invalid";
  registrationCheckForm :FormGroup;
  memReg!: MemReg;
  siteCode: String ='';

  constructor(private fb:FormBuilder, private apiService: FsnRegistrationService){
      this.registrationCheckForm = this.fb.group({
        siteCode:['',[Validators.required,Validators.maxLength(5),Validators.minLength(4)]]
      })
  }

  redirectToForm(event:Event){
      event.preventDefault();
      this.formDisabled= false;
      console.log(this.formDisabled);
  }

  onSubmit(){
      const siteCode:String = this.registrationCheckForm.get('siteCode')?.value;
      this.getMemRegDetails(siteCode);
      
  }

  getMemRegDetails(code:String){
      this.apiService.getMemRegDetails(code).subscribe((data: MemReg)=>{
        this.formDisabled=true;
        this.memReg = data;
        this.siteCode = code;
        if(this.memReg.registered){
          this.resultStatus='registered';
        }else{
          this.resultStatus='unregistered';
        }
      },error=>{
        console.log(error);
        this.resultStatus='invalid'
      })
  }

}
