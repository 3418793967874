import {Component, inject} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-critical-information',
  templateUrl: './critical-information.component.html',
  styleUrls: ['./critical-information.component.scss']
})
export class CriticalInformationComponent {
  readonly dialogRef = inject(MatDialogRef<CriticalInformationComponent>);

  returnToView() {
    this.dialogRef.close()
  }
}
