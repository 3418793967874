<div class="approver-container">

  <form [formGroup]="newApproverForm">
    <table>
      <thead>
      <tr>
        <th class="table-data text-white">Enter the Name of the Approver to be deleted or replaced</th>
        <th class="table-data text-white">Enter the Replacement Approver CDSID</th>
      </tr>
      <tr>
        <td class="table-data p-2"><input class="input" type="text" formControlName="approverCdsId"
                                          (input)="displayUpper('approverCdsId')"></td>
        <td class="table-data p-2"><input class="input" type="text" formControlName="replaceCdsId"
                                          (input)="displayUpper('replaceCdsId')"></td>
      </tr>
      </thead>
    </table>
    <p *ngIf="isError" class="text-danger text-sm mt-2 font-bold">The Approver Name entered was not found in the data
      displayed for your chains. Please enter valid Approver CDSID.</p>
    <div class="flex justify-center mt-2">
      <button class="approver-btn mr-2" (click)="delete()">Delete</button>
      <button class="approver-btn mr-2 ml-2" (click)="replace()">Replace</button>
      <button class="approver-btn ml-2" (click)="cancel()">Cancel</button>
    </div>
  </form>
</div>
