import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EverestTokenDetails } from 'src/app/models/perl-migration-web/everest-token/everest-token-details.model';
import { SiteCodes } from 'src/app/models/perl-migration-web/everest-token/site-codes.model';


@Injectable({
  providedIn: 'root'
})
export class EverestTokenServiceService {

  private url = environment.perlMigrationApiUrl

  constructor(private http: HttpClient) { }

  getSiteCodeBySupplierCode(supplier:String): Observable<String[]>{
      return this.http.get<String[]>(this.url+'/api/v1/getSiteCodeBySupplierCode?supplier='+supplier);
  }

  getEverestTokenDetails(sitecode:String) : Observable<EverestTokenDetails>{
    return this.http.get<EverestTokenDetails>(this.url+'/api/v1/everestTokenLookup?sitecode='+sitecode);
  }

  getListOfSiteCodes(file:File,email:String):Observable<SiteCodes>{
    const formData = new FormData();
    formData.append('file',file,file.name);
    formData.append('email', email.valueOf());

    return this.http.post<SiteCodes>(this.url+'/api/v1/everestTokenLookup/uploadFile',formData,{
      reportProgress: true
    })
  }

  getBulkTokenReport(siteCodes:SiteCodes):Observable<String>{
    return this.http.post<String>(this.url+'/api/v1/everestTokenLookup/bySiteCodeList',siteCodes,
      {withCredentials: true, responseType:'text' as 'json'});
  }
}
