<div class="controller-container">
  <p class="text-center heading">Provide the CDSID of the New Controller/Designee who should complete this review</p>
  <form [formGroup]="newControllerForm" (ngSubmit)="replace()">
    <div class="flex justify-content-center">
      <input class="input" type="text" formControlName="newController" (input)="displayUpperNcc()"/>
    </div>
    <div class="flex justify-center mt-3">
      <button class="mr-2 btn-controller" type="submit" [disabled]="newControllerForm.invalid">Replace</button>
      <button class="ml-2 btn-controller" type="button" (click)="cancel()">Cancel</button>
    </div>
  </form>
</div>
