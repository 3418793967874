import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import {catchError, finalize, Observable, throwError} from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingComponent } from '../common/loading/loading.component';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  dialogRef: MatDialogRef<LoadingComponent> | undefined;

  constructor(private dialog: MatDialog, private router:Router) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Retrieve the token from session storage
    const token = sessionStorage.getItem('encodedAccessToken');
    this.openLoadingDialog();

    // If the token exists, clone the request and add the Authorization header
    if (token) {
      const clonedRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
      return next.handle(clonedRequest).pipe(
        catchError((error: HttpErrorResponse)=>{

              if(error.status === 0){
                this.router.navigate(['/server-error']);
              }
              return throwError(error);
        }),
        finalize(() =>{
          this.closeLoadingDialog();
        })
      );
    }

    // If no token is found, continue with the original request
    return next.handle(request).pipe(
      finalize(() =>{
        this.closeLoadingDialog();
      })
    );
  }

  private openLoadingDialog(): void{
    if(!this.dialogRef){
      this.dialogRef = this.dialog.open(LoadingComponent,{
        disableClose: true,
        panelClass: 'loading-dialog'
      });
    }
  }

  private closeLoadingDialog():void{
    if(this.dialogRef){
      this.dialogRef.close();
      this.dialogRef=undefined;
    }
  }
}
