<div class="supplements-container">
  <p class="heading pl-1">Supplements - Indirect</p>
  <div>
    <table class="m-2 table-container" border="1" cellpadding="3" cellspacing="0">
      <tr>
        <th>Effective Date</th>
        <th>Document Name</th>
        <th>Description</th>
      </tr>
      <tr>
        <td>12/01/2007</td>
        <td><a class="color" (click)="getGtcSupplementalTermsChinaPdf()">GTC Supplemental Terms - China </a></td>
        <td>(written in Chinese/English)</td>
      </tr>
      <tr>
        <td>11/01/2014</td>
        <td><a class="color" (click)="getGtcSupplementalTermsCanadaPdf()">GTC Supplemental Terms - Canada</a></td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>08/01/2022</td>
        <td><a class="color" (click)="getGtcSupplementalTermsAustraliaDoc()">GTC Supplemental
          Terms - Australia</a></td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>12/01/2007</td>
        <td><a class="color" (click)="getGtcSupplementalTermsIndiaPdf()">GTC Supplemental
          Terms - India</a></td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>12/01/2007</td>
        <td>
          <a class="color" (click)="getGtcSupplementalTermsBrazilPdf()">GTC
            Supplemental Terms - Brazil</a></td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>12/01/2007</td>
        <td><a class="color" (click)="getGtcSupplementalTermsMexicoPdf()">GTC Supplemental Terms - Mexico</a></td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>10/15/2008</td>
        <td><a class="color" (click)="getGtcSupplementalTermsGreecePdf()">GTC Supplemental Terms - Greece</a></td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>12/11/2009</td>
        <td><a class="color" (click)="getIndividualCollectiveLaborRelEng()">Individual and Collective Labor Relations
          (English)</a></td>
        <td>&nbsp;</td>
      </tr>

      <tr>
        <td>12/11/2009</td>
        <td><a class="color" (click)="getIndividualCollectiveLaborRelSpanish()">Individual and Collective Labor
          Relations (Spanish)</a>
        </td>
        <td>&nbsp;</td>
      </tr>

      <tr>
        <td>11/02/2017</td>
        <td><a class="color" (click)="getGTCSupplementalTermsEnglish()">GTC Supplemental Terms-Spain(English)</a></td>
        <td>&nbsp;</td>
      </tr>

      <tr>
        <td>11/02/2017</td>
        <td><a class="color" (click)="getGTCSupplementalTermsSpanish()">GTC Supplemental Terms-Spain(Spanish)</a></td>
        <td>&nbsp;</td>
      </tr>
    </table>
  </div>
</div>
