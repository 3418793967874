import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AribaOnlineService {

  private url = environment.perlMigrationApiUrl

  constructor(private http: HttpClient) {
  }

  getOtp(aribaId:String, emailID:String):Observable<String>{
    return this.http.post<String>(this.url+'/api/v1/otpGeneration?aribaUserId='+aribaId+'&emailId='+emailID,null
      ,{withCredentials: true}
    );
  }

  validateOtp(otp:String):Observable<String>{
    return this.http.get<String>(this.url+'/api/v1/otpValidation?otp='+otp,{withCredentials: true});
  }
}
