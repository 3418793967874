<app-common-header></app-common-header>
<div class="container-fluid">
    <div class="row">
        <div class="page-title">
            <b>Data Loader</b>
        </div>
    </div>

    <div class="row" >
        <div class="col-9">
            <div class="row font1">
                <b>
                    &nbsp;&nbsp;Upload Your File for Processing
                </b>
            </div>
            <div class="row">
            </div>
            <div class="row font1">
                    <b>Click the "Browse" button to select a file from your computer and<BR>
                    </b>
            </div>
            <div class="row">
                <div>
                    <form [formGroup]="uploadFileGroup" (ngSubmit)="onSubmit()">
                        <div class="font2">
                            <input class="input-file" type="file" (change)="onFileUpload($event)">
                        </div>
                        <div class="font3">
                            <label for="continue"><b>Click the upload file button to continue : &nbsp;</b></label>
                            <button type="submit" class="btn btn-primary btn-sm"
                             [disabled]="uploadFileGroup.invalid">upload and run </button>&nbsp; &nbsp;
                            
                        </div>
                    </form>
                </div>
            </div>
            <div class="row">
                <div>
                    <br><hr>
                </div> 

                <div class="font1" [hidden] = "formDisabled">
                    <br><b class="text-danger">Upload Result </b> : <b>{{this.filename}} File uploaded successfully for data loader. </b> <br>
                </div>
                
            </div>
        </div>        
    </div>
</div>