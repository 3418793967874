import { Component } from '@angular/core';

@Component({
  selector: 'app-indirect-home',
  templateUrl: './indirect-home.component.html',
  styleUrls: ['./indirect-home.component.scss']
})
export class IndirectHomeComponent {

}
