import {Component, inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PerlMigrationService} from '../service/perl-migration.service';
import {Dialog} from "@angular/cdk/dialog";
import {PopUpComponent} from "../../common/pop-up/pop-up.component";

@Component({
  selector: 'app-ford-people-search',
  templateUrl: './ford-people-search.component.html',
  styleUrls: ['./ford-people-search.component.scss']
})
export class FordPeopleSearchComponent {
  dialog = inject(Dialog);
  fordDetailsForm: FormGroup;
  blobUrl!: string;

  constructor(private fb: FormBuilder, private apiService: PerlMigrationService) {
    this.fordDetailsForm = this.fb.group(({
      peopleName: ['', [Validators.required]]
    }))
  }

  onSubmit() {
    const cdsId: String = this.fordDetailsForm.get('peopleName')?.value;
    this.getPeopleDetails(cdsId);
  }

  getPeopleDetails(cdsId: String) {
    this.apiService.getFordPeopleSearch(cdsId).subscribe((data) => {
      const blob = new Blob([data], {type: 'application/xml'});
      this.blobUrl = URL.createObjectURL(blob);
      this.fordDetailsForm.reset();
      window.location.href = this.blobUrl;
    }, error => {
      const message: string = error.error;
      if(error.status !==0){
        this.dialog.open(PopUpComponent, {
          maxWidth: '500px',
          data: {
            message: message,
          },
        })
      }
    })
  }
}
