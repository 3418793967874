import { Component } from '@angular/core';

@Component({
  selector: 'app-gsdb-auth-denied',
  templateUrl: './gsdb-auth-denied.component.html',
  styleUrls: ['./gsdb-auth-denied.component.scss']
})
export class GsdbAuthDeniedComponent {

}
