import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EverestTokenServiceService } from '../service/everest-token-service.service';
import { EverestTokenDetails } from 'src/app/models/perl-migration-web/everest-token/everest-token-details.model';

@Component({
  selector: 'app-everest-token-select-sitecode',
  templateUrl: './everest-token-select-sitecode.component.html',
  styleUrls: ['./everest-token-select-sitecode.component.scss']
})
export class EverestTokenSelectSitecodeComponent {

  siteCodes : String[] =[];

  SiteCodeForm : FormGroup;

  everestTokenDetails: EverestTokenDetails | null=null;

  constructor(private router: Router, private fb: FormBuilder, private dataService: EverestTokenServiceService){
    const navigation = this.router.getCurrentNavigation();
    
    if(navigation!=null){
      const state = navigation.extras.state as {data:{siteCodes: String[]}} ;
      const response: String[] = state.data.siteCodes;
      if(response.length!=0){
        this.siteCodes = response;
      }
    }  
    
    this.SiteCodeForm = this.fb.group({
        site:['',Validators.required]
      });
      this.SiteCodeForm.controls['site'].setValue(this.siteCodes[0],{onlySelf:true});


    
  }

  onSubmit(){
    const sitecode = this.SiteCodeForm.get('site')?.value;
    this.fetchEverestTokenDetails(sitecode);
  }

  fetchEverestTokenDetails(sitecode:String){
    this.dataService.getEverestTokenDetails(sitecode).subscribe(
      (data: EverestTokenDetails)=>{
      this.everestTokenDetails = data;
      this.navigateToEverestTokeDetails();
    },error =>{
      console.error('Error fetching the site codes',error);
    });
  }

  navigateToEverestTokeDetails(){
    const data = {type:'code',tokenDetails: this.everestTokenDetails}
    this.router.navigate(["/perlMigrationWeb/everstTokenLookup/lookupReport"],{state:{data}});
  }


}
