<div class="flex justify-center items-center min-h-6 pt-2 pb-2 header-container">
  <a class="cursor-pointer font-bold navigation"
     href="https://fsp.covisint.com">Ford Supplier Portal</a>
  <img class="ml-3" src="assets/icon_cov_tab_divider.gif"/>
  <a class="ml-3 cursor-pointer font-bold navigation"
     href="https://portal.covisint.com/wps/private/lang/en/_pagr/108/_pa.108/121"> Applications</a>
  <img class="ml-3" src="assets/icon_cov_tab_divider.gif"/>
  <a class="ml-3 cursor-pointer font-bold navigation"
     href="https://portal.covisint.com/wps/private/lang/en/_pagr/108/_pa.108/122"> Communities</a>
  <img class="ml-3" src="assets/icon_cov_tab_divider.gif"/>
  <a class="ml-2 cursor-pointer font-bold navigation"
     href="/support">Help</a>
</div>
