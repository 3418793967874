import {Component, inject} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-self-removal-success',
  templateUrl: './self-removal-success.component.html',
  styleUrls: ['./self-removal-success.component.scss']
})
export class SelfRemovalSuccessComponent {
  readonly dialogRef = inject(MatDialogRef<SelfRemovalSuccessComponent>);

  returnToView() {
    this.dialogRef.close()
  }
}
