<app-common-header></app-common-header>
<div class = "everest-token-home">
    <table class="layout-table">
        <tr>
            <td>
                <div class="page-title">
                    <b>EVEREST Supplier Token Status Application</b>
                </div>
            </td>
        </tr>

        <tr>
            <td colspan=2 class="td1">
                <table class="table1">
                    <tr valign="top"> 
                        <td align="center"> 
                            <table class="table2">
                                <tr> 
                                 <td align="center"> 
                                      <p class="font1"><b>
                                          &nbsp;&nbsp;Please select one of the following options<br>
                                      </b></p>    
                                    </td>
                                </tr>
                            </table> 
                            <table class="table2">
                                <tr align="left">
                                    <td class="selection"></td>
                                    <td class="selection">
                                        <form [formGroup]="tokenLookupTypeForm" (ngSubmit)="onSubmit()">
                                            <div class="form-group">
                                                <label for="uploadFile">
                                                    <input type="radio" id="uploadFile" formControlName="typeSelect" name="typeSelect" value="uploadFile">
                                                    <b>&nbsp; Check the eVEREST token status of a set of <br>GSDB Site codes</b><br>&nbsp;
                                                </label>
                                            </div>
                                            <div class="form-group">
                                                <label for="siteCode">
                                                    <input type="radio" id="siteCode" formControlName="typeSelect" name="typeSelect" value="siteCode">
                                                    <b> &nbsp;Check the eVEREST token status of a single <br>GSDB Site code</b><br>
                                                </label>
                                            </div>
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="selection"></td>
                                    <td class="button">
                                        <button class="bg-blue-500 text-white py-2 px-4 rounded" type="submit" [disabled]= "tokenLookupTypeForm.get('typeSelect')?.invalid"(click)="onSubmit()" >Next &gt;&gt;</button><br>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>  
                    <tr>
                        <td>
                            <hr class="hr">
                        </td>
                    </tr>   
                    
                    <tr> 
                        <td align="center" colspan="3"> 
                            <table class="table3" >
                            <tr> 
                                <td class="font2" colspan="3"><b>This 
                                    Web Application allows you to upload a set of GSDB Site Codes or input a single GSDB 
                                    Site Code and get the following information:</b><br>
                                </td>
                            </tr>
                            <tr> 
                                <td align="center" colspan="3"> 
                                    <ul class="ul">
                                        <li><b>Is there at least one user that has the eVEREST User Token for the GSDB Site Code?</b></li>
                                    </ul>
                                </td>
                            </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>      

    </table>
</div>