export const stateOrProvice = [
  "AGUASCALIENTES", "ALABAMA", "ALASKA", "ALBERTA", "AMAZONAS", "ANTIOQUIA", "ANZOATEGUI",
  "ARAGUA", "ARAUCA", "ARIZONA", "ARKANSAS", "ATLANTICO", "AUSTRALIA CAPITAL TERRITORY",
  "BAJA CALIFORNIA", "BAJA CALIFORNIA SUR", "BARINAS", "BOLIVAR", "BOYACA", "BRITISH COLUMBIA",
  "CALDAS", "CALIFORNIA", "CAMPECHE", "CANAL ZONE", "CAPE PROVINCE", "CAQUETA", "CARABOBO",
  "CASANARE", "CAUCA", "CESAR", "CHANNEL ISLANDS", "CHIAPAS", "CHIHUAHUA", "CHOCO", "COAHUILA",
  "COLIMA", "COLORADO", "CONNECTICUT", "CORDOBA", "CUNDINAMARCA", "DELAWARE", "DELTA AMACURO",
  "DIST. OF COLUMBIA", "DISTRITO FEDERAL", "DURANGO", "ESTADO DE MEXICO", "FALCON", "FLORIDA",
  "GEORGIA", "GUAINIA", "GUAM", "GUANAJUATO", "GUARICO", "GUAVIARE", "GUERRERO", "HAWAII",
  "HIDALGO", "HUILA", "IDAHO", "ILLINOIS", "INDIANA", "IOWA", "ISLE OF MAN", "JALISCO", "KANSAS",
  "KENTUCKY", "LA GUAJIRA", "LABRADOR", "LARA", "LOUISIANA", "MAGDALENA", "MAINE", "MANITOBA",
  "MARYLAND", "MASSACHUSETTS", "MERIDA", "META", "MICHIGAN", "MICHOACAN", "MINNESOTA", "MIRANDA",
  "MISSISSIPPI", "MISSOURI", "MONAGAS", "MONTANA", "MORELOS", "NARINO", "NATAL", "NAYARIT",
  "NEBRASKA", "NEVADA", "NEW BRUNSWICK", "NEW HAMPSHIRE", "NEW JERSEY", "NEW MEXICO",
  "NEW SOUTH WALES", "NEW YORK", "NEWFOUNDLAND", "NORTE DE SANTANDER", "NORTH CAROLINA",
  "NORTH DAKOTA", "NORTHERN IRELAND", "NORTHERN TERRITORY", "NORTHWEST TERRITORIES",
  "NOVA SCOTIA", "NUEVA ESPARTA", "NUEVO LEON", "OAXACA", "OHIO", "OKLAHOMA", "ONTARIO",
  "ORANGE FREE STATE", "OREGON", "PENNSYLVANIA", "PORTUGUESA", "PRINCE EDWARD ISLAND", "PUEBLA",
  "PUERTO RICO", "PUTUMAYO", "QUEBEC", "QUEENSLAND", "QUERETARO", "QUINDIO", "QUINTANA ROO",
  "RHODE ISLAND", "RISARALDA", "SAN LUIS POTOSI", "SANTANDER", "SASKATCHEWAN", "SINALOA",
  "SONORA", "SOUTH AUSTRALIA", "SOUTH CAROLINA", "SOUTH DAKOTA", "SUCRE", "TABASCO", "TACHIRA",
  "TAMAULIPAS", "TASMANIA", "TENNESSEE", "TEXAS", "TLAXCALA", "TOLIMA", "TRANSVAAL", "TRUJILLO",
  "UTAH", "VALLE DEL CAUCA", "VAUPES", "VERACRUZ", "VERMONT", "VICHADA", "VICTORIA",
  "VIRGIN ISLANDS", "VIRGINIA", "WASHINGTON", "WEST VIRGINIA", "WESTERN AUSTRALIA", "WISCONSIN",
  "WYOMING", "YARACUY", "YUCATAN", "YUKON TERRITORY", "ZACATECAS", "ZULIA"
]

export const countries = [
  "AFGHANISTAN", "ALBANIA", "ALGERIA", "AMERICAN SAMOA", "ANDORRA", "ANGOLA", "ANGUILLA",
  "ANTARCTICA", "ANTIGUA AND BARBUDA", "ARGENTINA", "ARUBA", "AUSTRALIA", "AUSTRIA", "AZERBAIJAN",
  "BAHAMAS", "BAHRAIN", "BANGLADESH", "BARBADOS", "BELGIUM", "BELIZE", "BENIN", "BERMUDA", "BHUTAN",
  "BOLIVIA", "BOSNIA-HERZEGOVINA", "BOTSWANA", "BOUVET ISLAND", "BRASIL", "BRITISH INDIAN OCEAN TERR.",
  "BRUNEI", "BULGARIA", "BURMA", "BURUNDI", "CAMBODIA", "CAMEROON", "CANADA", "CAPE VERDE",
  "CAYMAN ISLANDS", "CENTRAL AFRICAN REPUBLIC", "CHAD", "CHILE", "CHRISTMAS ISLAND",
  "COCOS (KEELING) ISLANDS", "COLUMBIA", "COMOROS", "CONGO", "COOK ISLANDS", "COSTA RICA",
  "CROATIA", "CUBA", "CYPRUS", "CZECH REPUBLIC", "CZECHOSLOVAKIA", "DENMARK", "DJIBOUTI",
  "DOMINICA", "DOMINICAN REPUBLIC", "EAST TIMOR", "ECUADOR", "EGYPT", "EL SALVADOR",
  "EQUATORIAL GUINEA", "ESTONIA", "ETHIOPIA", "EUROPEAN COMMUNITY", "FAEROE ISLANDS",
  "FALKLAND ISLANDS", "FIJI", "FINLAND", "FRANCE", "FRENCH GUYANA", "FRENCH POLYNESIA",
  "FRENCH SOUTHERN TERRITORIES", "GAMBIA", "GERMANY", "GHANA", "GIBRALTAR", "GREECE",
  "GREENLAND", "GRENADA", "GUADELOUPE", "GUAM", "GUATEMALA", "GUINEA", "GUINEA BISSAU",
  "GUYANA", "HAITI", "HEARD AND MCDONALD ISLANDS", "HONDURAS", "HONG KONG", "HUNGARY", "ICELAND",
  "INDIA", "INDONESIA", "IRAN", "IRAQ", "ISRAEL", "ITALY", "IVORY COAST", "JAMAICA", "JAPAN",
  "JOHNSTON ISLAND", "JORDAN", "KENYA", "KIRIBATI", "KOREA", "KOREA (NORTH)", "KUWAIT", "KYRGYSTAN",
  "LAOS", "LATVIA", "LEBANON", "LESOTHO", "LIBERIA", "LIBYA", "LIECHTENSTEIN", "LITHUANIA",
  "LUXEMBOURG", "MACAU", "MACEDONIA", "MADAGASCAR", "MALAWI", "MALAYSIA", "MALDIVES", "MALI",
  "MALTA", "MARTINIQUE", "MAURITANIA", "MAURITIUS", "MEXICO", "MIDWAY ISLANDS", "MONACO",
  "MONGOLIA", "MONSERRAT", "MOROCCO", "MOZAMBIQUE", "NAMIBIA", "NAURU", "NEPAL", "NETHERLANDS",
  "NEUTRAL ZONE", "NEW CALEDONIA", "NEW GUINEA", "NEW ZEALAND", "NICARAGUA", "NIGER", "NIGERIA",
  "NIUE", "NORFOLK ISLAND", "NORTH MARIANA ISLAND", "NORWAY", "PACIFIC ISLANDS TRUST TERR.",
  "PAKISTAN", "PAPUA NEW GUINEA", "PARAGUAY", "PEOPLES REPUBLIC CHINA", "PERU", "PHILIPPINE REPUBLIC",
  "PITCAIRN ISLANDS", "POLAND", "PORTUGAL", "PUERTO RICO", "QATAR", "REPUBLIC OF BELARUS",
  "REPUBLIC OF GABON", "REPUBLIC OF IRELAND", "REPUBLIC OF PANAMA", "REUNION", "ROMANIA", "RUSSIA",
  "RWANDA", "SAINT CHRISTOPHER-NEVIS", "SAINT LUCIA", "SAMOA", "SAN MARINO", "SAO TOME AND PRINCIPE",
  "SAUDI ARABIA", "SENEGAL", "SERBIA", "SEYCHELLES", "SIERRA LEONE", "SINGAPORE", "SLOVAKIA",
  "SLOVENIA", "SOLOMON ISLANDS", "SOMALIA", "SOUTH AFRICA", "SPAIN", "SPANISH NORTH AFRICA",
  "SRI LANKA", "ST VINCENT AND THE GRENADINES", "ST. HELENA", "ST. PIERRE ET MIQUELON", "SUDAN",
  "SULTANATE OF OMAN", "SURINAM", "SVALBARD AND JAN MAYEN ISLAND", "SWAZILAND", "SWEDEN",
  "SWITZERLAND", "SYRIA", "TAIWAN", "TANZANIA", "THAILAND", "TOGO", "TOKELAU", "TONGA",
  "TRINIDAD AND TOBAGO", "TUNISIA", "TURKEY", "TURKMENISTAN", "TURKS AND CAICOS ISLANDS",
  "TUVALU", "U.S.A.", "UGANDA", "UKRAINE", "UNITED ARAB EMIRATES", "UNITED KINGDOM", "UPPER VOLTA",
  "URUGUAY", "US MISC. PACIFIC ISLANDS", "USSR", "UZBEKISTAN", "VANUATU", "VATICAN CITY",
  "VENEZUELA", "VIETNAM", "VIRGIN ISLANDS (BRITISH)", "VIRGIN ISLANDS (US)", "WAKE ISLAND",
  "WALLIS AND FUTUNA ISLANDS", "WEST INDIES (FRENCH)", "WESTERN SAHARA", "YEMEN", "YUGOSLAVIA",
  "ZAIRE", "ZAMBIA", "ZIMBABWE"
]
