import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {GsdbContactsRequestBody} from "../../../models/perl-migration-web/gsdb-online/gsdbContacts";
import {Observable} from 'rxjs';
import {GsdbContactsMain} from "../../../models/perl-migration-web/gsdb-contacts-main/gsdb-contacts-main.model";

@Injectable({
  providedIn: 'root'
})
export class GsdbContactsService {
  private url = environment.perlMigrationApiUrl
  private contactResults: any

  constructor(private http: HttpClient) {
  }

  getGsdbContactResults(gsdbContactsRequestBody: GsdbContactsRequestBody) {
    return this.http.post<any>(this.url + '/api/v1/gsdbContacts', gsdbContactsRequestBody)
  }

  getContactResults() {
    return this.contactResults
  }

  setContactResults(contactResults: any) {
    this.contactResults = contactResults
  }

  getContactDetails(param: String): Observable<GsdbContactsMain> {
    return this.http.get<GsdbContactsMain>(this.url + '/api/v1/getGsdbConstants?param=' + param);
  }

  updateContactDetails(requestBody: GsdbContactsMain): Observable<string> {
    return this.http.put<string>(this.url + '/api/v1/updateGsdbConstants', requestBody,
      {responseType: 'text' as 'json'});
  }
}
