<app-gsdb-online-header></app-gsdb-online-header>
<div class="body ml-8 mr-8 mt-8">
  <p class="para-title pl-4 mt-14"><b> GSDB Online Steps > Introduction :: Nomination ::
    Indemnification :: <span class="approval">Approval</span></b></p>
  <p>Your authorization to allow access to GSDB Online has been successfully submitted.</p>
  <p class="mb-0"> Your CSA will be notified by a Covisint email within 72 hours that electronic access has been granted to your
    company and he/she will be able to grant access to users to be able to view and update your company information in
    GSDB Online.</p>
  <p class="mt-0">To obtain additional information about GSDB Online, please access the <a class="nav-link" href="/support">Ford Help Desk Support</a>.
  </p>
  <p>Thank you,</p>
  <p class="mb-0">GSDBWEB</p>
  <p class="mt-0">Ford Motor Company</p>
  <hr class="mt-6 mb-4">
  <div class="mb-4 font-size">
    <p class="m-0 text-center">Questions about GSDB Online?</p>
    <p class="m-0 text-center">Contact <span class="text-decoration-underline">gsdbweb@ford.com</span> or access
      <a routerLink="/support" class="nav-link">Ford Help Desk Support</a></p>
    <p class="m-0 text-center">The customer support center is available 24 hours each day.</p>
  </div>
</div>

