import {Component, inject} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-review-completed',
  templateUrl: './review-completed.component.html',
  styleUrls: ['./review-completed.component.scss']
})
export class ReviewCompletedComponent {
  readonly dialogRef = inject(MatDialogRef<ReviewCompletedComponent>);

  exit() {
    this.dialogRef.close()
  }
}
