export class GsdbOnlineAuthResponse {
  public apiResponse: string = ''
  public companyName: string = ''
  public csaName: string = ''
  public gsdbCode: string = ''
  public lastname: string = ''
  public name: string = ''
  public phone: string = ''
  public pin: string = ''
  public positions: string = ''
  public status: string = ''
}

export class  GsdbOnlineInprocesRes{
  public status: string = ''
}
